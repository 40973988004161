@charset "UTF-8";
/*------------------------------------------------------------------
[CSS Table of Contents]

1. Bootstrap CSS
    1.1. Root
    1.2. Reboot
    1.3. Type
    1.4. Images
    1.5. Code
    1.6. Grid
    1.7. Tables
    1.8. Forms
    1.9. Buttons
    1.10. Transitions
    1.11. Dropdown
    1.12. Button-group
    1.13. Input-group
    1.14. Custom-forms
    1.15. Nav
    1.16. Navbar
    1.17. Card
    1.18. Breadcrumb
    1.19. Pagination
    1.20. Badge
    1.21. Jumbotron
    1.22. Alert
    1.23. Progress
    1.24. Media
    1.25. List-group
    1.26. Close
    1.27. Toasts
    1.28. Modal
    1.29. Tooltip
    1.30. Popover
    1.31. Carousel
    1.32. Spinners
    1.33. Utilities
    1.34. Print

2. MyTravel CSS
    2.1. Sizing
    2.2. Spaces
    2.3. Reboot
    2.4. List Group
    2.5. Headers
    2.6. Alert
    2.7. Avatar
    2.8. Badges
    2.9. Buttons
    2.10. Brand
    2.11. Breadcrumb
    2.12. Card
    2.13. Sidebar
    2.14. Clients
    2.15. Close
    2.16. Divider
    2.17. Dropdown
    2.18. Form Controls
    2.19. Google Map
    2.20. Go to Section
    2.21. Hamburger
    2.22. Hero Blocks
    2.23. Indicators
    2.24. Media
    2.25. Mockups
    2.26. Modal Window
    2.27. Page Preloader
    2.28. Pagination
    2.29. Nav
    2.30. News Blogs
    2.31. Progress
    2.32. Paging
    2.33. Quantity
    2.34. Search Forms
    2.35. Slick
    2.36. Spinner
    2.37. Stats
    2.38. SVG
    2.39. Table
    2.40. Timeline
    2.41. Sticky Block
    2.42. Utilities
    2.43. Vendor

3. User - Custom Style
-------------------------------------------------------------------*/
/*-------------------------------------------*\
    1. Bootstrap CSS
\*-------------------------------------------*/
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*-------------------------------------------*\
    1.1. Root
\*-------------------------------------------*/
:root {
  --blue: #377dff;
  --indigo: #2d1582;
  --purple: #8023df;
  --pink: #ffe5ee;
  --red: #de4437;
  --orange: #f05700;
  --yellow: #ffc107;
  --green: #28871c;
  --teal: #00c9a7;
  --cyan: #00dffc;
  --white: #fff;
  --gray: #8c98a4;
  --gray-dark: #343a40;
  --primary: #297cbb;
  --secondary: #77838f;
  --success: #00c9a7;
  --info: #00dffc;
  --warning: #ffc107;
  --danger: #de4437;
  --light: #f8f9fa;
  --dark: #3b444f;
  --white: #fff;
  --indigo: #2d1582;
  --gray: #ebf0f7;
  --violet: #532184;
  --violet-1: #a864a8;
  --violet-light: #6f13cd;
  --brown-light: #ceac61;
  --brown: #f8bd5b;
  --maroon: #c72f74;
  --indigo-light: #080e7b;
  --red-light-2: #ff3c4e;
  --blue-dark-1: #045cff;
  --gray-1: #67747c;
  --gray-2: #f7f8f9;
  --gray-3: #3b444f;
  --gray-4: #eceff3;
  --blue: #43c5db;
  --blue-1: #297cbb;
  --gray-5: #18181b;
  --gray-6: #18181a;
  --gray-7: #5c6770;
  --gray-8: #c5cdd8;
  --gray-9: #9fa9b8;
  --gray-10: #c1c1c1;
  --gray-11: #484848;
  --gray-23: #f4f4f4;
  --gray-33: #ebf0f7;
  --sea-green: #1d508d;
  --dark-1: #1e2022;
  --navy: #0057ff;
  --pink: #ffe5ee;
  --dark-2: #1a2b49;
  --yellow-lighter-2: #f7bc5a;
  --orange: #f05700;
  --green: #28871c;
  --pink-1: #ff0045;
  --purple-1: #232753;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-wd: 1480px;
  --font-family-sans-serif: "Rubik", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/*-------------------------------------------*\
    1.2. Reboot
\*-------------------------------------------*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3b444f;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #297cbb;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #1b527c;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8c98a4;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*-------------------------------------------*\
    1.3. Type
\*-------------------------------------------*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 300;
  line-height: 1.5;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e7eaf3;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #8c98a4;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

/*-------------------------------------------*\
    1.4. Images
\*-------------------------------------------*/
.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e7eaf3;
  border-radius: 0.3125rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #8c98a4;
}

/*-------------------------------------------*\
    1.5. Code
\*-------------------------------------------*/
code {
  font-size: 87.5%;
  color: #ffe5ee;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #3b444f;
  border-radius: 0.25rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #3b444f;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

/*-------------------------------------------*\
    1.6. Grid
\*-------------------------------------------*/
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1480px) {
  .container {
    max-width: 1320px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-wd-1, .col-wd-2, .col-wd-3, .col-wd-4, .col-wd-5, .col-wd-6, .col-wd-7, .col-wd-8, .col-wd-9, .col-wd-10, .col-wd-11, .col-wd-12, .col-wd,
.col-wd-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
      order: -1;
}

.order-last {
  -ms-flex-order: 13;
      order: 13;
}

.order-0 {
  -ms-flex-order: 0;
      order: 0;
}

.order-1 {
  -ms-flex-order: 1;
      order: 1;
}

.order-2 {
  -ms-flex-order: 2;
      order: 2;
}

.order-3 {
  -ms-flex-order: 3;
      order: 3;
}

.order-4 {
  -ms-flex-order: 4;
      order: 4;
}

.order-5 {
  -ms-flex-order: 5;
      order: 5;
}

.order-6 {
  -ms-flex-order: 6;
      order: 6;
}

.order-7 {
  -ms-flex-order: 7;
      order: 7;
}

.order-8 {
  -ms-flex-order: 8;
      order: 8;
}

.order-9 {
  -ms-flex-order: 9;
      order: 9;
}

.order-10 {
  -ms-flex-order: 10;
      order: 10;
}

.order-11 {
  -ms-flex-order: 11;
      order: 11;
}

.order-12 {
  -ms-flex-order: 12;
      order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1480px) {
  .col-wd {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%;
  }
  .col-wd-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-wd-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-wd-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-wd-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
  }
  .col-wd-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-wd-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-wd-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
  }
  .col-wd-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-wd-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-wd-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%;
  }
  .col-wd-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-wd-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-wd-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
  }
  .order-wd-first {
    -ms-flex-order: -1;
        order: -1;
  }
  .order-wd-last {
    -ms-flex-order: 13;
        order: 13;
  }
  .order-wd-0 {
    -ms-flex-order: 0;
        order: 0;
  }
  .order-wd-1 {
    -ms-flex-order: 1;
        order: 1;
  }
  .order-wd-2 {
    -ms-flex-order: 2;
        order: 2;
  }
  .order-wd-3 {
    -ms-flex-order: 3;
        order: 3;
  }
  .order-wd-4 {
    -ms-flex-order: 4;
        order: 4;
  }
  .order-wd-5 {
    -ms-flex-order: 5;
        order: 5;
  }
  .order-wd-6 {
    -ms-flex-order: 6;
        order: 6;
  }
  .order-wd-7 {
    -ms-flex-order: 7;
        order: 7;
  }
  .order-wd-8 {
    -ms-flex-order: 8;
        order: 8;
  }
  .order-wd-9 {
    -ms-flex-order: 9;
        order: 9;
  }
  .order-wd-10 {
    -ms-flex-order: 10;
        order: 10;
  }
  .order-wd-11 {
    -ms-flex-order: 11;
        order: 11;
  }
  .order-wd-12 {
    -ms-flex-order: 12;
        order: 12;
  }
  .offset-wd-0 {
    margin-left: 0;
  }
  .offset-wd-1 {
    margin-left: 8.33333%;
  }
  .offset-wd-2 {
    margin-left: 16.66667%;
  }
  .offset-wd-3 {
    margin-left: 25%;
  }
  .offset-wd-4 {
    margin-left: 33.33333%;
  }
  .offset-wd-5 {
    margin-left: 41.66667%;
  }
  .offset-wd-6 {
    margin-left: 50%;
  }
  .offset-wd-7 {
    margin-left: 58.33333%;
  }
  .offset-wd-8 {
    margin-left: 66.66667%;
  }
  .offset-wd-9 {
    margin-left: 75%;
  }
  .offset-wd-10 {
    margin-left: 83.33333%;
  }
  .offset-wd-11 {
    margin-left: 91.66667%;
  }
}

/*-------------------------------------------*\
    1.7. Tables
\*-------------------------------------------*/
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #3b444f;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e7eaf3;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e7eaf3;
}

.table tbody + tbody {
  border-top: 2px solid #e7eaf3;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e7eaf3;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e7eaf3;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8fafd;
}

.table-hover tbody tr:hover {
  color: #3b444f;
  background-color: rgba(231, 234, 243, 0.4);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c3daec;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #90bbdc;
}

.table-hover .table-primary:hover {
  background-color: #b0cee6;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b0cee6;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d9dce0;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b8bfc5;
}

.table-hover .table-secondary:hover {
  background-color: #cbcfd5;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cbcfd5;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f0e6;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ae3d1;
}

.table-hover .table-success:hover {
  background-color: #a3ecdf;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a3ecdf;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f6fe;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7aeefd;
}

.table-hover .table-info:hover {
  background-color: #9ff3fe;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #9ff3fe;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f6cbc7;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ee9e97;
}

.table-hover .table-danger:hover {
  background-color: #f2b7b1;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f2b7b1;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c8cbce;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #999ea3;
}

.table-hover .table-dark:hover {
  background-color: #bbbec2;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #bbbec2;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}

.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-indigo,
.table-indigo > th,
.table-indigo > td {
  background-color: #c4bddc;
}

.table-indigo th,
.table-indigo td,
.table-indigo thead th,
.table-indigo tbody + tbody {
  border-color: #9285be;
}

.table-hover .table-indigo:hover {
  background-color: #b5acd3;
}

.table-hover .table-indigo:hover > td,
.table-hover .table-indigo:hover > th {
  background-color: #b5acd3;
}

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #f9fbfd;
}

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #f5f7fb;
}

.table-hover .table-gray:hover {
  background-color: #e6eef7;
}

.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #e6eef7;
}

.table-violet,
.table-violet > th,
.table-violet > td {
  background-color: #cfc1dd;
}

.table-violet th,
.table-violet td,
.table-violet thead th,
.table-violet tbody + tbody {
  border-color: #a68cbf;
}

.table-hover .table-violet:hover {
  background-color: #c2b1d4;
}

.table-hover .table-violet:hover > td,
.table-hover .table-violet:hover > th {
  background-color: #c2b1d4;
}

.table-violet-1,
.table-violet-1 > th,
.table-violet-1 > td {
  background-color: #e7d4e7;
}

.table-violet-1 th,
.table-violet-1 td,
.table-violet-1 thead th,
.table-violet-1 tbody + tbody {
  border-color: #d2aed2;
}

.table-hover .table-violet-1:hover {
  background-color: #dec4de;
}

.table-hover .table-violet-1:hover > td,
.table-hover .table-violet-1:hover > th {
  background-color: #dec4de;
}

.table-violet-light,
.table-violet-light > th,
.table-violet-light > td {
  background-color: #d7bdf1;
}

.table-violet-light th,
.table-violet-light td,
.table-violet-light thead th,
.table-violet-light tbody + tbody {
  border-color: #b484e5;
}

.table-hover .table-violet-light:hover {
  background-color: #caa8ed;
}

.table-hover .table-violet-light:hover > td,
.table-hover .table-violet-light:hover > th {
  background-color: #caa8ed;
}

.table-brown-light,
.table-brown-light > th,
.table-brown-light > td {
  background-color: #f1e8d3;
}

.table-brown-light th,
.table-brown-light td,
.table-brown-light thead th,
.table-brown-light tbody + tbody {
  border-color: #e6d4ad;
}

.table-hover .table-brown-light:hover {
  background-color: #ebdec0;
}

.table-hover .table-brown-light:hover > td,
.table-hover .table-brown-light:hover > th {
  background-color: #ebdec0;
}

.table-brown,
.table-brown > th,
.table-brown > td {
  background-color: #fdedd1;
}

.table-brown th,
.table-brown td,
.table-brown thead th,
.table-brown tbody + tbody {
  border-color: #fbddaa;
}

.table-hover .table-brown:hover {
  background-color: #fce3b9;
}

.table-hover .table-brown:hover > td,
.table-hover .table-brown:hover > th {
  background-color: #fce3b9;
}

.table-maroon,
.table-maroon > th,
.table-maroon > td {
  background-color: #efc5d8;
}

.table-maroon th,
.table-maroon td,
.table-maroon thead th,
.table-maroon tbody + tbody {
  border-color: #e293b7;
}

.table-hover .table-maroon:hover {
  background-color: #e9b1cb;
}

.table-hover .table-maroon:hover > td,
.table-hover .table-maroon:hover > th {
  background-color: #e9b1cb;
}

.table-indigo-light,
.table-indigo-light > th,
.table-indigo-light > td {
  background-color: #babcda;
}

.table-indigo-light th,
.table-indigo-light td,
.table-indigo-light thead th,
.table-indigo-light tbody + tbody {
  border-color: #7f82ba;
}

.table-hover .table-indigo-light:hover {
  background-color: #a9acd1;
}

.table-hover .table-indigo-light:hover > td,
.table-hover .table-indigo-light:hover > th {
  background-color: #a9acd1;
}

.table-red-light-2,
.table-red-light-2 > th,
.table-red-light-2 > td {
  background-color: #ffc8cd;
}

.table-red-light-2 th,
.table-red-light-2 td,
.table-red-light-2 thead th,
.table-red-light-2 tbody + tbody {
  border-color: #ff9aa3;
}

.table-hover .table-red-light-2:hover {
  background-color: #ffafb6;
}

.table-hover .table-red-light-2:hover > td,
.table-hover .table-red-light-2:hover > th {
  background-color: #ffafb6;
}

.table-blue-dark-1,
.table-blue-dark-1 > th,
.table-blue-dark-1 > td {
  background-color: #b9d1ff;
}

.table-blue-dark-1 th,
.table-blue-dark-1 td,
.table-blue-dark-1 thead th,
.table-blue-dark-1 tbody + tbody {
  border-color: #7caaff;
}

.table-hover .table-blue-dark-1:hover {
  background-color: #a0c0ff;
}

.table-hover .table-blue-dark-1:hover > td,
.table-hover .table-blue-dark-1:hover > th {
  background-color: #a0c0ff;
}

.table-gray-1,
.table-gray-1 > th,
.table-gray-1 > td {
  background-color: #d4d8da;
}

.table-gray-1 th,
.table-gray-1 td,
.table-gray-1 thead th,
.table-gray-1 tbody + tbody {
  border-color: #b0b7bb;
}

.table-hover .table-gray-1:hover {
  background-color: #c6ccce;
}

.table-hover .table-gray-1:hover > td,
.table-hover .table-gray-1:hover > th {
  background-color: #c6ccce;
}

.table-gray-2,
.table-gray-2 > th,
.table-gray-2 > td {
  background-color: #fdfdfd;
}

.table-gray-2 th,
.table-gray-2 td,
.table-gray-2 thead th,
.table-gray-2 tbody + tbody {
  border-color: #fbfbfc;
}

.table-hover .table-gray-2:hover {
  background-color: #f0f0f0;
}

.table-hover .table-gray-2:hover > td,
.table-hover .table-gray-2:hover > th {
  background-color: #f0f0f0;
}

.table-gray-3,
.table-gray-3 > th,
.table-gray-3 > td {
  background-color: #c8cbce;
}

.table-gray-3 th,
.table-gray-3 td,
.table-gray-3 thead th,
.table-gray-3 tbody + tbody {
  border-color: #999ea3;
}

.table-hover .table-gray-3:hover {
  background-color: #bbbec2;
}

.table-hover .table-gray-3:hover > td,
.table-hover .table-gray-3:hover > th {
  background-color: #bbbec2;
}

.table-gray-4,
.table-gray-4 > th,
.table-gray-4 > td {
  background-color: #fafbfc;
}

.table-gray-4 th,
.table-gray-4 td,
.table-gray-4 thead th,
.table-gray-4 tbody + tbody {
  border-color: #f5f7f9;
}

.table-hover .table-gray-4:hover {
  background-color: #eaeef2;
}

.table-hover .table-gray-4:hover > td,
.table-hover .table-gray-4:hover > th {
  background-color: #eaeef2;
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #caeff5;
}

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #9de1ec;
}

.table-hover .table-blue:hover {
  background-color: #b5e9f1;
}

.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #b5e9f1;
}

.table-blue-1,
.table-blue-1 > th,
.table-blue-1 > td {
  background-color: #c3daec;
}

.table-blue-1 th,
.table-blue-1 td,
.table-blue-1 thead th,
.table-blue-1 tbody + tbody {
  border-color: #90bbdc;
}

.table-hover .table-blue-1:hover {
  background-color: #b0cee6;
}

.table-hover .table-blue-1:hover > td,
.table-hover .table-blue-1:hover > th {
  background-color: #b0cee6;
}

.table-gray-5,
.table-gray-5 > th,
.table-gray-5 > td {
  background-color: #bebebf;
}

.table-gray-5 th,
.table-gray-5 td,
.table-gray-5 thead th,
.table-gray-5 tbody + tbody {
  border-color: #878788;
}

.table-hover .table-gray-5:hover {
  background-color: #b1b1b2;
}

.table-hover .table-gray-5:hover > td,
.table-hover .table-gray-5:hover > th {
  background-color: #b1b1b2;
}

.table-gray-6,
.table-gray-6 > th,
.table-gray-6 > td {
  background-color: #bebebf;
}

.table-gray-6 th,
.table-gray-6 td,
.table-gray-6 thead th,
.table-gray-6 tbody + tbody {
  border-color: #878788;
}

.table-hover .table-gray-6:hover {
  background-color: #b1b1b2;
}

.table-hover .table-gray-6:hover > td,
.table-hover .table-gray-6:hover > th {
  background-color: #b1b1b2;
}

.table-gray-7,
.table-gray-7 > th,
.table-gray-7 > td {
  background-color: #d1d4d7;
}

.table-gray-7 th,
.table-gray-7 td,
.table-gray-7 thead th,
.table-gray-7 tbody + tbody {
  border-color: #aab0b5;
}

.table-hover .table-gray-7:hover {
  background-color: #c3c7cb;
}

.table-hover .table-gray-7:hover > td,
.table-hover .table-gray-7:hover > th {
  background-color: #c3c7cb;
}

.table-gray-8,
.table-gray-8 > th,
.table-gray-8 > td {
  background-color: #eff1f4;
}

.table-gray-8 th,
.table-gray-8 td,
.table-gray-8 thead th,
.table-gray-8 tbody + tbody {
  border-color: #e1e5eb;
}

.table-hover .table-gray-8:hover {
  background-color: #e0e4ea;
}

.table-hover .table-gray-8:hover > td,
.table-hover .table-gray-8:hover > th {
  background-color: #e0e4ea;
}

.table-gray-9,
.table-gray-9 > th,
.table-gray-9 > td {
  background-color: #e4e7eb;
}

.table-gray-9 th,
.table-gray-9 td,
.table-gray-9 thead th,
.table-gray-9 tbody + tbody {
  border-color: #cdd2da;
}

.table-hover .table-gray-9:hover {
  background-color: #d5dae0;
}

.table-hover .table-gray-9:hover > td,
.table-hover .table-gray-9:hover > th {
  background-color: #d5dae0;
}

.table-gray-10,
.table-gray-10 > th,
.table-gray-10 > td {
  background-color: #eeeeee;
}

.table-gray-10 th,
.table-gray-10 td,
.table-gray-10 thead th,
.table-gray-10 tbody + tbody {
  border-color: #dfdfdf;
}

.table-hover .table-gray-10:hover {
  background-color: #e1e1e1;
}

.table-hover .table-gray-10:hover > td,
.table-hover .table-gray-10:hover > th {
  background-color: #e1e1e1;
}

.table-gray-11,
.table-gray-11 > th,
.table-gray-11 > td {
  background-color: #cccccc;
}

.table-gray-11 th,
.table-gray-11 td,
.table-gray-11 thead th,
.table-gray-11 tbody + tbody {
  border-color: #a0a0a0;
}

.table-hover .table-gray-11:hover {
  background-color: #bfbfbf;
}

.table-hover .table-gray-11:hover > td,
.table-hover .table-gray-11:hover > th {
  background-color: #bfbfbf;
}

.table-gray-23,
.table-gray-23 > th,
.table-gray-23 > td {
  background-color: #fcfcfc;
}

.table-gray-23 th,
.table-gray-23 td,
.table-gray-23 thead th,
.table-gray-23 tbody + tbody {
  border-color: #f9f9f9;
}

.table-hover .table-gray-23:hover {
  background-color: #efefef;
}

.table-hover .table-gray-23:hover > td,
.table-hover .table-gray-23:hover > th {
  background-color: #efefef;
}

.table-gray-33,
.table-gray-33 > th,
.table-gray-33 > td {
  background-color: #f9fbfd;
}

.table-gray-33 th,
.table-gray-33 td,
.table-gray-33 thead th,
.table-gray-33 tbody + tbody {
  border-color: #f5f7fb;
}

.table-hover .table-gray-33:hover {
  background-color: #e6eef7;
}

.table-hover .table-gray-33:hover > td,
.table-hover .table-gray-33:hover > th {
  background-color: #e6eef7;
}

.table-sea-green,
.table-sea-green > th,
.table-sea-green > td {
  background-color: #c0cedf;
}

.table-sea-green th,
.table-sea-green td,
.table-sea-green thead th,
.table-sea-green tbody + tbody {
  border-color: #89a4c4;
}

.table-hover .table-sea-green:hover {
  background-color: #afc1d6;
}

.table-hover .table-sea-green:hover > td,
.table-hover .table-sea-green:hover > th {
  background-color: #afc1d6;
}

.table-dark-1,
.table-dark-1 > th,
.table-dark-1 > td {
  background-color: #c0c1c1;
}

.table-dark-1 th,
.table-dark-1 td,
.table-dark-1 thead th,
.table-dark-1 tbody + tbody {
  border-color: #8a8b8c;
}

.table-hover .table-dark-1:hover {
  background-color: #b3b4b4;
}

.table-hover .table-dark-1:hover > td,
.table-hover .table-dark-1:hover > th {
  background-color: #b3b4b4;
}

.table-navy,
.table-navy > th,
.table-navy > td {
  background-color: #b8d0ff;
}

.table-navy th,
.table-navy td,
.table-navy thead th,
.table-navy tbody + tbody {
  border-color: #7aa8ff;
}

.table-hover .table-navy:hover {
  background-color: #9fbfff;
}

.table-hover .table-navy:hover > td,
.table-hover .table-navy:hover > th {
  background-color: #9fbfff;
}

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #fff8fa;
}

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #fff1f6;
}

.table-hover .table-pink:hover {
  background-color: #ffdfe8;
}

.table-hover .table-pink:hover > td,
.table-hover .table-pink:hover > th {
  background-color: #ffdfe8;
}

.table-dark-2,
.table-dark-2 > th,
.table-dark-2 > td {
  background-color: #bfc4cc;
}

.table-dark-2 th,
.table-dark-2 td,
.table-dark-2 thead th,
.table-dark-2 tbody + tbody {
  border-color: #8891a0;
}

.table-hover .table-dark-2:hover {
  background-color: #b1b7c1;
}

.table-hover .table-dark-2:hover > td,
.table-hover .table-dark-2:hover > th {
  background-color: #b1b7c1;
}

.table-yellow-lighter-2,
.table-yellow-lighter-2 > th,
.table-yellow-lighter-2 > td {
  background-color: #fdecd1;
}

.table-yellow-lighter-2 th,
.table-yellow-lighter-2 td,
.table-yellow-lighter-2 thead th,
.table-yellow-lighter-2 tbody + tbody {
  border-color: #fbdca9;
}

.table-hover .table-yellow-lighter-2:hover {
  background-color: #fce2b9;
}

.table-hover .table-yellow-lighter-2:hover > td,
.table-hover .table-yellow-lighter-2:hover > th {
  background-color: #fce2b9;
}

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #fbd0b8;
}

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: #f7a87a;
}

.table-hover .table-orange:hover {
  background-color: #fac0a0;
}

.table-hover .table-orange:hover > td,
.table-hover .table-orange:hover > th {
  background-color: #fac0a0;
}

.table-green,
.table-green > th,
.table-green > td {
  background-color: #c3ddbf;
}

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #8fc189;
}

.table-hover .table-green:hover {
  background-color: #b3d4ae;
}

.table-hover .table-green:hover > td,
.table-hover .table-green:hover > th {
  background-color: #b3d4ae;
}

.table-pink-1,
.table-pink-1 > th,
.table-pink-1 > td {
  background-color: #ffb8cb;
}

.table-pink-1 th,
.table-pink-1 td,
.table-pink-1 thead th,
.table-pink-1 tbody + tbody {
  border-color: #ff7a9e;
}

.table-hover .table-pink-1:hover {
  background-color: #ff9fb8;
}

.table-hover .table-pink-1:hover > td,
.table-hover .table-pink-1:hover > th {
  background-color: #ff9fb8;
}

.table-purple-1,
.table-purple-1 > th,
.table-purple-1 > td {
  background-color: #c1c3cf;
}

.table-purple-1 th,
.table-purple-1 td,
.table-purple-1 thead th,
.table-purple-1 tbody + tbody {
  border-color: #8d8fa6;
}

.table-hover .table-purple-1:hover {
  background-color: #b3b5c4;
}

.table-hover .table-purple-1:hover > td,
.table-hover .table-purple-1:hover > th {
  background-color: #b3b5c4;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(231, 234, 243, 0.4);
}

.table-hover .table-active:hover {
  background-color: rgba(214, 219, 235, 0.4);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(214, 219, 235, 0.4);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #77838f;
  background-color: #f8fafd;
  border-color: #e7eaf3;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1479.98px) {
  .table-responsive-wd {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-wd > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

/*-------------------------------------------*\
    1.8. Forms
\*-------------------------------------------*/
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.8rem + 4px);
  padding: 0.9rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3b444f;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ebf0f7;
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #3b444f;
  background-color: #fff;
  border-color: #ebf0f7;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #5c6770;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #5c6770;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #5c6770;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #5c6770;
  opacity: 1;
}

.form-control::placeholder {
  color: #5c6770;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f8fafd;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #3b444f;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.9rem + 2px);
  padding-bottom: calc(0.9rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1.125rem + 2px);
  padding-bottom: calc(1.125rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.625rem + 2px);
  padding-bottom: calc(0.625rem + 2px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #3b444f;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 1.25rem + 4px);
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.form-control-lg {
  height: calc(1.5em + 2.25rem + 4px);
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #8c98a4;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00c9a7;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #00c9a7;
  border-radius: 0.3125rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00c9a7;
  padding-right: calc(1.5em + 1.8rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.45rem);
  background-size: calc(0.75em + 0.9rem) calc(0.75em + 0.9rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.8rem);
  background-position: top calc(0.375em + 0.45rem) right calc(0.375em + 0.45rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00c9a7;
  padding-right: calc((1em + 1.8rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.9rem) calc(0.75em + 0.9rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00c9a7;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00c9a7;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #00c9a7;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00fcd1;
  background-color: #00fcd1;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00c9a7;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00c9a7;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00c9a7;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #de4437;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #de4437;
  border-radius: 0.3125rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #de4437;
  padding-right: calc(1.5em + 1.8rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23de4437' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23de4437' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.45rem);
  background-size: calc(0.75em + 0.9rem) calc(0.75em + 0.9rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #de4437;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.8rem);
  background-position: top calc(0.375em + 0.45rem) right calc(0.375em + 0.45rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #de4437;
  padding-right: calc((1em + 1.8rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23de4437' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23de4437' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2rem/calc(0.75em + 0.9rem) calc(0.75em + 0.9rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #de4437;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #de4437;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #de4437;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #de4437;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e56d63;
  background-color: #e56d63;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #de4437;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #de4437;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #de4437;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

/*-------------------------------------------*\
    1.9. Buttons
\*-------------------------------------------*/
.btn {
  display: inline-block;
  font-weight: 500;
  color: #3b444f;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.9rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #3b444f;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary, .u-header.js-header-fix-moment .u-header__last-item-btn .btn[class*="-white"] {
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-primary:hover, .u-header.js-header-fix-moment .u-header__last-item-btn .btn:hover[class*="-white"] {
  color: #fff;
  background-color: #22679c;
  border-color: #206091;
}

.btn-primary:focus, .u-header.js-header-fix-moment .u-header__last-item-btn .btn:focus[class*="-white"], .btn-primary.focus, .u-header.js-header-fix-moment .u-header__last-item-btn .focus.btn[class*="-white"] {
  box-shadow: 0 0 0 0.2rem rgba(73, 144, 197, 0.5);
}

.btn-primary.disabled, .u-header.js-header-fix-moment .u-header__last-item-btn .disabled.btn[class*="-white"], .btn-primary:disabled, .u-header.js-header-fix-moment .u-header__last-item-btn .btn:disabled[class*="-white"] {
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-primary:not(:disabled):not(.disabled):active, .u-header.js-header-fix-moment .u-header__last-item-btn .btn:not(:disabled):not(.disabled):active[class*="-white"], .btn-primary:not(:disabled):not(.disabled).active, .u-header.js-header-fix-moment .u-header__last-item-btn .btn:not(:disabled):not(.disabled).active[class*="-white"],
.show > .btn-primary.dropdown-toggle,
.u-header.js-header-fix-moment .u-header__last-item-btn .show > .dropdown-toggle.btn[class*="-white"] {
  color: #fff;
  background-color: #206091;
  border-color: #1e5987;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .u-header.js-header-fix-moment .u-header__last-item-btn .btn:not(:disabled):not(.disabled):active:focus[class*="-white"], .btn-primary:not(:disabled):not(.disabled).active:focus, .u-header.js-header-fix-moment .u-header__last-item-btn .btn:not(:disabled):not(.disabled).active:focus[class*="-white"],
.show > .btn-primary.dropdown-toggle:focus,
.u-header.js-header-fix-moment .u-header__last-item-btn .show > .dropdown-toggle.btn:focus[class*="-white"] {
  box-shadow: 0 0 0 0.2rem rgba(73, 144, 197, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #77838f;
  border-color: #77838f;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #65707b;
  border-color: #5f6a74;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 150, 160, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #77838f;
  border-color: #77838f;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5f6a74;
  border-color: #5a636d;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 150, 160, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-success:hover {
  color: #fff;
  background-color: #00a387;
  border-color: #00967d;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00967d;
  border-color: #008972;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 209, 180, 0.5);
}

.btn-info {
  color: #3b444f;
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-info:hover {
  color: #fff;
  background-color: #00bdd6;
  border-color: #00b2c9;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 200, 226, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #3b444f;
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00b2c9;
  border-color: #00a7bc;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 200, 226, 0.5);
}

.btn-warning {
  color: #3b444f;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #3b444f;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 174, 18, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #3b444f;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #3b444f;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 174, 18, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}

.btn-danger:hover {
  color: #fff;
  background-color: #cd2f22;
  border-color: #c22d20;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 96, 85, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c22d20;
  border-color: #b72a1e;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 96, 85, 0.5);
}

.btn-light {
  color: #3b444f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #3b444f;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 222, 224, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #3b444f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #3b444f;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 222, 224, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2b3139;
  border-color: #252b32;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 96, 105, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #252b32;
  border-color: #20252b;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 96, 105, 0.5);
}

.btn-white {
  color: #3b444f;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  color: #3b444f;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-white:focus, .btn-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 227, 229, 0.5);
}

.btn-white.disabled, .btn-white:disabled {
  color: #3b444f;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #3b444f;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 227, 229, 0.5);
}

.btn-indigo {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}

.btn-indigo:hover {
  color: #fff;
  background-color: #221061;
  border-color: #1e0e56;
}

.btn-indigo:focus, .btn-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 56, 149, 0.5);
}

.btn-indigo.disabled, .btn-indigo:disabled {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}

.btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
.show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #1e0e56;
  border-color: #1a0c4b;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 56, 149, 0.5);
}

.btn-gray {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-gray:hover {
  color: #3b444f;
  background-color: #d0dcec;
  border-color: #c7d5e8;
}

.btn-gray:focus, .btn-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 214, 222, 0.5);
}

.btn-gray.disabled, .btn-gray:disabled {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: #3b444f;
  background-color: #c7d5e8;
  border-color: #bdcee5;
}

.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 214, 222, 0.5);
}

.btn-violet {
  color: #fff;
  background-color: #532184;
  border-color: #532184;
}

.btn-violet:hover {
  color: #fff;
  background-color: #401965;
  border-color: #39175b;
}

.btn-violet:focus, .btn-violet.focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 66, 150, 0.5);
}

.btn-violet.disabled, .btn-violet:disabled {
  color: #fff;
  background-color: #532184;
  border-color: #532184;
}

.btn-violet:not(:disabled):not(.disabled):active, .btn-violet:not(:disabled):not(.disabled).active,
.show > .btn-violet.dropdown-toggle {
  color: #fff;
  background-color: #39175b;
  border-color: #331451;
}

.btn-violet:not(:disabled):not(.disabled):active:focus, .btn-violet:not(:disabled):not(.disabled).active:focus,
.show > .btn-violet.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(109, 66, 150, 0.5);
}

.btn-violet-1 {
  color: #fff;
  background-color: #a864a8;
  border-color: #a864a8;
}

.btn-violet-1:hover {
  color: #fff;
  background-color: #935393;
  border-color: #8b4e8b;
}

.btn-violet-1:focus, .btn-violet-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 123, 181, 0.5);
}

.btn-violet-1.disabled, .btn-violet-1:disabled {
  color: #fff;
  background-color: #a864a8;
  border-color: #a864a8;
}

.btn-violet-1:not(:disabled):not(.disabled):active, .btn-violet-1:not(:disabled):not(.disabled).active,
.show > .btn-violet-1.dropdown-toggle {
  color: #fff;
  background-color: #8b4e8b;
  border-color: #834983;
}

.btn-violet-1:not(:disabled):not(.disabled):active:focus, .btn-violet-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-violet-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 123, 181, 0.5);
}

.btn-violet-light {
  color: #fff;
  background-color: #6f13cd;
  border-color: #6f13cd;
}

.btn-violet-light:hover {
  color: #fff;
  background-color: #5c10aa;
  border-color: #560f9e;
}

.btn-violet-light:focus, .btn-violet-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 54, 213, 0.5);
}

.btn-violet-light.disabled, .btn-violet-light:disabled {
  color: #fff;
  background-color: #6f13cd;
  border-color: #6f13cd;
}

.btn-violet-light:not(:disabled):not(.disabled):active, .btn-violet-light:not(:disabled):not(.disabled).active,
.show > .btn-violet-light.dropdown-toggle {
  color: #fff;
  background-color: #560f9e;
  border-color: #4f0e93;
}

.btn-violet-light:not(:disabled):not(.disabled):active:focus, .btn-violet-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-violet-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 54, 213, 0.5);
}

.btn-brown-light {
  color: #3b444f;
  background-color: #ceac61;
  border-color: #ceac61;
}

.btn-brown-light:hover {
  color: #3b444f;
  background-color: #c59d44;
  border-color: #c0973c;
}

.btn-brown-light:focus, .btn-brown-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 156, 94, 0.5);
}

.btn-brown-light.disabled, .btn-brown-light:disabled {
  color: #3b444f;
  background-color: #ceac61;
  border-color: #ceac61;
}

.btn-brown-light:not(:disabled):not(.disabled):active, .btn-brown-light:not(:disabled):not(.disabled).active,
.show > .btn-brown-light.dropdown-toggle {
  color: #3b444f;
  background-color: #c0973c;
  border-color: #b78f39;
}

.btn-brown-light:not(:disabled):not(.disabled):active:focus, .btn-brown-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-brown-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 156, 94, 0.5);
}

.btn-brown {
  color: #3b444f;
  background-color: #f8bd5b;
  border-color: #f8bd5b;
}

.btn-brown:hover {
  color: #3b444f;
  background-color: #f6ae36;
  border-color: #f6a92a;
}

.btn-brown:focus, .btn-brown.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 171, 89, 0.5);
}

.btn-brown.disabled, .btn-brown:disabled {
  color: #3b444f;
  background-color: #f8bd5b;
  border-color: #f8bd5b;
}

.btn-brown:not(:disabled):not(.disabled):active, .btn-brown:not(:disabled):not(.disabled).active,
.show > .btn-brown.dropdown-toggle {
  color: #3b444f;
  background-color: #f6a92a;
  border-color: #f5a41e;
}

.btn-brown:not(:disabled):not(.disabled):active:focus, .btn-brown:not(:disabled):not(.disabled).active:focus,
.show > .btn-brown.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 171, 89, 0.5);
}

.btn-maroon {
  color: #fff;
  background-color: #c72f74;
  border-color: #c72f74;
}

.btn-maroon:hover {
  color: #fff;
  background-color: #a82862;
  border-color: #9e255c;
}

.btn-maroon:focus, .btn-maroon.focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 78, 137, 0.5);
}

.btn-maroon.disabled, .btn-maroon:disabled {
  color: #fff;
  background-color: #c72f74;
  border-color: #c72f74;
}

.btn-maroon:not(:disabled):not(.disabled):active, .btn-maroon:not(:disabled):not(.disabled).active,
.show > .btn-maroon.dropdown-toggle {
  color: #fff;
  background-color: #9e255c;
  border-color: #932356;
}

.btn-maroon:not(:disabled):not(.disabled):active:focus, .btn-maroon:not(:disabled):not(.disabled).active:focus,
.show > .btn-maroon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 78, 137, 0.5);
}

.btn-indigo-light {
  color: #fff;
  background-color: #080e7b;
  border-color: #080e7b;
}

.btn-indigo-light:hover {
  color: #fff;
  background-color: #060a57;
  border-color: #05094b;
}

.btn-indigo-light:focus, .btn-indigo-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 50, 143, 0.5);
}

.btn-indigo-light.disabled, .btn-indigo-light:disabled {
  color: #fff;
  background-color: #080e7b;
  border-color: #080e7b;
}

.btn-indigo-light:not(:disabled):not(.disabled):active, .btn-indigo-light:not(:disabled):not(.disabled).active,
.show > .btn-indigo-light.dropdown-toggle {
  color: #fff;
  background-color: #05094b;
  border-color: #04073f;
}

.btn-indigo-light:not(:disabled):not(.disabled):active:focus, .btn-indigo-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-indigo-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 50, 143, 0.5);
}

.btn-red-light-2 {
  color: #fff;
  background-color: #ff3c4e;
  border-color: #ff3c4e;
}

.btn-red-light-2:hover {
  color: #fff;
  background-color: #ff162b;
  border-color: #ff0920;
}

.btn-red-light-2:focus, .btn-red-light-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 89, 105, 0.5);
}

.btn-red-light-2.disabled, .btn-red-light-2:disabled {
  color: #fff;
  background-color: #ff3c4e;
  border-color: #ff3c4e;
}

.btn-red-light-2:not(:disabled):not(.disabled):active, .btn-red-light-2:not(:disabled):not(.disabled).active,
.show > .btn-red-light-2.dropdown-toggle {
  color: #fff;
  background-color: #ff0920;
  border-color: #fb0017;
}

.btn-red-light-2:not(:disabled):not(.disabled):active:focus, .btn-red-light-2:not(:disabled):not(.disabled).active:focus,
.show > .btn-red-light-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 89, 105, 0.5);
}

.btn-blue-dark-1 {
  color: #fff;
  background-color: #045cff;
  border-color: #045cff;
}

.btn-blue-dark-1:hover {
  color: #fff;
  background-color: #004ddd;
  border-color: #0049d0;
}

.btn-blue-dark-1:focus, .btn-blue-dark-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 116, 255, 0.5);
}

.btn-blue-dark-1.disabled, .btn-blue-dark-1:disabled {
  color: #fff;
  background-color: #045cff;
  border-color: #045cff;
}

.btn-blue-dark-1:not(:disabled):not(.disabled):active, .btn-blue-dark-1:not(:disabled):not(.disabled).active,
.show > .btn-blue-dark-1.dropdown-toggle {
  color: #fff;
  background-color: #0049d0;
  border-color: #0044c3;
}

.btn-blue-dark-1:not(:disabled):not(.disabled):active:focus, .btn-blue-dark-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-dark-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 116, 255, 0.5);
}

.btn-gray-1 {
  color: #fff;
  background-color: #67747c;
  border-color: #67747c;
}

.btn-gray-1:hover {
  color: #fff;
  background-color: #566067;
  border-color: #505a60;
}

.btn-gray-1:focus, .btn-gray-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 137, 144, 0.5);
}

.btn-gray-1.disabled, .btn-gray-1:disabled {
  color: #fff;
  background-color: #67747c;
  border-color: #67747c;
}

.btn-gray-1:not(:disabled):not(.disabled):active, .btn-gray-1:not(:disabled):not(.disabled).active,
.show > .btn-gray-1.dropdown-toggle {
  color: #fff;
  background-color: #505a60;
  border-color: #4a5359;
}

.btn-gray-1:not(:disabled):not(.disabled):active:focus, .btn-gray-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(126, 137, 144, 0.5);
}

.btn-gray-2 {
  color: #3b444f;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-gray-2:hover {
  color: #3b444f;
  background-color: #e1e5e9;
  border-color: #dadfe3;
}

.btn-gray-2:focus, .btn-gray-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 221, 224, 0.5);
}

.btn-gray-2.disabled, .btn-gray-2:disabled {
  color: #3b444f;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-gray-2:not(:disabled):not(.disabled):active, .btn-gray-2:not(:disabled):not(.disabled).active,
.show > .btn-gray-2.dropdown-toggle {
  color: #3b444f;
  background-color: #dadfe3;
  border-color: #d3d8de;
}

.btn-gray-2:not(:disabled):not(.disabled):active:focus, .btn-gray-2:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 221, 224, 0.5);
}

.btn-gray-3 {
  color: #fff;
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-gray-3:hover {
  color: #fff;
  background-color: #2b3139;
  border-color: #252b32;
}

.btn-gray-3:focus, .btn-gray-3.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 96, 105, 0.5);
}

.btn-gray-3.disabled, .btn-gray-3:disabled {
  color: #fff;
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-gray-3:not(:disabled):not(.disabled):active, .btn-gray-3:not(:disabled):not(.disabled).active,
.show > .btn-gray-3.dropdown-toggle {
  color: #fff;
  background-color: #252b32;
  border-color: #20252b;
}

.btn-gray-3:not(:disabled):not(.disabled):active:focus, .btn-gray-3:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 96, 105, 0.5);
}

.btn-gray-4 {
  color: #3b444f;
  background-color: #eceff3;
  border-color: #eceff3;
}

.btn-gray-4:hover {
  color: #3b444f;
  background-color: #d5dbe4;
  border-color: #cdd5df;
}

.btn-gray-4:focus, .btn-gray-4.focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 213, 218, 0.5);
}

.btn-gray-4.disabled, .btn-gray-4:disabled {
  color: #3b444f;
  background-color: #eceff3;
  border-color: #eceff3;
}

.btn-gray-4:not(:disabled):not(.disabled):active, .btn-gray-4:not(:disabled):not(.disabled).active,
.show > .btn-gray-4.dropdown-toggle {
  color: #3b444f;
  background-color: #cdd5df;
  border-color: #c5ceda;
}

.btn-gray-4:not(:disabled):not(.disabled):active:focus, .btn-gray-4:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-4.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 213, 218, 0.5);
}

.btn-blue {
  color: #3b444f;
  background-color: #43c5db;
  border-color: #43c5db;
}

.btn-blue:hover {
  color: #fff;
  background-color: #28b8d0;
  border-color: #26aec5;
}

.btn-blue:focus, .btn-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 178, 198, 0.5);
}

.btn-blue.disabled, .btn-blue:disabled {
  color: #3b444f;
  background-color: #43c5db;
  border-color: #43c5db;
}

.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #26aec5;
  border-color: #24a5bb;
}

.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 178, 198, 0.5);
}

.btn-blue-1 {
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-blue-1:hover {
  color: #fff;
  background-color: #22679c;
  border-color: #206091;
}

.btn-blue-1:focus, .btn-blue-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 144, 197, 0.5);
}

.btn-blue-1.disabled, .btn-blue-1:disabled {
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-blue-1:not(:disabled):not(.disabled):active, .btn-blue-1:not(:disabled):not(.disabled).active,
.show > .btn-blue-1.dropdown-toggle {
  color: #fff;
  background-color: #206091;
  border-color: #1e5987;
}

.btn-blue-1:not(:disabled):not(.disabled):active:focus, .btn-blue-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 144, 197, 0.5);
}

.btn-gray-5 {
  color: #fff;
  background-color: #18181b;
  border-color: #18181b;
}

.btn-gray-5:hover {
  color: #fff;
  background-color: #060607;
  border-color: black;
}

.btn-gray-5:focus, .btn-gray-5.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 59, 61, 0.5);
}

.btn-gray-5.disabled, .btn-gray-5:disabled {
  color: #fff;
  background-color: #18181b;
  border-color: #18181b;
}

.btn-gray-5:not(:disabled):not(.disabled):active, .btn-gray-5:not(:disabled):not(.disabled).active,
.show > .btn-gray-5.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-gray-5:not(:disabled):not(.disabled):active:focus, .btn-gray-5:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-5.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 59, 61, 0.5);
}

.btn-gray-6 {
  color: #fff;
  background-color: #18181a;
  border-color: #18181a;
}

.btn-gray-6:hover {
  color: #fff;
  background-color: #060606;
  border-color: black;
}

.btn-gray-6:focus, .btn-gray-6.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 59, 60, 0.5);
}

.btn-gray-6.disabled, .btn-gray-6:disabled {
  color: #fff;
  background-color: #18181a;
  border-color: #18181a;
}

.btn-gray-6:not(:disabled):not(.disabled):active, .btn-gray-6:not(:disabled):not(.disabled).active,
.show > .btn-gray-6.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-gray-6:not(:disabled):not(.disabled):active:focus, .btn-gray-6:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-6.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 59, 60, 0.5);
}

.btn-gray-7 {
  color: #fff;
  background-color: #5c6770;
  border-color: #5c6770;
}

.btn-gray-7:hover {
  color: #fff;
  background-color: #4b545b;
  border-color: #454d54;
}

.btn-gray-7:focus, .btn-gray-7.focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 126, 133, 0.5);
}

.btn-gray-7.disabled, .btn-gray-7:disabled {
  color: #fff;
  background-color: #5c6770;
  border-color: #5c6770;
}

.btn-gray-7:not(:disabled):not(.disabled):active, .btn-gray-7:not(:disabled):not(.disabled).active,
.show > .btn-gray-7.dropdown-toggle {
  color: #fff;
  background-color: #454d54;
  border-color: #3f474d;
}

.btn-gray-7:not(:disabled):not(.disabled):active:focus, .btn-gray-7:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-7.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(116, 126, 133, 0.5);
}

.btn-gray-8 {
  color: #3b444f;
  background-color: #c5cdd8;
  border-color: #c5cdd8;
}

.btn-gray-8:hover {
  color: #3b444f;
  background-color: #aeb9c9;
  border-color: #a7b3c3;
}

.btn-gray-8:focus, .btn-gray-8.focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 184, 195, 0.5);
}

.btn-gray-8.disabled, .btn-gray-8:disabled {
  color: #3b444f;
  background-color: #c5cdd8;
  border-color: #c5cdd8;
}

.btn-gray-8:not(:disabled):not(.disabled):active, .btn-gray-8:not(:disabled):not(.disabled).active,
.show > .btn-gray-8.dropdown-toggle {
  color: #3b444f;
  background-color: #a7b3c3;
  border-color: #9facbe;
}

.btn-gray-8:not(:disabled):not(.disabled):active:focus, .btn-gray-8:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-8.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 184, 195, 0.5);
}

.btn-gray-9 {
  color: #3b444f;
  background-color: #9fa9b8;
  border-color: #9fa9b8;
}

.btn-gray-9:hover {
  color: #fff;
  background-color: #8995a8;
  border-color: #828fa2;
}

.btn-gray-9:focus, .btn-gray-9.focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 154, 168, 0.5);
}

.btn-gray-9.disabled, .btn-gray-9:disabled {
  color: #3b444f;
  background-color: #9fa9b8;
  border-color: #9fa9b8;
}

.btn-gray-9:not(:disabled):not(.disabled):active, .btn-gray-9:not(:disabled):not(.disabled).active,
.show > .btn-gray-9.dropdown-toggle {
  color: #fff;
  background-color: #828fa2;
  border-color: #7a889d;
}

.btn-gray-9:not(:disabled):not(.disabled):active:focus, .btn-gray-9:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-9.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(144, 154, 168, 0.5);
}

.btn-gray-10 {
  color: #3b444f;
  background-color: #c1c1c1;
  border-color: #c1c1c1;
}

.btn-gray-10:hover {
  color: #3b444f;
  background-color: #aeaeae;
  border-color: #a8a8a8;
}

.btn-gray-10:focus, .btn-gray-10.focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 174, 176, 0.5);
}

.btn-gray-10.disabled, .btn-gray-10:disabled {
  color: #3b444f;
  background-color: #c1c1c1;
  border-color: #c1c1c1;
}

.btn-gray-10:not(:disabled):not(.disabled):active, .btn-gray-10:not(:disabled):not(.disabled).active,
.show > .btn-gray-10.dropdown-toggle {
  color: #3b444f;
  background-color: #a8a8a8;
  border-color: #a1a1a1;
}

.btn-gray-10:not(:disabled):not(.disabled):active:focus, .btn-gray-10:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(173, 174, 176, 0.5);
}

.btn-gray-11 {
  color: #fff;
  background-color: #484848;
  border-color: #484848;
}

.btn-gray-11:hover {
  color: #fff;
  background-color: #353535;
  border-color: #2f2f2f;
}

.btn-gray-11:focus, .btn-gray-11.focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 99, 99, 0.5);
}

.btn-gray-11.disabled, .btn-gray-11:disabled {
  color: #fff;
  background-color: #484848;
  border-color: #484848;
}

.btn-gray-11:not(:disabled):not(.disabled):active, .btn-gray-11:not(:disabled):not(.disabled).active,
.show > .btn-gray-11.dropdown-toggle {
  color: #fff;
  background-color: #2f2f2f;
  border-color: #282828;
}

.btn-gray-11:not(:disabled):not(.disabled):active:focus, .btn-gray-11:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-11.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 99, 99, 0.5);
}

.btn-gray-23 {
  color: #3b444f;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.btn-gray-23:hover {
  color: #3b444f;
  background-color: #e1e1e1;
  border-color: #dbdbdb;
}

.btn-gray-23:focus, .btn-gray-23.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 218, 219, 0.5);
}

.btn-gray-23.disabled, .btn-gray-23:disabled {
  color: #3b444f;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.btn-gray-23:not(:disabled):not(.disabled):active, .btn-gray-23:not(:disabled):not(.disabled).active,
.show > .btn-gray-23.dropdown-toggle {
  color: #3b444f;
  background-color: #dbdbdb;
  border-color: #d4d4d4;
}

.btn-gray-23:not(:disabled):not(.disabled):active:focus, .btn-gray-23:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-23.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 218, 219, 0.5);
}

.btn-gray-33 {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-gray-33:hover {
  color: #3b444f;
  background-color: #d0dcec;
  border-color: #c7d5e8;
}

.btn-gray-33:focus, .btn-gray-33.focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 214, 222, 0.5);
}

.btn-gray-33.disabled, .btn-gray-33:disabled {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-gray-33:not(:disabled):not(.disabled):active, .btn-gray-33:not(:disabled):not(.disabled).active,
.show > .btn-gray-33.dropdown-toggle {
  color: #3b444f;
  background-color: #c7d5e8;
  border-color: #bdcee5;
}

.btn-gray-33:not(:disabled):not(.disabled):active:focus, .btn-gray-33:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-33.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 214, 222, 0.5);
}

.btn-sea-green {
  color: #fff;
  background-color: #1d508d;
  border-color: #1d508d;
}

.btn-sea-green:hover {
  color: #fff;
  background-color: #163e6d;
  border-color: #143863;
}

.btn-sea-green:focus, .btn-sea-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 106, 158, 0.5);
}

.btn-sea-green.disabled, .btn-sea-green:disabled {
  color: #fff;
  background-color: #1d508d;
  border-color: #1d508d;
}

.btn-sea-green:not(:disabled):not(.disabled):active, .btn-sea-green:not(:disabled):not(.disabled).active,
.show > .btn-sea-green.dropdown-toggle {
  color: #fff;
  background-color: #143863;
  border-color: #123258;
}

.btn-sea-green:not(:disabled):not(.disabled):active:focus, .btn-sea-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-sea-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 106, 158, 0.5);
}

.btn-dark-1 {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}

.btn-dark-1:hover {
  color: #fff;
  background-color: #0c0d0e;
  border-color: #060707;
}

.btn-dark-1:focus, .btn-dark-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 65, 67, 0.5);
}

.btn-dark-1.disabled, .btn-dark-1:disabled {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}

.btn-dark-1:not(:disabled):not(.disabled):active, .btn-dark-1:not(:disabled):not(.disabled).active,
.show > .btn-dark-1.dropdown-toggle {
  color: #fff;
  background-color: #060707;
  border-color: black;
}

.btn-dark-1:not(:disabled):not(.disabled):active:focus, .btn-dark-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 65, 67, 0.5);
}

.btn-navy {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-navy:hover {
  color: #fff;
  background-color: #004ad9;
  border-color: #0046cc;
}

.btn-navy:focus, .btn-navy.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
}

.btn-navy.disabled, .btn-navy:disabled {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-navy:not(:disabled):not(.disabled):active, .btn-navy:not(:disabled):not(.disabled).active,
.show > .btn-navy.dropdown-toggle {
  color: #fff;
  background-color: #0046cc;
  border-color: #0041bf;
}

.btn-navy:not(:disabled):not(.disabled):active:focus, .btn-navy:not(:disabled):not(.disabled).active:focus,
.show > .btn-navy.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
}

.btn-pink {
  color: #3b444f;
  background-color: #ffe5ee;
  border-color: #ffe5ee;
}

.btn-pink:hover {
  color: #3b444f;
  background-color: #ffbfd5;
  border-color: #ffb2cd;
}

.btn-pink:focus, .btn-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 205, 214, 0.5);
}

.btn-pink.disabled, .btn-pink:disabled {
  color: #3b444f;
  background-color: #ffe5ee;
  border-color: #ffe5ee;
}

.btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
.show > .btn-pink.dropdown-toggle {
  color: #3b444f;
  background-color: #ffb2cd;
  border-color: #ffa5c4;
}

.btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 205, 214, 0.5);
}

.btn-dark-2 {
  color: #fff;
  background-color: #1a2b49;
  border-color: #1a2b49;
}

.btn-dark-2:hover {
  color: #fff;
  background-color: #101a2d;
  border-color: #0d1523;
}

.btn-dark-2:focus, .btn-dark-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 75, 100, 0.5);
}

.btn-dark-2.disabled, .btn-dark-2:disabled {
  color: #fff;
  background-color: #1a2b49;
  border-color: #1a2b49;
}

.btn-dark-2:not(:disabled):not(.disabled):active, .btn-dark-2:not(:disabled):not(.disabled).active,
.show > .btn-dark-2.dropdown-toggle {
  color: #fff;
  background-color: #0d1523;
  border-color: #090f1a;
}

.btn-dark-2:not(:disabled):not(.disabled):active:focus, .btn-dark-2:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 75, 100, 0.5);
}

.btn-yellow-lighter-2 {
  color: #3b444f;
  background-color: #f7bc5a;
  border-color: #f7bc5a;
}

.btn-yellow-lighter-2:hover {
  color: #3b444f;
  background-color: #f5ad36;
  border-color: #f5a829;
}

.btn-yellow-lighter-2:focus, .btn-yellow-lighter-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 170, 88, 0.5);
}

.btn-yellow-lighter-2.disabled, .btn-yellow-lighter-2:disabled {
  color: #3b444f;
  background-color: #f7bc5a;
  border-color: #f7bc5a;
}

.btn-yellow-lighter-2:not(:disabled):not(.disabled):active, .btn-yellow-lighter-2:not(:disabled):not(.disabled).active,
.show > .btn-yellow-lighter-2.dropdown-toggle {
  color: #3b444f;
  background-color: #f5a829;
  border-color: #f4a31d;
}

.btn-yellow-lighter-2:not(:disabled):not(.disabled):active:focus, .btn-yellow-lighter-2:not(:disabled):not(.disabled).active:focus,
.show > .btn-yellow-lighter-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(219, 170, 88, 0.5);
}

.btn-orange {
  color: #fff;
  background-color: #f05700;
  border-color: #f05700;
}

.btn-orange:hover {
  color: #fff;
  background-color: #ca4900;
  border-color: #bd4500;
}

.btn-orange:focus, .btn-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 112, 38, 0.5);
}

.btn-orange.disabled, .btn-orange:disabled {
  color: #fff;
  background-color: #f05700;
  border-color: #f05700;
}

.btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
.show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #bd4500;
  border-color: #b04000;
}

.btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 112, 38, 0.5);
}

.btn-green {
  color: #fff;
  background-color: #28871c;
  border-color: #28871c;
}

.btn-green:hover {
  color: #fff;
  background-color: #1f6715;
  border-color: #1b5d13;
}

.btn-green:focus, .btn-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 153, 62, 0.5);
}

.btn-green.disabled, .btn-green:disabled {
  color: #fff;
  background-color: #28871c;
  border-color: #28871c;
}

.btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
.show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: #1b5d13;
  border-color: #185211;
}

.btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 153, 62, 0.5);
}

.btn-pink-1 {
  color: #fff;
  background-color: #ff0045;
  border-color: #ff0045;
}

.btn-pink-1:hover {
  color: #fff;
  background-color: #d9003b;
  border-color: #cc0037;
}

.btn-pink-1:focus, .btn-pink-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 97, 0.5);
}

.btn-pink-1.disabled, .btn-pink-1:disabled {
  color: #fff;
  background-color: #ff0045;
  border-color: #ff0045;
}

.btn-pink-1:not(:disabled):not(.disabled):active, .btn-pink-1:not(:disabled):not(.disabled).active,
.show > .btn-pink-1.dropdown-toggle {
  color: #fff;
  background-color: #cc0037;
  border-color: #bf0034;
}

.btn-pink-1:not(:disabled):not(.disabled):active:focus, .btn-pink-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-pink-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 38, 97, 0.5);
}

.btn-purple-1 {
  color: #fff;
  background-color: #232753;
  border-color: #232753;
}

.btn-purple-1:hover {
  color: #fff;
  background-color: #181a38;
  border-color: #14162f;
}

.btn-purple-1:focus, .btn-purple-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 71, 109, 0.5);
}

.btn-purple-1.disabled, .btn-purple-1:disabled {
  color: #fff;
  background-color: #232753;
  border-color: #232753;
}

.btn-purple-1:not(:disabled):not(.disabled):active, .btn-purple-1:not(:disabled):not(.disabled).active,
.show > .btn-purple-1.dropdown-toggle {
  color: #fff;
  background-color: #14162f;
  border-color: #101226;
}

.btn-purple-1:not(:disabled):not(.disabled):active:focus, .btn-purple-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 71, 109, 0.5);
}

.btn-outline-primary {
  color: #297cbb;
  border-color: #297cbb;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #297cbb;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.5);
}

.btn-outline-secondary {
  color: #77838f;
  border-color: #77838f;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #77838f;
  border-color: #77838f;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 131, 143, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #77838f;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #77838f;
  border-color: #77838f;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 131, 143, 0.5);
}

.btn-outline-success {
  color: #00c9a7;
  border-color: #00c9a7;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00c9a7;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.btn-outline-info {
  color: #00dffc;
  border-color: #00dffc;
}

.btn-outline-info:hover {
  color: #3b444f;
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #00dffc;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #3b444f;
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #3b444f;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #3b444f;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #de4437;
  border-color: #de4437;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #de4437;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #de4437;
  border-color: #de4437;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #3b444f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #3b444f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #3b444f;
  border-color: #3b444f;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 68, 79, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #3b444f;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 68, 79, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #3b444f;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #3b444f;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-indigo {
  color: #2d1582;
  border-color: #2d1582;
}

.btn-outline-indigo:hover {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}

.btn-outline-indigo:focus, .btn-outline-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
}

.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
  color: #2d1582;
  background-color: transparent;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
.show > .btn-outline-indigo.dropdown-toggle {
  color: #fff;
  background-color: #2d1582;
  border-color: #2d1582;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
}

.btn-outline-gray {
  color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-outline-gray:hover {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-outline-gray:focus, .btn-outline-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 240, 247, 0.5);
}

.btn-outline-gray.disabled, .btn-outline-gray:disabled {
  color: #ebf0f7;
  background-color: transparent;
}

.btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 240, 247, 0.5);
}

.btn-outline-violet {
  color: #532184;
  border-color: #532184;
}

.btn-outline-violet:hover {
  color: #fff;
  background-color: #532184;
  border-color: #532184;
}

.btn-outline-violet:focus, .btn-outline-violet.focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 33, 132, 0.5);
}

.btn-outline-violet.disabled, .btn-outline-violet:disabled {
  color: #532184;
  background-color: transparent;
}

.btn-outline-violet:not(:disabled):not(.disabled):active, .btn-outline-violet:not(:disabled):not(.disabled).active,
.show > .btn-outline-violet.dropdown-toggle {
  color: #fff;
  background-color: #532184;
  border-color: #532184;
}

.btn-outline-violet:not(:disabled):not(.disabled):active:focus, .btn-outline-violet:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-violet.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 33, 132, 0.5);
}

.btn-outline-violet-1 {
  color: #a864a8;
  border-color: #a864a8;
}

.btn-outline-violet-1:hover {
  color: #fff;
  background-color: #a864a8;
  border-color: #a864a8;
}

.btn-outline-violet-1:focus, .btn-outline-violet-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 100, 168, 0.5);
}

.btn-outline-violet-1.disabled, .btn-outline-violet-1:disabled {
  color: #a864a8;
  background-color: transparent;
}

.btn-outline-violet-1:not(:disabled):not(.disabled):active, .btn-outline-violet-1:not(:disabled):not(.disabled).active,
.show > .btn-outline-violet-1.dropdown-toggle {
  color: #fff;
  background-color: #a864a8;
  border-color: #a864a8;
}

.btn-outline-violet-1:not(:disabled):not(.disabled):active:focus, .btn-outline-violet-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-violet-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(168, 100, 168, 0.5);
}

.btn-outline-violet-light {
  color: #6f13cd;
  border-color: #6f13cd;
}

.btn-outline-violet-light:hover {
  color: #fff;
  background-color: #6f13cd;
  border-color: #6f13cd;
}

.btn-outline-violet-light:focus, .btn-outline-violet-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 19, 205, 0.5);
}

.btn-outline-violet-light.disabled, .btn-outline-violet-light:disabled {
  color: #6f13cd;
  background-color: transparent;
}

.btn-outline-violet-light:not(:disabled):not(.disabled):active, .btn-outline-violet-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-violet-light.dropdown-toggle {
  color: #fff;
  background-color: #6f13cd;
  border-color: #6f13cd;
}

.btn-outline-violet-light:not(:disabled):not(.disabled):active:focus, .btn-outline-violet-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-violet-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 19, 205, 0.5);
}

.btn-outline-brown-light {
  color: #ceac61;
  border-color: #ceac61;
}

.btn-outline-brown-light:hover {
  color: #3b444f;
  background-color: #ceac61;
  border-color: #ceac61;
}

.btn-outline-brown-light:focus, .btn-outline-brown-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 172, 97, 0.5);
}

.btn-outline-brown-light.disabled, .btn-outline-brown-light:disabled {
  color: #ceac61;
  background-color: transparent;
}

.btn-outline-brown-light:not(:disabled):not(.disabled):active, .btn-outline-brown-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-brown-light.dropdown-toggle {
  color: #3b444f;
  background-color: #ceac61;
  border-color: #ceac61;
}

.btn-outline-brown-light:not(:disabled):not(.disabled):active:focus, .btn-outline-brown-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-brown-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 172, 97, 0.5);
}

.btn-outline-brown {
  color: #f8bd5b;
  border-color: #f8bd5b;
}

.btn-outline-brown:hover {
  color: #3b444f;
  background-color: #f8bd5b;
  border-color: #f8bd5b;
}

.btn-outline-brown:focus, .btn-outline-brown.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 189, 91, 0.5);
}

.btn-outline-brown.disabled, .btn-outline-brown:disabled {
  color: #f8bd5b;
  background-color: transparent;
}

.btn-outline-brown:not(:disabled):not(.disabled):active, .btn-outline-brown:not(:disabled):not(.disabled).active,
.show > .btn-outline-brown.dropdown-toggle {
  color: #3b444f;
  background-color: #f8bd5b;
  border-color: #f8bd5b;
}

.btn-outline-brown:not(:disabled):not(.disabled):active:focus, .btn-outline-brown:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-brown.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 189, 91, 0.5);
}

.btn-outline-maroon {
  color: #c72f74;
  border-color: #c72f74;
}

.btn-outline-maroon:hover {
  color: #fff;
  background-color: #c72f74;
  border-color: #c72f74;
}

.btn-outline-maroon:focus, .btn-outline-maroon.focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 47, 116, 0.5);
}

.btn-outline-maroon.disabled, .btn-outline-maroon:disabled {
  color: #c72f74;
  background-color: transparent;
}

.btn-outline-maroon:not(:disabled):not(.disabled):active, .btn-outline-maroon:not(:disabled):not(.disabled).active,
.show > .btn-outline-maroon.dropdown-toggle {
  color: #fff;
  background-color: #c72f74;
  border-color: #c72f74;
}

.btn-outline-maroon:not(:disabled):not(.disabled):active:focus, .btn-outline-maroon:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-maroon.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 47, 116, 0.5);
}

.btn-outline-indigo-light {
  color: #080e7b;
  border-color: #080e7b;
}

.btn-outline-indigo-light:hover {
  color: #fff;
  background-color: #080e7b;
  border-color: #080e7b;
}

.btn-outline-indigo-light:focus, .btn-outline-indigo-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(8, 14, 123, 0.5);
}

.btn-outline-indigo-light.disabled, .btn-outline-indigo-light:disabled {
  color: #080e7b;
  background-color: transparent;
}

.btn-outline-indigo-light:not(:disabled):not(.disabled):active, .btn-outline-indigo-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-indigo-light.dropdown-toggle {
  color: #fff;
  background-color: #080e7b;
  border-color: #080e7b;
}

.btn-outline-indigo-light:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-indigo-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(8, 14, 123, 0.5);
}

.btn-outline-red-light-2 {
  color: #ff3c4e;
  border-color: #ff3c4e;
}

.btn-outline-red-light-2:hover {
  color: #fff;
  background-color: #ff3c4e;
  border-color: #ff3c4e;
}

.btn-outline-red-light-2:focus, .btn-outline-red-light-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 60, 78, 0.5);
}

.btn-outline-red-light-2.disabled, .btn-outline-red-light-2:disabled {
  color: #ff3c4e;
  background-color: transparent;
}

.btn-outline-red-light-2:not(:disabled):not(.disabled):active, .btn-outline-red-light-2:not(:disabled):not(.disabled).active,
.show > .btn-outline-red-light-2.dropdown-toggle {
  color: #fff;
  background-color: #ff3c4e;
  border-color: #ff3c4e;
}

.btn-outline-red-light-2:not(:disabled):not(.disabled):active:focus, .btn-outline-red-light-2:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-red-light-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 60, 78, 0.5);
}

.btn-outline-blue-dark-1 {
  color: #045cff;
  border-color: #045cff;
}

.btn-outline-blue-dark-1:hover {
  color: #fff;
  background-color: #045cff;
  border-color: #045cff;
}

.btn-outline-blue-dark-1:focus, .btn-outline-blue-dark-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 92, 255, 0.5);
}

.btn-outline-blue-dark-1.disabled, .btn-outline-blue-dark-1:disabled {
  color: #045cff;
  background-color: transparent;
}

.btn-outline-blue-dark-1:not(:disabled):not(.disabled):active, .btn-outline-blue-dark-1:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-dark-1.dropdown-toggle {
  color: #fff;
  background-color: #045cff;
  border-color: #045cff;
}

.btn-outline-blue-dark-1:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-dark-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-dark-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 92, 255, 0.5);
}

.btn-outline-gray-1 {
  color: #67747c;
  border-color: #67747c;
}

.btn-outline-gray-1:hover {
  color: #fff;
  background-color: #67747c;
  border-color: #67747c;
}

.btn-outline-gray-1:focus, .btn-outline-gray-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 116, 124, 0.5);
}

.btn-outline-gray-1.disabled, .btn-outline-gray-1:disabled {
  color: #67747c;
  background-color: transparent;
}

.btn-outline-gray-1:not(:disabled):not(.disabled):active, .btn-outline-gray-1:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-1.dropdown-toggle {
  color: #fff;
  background-color: #67747c;
  border-color: #67747c;
}

.btn-outline-gray-1:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 116, 124, 0.5);
}

.btn-outline-gray-2 {
  color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-outline-gray-2:hover {
  color: #3b444f;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-outline-gray-2:focus, .btn-outline-gray-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 248, 249, 0.5);
}

.btn-outline-gray-2.disabled, .btn-outline-gray-2:disabled {
  color: #f7f8f9;
  background-color: transparent;
}

.btn-outline-gray-2:not(:disabled):not(.disabled):active, .btn-outline-gray-2:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-2.dropdown-toggle {
  color: #3b444f;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-outline-gray-2:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-2:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 248, 249, 0.5);
}

.btn-outline-gray-3 {
  color: #3b444f;
  border-color: #3b444f;
}

.btn-outline-gray-3:hover {
  color: #fff;
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-outline-gray-3:focus, .btn-outline-gray-3.focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 68, 79, 0.5);
}

.btn-outline-gray-3.disabled, .btn-outline-gray-3:disabled {
  color: #3b444f;
  background-color: transparent;
}

.btn-outline-gray-3:not(:disabled):not(.disabled):active, .btn-outline-gray-3:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-3.dropdown-toggle {
  color: #fff;
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-outline-gray-3:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-3:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 68, 79, 0.5);
}

.btn-outline-gray-4 {
  color: #eceff3;
  border-color: #eceff3;
}

.btn-outline-gray-4:hover {
  color: #3b444f;
  background-color: #eceff3;
  border-color: #eceff3;
}

.btn-outline-gray-4:focus, .btn-outline-gray-4.focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 239, 243, 0.5);
}

.btn-outline-gray-4.disabled, .btn-outline-gray-4:disabled {
  color: #eceff3;
  background-color: transparent;
}

.btn-outline-gray-4:not(:disabled):not(.disabled):active, .btn-outline-gray-4:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-4.dropdown-toggle {
  color: #3b444f;
  background-color: #eceff3;
  border-color: #eceff3;
}

.btn-outline-gray-4:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-4:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-4.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(236, 239, 243, 0.5);
}

.btn-outline-blue {
  color: #43c5db;
  border-color: #43c5db;
}

.btn-outline-blue:hover {
  color: #3b444f;
  background-color: #43c5db;
  border-color: #43c5db;
}

.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 197, 219, 0.5);
}

.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #43c5db;
  background-color: transparent;
}

.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  color: #3b444f;
  background-color: #43c5db;
  border-color: #43c5db;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 197, 219, 0.5);
}

.btn-outline-blue-1 {
  color: #297cbb;
  border-color: #297cbb;
}

.btn-outline-blue-1:hover {
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-outline-blue-1:focus, .btn-outline-blue-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.5);
}

.btn-outline-blue-1.disabled, .btn-outline-blue-1:disabled {
  color: #297cbb;
  background-color: transparent;
}

.btn-outline-blue-1:not(:disabled):not(.disabled):active, .btn-outline-blue-1:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-1.dropdown-toggle {
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-outline-blue-1:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.5);
}

.btn-outline-gray-5 {
  color: #18181b;
  border-color: #18181b;
}

.btn-outline-gray-5:hover {
  color: #fff;
  background-color: #18181b;
  border-color: #18181b;
}

.btn-outline-gray-5:focus, .btn-outline-gray-5.focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 24, 27, 0.5);
}

.btn-outline-gray-5.disabled, .btn-outline-gray-5:disabled {
  color: #18181b;
  background-color: transparent;
}

.btn-outline-gray-5:not(:disabled):not(.disabled):active, .btn-outline-gray-5:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-5.dropdown-toggle {
  color: #fff;
  background-color: #18181b;
  border-color: #18181b;
}

.btn-outline-gray-5:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-5:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-5.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 24, 27, 0.5);
}

.btn-outline-gray-6 {
  color: #18181a;
  border-color: #18181a;
}

.btn-outline-gray-6:hover {
  color: #fff;
  background-color: #18181a;
  border-color: #18181a;
}

.btn-outline-gray-6:focus, .btn-outline-gray-6.focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 24, 26, 0.5);
}

.btn-outline-gray-6.disabled, .btn-outline-gray-6:disabled {
  color: #18181a;
  background-color: transparent;
}

.btn-outline-gray-6:not(:disabled):not(.disabled):active, .btn-outline-gray-6:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-6.dropdown-toggle {
  color: #fff;
  background-color: #18181a;
  border-color: #18181a;
}

.btn-outline-gray-6:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-6:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-6.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 24, 26, 0.5);
}

.btn-outline-gray-7 {
  color: #5c6770;
  border-color: #5c6770;
}

.btn-outline-gray-7:hover {
  color: #fff;
  background-color: #5c6770;
  border-color: #5c6770;
}

.btn-outline-gray-7:focus, .btn-outline-gray-7.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 103, 112, 0.5);
}

.btn-outline-gray-7.disabled, .btn-outline-gray-7:disabled {
  color: #5c6770;
  background-color: transparent;
}

.btn-outline-gray-7:not(:disabled):not(.disabled):active, .btn-outline-gray-7:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-7.dropdown-toggle {
  color: #fff;
  background-color: #5c6770;
  border-color: #5c6770;
}

.btn-outline-gray-7:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-7:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-7.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 103, 112, 0.5);
}

.btn-outline-gray-8 {
  color: #c5cdd8;
  border-color: #c5cdd8;
}

.btn-outline-gray-8:hover {
  color: #3b444f;
  background-color: #c5cdd8;
  border-color: #c5cdd8;
}

.btn-outline-gray-8:focus, .btn-outline-gray-8.focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 205, 216, 0.5);
}

.btn-outline-gray-8.disabled, .btn-outline-gray-8:disabled {
  color: #c5cdd8;
  background-color: transparent;
}

.btn-outline-gray-8:not(:disabled):not(.disabled):active, .btn-outline-gray-8:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-8.dropdown-toggle {
  color: #3b444f;
  background-color: #c5cdd8;
  border-color: #c5cdd8;
}

.btn-outline-gray-8:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-8:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-8.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 205, 216, 0.5);
}

.btn-outline-gray-9 {
  color: #9fa9b8;
  border-color: #9fa9b8;
}

.btn-outline-gray-9:hover {
  color: #3b444f;
  background-color: #9fa9b8;
  border-color: #9fa9b8;
}

.btn-outline-gray-9:focus, .btn-outline-gray-9.focus {
  box-shadow: 0 0 0 0.2rem rgba(159, 169, 184, 0.5);
}

.btn-outline-gray-9.disabled, .btn-outline-gray-9:disabled {
  color: #9fa9b8;
  background-color: transparent;
}

.btn-outline-gray-9:not(:disabled):not(.disabled):active, .btn-outline-gray-9:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-9.dropdown-toggle {
  color: #3b444f;
  background-color: #9fa9b8;
  border-color: #9fa9b8;
}

.btn-outline-gray-9:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-9:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-9.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(159, 169, 184, 0.5);
}

.btn-outline-gray-10 {
  color: #c1c1c1;
  border-color: #c1c1c1;
}

.btn-outline-gray-10:hover {
  color: #3b444f;
  background-color: #c1c1c1;
  border-color: #c1c1c1;
}

.btn-outline-gray-10:focus, .btn-outline-gray-10.focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 193, 0.5);
}

.btn-outline-gray-10.disabled, .btn-outline-gray-10:disabled {
  color: #c1c1c1;
  background-color: transparent;
}

.btn-outline-gray-10:not(:disabled):not(.disabled):active, .btn-outline-gray-10:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-10.dropdown-toggle {
  color: #3b444f;
  background-color: #c1c1c1;
  border-color: #c1c1c1;
}

.btn-outline-gray-10:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-10:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 193, 0.5);
}

.btn-outline-gray-11 {
  color: #484848;
  border-color: #484848;
}

.btn-outline-gray-11:hover {
  color: #fff;
  background-color: #484848;
  border-color: #484848;
}

.btn-outline-gray-11:focus, .btn-outline-gray-11.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 72, 72, 0.5);
}

.btn-outline-gray-11.disabled, .btn-outline-gray-11:disabled {
  color: #484848;
  background-color: transparent;
}

.btn-outline-gray-11:not(:disabled):not(.disabled):active, .btn-outline-gray-11:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-11.dropdown-toggle {
  color: #fff;
  background-color: #484848;
  border-color: #484848;
}

.btn-outline-gray-11:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-11:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-11.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 72, 72, 0.5);
}

.btn-outline-gray-23 {
  color: #f4f4f4;
  border-color: #f4f4f4;
}

.btn-outline-gray-23:hover {
  color: #3b444f;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.btn-outline-gray-23:focus, .btn-outline-gray-23.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.btn-outline-gray-23.disabled, .btn-outline-gray-23:disabled {
  color: #f4f4f4;
  background-color: transparent;
}

.btn-outline-gray-23:not(:disabled):not(.disabled):active, .btn-outline-gray-23:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-23.dropdown-toggle {
  color: #3b444f;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.btn-outline-gray-23:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-23:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-23.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.btn-outline-gray-33 {
  color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-outline-gray-33:hover {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-outline-gray-33:focus, .btn-outline-gray-33.focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 240, 247, 0.5);
}

.btn-outline-gray-33.disabled, .btn-outline-gray-33:disabled {
  color: #ebf0f7;
  background-color: transparent;
}

.btn-outline-gray-33:not(:disabled):not(.disabled):active, .btn-outline-gray-33:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-33.dropdown-toggle {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-outline-gray-33:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-33:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-33.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 240, 247, 0.5);
}

.btn-outline-sea-green {
  color: #1d508d;
  border-color: #1d508d;
}

.btn-outline-sea-green:hover {
  color: #fff;
  background-color: #1d508d;
  border-color: #1d508d;
}

.btn-outline-sea-green:focus, .btn-outline-sea-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 80, 141, 0.5);
}

.btn-outline-sea-green.disabled, .btn-outline-sea-green:disabled {
  color: #1d508d;
  background-color: transparent;
}

.btn-outline-sea-green:not(:disabled):not(.disabled):active, .btn-outline-sea-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-sea-green.dropdown-toggle {
  color: #fff;
  background-color: #1d508d;
  border-color: #1d508d;
}

.btn-outline-sea-green:not(:disabled):not(.disabled):active:focus, .btn-outline-sea-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-sea-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 80, 141, 0.5);
}

.btn-outline-dark-1 {
  color: #1e2022;
  border-color: #1e2022;
}

.btn-outline-dark-1:hover {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}

.btn-outline-dark-1:focus, .btn-outline-dark-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 32, 34, 0.5);
}

.btn-outline-dark-1.disabled, .btn-outline-dark-1:disabled {
  color: #1e2022;
  background-color: transparent;
}

.btn-outline-dark-1:not(:disabled):not(.disabled):active, .btn-outline-dark-1:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-1.dropdown-toggle {
  color: #fff;
  background-color: #1e2022;
  border-color: #1e2022;
}

.btn-outline-dark-1:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 32, 34, 0.5);
}

.btn-outline-navy {
  color: #0057ff;
  border-color: #0057ff;
}

.btn-outline-navy:hover {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-outline-navy:focus, .btn-outline-navy.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
}

.btn-outline-navy.disabled, .btn-outline-navy:disabled {
  color: #0057ff;
  background-color: transparent;
}

.btn-outline-navy:not(:disabled):not(.disabled):active, .btn-outline-navy:not(:disabled):not(.disabled).active,
.show > .btn-outline-navy.dropdown-toggle {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-outline-navy:not(:disabled):not(.disabled):active:focus, .btn-outline-navy:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-navy.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
}

.btn-outline-pink {
  color: #ffe5ee;
  border-color: #ffe5ee;
}

.btn-outline-pink:hover {
  color: #3b444f;
  background-color: #ffe5ee;
  border-color: #ffe5ee;
}

.btn-outline-pink:focus, .btn-outline-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 229, 238, 0.5);
}

.btn-outline-pink.disabled, .btn-outline-pink:disabled {
  color: #ffe5ee;
  background-color: transparent;
}

.btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
.show > .btn-outline-pink.dropdown-toggle {
  color: #3b444f;
  background-color: #ffe5ee;
  border-color: #ffe5ee;
}

.btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 229, 238, 0.5);
}

.btn-outline-dark-2 {
  color: #1a2b49;
  border-color: #1a2b49;
}

.btn-outline-dark-2:hover {
  color: #fff;
  background-color: #1a2b49;
  border-color: #1a2b49;
}

.btn-outline-dark-2:focus, .btn-outline-dark-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 43, 73, 0.5);
}

.btn-outline-dark-2.disabled, .btn-outline-dark-2:disabled {
  color: #1a2b49;
  background-color: transparent;
}

.btn-outline-dark-2:not(:disabled):not(.disabled):active, .btn-outline-dark-2:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-2.dropdown-toggle {
  color: #fff;
  background-color: #1a2b49;
  border-color: #1a2b49;
}

.btn-outline-dark-2:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-2:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(26, 43, 73, 0.5);
}

.btn-outline-yellow-lighter-2 {
  color: #f7bc5a;
  border-color: #f7bc5a;
}

.btn-outline-yellow-lighter-2:hover {
  color: #3b444f;
  background-color: #f7bc5a;
  border-color: #f7bc5a;
}

.btn-outline-yellow-lighter-2:focus, .btn-outline-yellow-lighter-2.focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 188, 90, 0.5);
}

.btn-outline-yellow-lighter-2.disabled, .btn-outline-yellow-lighter-2:disabled {
  color: #f7bc5a;
  background-color: transparent;
}

.btn-outline-yellow-lighter-2:not(:disabled):not(.disabled):active, .btn-outline-yellow-lighter-2:not(:disabled):not(.disabled).active,
.show > .btn-outline-yellow-lighter-2.dropdown-toggle {
  color: #3b444f;
  background-color: #f7bc5a;
  border-color: #f7bc5a;
}

.btn-outline-yellow-lighter-2:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow-lighter-2:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-yellow-lighter-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 188, 90, 0.5);
}

.btn-outline-orange {
  color: #f05700;
  border-color: #f05700;
}

.btn-outline-orange:hover {
  color: #fff;
  background-color: #f05700;
  border-color: #f05700;
}

.btn-outline-orange:focus, .btn-outline-orange.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 87, 0, 0.5);
}

.btn-outline-orange.disabled, .btn-outline-orange:disabled {
  color: #f05700;
  background-color: transparent;
}

.btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange.dropdown-toggle {
  color: #fff;
  background-color: #f05700;
  border-color: #f05700;
}

.btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 87, 0, 0.5);
}

.btn-outline-green {
  color: #28871c;
  border-color: #28871c;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #28871c;
  border-color: #28871c;
}

.btn-outline-green:focus, .btn-outline-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 135, 28, 0.5);
}

.btn-outline-green.disabled, .btn-outline-green:disabled {
  color: #28871c;
  background-color: transparent;
}

.btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-green.dropdown-toggle {
  color: #fff;
  background-color: #28871c;
  border-color: #28871c;
}

.btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 135, 28, 0.5);
}

.btn-outline-pink-1 {
  color: #ff0045;
  border-color: #ff0045;
}

.btn-outline-pink-1:hover {
  color: #fff;
  background-color: #ff0045;
  border-color: #ff0045;
}

.btn-outline-pink-1:focus, .btn-outline-pink-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 69, 0.5);
}

.btn-outline-pink-1.disabled, .btn-outline-pink-1:disabled {
  color: #ff0045;
  background-color: transparent;
}

.btn-outline-pink-1:not(:disabled):not(.disabled):active, .btn-outline-pink-1:not(:disabled):not(.disabled).active,
.show > .btn-outline-pink-1.dropdown-toggle {
  color: #fff;
  background-color: #ff0045;
  border-color: #ff0045;
}

.btn-outline-pink-1:not(:disabled):not(.disabled):active:focus, .btn-outline-pink-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pink-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 69, 0.5);
}

.btn-outline-purple-1 {
  color: #232753;
  border-color: #232753;
}

.btn-outline-purple-1:hover {
  color: #fff;
  background-color: #232753;
  border-color: #232753;
}

.btn-outline-purple-1:focus, .btn-outline-purple-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 39, 83, 0.5);
}

.btn-outline-purple-1.disabled, .btn-outline-purple-1:disabled {
  color: #232753;
  background-color: transparent;
}

.btn-outline-purple-1:not(:disabled):not(.disabled):active, .btn-outline-purple-1:not(:disabled):not(.disabled).active,
.show > .btn-outline-purple-1.dropdown-toggle {
  color: #fff;
  background-color: #232753;
  border-color: #232753;
}

.btn-outline-purple-1:not(:disabled):not(.disabled):active:focus, .btn-outline-purple-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(35, 39, 83, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #297cbb;
  text-decoration: none;
}

.btn-link:hover {
  color: #1b527c;
  text-decoration: none;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #8c98a4;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

/*-------------------------------------------*\
    1.10. Transitions
\*-------------------------------------------*/
.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

/*-------------------------------------------*\
    1.11. Dropdown
\*-------------------------------------------*/
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 8.4375rem;
  padding: 1rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #3b444f;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.3125rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-wd-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-wd-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e7eaf3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #67747c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #3b444f;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #8c98a4;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #67747c;
}

/*-------------------------------------------*\
    1.12. Button-group
\*-------------------------------------------*/
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

/*-------------------------------------------*\
    1.13. Input-group
\*-------------------------------------------*/
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.9rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8c98a4;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 2px solid #ebf0f7;
  border-radius: 0.3125rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 2.25rem + 4px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.125rem 1.125rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 1.25rem + 4px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom__input-group > .input-group-append > .btn {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.custom__input-group > .form-control:not(:last-child) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/*-------------------------------------------*\
    1.14. Custom-forms
\*-------------------------------------------*/
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.813rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #297cbb;
  background-color: #297cbb;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ebf0f7;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #aad0ec;
  border-color: #aad0ec;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #8c98a4;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f8fafd;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.0935rem;
  left: -1.813rem;
  display: block;
  width: 1.313rem;
  height: 1.313rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #ebf0f7 solid 2px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.0935rem;
  left: -1.813rem;
  display: block;
  width: 1.313rem;
  height: 1.313rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.3125rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #297cbb;
  background-color: #297cbb;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(41, 124, 187, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(41, 124, 187, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(41, 124, 187, 0.5);
}

.custom-switch {
  padding-left: 4.5rem;
}

.custom-switch .custom-control-label::before {
  left: -4.5rem;
  width: 4rem;
  pointer-events: all;
  border-radius: 0.6565rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.0935rem + 4px);
  left: calc(-4.5rem + 4px);
  width: 1.25rem;
  height: 1.25rem;
  background-color: #ebf0f7;
  border-radius: 0.6565rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(2.687rem);
          transform: translateX(2.687rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(41, 124, 187, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.8rem + 4px);
  padding: 0.9rem 2rem 0.9rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3b444f;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  background-color: #fff;
  border: 2px solid #d5dae2;
  border-radius: 0.3125rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-select:focus {
  border-color: rgba(41, 124, 187, 0.5);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.25);
}

.custom-select:focus::-ms-value {
  color: #3b444f;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.custom-select:disabled {
  color: #8c98a4;
  background-color: #f8fafd;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 1.25rem + 4px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.125rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 2.25rem + 4px);
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-left: 1.125rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1.8rem + 4px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1.8rem + 4px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #ebf0f7;
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #f8fafd;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1.8rem + 4px);
  padding: 0.9rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3b444f;
  background-color: #fff;
  border: 2px solid #ebf0f7;
  border-radius: 0.3125rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1.8rem);
  padding: 0.9rem 1rem;
  line-height: 1.5;
  color: #3b444f;
  content: "Browse";
  background-color: #fff;
  border-left: inherit;
  border-radius: 0 0.3125rem 0.3125rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(41, 124, 187, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(41, 124, 187, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(41, 124, 187, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #297cbb;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #aad0ec;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #297cbb;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #aad0ec;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e7eaf3;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #297cbb;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #aad0ec;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #e7eaf3;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e7eaf3;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #97a4af;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #97a4af;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #97a4af;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

/*-------------------------------------------*\
    1.15. Nav
\*-------------------------------------------*/
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.438rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #8c98a4;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e7eaf3;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f8fafd #f8fafd #e7eaf3;
}

.nav-tabs .nav-link.disabled {
  color: #8c98a4;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #77838f;
  background-color: #fff;
  border-color: #e7eaf3 #e7eaf3 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.3125rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #297cbb;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

/*-------------------------------------------*\
    1.16. Navbar
\*-------------------------------------------*/
.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0.5rem 1.875rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.2505rem;
  padding-bottom: 0.2505rem;
  margin-right: 1.875rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.438rem;
  padding-bottom: 0.438rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-align: center;
      align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.3125rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1479.98px) {
  .navbar-expand-wd > .container,
  .navbar-expand-wd > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1480px) {
  .navbar-expand-wd {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start;
  }
  .navbar-expand-wd .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .navbar-expand-wd .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-wd .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-wd > .container,
  .navbar-expand-wd > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-wd .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-wd .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
      flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

/*-------------------------------------------*\
    1.17. Card
\*-------------------------------------------*/
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e7eaf3;
  border-radius: 0.3125rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #e7eaf3;
}

.card-header:first-child {
  border-radius: calc(0.3125rem - 1px) calc(0.3125rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1rem 1rem;
  background-color: #fff;
  border-top: 1px solid #e7eaf3;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.3125rem - 1px) calc(0.3125rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -1rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.3125rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.3125rem - 1px);
  border-top-right-radius: calc(0.3125rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.3125rem - 1px);
  border-bottom-left-radius: calc(0.3125rem - 1px);
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: -1px;
}

/*-------------------------------------------*\
    1.18. Breadcrumb
\*-------------------------------------------*/
.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.3125rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #67747c;
  content: ">";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #67747c;
}

/*-------------------------------------------*\
    1.19. Pagination
\*-------------------------------------------*/
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.3125rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #77838f;
  background-color: #fff;
  border: 0 solid #77838f;
}

.page-link:hover {
  z-index: 2;
  color: #297cbb;
  text-decoration: none;
  background-color: rgba(41, 124, 187, 0.1);
  border-color: rgba(41, 124, 187, 0.1);
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.page-item.disabled .page-link {
  color: #8c98a4;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e7eaf3;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*-------------------------------------------*\
    1.20. Badge
\*-------------------------------------------*/
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.3125rem;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #297cbb;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #206091;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #77838f;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #5f6a74;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(119, 131, 143, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #00c9a7;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #00967d;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 201, 167, 0.5);
}

.badge-info {
  color: #3b444f;
  background-color: #00dffc;
}

a.badge-info:hover, a.badge-info:focus {
  color: #3b444f;
  background-color: #00b2c9;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5);
}

.badge-warning {
  color: #3b444f;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #3b444f;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #de4437;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c22d20;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(222, 68, 55, 0.5);
}

.badge-light {
  color: #3b444f;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #3b444f;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #3b444f;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #252b32;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(59, 68, 79, 0.5);
}

.badge-white {
  color: #3b444f;
  background-color: #fff;
}

a.badge-white:hover, a.badge-white:focus {
  color: #3b444f;
  background-color: #e6e6e6;
}

a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-indigo {
  color: #fff;
  background-color: #2d1582;
}

a.badge-indigo:hover, a.badge-indigo:focus {
  color: #fff;
  background-color: #1e0e56;
}

a.badge-indigo:focus, a.badge-indigo.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 21, 130, 0.5);
}

.badge-gray {
  color: #3b444f;
  background-color: #ebf0f7;
}

a.badge-gray:hover, a.badge-gray:focus {
  color: #3b444f;
  background-color: #c7d5e8;
}

a.badge-gray:focus, a.badge-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 240, 247, 0.5);
}

.badge-violet {
  color: #fff;
  background-color: #532184;
}

a.badge-violet:hover, a.badge-violet:focus {
  color: #fff;
  background-color: #39175b;
}

a.badge-violet:focus, a.badge-violet.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(83, 33, 132, 0.5);
}

.badge-violet-1 {
  color: #fff;
  background-color: #a864a8;
}

a.badge-violet-1:hover, a.badge-violet-1:focus {
  color: #fff;
  background-color: #8b4e8b;
}

a.badge-violet-1:focus, a.badge-violet-1.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(168, 100, 168, 0.5);
}

.badge-violet-light {
  color: #fff;
  background-color: #6f13cd;
}

a.badge-violet-light:hover, a.badge-violet-light:focus {
  color: #fff;
  background-color: #560f9e;
}

a.badge-violet-light:focus, a.badge-violet-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(111, 19, 205, 0.5);
}

.badge-brown-light {
  color: #3b444f;
  background-color: #ceac61;
}

a.badge-brown-light:hover, a.badge-brown-light:focus {
  color: #3b444f;
  background-color: #c0973c;
}

a.badge-brown-light:focus, a.badge-brown-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(206, 172, 97, 0.5);
}

.badge-brown {
  color: #3b444f;
  background-color: #f8bd5b;
}

a.badge-brown:hover, a.badge-brown:focus {
  color: #3b444f;
  background-color: #f6a92a;
}

a.badge-brown:focus, a.badge-brown.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 189, 91, 0.5);
}

.badge-maroon {
  color: #fff;
  background-color: #c72f74;
}

a.badge-maroon:hover, a.badge-maroon:focus {
  color: #fff;
  background-color: #9e255c;
}

a.badge-maroon:focus, a.badge-maroon.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(199, 47, 116, 0.5);
}

.badge-indigo-light {
  color: #fff;
  background-color: #080e7b;
}

a.badge-indigo-light:hover, a.badge-indigo-light:focus {
  color: #fff;
  background-color: #05094b;
}

a.badge-indigo-light:focus, a.badge-indigo-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(8, 14, 123, 0.5);
}

.badge-red-light-2 {
  color: #fff;
  background-color: #ff3c4e;
}

a.badge-red-light-2:hover, a.badge-red-light-2:focus {
  color: #fff;
  background-color: #ff0920;
}

a.badge-red-light-2:focus, a.badge-red-light-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 60, 78, 0.5);
}

.badge-blue-dark-1 {
  color: #fff;
  background-color: #045cff;
}

a.badge-blue-dark-1:hover, a.badge-blue-dark-1:focus {
  color: #fff;
  background-color: #0049d0;
}

a.badge-blue-dark-1:focus, a.badge-blue-dark-1.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 92, 255, 0.5);
}

.badge-gray-1 {
  color: #fff;
  background-color: #67747c;
}

a.badge-gray-1:hover, a.badge-gray-1:focus {
  color: #fff;
  background-color: #505a60;
}

a.badge-gray-1:focus, a.badge-gray-1.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(103, 116, 124, 0.5);
}

.badge-gray-2 {
  color: #3b444f;
  background-color: #f7f8f9;
}

a.badge-gray-2:hover, a.badge-gray-2:focus {
  color: #3b444f;
  background-color: #dadfe3;
}

a.badge-gray-2:focus, a.badge-gray-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(247, 248, 249, 0.5);
}

.badge-gray-3 {
  color: #fff;
  background-color: #3b444f;
}

a.badge-gray-3:hover, a.badge-gray-3:focus {
  color: #fff;
  background-color: #252b32;
}

a.badge-gray-3:focus, a.badge-gray-3.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(59, 68, 79, 0.5);
}

.badge-gray-4 {
  color: #3b444f;
  background-color: #eceff3;
}

a.badge-gray-4:hover, a.badge-gray-4:focus {
  color: #3b444f;
  background-color: #cdd5df;
}

a.badge-gray-4:focus, a.badge-gray-4.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(236, 239, 243, 0.5);
}

.badge-blue {
  color: #3b444f;
  background-color: #43c5db;
}

a.badge-blue:hover, a.badge-blue:focus {
  color: #3b444f;
  background-color: #26aec5;
}

a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 197, 219, 0.5);
}

.badge-blue-1 {
  color: #fff;
  background-color: #297cbb;
}

a.badge-blue-1:hover, a.badge-blue-1:focus {
  color: #fff;
  background-color: #206091;
}

a.badge-blue-1:focus, a.badge-blue-1.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(41, 124, 187, 0.5);
}

.badge-gray-5 {
  color: #fff;
  background-color: #18181b;
}

a.badge-gray-5:hover, a.badge-gray-5:focus {
  color: #fff;
  background-color: black;
}

a.badge-gray-5:focus, a.badge-gray-5.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(24, 24, 27, 0.5);
}

.badge-gray-6 {
  color: #fff;
  background-color: #18181a;
}

a.badge-gray-6:hover, a.badge-gray-6:focus {
  color: #fff;
  background-color: black;
}

a.badge-gray-6:focus, a.badge-gray-6.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(24, 24, 26, 0.5);
}

.badge-gray-7 {
  color: #fff;
  background-color: #5c6770;
}

a.badge-gray-7:hover, a.badge-gray-7:focus {
  color: #fff;
  background-color: #454d54;
}

a.badge-gray-7:focus, a.badge-gray-7.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(92, 103, 112, 0.5);
}

.badge-gray-8 {
  color: #3b444f;
  background-color: #c5cdd8;
}

a.badge-gray-8:hover, a.badge-gray-8:focus {
  color: #3b444f;
  background-color: #a7b3c3;
}

a.badge-gray-8:focus, a.badge-gray-8.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(197, 205, 216, 0.5);
}

.badge-gray-9 {
  color: #3b444f;
  background-color: #9fa9b8;
}

a.badge-gray-9:hover, a.badge-gray-9:focus {
  color: #3b444f;
  background-color: #828fa2;
}

a.badge-gray-9:focus, a.badge-gray-9.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(159, 169, 184, 0.5);
}

.badge-gray-10 {
  color: #3b444f;
  background-color: #c1c1c1;
}

a.badge-gray-10:hover, a.badge-gray-10:focus {
  color: #3b444f;
  background-color: #a8a8a8;
}

a.badge-gray-10:focus, a.badge-gray-10.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 193, 0.5);
}

.badge-gray-11 {
  color: #fff;
  background-color: #484848;
}

a.badge-gray-11:hover, a.badge-gray-11:focus {
  color: #fff;
  background-color: #2f2f2f;
}

a.badge-gray-11:focus, a.badge-gray-11.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(72, 72, 72, 0.5);
}

.badge-gray-23 {
  color: #3b444f;
  background-color: #f4f4f4;
}

a.badge-gray-23:hover, a.badge-gray-23:focus {
  color: #3b444f;
  background-color: #dbdbdb;
}

a.badge-gray-23:focus, a.badge-gray-23.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5);
}

.badge-gray-33 {
  color: #3b444f;
  background-color: #ebf0f7;
}

a.badge-gray-33:hover, a.badge-gray-33:focus {
  color: #3b444f;
  background-color: #c7d5e8;
}

a.badge-gray-33:focus, a.badge-gray-33.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 240, 247, 0.5);
}

.badge-sea-green {
  color: #fff;
  background-color: #1d508d;
}

a.badge-sea-green:hover, a.badge-sea-green:focus {
  color: #fff;
  background-color: #143863;
}

a.badge-sea-green:focus, a.badge-sea-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(29, 80, 141, 0.5);
}

.badge-dark-1 {
  color: #fff;
  background-color: #1e2022;
}

a.badge-dark-1:hover, a.badge-dark-1:focus {
  color: #fff;
  background-color: #060707;
}

a.badge-dark-1:focus, a.badge-dark-1.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(30, 32, 34, 0.5);
}

.badge-navy {
  color: #fff;
  background-color: #0057ff;
}

a.badge-navy:hover, a.badge-navy:focus {
  color: #fff;
  background-color: #0046cc;
}

a.badge-navy:focus, a.badge-navy.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 87, 255, 0.5);
}

.badge-pink {
  color: #3b444f;
  background-color: #ffe5ee;
}

a.badge-pink:hover, a.badge-pink:focus {
  color: #3b444f;
  background-color: #ffb2cd;
}

a.badge-pink:focus, a.badge-pink.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 229, 238, 0.5);
}

.badge-dark-2 {
  color: #fff;
  background-color: #1a2b49;
}

a.badge-dark-2:hover, a.badge-dark-2:focus {
  color: #fff;
  background-color: #0d1523;
}

a.badge-dark-2:focus, a.badge-dark-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(26, 43, 73, 0.5);
}

.badge-yellow-lighter-2 {
  color: #3b444f;
  background-color: #f7bc5a;
}

a.badge-yellow-lighter-2:hover, a.badge-yellow-lighter-2:focus {
  color: #3b444f;
  background-color: #f5a829;
}

a.badge-yellow-lighter-2:focus, a.badge-yellow-lighter-2.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(247, 188, 90, 0.5);
}

.badge-orange {
  color: #fff;
  background-color: #f05700;
}

a.badge-orange:hover, a.badge-orange:focus {
  color: #fff;
  background-color: #bd4500;
}

a.badge-orange:focus, a.badge-orange.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(240, 87, 0, 0.5);
}

.badge-green {
  color: #fff;
  background-color: #28871c;
}

a.badge-green:hover, a.badge-green:focus {
  color: #fff;
  background-color: #1b5d13;
}

a.badge-green:focus, a.badge-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 135, 28, 0.5);
}

.badge-pink-1 {
  color: #fff;
  background-color: #ff0045;
}

a.badge-pink-1:hover, a.badge-pink-1:focus {
  color: #fff;
  background-color: #cc0037;
}

a.badge-pink-1:focus, a.badge-pink-1.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 69, 0.5);
}

.badge-purple-1 {
  color: #fff;
  background-color: #232753;
}

a.badge-purple-1:hover, a.badge-purple-1:focus {
  color: #fff;
  background-color: #14162f;
}

a.badge-purple-1:focus, a.badge-purple-1.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(35, 39, 83, 0.5);
}

/*-------------------------------------------*\
    1.21. Jumbotron
\*-------------------------------------------*/
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f8fafd;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

/*-------------------------------------------*\
    1.22. Alert
\*-------------------------------------------*/
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.3125rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.75rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #154061;
  background-color: #d4e5f1;
  border-color: #c3daec;
}

.alert-primary hr {
  border-top-color: #b0cee6;
}

.alert-primary .alert-link {
  color: #0c2437;
}

.alert-secondary {
  color: #3e444a;
  background-color: #e4e6e9;
  border-color: #d9dce0;
}

.alert-secondary hr {
  border-top-color: #cbcfd5;
}

.alert-secondary .alert-link {
  color: #272b2e;
}

.alert-success {
  color: #006957;
  background-color: #ccf4ed;
  border-color: #b8f0e6;
}

.alert-success hr {
  border-top-color: #a3ecdf;
}

.alert-success .alert-link {
  color: #00362d;
}

.alert-info {
  color: #007483;
  background-color: #ccf9fe;
  border-color: #b8f6fe;
}

.alert-info hr {
  border-top-color: #9ff3fe;
}

.alert-info .alert-link {
  color: #004750;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #73231d;
  background-color: #f8dad7;
  border-color: #f6cbc7;
}

.alert-danger hr {
  border-top-color: #f2b7b1;
}

.alert-danger .alert-link {
  color: #4a1713;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1f2329;
  background-color: #d8dadc;
  border-color: #c8cbce;
}

.alert-dark hr {
  border-top-color: #bbbec2;
}

.alert-dark .alert-link {
  color: #090a0c;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-white hr {
  border-top-color: #f2f2f2;
}

.alert-white .alert-link {
  color: #6c6c6c;
}

.alert-indigo {
  color: #170b44;
  background-color: #d5d0e6;
  border-color: #c4bddc;
}

.alert-indigo hr {
  border-top-color: #b5acd3;
}

.alert-indigo .alert-link {
  color: #080418;
}

.alert-gray {
  color: #7a7d80;
  background-color: #fbfcfd;
  border-color: #f9fbfd;
}

.alert-gray hr {
  border-top-color: #e6eef7;
}

.alert-gray .alert-link {
  color: #616466;
}

.alert-violet {
  color: #2b1145;
  background-color: #ddd3e6;
  border-color: #cfc1dd;
}

.alert-violet hr {
  border-top-color: #c2b1d4;
}

.alert-violet .alert-link {
  color: #12071c;
}

.alert-violet-1 {
  color: #573457;
  background-color: #eee0ee;
  border-color: #e7d4e7;
}

.alert-violet-1 hr {
  border-top-color: #dec4de;
}

.alert-violet-1 .alert-link {
  color: #372137;
}

.alert-violet-light {
  color: #3a0a6b;
  background-color: #e2d0f5;
  border-color: #d7bdf1;
}

.alert-violet-light hr {
  border-top-color: #caa8ed;
}

.alert-violet-light .alert-link {
  color: #21063c;
}

.alert-brown-light {
  color: #6b5932;
  background-color: #f5eedf;
  border-color: #f1e8d3;
}

.alert-brown-light hr {
  border-top-color: #ebdec0;
}

.alert-brown-light .alert-link {
  color: #483c22;
}

.alert-brown {
  color: #81622f;
  background-color: #fef2de;
  border-color: #fdedd1;
}

.alert-brown hr {
  border-top-color: #fce3b9;
}

.alert-brown .alert-link {
  color: #5c4621;
}

.alert-maroon {
  color: #67183c;
  background-color: #f4d5e3;
  border-color: #efc5d8;
}

.alert-maroon hr {
  border-top-color: #e9b1cb;
}

.alert-maroon .alert-link {
  color: #3e0e24;
}

.alert-indigo-light {
  color: #040740;
  background-color: #cecfe5;
  border-color: #babcda;
}

.alert-indigo-light hr {
  border-top-color: #a9acd1;
}

.alert-indigo-light .alert-link {
  color: #010210;
}

.alert-red-light-2 {
  color: #851f29;
  background-color: #ffd8dc;
  border-color: #ffc8cd;
}

.alert-red-light-2 hr {
  border-top-color: #ffafb6;
}

.alert-red-light-2 .alert-link {
  color: #5c151c;
}

.alert-blue-dark-1 {
  color: #023085;
  background-color: #cddeff;
  border-color: #b9d1ff;
}

.alert-blue-dark-1 hr {
  border-top-color: #a0c0ff;
}

.alert-blue-dark-1 .alert-link {
  color: #011e53;
}

.alert-gray-1 {
  color: #363c40;
  background-color: #e1e3e5;
  border-color: #d4d8da;
}

.alert-gray-1 hr {
  border-top-color: #c6ccce;
}

.alert-gray-1 .alert-link {
  color: #1f2224;
}

.alert-gray-2 {
  color: #808181;
  background-color: #fdfefe;
  border-color: #fdfdfd;
}

.alert-gray-2 hr {
  border-top-color: #f0f0f0;
}

.alert-gray-2 .alert-link {
  color: #676767;
}

.alert-gray-3 {
  color: #1f2329;
  background-color: #d8dadc;
  border-color: #c8cbce;
}

.alert-gray-3 hr {
  border-top-color: #bbbec2;
}

.alert-gray-3 .alert-link {
  color: #090a0c;
}

.alert-gray-4 {
  color: #7b7c7e;
  background-color: #fbfcfd;
  border-color: #fafbfc;
}

.alert-gray-4 hr {
  border-top-color: #eaeef2;
}

.alert-gray-4 .alert-link {
  color: #626364;
}

.alert-blue {
  color: #236672;
  background-color: #d9f3f8;
  border-color: #caeff5;
}

.alert-blue hr {
  border-top-color: #b5e9f1;
}

.alert-blue .alert-link {
  color: #17434b;
}

.alert-blue-1 {
  color: #154061;
  background-color: #d4e5f1;
  border-color: #c3daec;
}

.alert-blue-1 hr {
  border-top-color: #b0cee6;
}

.alert-blue-1 .alert-link {
  color: #0c2437;
}

.alert-gray-5 {
  color: #0c0c0e;
  background-color: #d1d1d1;
  border-color: #bebebf;
}

.alert-gray-5 hr {
  border-top-color: #b1b1b2;
}

.alert-gray-5 .alert-link {
  color: black;
}

.alert-gray-6 {
  color: #0c0c0e;
  background-color: #d1d1d1;
  border-color: #bebebf;
}

.alert-gray-6 hr {
  border-top-color: #b1b1b2;
}

.alert-gray-6 .alert-link {
  color: black;
}

.alert-gray-7 {
  color: #30363a;
  background-color: #dee1e2;
  border-color: #d1d4d7;
}

.alert-gray-7 hr {
  border-top-color: #c3c7cb;
}

.alert-gray-7 .alert-link {
  color: #191c1e;
}

.alert-gray-8 {
  color: #666b70;
  background-color: #f3f5f7;
  border-color: #eff1f4;
}

.alert-gray-8 hr {
  border-top-color: #e0e4ea;
}

.alert-gray-8 .alert-link {
  color: #4e5255;
}

.alert-gray-9 {
  color: #535860;
  background-color: #eceef1;
  border-color: #e4e7eb;
}

.alert-gray-9 hr {
  border-top-color: #d5dae0;
}

.alert-gray-9 .alert-link {
  color: #3b3f45;
}

.alert-gray-10 {
  color: #646464;
  background-color: #f3f3f3;
  border-color: #eeeeee;
}

.alert-gray-10 hr {
  border-top-color: #e1e1e1;
}

.alert-gray-10 .alert-link {
  color: #4b4b4b;
}

.alert-gray-11 {
  color: #252525;
  background-color: #dadada;
  border-color: #cccccc;
}

.alert-gray-11 hr {
  border-top-color: #bfbfbf;
}

.alert-gray-11 .alert-link {
  color: #0c0c0c;
}

.alert-gray-23 {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}

.alert-gray-23 hr {
  border-top-color: #efefef;
}

.alert-gray-23 .alert-link {
  color: #666666;
}

.alert-gray-33 {
  color: #7a7d80;
  background-color: #fbfcfd;
  border-color: #f9fbfd;
}

.alert-gray-33 hr {
  border-top-color: #e6eef7;
}

.alert-gray-33 .alert-link {
  color: #616466;
}

.alert-sea-green {
  color: #0f2a49;
  background-color: #d2dce8;
  border-color: #c0cedf;
}

.alert-sea-green hr {
  border-top-color: #afc1d6;
}

.alert-sea-green .alert-link {
  color: #06121f;
}

.alert-dark-1 {
  color: #101112;
  background-color: #d2d2d3;
  border-color: #c0c1c1;
}

.alert-dark-1 hr {
  border-top-color: #b3b4b4;
}

.alert-dark-1 .alert-link {
  color: black;
}

.alert-navy {
  color: #002d85;
  background-color: #ccddff;
  border-color: #b8d0ff;
}

.alert-navy hr {
  border-top-color: #9fbfff;
}

.alert-navy .alert-link {
  color: #001c52;
}

.alert-pink {
  color: #85777c;
  background-color: #fffafc;
  border-color: #fff8fa;
}

.alert-pink hr {
  border-top-color: #ffdfe8;
}

.alert-pink .alert-link {
  color: #6a5f63;
}

.alert-dark-2 {
  color: #0e1626;
  background-color: #d1d5db;
  border-color: #bfc4cc;
}

.alert-dark-2 hr {
  border-top-color: #b1b7c1;
}

.alert-dark-2 .alert-link {
  color: #000001;
}

.alert-yellow-lighter-2 {
  color: #80622f;
  background-color: #fdf2de;
  border-color: #fdecd1;
}

.alert-yellow-lighter-2 hr {
  border-top-color: #fce2b9;
}

.alert-yellow-lighter-2 .alert-link {
  color: #5b4521;
}

.alert-orange {
  color: #7d2d00;
  background-color: #fcddcc;
  border-color: #fbd0b8;
}

.alert-orange hr {
  border-top-color: #fac0a0;
}

.alert-orange .alert-link {
  color: #4a1b00;
}

.alert-green {
  color: #15460f;
  background-color: #d4e7d2;
  border-color: #c3ddbf;
}

.alert-green hr {
  border-top-color: #b3d4ae;
}

.alert-green .alert-link {
  color: #081c06;
}

.alert-pink-1 {
  color: #850024;
  background-color: #ffccda;
  border-color: #ffb8cb;
}

.alert-pink-1 hr {
  border-top-color: #ff9fb8;
}

.alert-pink-1 .alert-link {
  color: #520016;
}

.alert-purple-1 {
  color: #12142b;
  background-color: #d3d4dd;
  border-color: #c1c3cf;
}

.alert-purple-1 hr {
  border-top-color: #b3b5c4;
}

.alert-purple-1 .alert-link {
  color: #030307;
}

/*-------------------------------------------*\
    1.23. Progress
\*-------------------------------------------*/
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f8fafd;
  border-radius: 0.3125rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #297cbb;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}

/*-------------------------------------------*\
    1.24. Media
\*-------------------------------------------*/
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
      flex: 1;
}

/*-------------------------------------------*\
    1.25. List-group
\*-------------------------------------------*/
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #77838f;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #297cbb;
  text-decoration: none;
  background-color: transparent;
}

.list-group-item-action:active {
  color: #297cbb;
  background-color: transparent;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.425rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #e7eaf3;
}

.list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.list-group-horizontal {
  -ms-flex-direction: row;
      flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1480px) {
  .list-group-horizontal-wd {
    -ms-flex-direction: row;
        flex-direction: row;
  }
  .list-group-horizontal-wd .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-wd .list-group-item:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-wd .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #154061;
  background-color: #c3daec;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #154061;
  background-color: #b0cee6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #154061;
  border-color: #154061;
}

.list-group-item-secondary {
  color: #3e444a;
  background-color: #d9dce0;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #3e444a;
  background-color: #cbcfd5;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #3e444a;
  border-color: #3e444a;
}

.list-group-item-success {
  color: #006957;
  background-color: #b8f0e6;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #006957;
  background-color: #a3ecdf;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #006957;
  border-color: #006957;
}

.list-group-item-info {
  color: #007483;
  background-color: #b8f6fe;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #007483;
  background-color: #9ff3fe;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #007483;
  border-color: #007483;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #73231d;
  background-color: #f6cbc7;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #73231d;
  background-color: #f2b7b1;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #73231d;
  border-color: #73231d;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1f2329;
  background-color: #c8cbce;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1f2329;
  background-color: #bbbec2;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f2329;
  border-color: #1f2329;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-indigo {
  color: #170b44;
  background-color: #c4bddc;
}

.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
  color: #170b44;
  background-color: #b5acd3;
}

.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #170b44;
  border-color: #170b44;
}

.list-group-item-gray {
  color: #7a7d80;
  background-color: #f9fbfd;
}

.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #7a7d80;
  background-color: #e6eef7;
}

.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #7a7d80;
  border-color: #7a7d80;
}

.list-group-item-violet {
  color: #2b1145;
  background-color: #cfc1dd;
}

.list-group-item-violet.list-group-item-action:hover, .list-group-item-violet.list-group-item-action:focus {
  color: #2b1145;
  background-color: #c2b1d4;
}

.list-group-item-violet.list-group-item-action.active {
  color: #fff;
  background-color: #2b1145;
  border-color: #2b1145;
}

.list-group-item-violet-1 {
  color: #573457;
  background-color: #e7d4e7;
}

.list-group-item-violet-1.list-group-item-action:hover, .list-group-item-violet-1.list-group-item-action:focus {
  color: #573457;
  background-color: #dec4de;
}

.list-group-item-violet-1.list-group-item-action.active {
  color: #fff;
  background-color: #573457;
  border-color: #573457;
}

.list-group-item-violet-light {
  color: #3a0a6b;
  background-color: #d7bdf1;
}

.list-group-item-violet-light.list-group-item-action:hover, .list-group-item-violet-light.list-group-item-action:focus {
  color: #3a0a6b;
  background-color: #caa8ed;
}

.list-group-item-violet-light.list-group-item-action.active {
  color: #fff;
  background-color: #3a0a6b;
  border-color: #3a0a6b;
}

.list-group-item-brown-light {
  color: #6b5932;
  background-color: #f1e8d3;
}

.list-group-item-brown-light.list-group-item-action:hover, .list-group-item-brown-light.list-group-item-action:focus {
  color: #6b5932;
  background-color: #ebdec0;
}

.list-group-item-brown-light.list-group-item-action.active {
  color: #fff;
  background-color: #6b5932;
  border-color: #6b5932;
}

.list-group-item-brown {
  color: #81622f;
  background-color: #fdedd1;
}

.list-group-item-brown.list-group-item-action:hover, .list-group-item-brown.list-group-item-action:focus {
  color: #81622f;
  background-color: #fce3b9;
}

.list-group-item-brown.list-group-item-action.active {
  color: #fff;
  background-color: #81622f;
  border-color: #81622f;
}

.list-group-item-maroon {
  color: #67183c;
  background-color: #efc5d8;
}

.list-group-item-maroon.list-group-item-action:hover, .list-group-item-maroon.list-group-item-action:focus {
  color: #67183c;
  background-color: #e9b1cb;
}

.list-group-item-maroon.list-group-item-action.active {
  color: #fff;
  background-color: #67183c;
  border-color: #67183c;
}

.list-group-item-indigo-light {
  color: #040740;
  background-color: #babcda;
}

.list-group-item-indigo-light.list-group-item-action:hover, .list-group-item-indigo-light.list-group-item-action:focus {
  color: #040740;
  background-color: #a9acd1;
}

.list-group-item-indigo-light.list-group-item-action.active {
  color: #fff;
  background-color: #040740;
  border-color: #040740;
}

.list-group-item-red-light-2 {
  color: #851f29;
  background-color: #ffc8cd;
}

.list-group-item-red-light-2.list-group-item-action:hover, .list-group-item-red-light-2.list-group-item-action:focus {
  color: #851f29;
  background-color: #ffafb6;
}

.list-group-item-red-light-2.list-group-item-action.active {
  color: #fff;
  background-color: #851f29;
  border-color: #851f29;
}

.list-group-item-blue-dark-1 {
  color: #023085;
  background-color: #b9d1ff;
}

.list-group-item-blue-dark-1.list-group-item-action:hover, .list-group-item-blue-dark-1.list-group-item-action:focus {
  color: #023085;
  background-color: #a0c0ff;
}

.list-group-item-blue-dark-1.list-group-item-action.active {
  color: #fff;
  background-color: #023085;
  border-color: #023085;
}

.list-group-item-gray-1 {
  color: #363c40;
  background-color: #d4d8da;
}

.list-group-item-gray-1.list-group-item-action:hover, .list-group-item-gray-1.list-group-item-action:focus {
  color: #363c40;
  background-color: #c6ccce;
}

.list-group-item-gray-1.list-group-item-action.active {
  color: #fff;
  background-color: #363c40;
  border-color: #363c40;
}

.list-group-item-gray-2 {
  color: #808181;
  background-color: #fdfdfd;
}

.list-group-item-gray-2.list-group-item-action:hover, .list-group-item-gray-2.list-group-item-action:focus {
  color: #808181;
  background-color: #f0f0f0;
}

.list-group-item-gray-2.list-group-item-action.active {
  color: #fff;
  background-color: #808181;
  border-color: #808181;
}

.list-group-item-gray-3 {
  color: #1f2329;
  background-color: #c8cbce;
}

.list-group-item-gray-3.list-group-item-action:hover, .list-group-item-gray-3.list-group-item-action:focus {
  color: #1f2329;
  background-color: #bbbec2;
}

.list-group-item-gray-3.list-group-item-action.active {
  color: #fff;
  background-color: #1f2329;
  border-color: #1f2329;
}

.list-group-item-gray-4 {
  color: #7b7c7e;
  background-color: #fafbfc;
}

.list-group-item-gray-4.list-group-item-action:hover, .list-group-item-gray-4.list-group-item-action:focus {
  color: #7b7c7e;
  background-color: #eaeef2;
}

.list-group-item-gray-4.list-group-item-action.active {
  color: #fff;
  background-color: #7b7c7e;
  border-color: #7b7c7e;
}

.list-group-item-blue {
  color: #236672;
  background-color: #caeff5;
}

.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #236672;
  background-color: #b5e9f1;
}

.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #236672;
  border-color: #236672;
}

.list-group-item-blue-1 {
  color: #154061;
  background-color: #c3daec;
}

.list-group-item-blue-1.list-group-item-action:hover, .list-group-item-blue-1.list-group-item-action:focus {
  color: #154061;
  background-color: #b0cee6;
}

.list-group-item-blue-1.list-group-item-action.active {
  color: #fff;
  background-color: #154061;
  border-color: #154061;
}

.list-group-item-gray-5 {
  color: #0c0c0e;
  background-color: #bebebf;
}

.list-group-item-gray-5.list-group-item-action:hover, .list-group-item-gray-5.list-group-item-action:focus {
  color: #0c0c0e;
  background-color: #b1b1b2;
}

.list-group-item-gray-5.list-group-item-action.active {
  color: #fff;
  background-color: #0c0c0e;
  border-color: #0c0c0e;
}

.list-group-item-gray-6 {
  color: #0c0c0e;
  background-color: #bebebf;
}

.list-group-item-gray-6.list-group-item-action:hover, .list-group-item-gray-6.list-group-item-action:focus {
  color: #0c0c0e;
  background-color: #b1b1b2;
}

.list-group-item-gray-6.list-group-item-action.active {
  color: #fff;
  background-color: #0c0c0e;
  border-color: #0c0c0e;
}

.list-group-item-gray-7 {
  color: #30363a;
  background-color: #d1d4d7;
}

.list-group-item-gray-7.list-group-item-action:hover, .list-group-item-gray-7.list-group-item-action:focus {
  color: #30363a;
  background-color: #c3c7cb;
}

.list-group-item-gray-7.list-group-item-action.active {
  color: #fff;
  background-color: #30363a;
  border-color: #30363a;
}

.list-group-item-gray-8 {
  color: #666b70;
  background-color: #eff1f4;
}

.list-group-item-gray-8.list-group-item-action:hover, .list-group-item-gray-8.list-group-item-action:focus {
  color: #666b70;
  background-color: #e0e4ea;
}

.list-group-item-gray-8.list-group-item-action.active {
  color: #fff;
  background-color: #666b70;
  border-color: #666b70;
}

.list-group-item-gray-9 {
  color: #535860;
  background-color: #e4e7eb;
}

.list-group-item-gray-9.list-group-item-action:hover, .list-group-item-gray-9.list-group-item-action:focus {
  color: #535860;
  background-color: #d5dae0;
}

.list-group-item-gray-9.list-group-item-action.active {
  color: #fff;
  background-color: #535860;
  border-color: #535860;
}

.list-group-item-gray-10 {
  color: #646464;
  background-color: #eeeeee;
}

.list-group-item-gray-10.list-group-item-action:hover, .list-group-item-gray-10.list-group-item-action:focus {
  color: #646464;
  background-color: #e1e1e1;
}

.list-group-item-gray-10.list-group-item-action.active {
  color: #fff;
  background-color: #646464;
  border-color: #646464;
}

.list-group-item-gray-11 {
  color: #252525;
  background-color: #cccccc;
}

.list-group-item-gray-11.list-group-item-action:hover, .list-group-item-gray-11.list-group-item-action:focus {
  color: #252525;
  background-color: #bfbfbf;
}

.list-group-item-gray-11.list-group-item-action.active {
  color: #fff;
  background-color: #252525;
  border-color: #252525;
}

.list-group-item-gray-23 {
  color: #7f7f7f;
  background-color: #fcfcfc;
}

.list-group-item-gray-23.list-group-item-action:hover, .list-group-item-gray-23.list-group-item-action:focus {
  color: #7f7f7f;
  background-color: #efefef;
}

.list-group-item-gray-23.list-group-item-action.active {
  color: #fff;
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

.list-group-item-gray-33 {
  color: #7a7d80;
  background-color: #f9fbfd;
}

.list-group-item-gray-33.list-group-item-action:hover, .list-group-item-gray-33.list-group-item-action:focus {
  color: #7a7d80;
  background-color: #e6eef7;
}

.list-group-item-gray-33.list-group-item-action.active {
  color: #fff;
  background-color: #7a7d80;
  border-color: #7a7d80;
}

.list-group-item-sea-green {
  color: #0f2a49;
  background-color: #c0cedf;
}

.list-group-item-sea-green.list-group-item-action:hover, .list-group-item-sea-green.list-group-item-action:focus {
  color: #0f2a49;
  background-color: #afc1d6;
}

.list-group-item-sea-green.list-group-item-action.active {
  color: #fff;
  background-color: #0f2a49;
  border-color: #0f2a49;
}

.list-group-item-dark-1 {
  color: #101112;
  background-color: #c0c1c1;
}

.list-group-item-dark-1.list-group-item-action:hover, .list-group-item-dark-1.list-group-item-action:focus {
  color: #101112;
  background-color: #b3b4b4;
}

.list-group-item-dark-1.list-group-item-action.active {
  color: #fff;
  background-color: #101112;
  border-color: #101112;
}

.list-group-item-navy {
  color: #002d85;
  background-color: #b8d0ff;
}

.list-group-item-navy.list-group-item-action:hover, .list-group-item-navy.list-group-item-action:focus {
  color: #002d85;
  background-color: #9fbfff;
}

.list-group-item-navy.list-group-item-action.active {
  color: #fff;
  background-color: #002d85;
  border-color: #002d85;
}

.list-group-item-pink {
  color: #85777c;
  background-color: #fff8fa;
}

.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
  color: #85777c;
  background-color: #ffdfe8;
}

.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #85777c;
  border-color: #85777c;
}

.list-group-item-dark-2 {
  color: #0e1626;
  background-color: #bfc4cc;
}

.list-group-item-dark-2.list-group-item-action:hover, .list-group-item-dark-2.list-group-item-action:focus {
  color: #0e1626;
  background-color: #b1b7c1;
}

.list-group-item-dark-2.list-group-item-action.active {
  color: #fff;
  background-color: #0e1626;
  border-color: #0e1626;
}

.list-group-item-yellow-lighter-2 {
  color: #80622f;
  background-color: #fdecd1;
}

.list-group-item-yellow-lighter-2.list-group-item-action:hover, .list-group-item-yellow-lighter-2.list-group-item-action:focus {
  color: #80622f;
  background-color: #fce2b9;
}

.list-group-item-yellow-lighter-2.list-group-item-action.active {
  color: #fff;
  background-color: #80622f;
  border-color: #80622f;
}

.list-group-item-orange {
  color: #7d2d00;
  background-color: #fbd0b8;
}

.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  color: #7d2d00;
  background-color: #fac0a0;
}

.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #7d2d00;
  border-color: #7d2d00;
}

.list-group-item-green {
  color: #15460f;
  background-color: #c3ddbf;
}

.list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
  color: #15460f;
  background-color: #b3d4ae;
}

.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #15460f;
  border-color: #15460f;
}

.list-group-item-pink-1 {
  color: #850024;
  background-color: #ffb8cb;
}

.list-group-item-pink-1.list-group-item-action:hover, .list-group-item-pink-1.list-group-item-action:focus {
  color: #850024;
  background-color: #ff9fb8;
}

.list-group-item-pink-1.list-group-item-action.active {
  color: #fff;
  background-color: #850024;
  border-color: #850024;
}

.list-group-item-purple-1 {
  color: #12142b;
  background-color: #c1c3cf;
}

.list-group-item-purple-1.list-group-item-action:hover, .list-group-item-purple-1.list-group-item-action:focus {
  color: #12142b;
  background-color: #b3b5c4;
}

.list-group-item-purple-1.list-group-item-action.active {
  color: #fff;
  background-color: #12142b;
  border-color: #12142b;
}

/*-------------------------------------------*\
    1.26. Close
\*-------------------------------------------*/
.close {
  float: right;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  color: #3b444f;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #3b444f;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

/*-------------------------------------------*\
    1.27. Toasts
\*-------------------------------------------*/
.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.25rem 0.75rem;
  color: #8c98a4;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

/*-------------------------------------------*\
    1.28. Modal
\*-------------------------------------------*/
.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e7eaf3;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e7eaf3;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

/*-------------------------------------------*\
    1.29. Tooltip
\*-------------------------------------------*/
.tooltip {
  position: absolute;
  z-index: 9999;
  display: block;
  margin: 0;
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #297cbb;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #297cbb;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #297cbb;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #297cbb;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #297cbb;
  border-radius: 0.3125rem;
}

/*-------------------------------------------*\
    1.30. Popover
\*-------------------------------------------*/
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Rubik", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #3b444f;
}

/*-------------------------------------------*\
    1.31. Carousel
\*-------------------------------------------*/
.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

/*-------------------------------------------*\
    1.32. Spinners
\*-------------------------------------------*/
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

/*-------------------------------------------*\
    1.33. Utilities
\*-------------------------------------------*/
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #297cbb !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #206091 !important;
}

.bg-secondary {
  background-color: #77838f !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #5f6a74 !important;
}

.bg-success {
  background-color: #00c9a7 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #00967d !important;
}

.bg-info {
  background-color: #00dffc !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00b2c9 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #de4437 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c22d20 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #3b444f !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #252b32 !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-indigo {
  background-color: #2d1582 !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #1e0e56 !important;
}

.bg-gray {
  background-color: #ebf0f7 !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #c7d5e8 !important;
}

.bg-violet {
  background-color: #532184 !important;
}

a.bg-violet:hover, a.bg-violet:focus,
button.bg-violet:hover,
button.bg-violet:focus {
  background-color: #39175b !important;
}

.bg-violet-1 {
  background-color: #a864a8 !important;
}

a.bg-violet-1:hover, a.bg-violet-1:focus,
button.bg-violet-1:hover,
button.bg-violet-1:focus {
  background-color: #8b4e8b !important;
}

.bg-violet-light {
  background-color: #6f13cd !important;
}

a.bg-violet-light:hover, a.bg-violet-light:focus,
button.bg-violet-light:hover,
button.bg-violet-light:focus {
  background-color: #560f9e !important;
}

.bg-brown-light {
  background-color: #ceac61 !important;
}

a.bg-brown-light:hover, a.bg-brown-light:focus,
button.bg-brown-light:hover,
button.bg-brown-light:focus {
  background-color: #c0973c !important;
}

.bg-brown {
  background-color: #f8bd5b !important;
}

a.bg-brown:hover, a.bg-brown:focus,
button.bg-brown:hover,
button.bg-brown:focus {
  background-color: #f6a92a !important;
}

.bg-maroon {
  background-color: #c72f74 !important;
}

a.bg-maroon:hover, a.bg-maroon:focus,
button.bg-maroon:hover,
button.bg-maroon:focus {
  background-color: #9e255c !important;
}

.bg-indigo-light {
  background-color: #080e7b !important;
}

a.bg-indigo-light:hover, a.bg-indigo-light:focus,
button.bg-indigo-light:hover,
button.bg-indigo-light:focus {
  background-color: #05094b !important;
}

.bg-red-light-2 {
  background-color: #ff3c4e !important;
}

a.bg-red-light-2:hover, a.bg-red-light-2:focus,
button.bg-red-light-2:hover,
button.bg-red-light-2:focus {
  background-color: #ff0920 !important;
}

.bg-blue-dark-1 {
  background-color: #045cff !important;
}

a.bg-blue-dark-1:hover, a.bg-blue-dark-1:focus,
button.bg-blue-dark-1:hover,
button.bg-blue-dark-1:focus {
  background-color: #0049d0 !important;
}

.bg-gray-1 {
  background-color: #67747c !important;
}

a.bg-gray-1:hover, a.bg-gray-1:focus,
button.bg-gray-1:hover,
button.bg-gray-1:focus {
  background-color: #505a60 !important;
}

.bg-gray-2 {
  background-color: #f7f8f9 !important;
}

a.bg-gray-2:hover, a.bg-gray-2:focus,
button.bg-gray-2:hover,
button.bg-gray-2:focus {
  background-color: #dadfe3 !important;
}

.bg-gray-3 {
  background-color: #3b444f !important;
}

a.bg-gray-3:hover, a.bg-gray-3:focus,
button.bg-gray-3:hover,
button.bg-gray-3:focus {
  background-color: #252b32 !important;
}

.bg-gray-4 {
  background-color: #eceff3 !important;
}

a.bg-gray-4:hover, a.bg-gray-4:focus,
button.bg-gray-4:hover,
button.bg-gray-4:focus {
  background-color: #cdd5df !important;
}

.bg-blue {
  background-color: #43c5db !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #26aec5 !important;
}

.bg-blue-1 {
  background-color: #297cbb !important;
}

a.bg-blue-1:hover, a.bg-blue-1:focus,
button.bg-blue-1:hover,
button.bg-blue-1:focus {
  background-color: #206091 !important;
}

.bg-gray-5 {
  background-color: #18181b !important;
}

a.bg-gray-5:hover, a.bg-gray-5:focus,
button.bg-gray-5:hover,
button.bg-gray-5:focus {
  background-color: black !important;
}

.bg-gray-6 {
  background-color: #18181a !important;
}

a.bg-gray-6:hover, a.bg-gray-6:focus,
button.bg-gray-6:hover,
button.bg-gray-6:focus {
  background-color: black !important;
}

.bg-gray-7 {
  background-color: #5c6770 !important;
}

a.bg-gray-7:hover, a.bg-gray-7:focus,
button.bg-gray-7:hover,
button.bg-gray-7:focus {
  background-color: #454d54 !important;
}

.bg-gray-8 {
  background-color: #c5cdd8 !important;
}

a.bg-gray-8:hover, a.bg-gray-8:focus,
button.bg-gray-8:hover,
button.bg-gray-8:focus {
  background-color: #a7b3c3 !important;
}

.bg-gray-9 {
  background-color: #9fa9b8 !important;
}

a.bg-gray-9:hover, a.bg-gray-9:focus,
button.bg-gray-9:hover,
button.bg-gray-9:focus {
  background-color: #828fa2 !important;
}

.bg-gray-10 {
  background-color: #c1c1c1 !important;
}

a.bg-gray-10:hover, a.bg-gray-10:focus,
button.bg-gray-10:hover,
button.bg-gray-10:focus {
  background-color: #a8a8a8 !important;
}

.bg-gray-11 {
  background-color: #484848 !important;
}

a.bg-gray-11:hover, a.bg-gray-11:focus,
button.bg-gray-11:hover,
button.bg-gray-11:focus {
  background-color: #2f2f2f !important;
}

.bg-gray-23 {
  background-color: #f4f4f4 !important;
}

a.bg-gray-23:hover, a.bg-gray-23:focus,
button.bg-gray-23:hover,
button.bg-gray-23:focus {
  background-color: #dbdbdb !important;
}

.bg-gray-33 {
  background-color: #ebf0f7 !important;
}

a.bg-gray-33:hover, a.bg-gray-33:focus,
button.bg-gray-33:hover,
button.bg-gray-33:focus {
  background-color: #c7d5e8 !important;
}

.bg-sea-green {
  background-color: #1d508d !important;
}

a.bg-sea-green:hover, a.bg-sea-green:focus,
button.bg-sea-green:hover,
button.bg-sea-green:focus {
  background-color: #143863 !important;
}

.bg-dark-1 {
  background-color: #1e2022 !important;
}

a.bg-dark-1:hover, a.bg-dark-1:focus,
button.bg-dark-1:hover,
button.bg-dark-1:focus {
  background-color: #060707 !important;
}

.bg-navy {
  background-color: #0057ff !important;
}

a.bg-navy:hover, a.bg-navy:focus,
button.bg-navy:hover,
button.bg-navy:focus {
  background-color: #0046cc !important;
}

.bg-pink {
  background-color: #ffe5ee !important;
}

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #ffb2cd !important;
}

.bg-dark-2 {
  background-color: #1a2b49 !important;
}

a.bg-dark-2:hover, a.bg-dark-2:focus,
button.bg-dark-2:hover,
button.bg-dark-2:focus {
  background-color: #0d1523 !important;
}

.bg-yellow-lighter-2 {
  background-color: #f7bc5a !important;
}

a.bg-yellow-lighter-2:hover, a.bg-yellow-lighter-2:focus,
button.bg-yellow-lighter-2:hover,
button.bg-yellow-lighter-2:focus {
  background-color: #f5a829 !important;
}

.bg-orange {
  background-color: #f05700 !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #bd4500 !important;
}

.bg-green {
  background-color: #28871c !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #1b5d13 !important;
}

.bg-pink-1 {
  background-color: #ff0045 !important;
}

a.bg-pink-1:hover, a.bg-pink-1:focus,
button.bg-pink-1:hover,
button.bg-pink-1:focus {
  background-color: #cc0037 !important;
}

.bg-purple-1 {
  background-color: #232753 !important;
}

a.bg-purple-1:hover, a.bg-purple-1:focus,
button.bg-purple-1:hover,
button.bg-purple-1:focus {
  background-color: #14162f !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e7eaf3 !important;
}

.border-top {
  border-top: 1px solid #e7eaf3 !important;
}

.border-right {
  border-right: 1px solid #e7eaf3 !important;
}

.border-bottom {
  border-bottom: 1px solid #e7eaf3 !important;
}

.border-left {
  border-left: 1px solid #e7eaf3 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #297cbb !important;
}

.border-secondary {
  border-color: #77838f !important;
}

.border-success {
  border-color: #00c9a7 !important;
}

.border-info {
  border-color: #00dffc !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #de4437 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #3b444f !important;
}

.border-white {
  border-color: #fff !important;
}

.border-indigo {
  border-color: #2d1582 !important;
}

.border-gray {
  border-color: #ebf0f7 !important;
}

.border-violet {
  border-color: #532184 !important;
}

.border-violet-1 {
  border-color: #a864a8 !important;
}

.border-violet-light {
  border-color: #6f13cd !important;
}

.border-brown-light {
  border-color: #ceac61 !important;
}

.border-brown {
  border-color: #f8bd5b !important;
}

.border-maroon {
  border-color: #c72f74 !important;
}

.border-indigo-light {
  border-color: #080e7b !important;
}

.border-red-light-2 {
  border-color: #ff3c4e !important;
}

.border-blue-dark-1 {
  border-color: #045cff !important;
}

.border-gray-1 {
  border-color: #67747c !important;
}

.border-gray-2 {
  border-color: #f7f8f9 !important;
}

.border-gray-3 {
  border-color: #3b444f !important;
}

.border-gray-4 {
  border-color: #eceff3 !important;
}

.border-blue {
  border-color: #43c5db !important;
}

.border-blue-1 {
  border-color: #297cbb !important;
}

.border-gray-5 {
  border-color: #18181b !important;
}

.border-gray-6 {
  border-color: #18181a !important;
}

.border-gray-7 {
  border-color: #5c6770 !important;
}

.border-gray-8 {
  border-color: #c5cdd8 !important;
}

.border-gray-9 {
  border-color: #9fa9b8 !important;
}

.border-gray-10 {
  border-color: #c1c1c1 !important;
}

.border-gray-11 {
  border-color: #484848 !important;
}

.border-gray-23 {
  border-color: #f4f4f4 !important;
}

.border-gray-33 {
  border-color: #ebf0f7 !important;
}

.border-sea-green {
  border-color: #1d508d !important;
}

.border-dark-1 {
  border-color: #1e2022 !important;
}

.border-navy {
  border-color: #0057ff !important;
}

.border-pink {
  border-color: #ffe5ee !important;
}

.border-dark-2 {
  border-color: #1a2b49 !important;
}

.border-yellow-lighter-2 {
  border-color: #f7bc5a !important;
}

.border-orange {
  border-color: #f05700 !important;
}

.border-green {
  border-color: #28871c !important;
}

.border-pink-1 {
  border-color: #ff0045 !important;
}

.border-purple-1 {
  border-color: #232753 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.25rem !important;
}

.rounded {
  border-radius: 0.3125rem !important;
}

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important;
}

.rounded-right {
  border-top-right-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-left {
  border-top-left-radius: 0.3125rem !important;
  border-bottom-left-radius: 0.3125rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1480px) {
  .d-wd-none {
    display: none !important;
  }
  .d-wd-inline {
    display: inline !important;
  }
  .d-wd-inline-block {
    display: inline-block !important;
  }
  .d-wd-block {
    display: block !important;
  }
  .d-wd-table {
    display: table !important;
  }
  .d-wd-table-row {
    display: table-row !important;
  }
  .d-wd-table-cell {
    display: table-cell !important;
  }
  .d-wd-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-wd-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
      justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

@media (min-width: 1480px) {
  .flex-wd-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important;
  }
  .flex-wd-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important;
  }
  .flex-wd-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
  }
  .flex-wd-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
  }
  .flex-wd-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-wd-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-wd-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-wd-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
  }
  .flex-wd-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
  }
  .flex-wd-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
  }
  .flex-wd-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-wd-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-wd-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
  }
  .justify-content-wd-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important;
  }
  .justify-content-wd-center {
    -ms-flex-pack: center !important;
        justify-content: center !important;
  }
  .justify-content-wd-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important;
  }
  .justify-content-wd-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-wd-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important;
  }
  .align-items-wd-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important;
  }
  .align-items-wd-center {
    -ms-flex-align: center !important;
        align-items: center !important;
  }
  .align-items-wd-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important;
  }
  .align-items-wd-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important;
  }
  .align-content-wd-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-wd-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-wd-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-wd-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-wd-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-wd-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-wd-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-wd-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-wd-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-wd-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-wd-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-wd-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1480px) {
  .float-wd-left {
    float: left !important;
  }
  .float-wd-right {
    float: right !important;
  }
  .float-wd-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125) !important;
}

.shadow {
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125) !important;
}

.shadow-lg {
  box-shadow: 0 10px 40px 10px rgba(140, 152, 164, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-15 {
  height: 15% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-0dot5 {
  margin: 0.143rem !important;
}

.mt-0dot5,
.my-0dot5 {
  margin-top: 0.143rem !important;
}

.mr-0dot5,
.mx-0dot5 {
  margin-right: 0.143rem !important;
}

.mb-0dot5,
.my-0dot5 {
  margin-bottom: 0.143rem !important;
}

.ml-0dot5,
.mx-0dot5 {
  margin-left: 0.143rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 4.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 4.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.mt-11,
.my-11 {
  margin-top: 5rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 5rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 5rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 5rem !important;
}

.m-12 {
  margin: 5.5rem !important;
}

.mt-12,
.my-12 {
  margin-top: 5.5rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 5.5rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 5.5rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 5.5rem !important;
}

.m-13 {
  margin: 6rem !important;
}

.mt-13,
.my-13 {
  margin-top: 6rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 6rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 6rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 6rem !important;
}

.m-14 {
  margin: 6.5rem !important;
}

.mt-14,
.my-14 {
  margin-top: 6.5rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 6.5rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 6.5rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 6.5rem !important;
}

.m-15 {
  margin: 7rem !important;
}

.mt-15,
.my-15 {
  margin-top: 7rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 7rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 7rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 7rem !important;
}

.m-16 {
  margin: 8rem !important;
}

.mt-16,
.my-16 {
  margin-top: 8rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 8rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 8rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 8rem !important;
}

.m-17 {
  margin: 9rem !important;
}

.mt-17,
.my-17 {
  margin-top: 9rem !important;
}

.mr-17,
.mx-17 {
  margin-right: 9rem !important;
}

.mb-17,
.my-17 {
  margin-bottom: 9rem !important;
}

.ml-17,
.mx-17 {
  margin-left: 9rem !important;
}

.m-18 {
  margin: 10rem !important;
}

.mt-18,
.my-18 {
  margin-top: 10rem !important;
}

.mr-18,
.mx-18 {
  margin-right: 10rem !important;
}

.mb-18,
.my-18 {
  margin-bottom: 10rem !important;
}

.ml-18,
.mx-18 {
  margin-left: 10rem !important;
}

.m-19 {
  margin: 10.6rem !important;
}

.mt-19,
.my-19 {
  margin-top: 10.6rem !important;
}

.mr-19,
.mx-19 {
  margin-right: 10.6rem !important;
}

.mb-19,
.my-19 {
  margin-bottom: 10.6rem !important;
}

.ml-19,
.mx-19 {
  margin-left: 10.6rem !important;
}

.m-20 {
  margin: 12.4rem !important;
}

.mt-20,
.my-20 {
  margin-top: 12.4rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 12.4rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 12.4rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 12.4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-0dot5 {
  padding: 0.143rem !important;
}

.pt-0dot5,
.py-0dot5 {
  padding-top: 0.143rem !important;
}

.pr-0dot5,
.px-0dot5 {
  padding-right: 0.143rem !important;
}

.pb-0dot5,
.py-0dot5 {
  padding-bottom: 0.143rem !important;
}

.pl-0dot5,
.px-0dot5 {
  padding-left: 0.143rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 4.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 4.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 4.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 4.5rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.pt-11,
.py-11 {
  padding-top: 5rem !important;
}

.pr-11,
.px-11 {
  padding-right: 5rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 5rem !important;
}

.pl-11,
.px-11 {
  padding-left: 5rem !important;
}

.p-12 {
  padding: 5.5rem !important;
}

.pt-12,
.py-12 {
  padding-top: 5.5rem !important;
}

.pr-12,
.px-12 {
  padding-right: 5.5rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 5.5rem !important;
}

.pl-12,
.px-12 {
  padding-left: 5.5rem !important;
}

.p-13 {
  padding: 6rem !important;
}

.pt-13,
.py-13 {
  padding-top: 6rem !important;
}

.pr-13,
.px-13 {
  padding-right: 6rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 6rem !important;
}

.pl-13,
.px-13 {
  padding-left: 6rem !important;
}

.p-14 {
  padding: 6.5rem !important;
}

.pt-14,
.py-14 {
  padding-top: 6.5rem !important;
}

.pr-14,
.px-14 {
  padding-right: 6.5rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 6.5rem !important;
}

.pl-14,
.px-14 {
  padding-left: 6.5rem !important;
}

.p-15 {
  padding: 7rem !important;
}

.pt-15,
.py-15 {
  padding-top: 7rem !important;
}

.pr-15,
.px-15 {
  padding-right: 7rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 7rem !important;
}

.pl-15,
.px-15 {
  padding-left: 7rem !important;
}

.p-16 {
  padding: 8rem !important;
}

.pt-16,
.py-16 {
  padding-top: 8rem !important;
}

.pr-16,
.px-16 {
  padding-right: 8rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 8rem !important;
}

.pl-16,
.px-16 {
  padding-left: 8rem !important;
}

.p-17 {
  padding: 9rem !important;
}

.pt-17,
.py-17 {
  padding-top: 9rem !important;
}

.pr-17,
.px-17 {
  padding-right: 9rem !important;
}

.pb-17,
.py-17 {
  padding-bottom: 9rem !important;
}

.pl-17,
.px-17 {
  padding-left: 9rem !important;
}

.p-18 {
  padding: 10rem !important;
}

.pt-18,
.py-18 {
  padding-top: 10rem !important;
}

.pr-18,
.px-18 {
  padding-right: 10rem !important;
}

.pb-18,
.py-18 {
  padding-bottom: 10rem !important;
}

.pl-18,
.px-18 {
  padding-left: 10rem !important;
}

.p-19 {
  padding: 10.6rem !important;
}

.pt-19,
.py-19 {
  padding-top: 10.6rem !important;
}

.pr-19,
.px-19 {
  padding-right: 10.6rem !important;
}

.pb-19,
.py-19 {
  padding-bottom: 10.6rem !important;
}

.pl-19,
.px-19 {
  padding-left: 10.6rem !important;
}

.p-20 {
  padding: 12.4rem !important;
}

.pt-20,
.py-20 {
  padding-top: 12.4rem !important;
}

.pr-20,
.px-20 {
  padding-right: 12.4rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 12.4rem !important;
}

.pl-20,
.px-20 {
  padding-left: 12.4rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -4.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -4.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -4.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -4.5rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -5rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -5rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -5rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -5rem !important;
}

.m-n12 {
  margin: -5.5rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -5.5rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -5.5rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -5.5rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -5.5rem !important;
}

.m-n13 {
  margin: -6rem !important;
}

.mt-n13,
.my-n13 {
  margin-top: -6rem !important;
}

.mr-n13,
.mx-n13 {
  margin-right: -6rem !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -6rem !important;
}

.ml-n13,
.mx-n13 {
  margin-left: -6rem !important;
}

.m-n14 {
  margin: -6.5rem !important;
}

.mt-n14,
.my-n14 {
  margin-top: -6.5rem !important;
}

.mr-n14,
.mx-n14 {
  margin-right: -6.5rem !important;
}

.mb-n14,
.my-n14 {
  margin-bottom: -6.5rem !important;
}

.ml-n14,
.mx-n14 {
  margin-left: -6.5rem !important;
}

.m-n15 {
  margin: -7rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -7rem !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -7rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -7rem !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -7rem !important;
}

.m-n16 {
  margin: -8rem !important;
}

.mt-n16,
.my-n16 {
  margin-top: -8rem !important;
}

.mr-n16,
.mx-n16 {
  margin-right: -8rem !important;
}

.mb-n16,
.my-n16 {
  margin-bottom: -8rem !important;
}

.ml-n16,
.mx-n16 {
  margin-left: -8rem !important;
}

.m-n17 {
  margin: -9rem !important;
}

.mt-n17,
.my-n17 {
  margin-top: -9rem !important;
}

.mr-n17,
.mx-n17 {
  margin-right: -9rem !important;
}

.mb-n17,
.my-n17 {
  margin-bottom: -9rem !important;
}

.ml-n17,
.mx-n17 {
  margin-left: -9rem !important;
}

.m-n18 {
  margin: -10rem !important;
}

.mt-n18,
.my-n18 {
  margin-top: -10rem !important;
}

.mr-n18,
.mx-n18 {
  margin-right: -10rem !important;
}

.mb-n18,
.my-n18 {
  margin-bottom: -10rem !important;
}

.ml-n18,
.mx-n18 {
  margin-left: -10rem !important;
}

.m-n19 {
  margin: -10.6rem !important;
}

.mt-n19,
.my-n19 {
  margin-top: -10.6rem !important;
}

.mr-n19,
.mx-n19 {
  margin-right: -10.6rem !important;
}

.mb-n19,
.my-n19 {
  margin-bottom: -10.6rem !important;
}

.ml-n19,
.mx-n19 {
  margin-left: -10.6rem !important;
}

.m-n20 {
  margin: -12.4rem !important;
}

.mt-n20,
.my-n20 {
  margin-top: -12.4rem !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -12.4rem !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -12.4rem !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -12.4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-0dot5 {
    margin: 0.143rem !important;
  }
  .mt-sm-0dot5,
  .my-sm-0dot5 {
    margin-top: 0.143rem !important;
  }
  .mr-sm-0dot5,
  .mx-sm-0dot5 {
    margin-right: 0.143rem !important;
  }
  .mb-sm-0dot5,
  .my-sm-0dot5 {
    margin-bottom: 0.143rem !important;
  }
  .ml-sm-0dot5,
  .mx-sm-0dot5 {
    margin-left: 0.143rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4rem !important;
  }
  .m-sm-10 {
    margin: 4.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 4.5rem !important;
  }
  .m-sm-11 {
    margin: 5rem !important;
  }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5rem !important;
  }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5rem !important;
  }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5rem !important;
  }
  .m-sm-12 {
    margin: 5.5rem !important;
  }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 5.5rem !important;
  }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 5.5rem !important;
  }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 5.5rem !important;
  }
  .m-sm-13 {
    margin: 6rem !important;
  }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 6rem !important;
  }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 6rem !important;
  }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 6rem !important;
  }
  .m-sm-14 {
    margin: 6.5rem !important;
  }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 6.5rem !important;
  }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 6.5rem !important;
  }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 6.5rem !important;
  }
  .m-sm-15 {
    margin: 7rem !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 7rem !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 7rem !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 7rem !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 7rem !important;
  }
  .m-sm-16 {
    margin: 8rem !important;
  }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 8rem !important;
  }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 8rem !important;
  }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 8rem !important;
  }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 8rem !important;
  }
  .m-sm-17 {
    margin: 9rem !important;
  }
  .mt-sm-17,
  .my-sm-17 {
    margin-top: 9rem !important;
  }
  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 9rem !important;
  }
  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 9rem !important;
  }
  .m-sm-18 {
    margin: 10rem !important;
  }
  .mt-sm-18,
  .my-sm-18 {
    margin-top: 10rem !important;
  }
  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 10rem !important;
  }
  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 10rem !important;
  }
  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 10rem !important;
  }
  .m-sm-19 {
    margin: 10.6rem !important;
  }
  .mt-sm-19,
  .my-sm-19 {
    margin-top: 10.6rem !important;
  }
  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 10.6rem !important;
  }
  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 10.6rem !important;
  }
  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 10.6rem !important;
  }
  .m-sm-20 {
    margin: 12.4rem !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 12.4rem !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 12.4rem !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 12.4rem !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 12.4rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-0dot5 {
    padding: 0.143rem !important;
  }
  .pt-sm-0dot5,
  .py-sm-0dot5 {
    padding-top: 0.143rem !important;
  }
  .pr-sm-0dot5,
  .px-sm-0dot5 {
    padding-right: 0.143rem !important;
  }
  .pb-sm-0dot5,
  .py-sm-0dot5 {
    padding-bottom: 0.143rem !important;
  }
  .pl-sm-0dot5,
  .px-sm-0dot5 {
    padding-left: 0.143rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4rem !important;
  }
  .p-sm-10 {
    padding: 4.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 4.5rem !important;
  }
  .p-sm-11 {
    padding: 5rem !important;
  }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5rem !important;
  }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5rem !important;
  }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5rem !important;
  }
  .p-sm-12 {
    padding: 5.5rem !important;
  }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 5.5rem !important;
  }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 5.5rem !important;
  }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 5.5rem !important;
  }
  .p-sm-13 {
    padding: 6rem !important;
  }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 6rem !important;
  }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 6rem !important;
  }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 6rem !important;
  }
  .p-sm-14 {
    padding: 6.5rem !important;
  }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 6.5rem !important;
  }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 6.5rem !important;
  }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 6.5rem !important;
  }
  .p-sm-15 {
    padding: 7rem !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 7rem !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 7rem !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 7rem !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 7rem !important;
  }
  .p-sm-16 {
    padding: 8rem !important;
  }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 8rem !important;
  }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 8rem !important;
  }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 8rem !important;
  }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 8rem !important;
  }
  .p-sm-17 {
    padding: 9rem !important;
  }
  .pt-sm-17,
  .py-sm-17 {
    padding-top: 9rem !important;
  }
  .pr-sm-17,
  .px-sm-17 {
    padding-right: 9rem !important;
  }
  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-17,
  .px-sm-17 {
    padding-left: 9rem !important;
  }
  .p-sm-18 {
    padding: 10rem !important;
  }
  .pt-sm-18,
  .py-sm-18 {
    padding-top: 10rem !important;
  }
  .pr-sm-18,
  .px-sm-18 {
    padding-right: 10rem !important;
  }
  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 10rem !important;
  }
  .pl-sm-18,
  .px-sm-18 {
    padding-left: 10rem !important;
  }
  .p-sm-19 {
    padding: 10.6rem !important;
  }
  .pt-sm-19,
  .py-sm-19 {
    padding-top: 10.6rem !important;
  }
  .pr-sm-19,
  .px-sm-19 {
    padding-right: 10.6rem !important;
  }
  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 10.6rem !important;
  }
  .pl-sm-19,
  .px-sm-19 {
    padding-left: 10.6rem !important;
  }
  .p-sm-20 {
    padding: 12.4rem !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 12.4rem !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 12.4rem !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 12.4rem !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 12.4rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4rem !important;
  }
  .m-sm-n10 {
    margin: -4.5rem !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -4.5rem !important;
  }
  .m-sm-n11 {
    margin: -5rem !important;
  }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -5rem !important;
  }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -5rem !important;
  }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -5rem !important;
  }
  .m-sm-n12 {
    margin: -5.5rem !important;
  }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -5.5rem !important;
  }
  .m-sm-n13 {
    margin: -6rem !important;
  }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -6rem !important;
  }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -6rem !important;
  }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -6rem !important;
  }
  .m-sm-n14 {
    margin: -6.5rem !important;
  }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -6.5rem !important;
  }
  .m-sm-n15 {
    margin: -7rem !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -7rem !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -7rem !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -7rem !important;
  }
  .m-sm-n16 {
    margin: -8rem !important;
  }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -8rem !important;
  }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -8rem !important;
  }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -8rem !important;
  }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -8rem !important;
  }
  .m-sm-n17 {
    margin: -9rem !important;
  }
  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -9rem !important;
  }
  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -9rem !important;
  }
  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -9rem !important;
  }
  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -9rem !important;
  }
  .m-sm-n18 {
    margin: -10rem !important;
  }
  .mt-sm-n18,
  .my-sm-n18 {
    margin-top: -10rem !important;
  }
  .mr-sm-n18,
  .mx-sm-n18 {
    margin-right: -10rem !important;
  }
  .mb-sm-n18,
  .my-sm-n18 {
    margin-bottom: -10rem !important;
  }
  .ml-sm-n18,
  .mx-sm-n18 {
    margin-left: -10rem !important;
  }
  .m-sm-n19 {
    margin: -10.6rem !important;
  }
  .mt-sm-n19,
  .my-sm-n19 {
    margin-top: -10.6rem !important;
  }
  .mr-sm-n19,
  .mx-sm-n19 {
    margin-right: -10.6rem !important;
  }
  .mb-sm-n19,
  .my-sm-n19 {
    margin-bottom: -10.6rem !important;
  }
  .ml-sm-n19,
  .mx-sm-n19 {
    margin-left: -10.6rem !important;
  }
  .m-sm-n20 {
    margin: -12.4rem !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -12.4rem !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -12.4rem !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -12.4rem !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -12.4rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-0dot5 {
    margin: 0.143rem !important;
  }
  .mt-md-0dot5,
  .my-md-0dot5 {
    margin-top: 0.143rem !important;
  }
  .mr-md-0dot5,
  .mx-md-0dot5 {
    margin-right: 0.143rem !important;
  }
  .mb-md-0dot5,
  .my-md-0dot5 {
    margin-bottom: 0.143rem !important;
  }
  .ml-md-0dot5,
  .mx-md-0dot5 {
    margin-left: 0.143rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4rem !important;
  }
  .m-md-10 {
    margin: 4.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 4.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 4.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 4.5rem !important;
  }
  .m-md-11 {
    margin: 5rem !important;
  }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5rem !important;
  }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5rem !important;
  }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5rem !important;
  }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5rem !important;
  }
  .m-md-12 {
    margin: 5.5rem !important;
  }
  .mt-md-12,
  .my-md-12 {
    margin-top: 5.5rem !important;
  }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 5.5rem !important;
  }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 5.5rem !important;
  }
  .m-md-13 {
    margin: 6rem !important;
  }
  .mt-md-13,
  .my-md-13 {
    margin-top: 6rem !important;
  }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 6rem !important;
  }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 6rem !important;
  }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 6rem !important;
  }
  .m-md-14 {
    margin: 6.5rem !important;
  }
  .mt-md-14,
  .my-md-14 {
    margin-top: 6.5rem !important;
  }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 6.5rem !important;
  }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 6.5rem !important;
  }
  .m-md-15 {
    margin: 7rem !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 7rem !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 7rem !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 7rem !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 7rem !important;
  }
  .m-md-16 {
    margin: 8rem !important;
  }
  .mt-md-16,
  .my-md-16 {
    margin-top: 8rem !important;
  }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 8rem !important;
  }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 8rem !important;
  }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 8rem !important;
  }
  .m-md-17 {
    margin: 9rem !important;
  }
  .mt-md-17,
  .my-md-17 {
    margin-top: 9rem !important;
  }
  .mr-md-17,
  .mx-md-17 {
    margin-right: 9rem !important;
  }
  .mb-md-17,
  .my-md-17 {
    margin-bottom: 9rem !important;
  }
  .ml-md-17,
  .mx-md-17 {
    margin-left: 9rem !important;
  }
  .m-md-18 {
    margin: 10rem !important;
  }
  .mt-md-18,
  .my-md-18 {
    margin-top: 10rem !important;
  }
  .mr-md-18,
  .mx-md-18 {
    margin-right: 10rem !important;
  }
  .mb-md-18,
  .my-md-18 {
    margin-bottom: 10rem !important;
  }
  .ml-md-18,
  .mx-md-18 {
    margin-left: 10rem !important;
  }
  .m-md-19 {
    margin: 10.6rem !important;
  }
  .mt-md-19,
  .my-md-19 {
    margin-top: 10.6rem !important;
  }
  .mr-md-19,
  .mx-md-19 {
    margin-right: 10.6rem !important;
  }
  .mb-md-19,
  .my-md-19 {
    margin-bottom: 10.6rem !important;
  }
  .ml-md-19,
  .mx-md-19 {
    margin-left: 10.6rem !important;
  }
  .m-md-20 {
    margin: 12.4rem !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 12.4rem !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 12.4rem !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 12.4rem !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 12.4rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-0dot5 {
    padding: 0.143rem !important;
  }
  .pt-md-0dot5,
  .py-md-0dot5 {
    padding-top: 0.143rem !important;
  }
  .pr-md-0dot5,
  .px-md-0dot5 {
    padding-right: 0.143rem !important;
  }
  .pb-md-0dot5,
  .py-md-0dot5 {
    padding-bottom: 0.143rem !important;
  }
  .pl-md-0dot5,
  .px-md-0dot5 {
    padding-left: 0.143rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4rem !important;
  }
  .p-md-10 {
    padding: 4.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 4.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 4.5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 4.5rem !important;
  }
  .p-md-11 {
    padding: 5rem !important;
  }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5rem !important;
  }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5rem !important;
  }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5rem !important;
  }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5rem !important;
  }
  .p-md-12 {
    padding: 5.5rem !important;
  }
  .pt-md-12,
  .py-md-12 {
    padding-top: 5.5rem !important;
  }
  .pr-md-12,
  .px-md-12 {
    padding-right: 5.5rem !important;
  }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-md-12,
  .px-md-12 {
    padding-left: 5.5rem !important;
  }
  .p-md-13 {
    padding: 6rem !important;
  }
  .pt-md-13,
  .py-md-13 {
    padding-top: 6rem !important;
  }
  .pr-md-13,
  .px-md-13 {
    padding-right: 6rem !important;
  }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 6rem !important;
  }
  .pl-md-13,
  .px-md-13 {
    padding-left: 6rem !important;
  }
  .p-md-14 {
    padding: 6.5rem !important;
  }
  .pt-md-14,
  .py-md-14 {
    padding-top: 6.5rem !important;
  }
  .pr-md-14,
  .px-md-14 {
    padding-right: 6.5rem !important;
  }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-md-14,
  .px-md-14 {
    padding-left: 6.5rem !important;
  }
  .p-md-15 {
    padding: 7rem !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 7rem !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 7rem !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 7rem !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 7rem !important;
  }
  .p-md-16 {
    padding: 8rem !important;
  }
  .pt-md-16,
  .py-md-16 {
    padding-top: 8rem !important;
  }
  .pr-md-16,
  .px-md-16 {
    padding-right: 8rem !important;
  }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 8rem !important;
  }
  .pl-md-16,
  .px-md-16 {
    padding-left: 8rem !important;
  }
  .p-md-17 {
    padding: 9rem !important;
  }
  .pt-md-17,
  .py-md-17 {
    padding-top: 9rem !important;
  }
  .pr-md-17,
  .px-md-17 {
    padding-right: 9rem !important;
  }
  .pb-md-17,
  .py-md-17 {
    padding-bottom: 9rem !important;
  }
  .pl-md-17,
  .px-md-17 {
    padding-left: 9rem !important;
  }
  .p-md-18 {
    padding: 10rem !important;
  }
  .pt-md-18,
  .py-md-18 {
    padding-top: 10rem !important;
  }
  .pr-md-18,
  .px-md-18 {
    padding-right: 10rem !important;
  }
  .pb-md-18,
  .py-md-18 {
    padding-bottom: 10rem !important;
  }
  .pl-md-18,
  .px-md-18 {
    padding-left: 10rem !important;
  }
  .p-md-19 {
    padding: 10.6rem !important;
  }
  .pt-md-19,
  .py-md-19 {
    padding-top: 10.6rem !important;
  }
  .pr-md-19,
  .px-md-19 {
    padding-right: 10.6rem !important;
  }
  .pb-md-19,
  .py-md-19 {
    padding-bottom: 10.6rem !important;
  }
  .pl-md-19,
  .px-md-19 {
    padding-left: 10.6rem !important;
  }
  .p-md-20 {
    padding: 12.4rem !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 12.4rem !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 12.4rem !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 12.4rem !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 12.4rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4rem !important;
  }
  .m-md-n10 {
    margin: -4.5rem !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -4.5rem !important;
  }
  .m-md-n11 {
    margin: -5rem !important;
  }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -5rem !important;
  }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -5rem !important;
  }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -5rem !important;
  }
  .m-md-n12 {
    margin: -5.5rem !important;
  }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -5.5rem !important;
  }
  .m-md-n13 {
    margin: -6rem !important;
  }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -6rem !important;
  }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -6rem !important;
  }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -6rem !important;
  }
  .m-md-n14 {
    margin: -6.5rem !important;
  }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -6.5rem !important;
  }
  .m-md-n15 {
    margin: -7rem !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -7rem !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -7rem !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -7rem !important;
  }
  .m-md-n16 {
    margin: -8rem !important;
  }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -8rem !important;
  }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -8rem !important;
  }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -8rem !important;
  }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -8rem !important;
  }
  .m-md-n17 {
    margin: -9rem !important;
  }
  .mt-md-n17,
  .my-md-n17 {
    margin-top: -9rem !important;
  }
  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -9rem !important;
  }
  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -9rem !important;
  }
  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -9rem !important;
  }
  .m-md-n18 {
    margin: -10rem !important;
  }
  .mt-md-n18,
  .my-md-n18 {
    margin-top: -10rem !important;
  }
  .mr-md-n18,
  .mx-md-n18 {
    margin-right: -10rem !important;
  }
  .mb-md-n18,
  .my-md-n18 {
    margin-bottom: -10rem !important;
  }
  .ml-md-n18,
  .mx-md-n18 {
    margin-left: -10rem !important;
  }
  .m-md-n19 {
    margin: -10.6rem !important;
  }
  .mt-md-n19,
  .my-md-n19 {
    margin-top: -10.6rem !important;
  }
  .mr-md-n19,
  .mx-md-n19 {
    margin-right: -10.6rem !important;
  }
  .mb-md-n19,
  .my-md-n19 {
    margin-bottom: -10.6rem !important;
  }
  .ml-md-n19,
  .mx-md-n19 {
    margin-left: -10.6rem !important;
  }
  .m-md-n20 {
    margin: -12.4rem !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -12.4rem !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -12.4rem !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -12.4rem !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -12.4rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-0dot5 {
    margin: 0.143rem !important;
  }
  .mt-lg-0dot5,
  .my-lg-0dot5 {
    margin-top: 0.143rem !important;
  }
  .mr-lg-0dot5,
  .mx-lg-0dot5 {
    margin-right: 0.143rem !important;
  }
  .mb-lg-0dot5,
  .my-lg-0dot5 {
    margin-bottom: 0.143rem !important;
  }
  .ml-lg-0dot5,
  .mx-lg-0dot5 {
    margin-left: 0.143rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .m-lg-10 {
    margin: 4.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 4.5rem !important;
  }
  .m-lg-11 {
    margin: 5rem !important;
  }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5rem !important;
  }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5rem !important;
  }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5rem !important;
  }
  .m-lg-12 {
    margin: 5.5rem !important;
  }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 5.5rem !important;
  }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 5.5rem !important;
  }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 5.5rem !important;
  }
  .m-lg-13 {
    margin: 6rem !important;
  }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 6rem !important;
  }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 6rem !important;
  }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 6rem !important;
  }
  .m-lg-14 {
    margin: 6.5rem !important;
  }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 6.5rem !important;
  }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 6.5rem !important;
  }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 6.5rem !important;
  }
  .m-lg-15 {
    margin: 7rem !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 7rem !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 7rem !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 7rem !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 7rem !important;
  }
  .m-lg-16 {
    margin: 8rem !important;
  }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 8rem !important;
  }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 8rem !important;
  }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 8rem !important;
  }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 8rem !important;
  }
  .m-lg-17 {
    margin: 9rem !important;
  }
  .mt-lg-17,
  .my-lg-17 {
    margin-top: 9rem !important;
  }
  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 9rem !important;
  }
  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 9rem !important;
  }
  .m-lg-18 {
    margin: 10rem !important;
  }
  .mt-lg-18,
  .my-lg-18 {
    margin-top: 10rem !important;
  }
  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 10rem !important;
  }
  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 10rem !important;
  }
  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 10rem !important;
  }
  .m-lg-19 {
    margin: 10.6rem !important;
  }
  .mt-lg-19,
  .my-lg-19 {
    margin-top: 10.6rem !important;
  }
  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 10.6rem !important;
  }
  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 10.6rem !important;
  }
  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 10.6rem !important;
  }
  .m-lg-20 {
    margin: 12.4rem !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 12.4rem !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 12.4rem !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 12.4rem !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 12.4rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-0dot5 {
    padding: 0.143rem !important;
  }
  .pt-lg-0dot5,
  .py-lg-0dot5 {
    padding-top: 0.143rem !important;
  }
  .pr-lg-0dot5,
  .px-lg-0dot5 {
    padding-right: 0.143rem !important;
  }
  .pb-lg-0dot5,
  .py-lg-0dot5 {
    padding-bottom: 0.143rem !important;
  }
  .pl-lg-0dot5,
  .px-lg-0dot5 {
    padding-left: 0.143rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4rem !important;
  }
  .p-lg-10 {
    padding: 4.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 4.5rem !important;
  }
  .p-lg-11 {
    padding: 5rem !important;
  }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5rem !important;
  }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5rem !important;
  }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5rem !important;
  }
  .p-lg-12 {
    padding: 5.5rem !important;
  }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 5.5rem !important;
  }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 5.5rem !important;
  }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 5.5rem !important;
  }
  .p-lg-13 {
    padding: 6rem !important;
  }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 6rem !important;
  }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 6rem !important;
  }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 6rem !important;
  }
  .p-lg-14 {
    padding: 6.5rem !important;
  }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 6.5rem !important;
  }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 6.5rem !important;
  }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 6.5rem !important;
  }
  .p-lg-15 {
    padding: 7rem !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 7rem !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 7rem !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 7rem !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 7rem !important;
  }
  .p-lg-16 {
    padding: 8rem !important;
  }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 8rem !important;
  }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 8rem !important;
  }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 8rem !important;
  }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 8rem !important;
  }
  .p-lg-17 {
    padding: 9rem !important;
  }
  .pt-lg-17,
  .py-lg-17 {
    padding-top: 9rem !important;
  }
  .pr-lg-17,
  .px-lg-17 {
    padding-right: 9rem !important;
  }
  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-17,
  .px-lg-17 {
    padding-left: 9rem !important;
  }
  .p-lg-18 {
    padding: 10rem !important;
  }
  .pt-lg-18,
  .py-lg-18 {
    padding-top: 10rem !important;
  }
  .pr-lg-18,
  .px-lg-18 {
    padding-right: 10rem !important;
  }
  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 10rem !important;
  }
  .pl-lg-18,
  .px-lg-18 {
    padding-left: 10rem !important;
  }
  .p-lg-19 {
    padding: 10.6rem !important;
  }
  .pt-lg-19,
  .py-lg-19 {
    padding-top: 10.6rem !important;
  }
  .pr-lg-19,
  .px-lg-19 {
    padding-right: 10.6rem !important;
  }
  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 10.6rem !important;
  }
  .pl-lg-19,
  .px-lg-19 {
    padding-left: 10.6rem !important;
  }
  .p-lg-20 {
    padding: 12.4rem !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 12.4rem !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 12.4rem !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 12.4rem !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 12.4rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .m-lg-n10 {
    margin: -4.5rem !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -4.5rem !important;
  }
  .m-lg-n11 {
    margin: -5rem !important;
  }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -5rem !important;
  }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -5rem !important;
  }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -5rem !important;
  }
  .m-lg-n12 {
    margin: -5.5rem !important;
  }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -5.5rem !important;
  }
  .m-lg-n13 {
    margin: -6rem !important;
  }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -6rem !important;
  }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -6rem !important;
  }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -6rem !important;
  }
  .m-lg-n14 {
    margin: -6.5rem !important;
  }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -6.5rem !important;
  }
  .m-lg-n15 {
    margin: -7rem !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -7rem !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -7rem !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -7rem !important;
  }
  .m-lg-n16 {
    margin: -8rem !important;
  }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -8rem !important;
  }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -8rem !important;
  }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -8rem !important;
  }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -8rem !important;
  }
  .m-lg-n17 {
    margin: -9rem !important;
  }
  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -9rem !important;
  }
  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -9rem !important;
  }
  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -9rem !important;
  }
  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -9rem !important;
  }
  .m-lg-n18 {
    margin: -10rem !important;
  }
  .mt-lg-n18,
  .my-lg-n18 {
    margin-top: -10rem !important;
  }
  .mr-lg-n18,
  .mx-lg-n18 {
    margin-right: -10rem !important;
  }
  .mb-lg-n18,
  .my-lg-n18 {
    margin-bottom: -10rem !important;
  }
  .ml-lg-n18,
  .mx-lg-n18 {
    margin-left: -10rem !important;
  }
  .m-lg-n19 {
    margin: -10.6rem !important;
  }
  .mt-lg-n19,
  .my-lg-n19 {
    margin-top: -10.6rem !important;
  }
  .mr-lg-n19,
  .mx-lg-n19 {
    margin-right: -10.6rem !important;
  }
  .mb-lg-n19,
  .my-lg-n19 {
    margin-bottom: -10.6rem !important;
  }
  .ml-lg-n19,
  .mx-lg-n19 {
    margin-left: -10.6rem !important;
  }
  .m-lg-n20 {
    margin: -12.4rem !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -12.4rem !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -12.4rem !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -12.4rem !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -12.4rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-0dot5 {
    margin: 0.143rem !important;
  }
  .mt-xl-0dot5,
  .my-xl-0dot5 {
    margin-top: 0.143rem !important;
  }
  .mr-xl-0dot5,
  .mx-xl-0dot5 {
    margin-right: 0.143rem !important;
  }
  .mb-xl-0dot5,
  .my-xl-0dot5 {
    margin-bottom: 0.143rem !important;
  }
  .ml-xl-0dot5,
  .mx-xl-0dot5 {
    margin-left: 0.143rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .m-xl-10 {
    margin: 4.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 4.5rem !important;
  }
  .m-xl-11 {
    margin: 5rem !important;
  }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5rem !important;
  }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5rem !important;
  }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5rem !important;
  }
  .m-xl-12 {
    margin: 5.5rem !important;
  }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 5.5rem !important;
  }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 5.5rem !important;
  }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 5.5rem !important;
  }
  .m-xl-13 {
    margin: 6rem !important;
  }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 6rem !important;
  }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 6rem !important;
  }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 6rem !important;
  }
  .m-xl-14 {
    margin: 6.5rem !important;
  }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 6.5rem !important;
  }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 6.5rem !important;
  }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 6.5rem !important;
  }
  .m-xl-15 {
    margin: 7rem !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 7rem !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 7rem !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 7rem !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 7rem !important;
  }
  .m-xl-16 {
    margin: 8rem !important;
  }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 8rem !important;
  }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 8rem !important;
  }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 8rem !important;
  }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 8rem !important;
  }
  .m-xl-17 {
    margin: 9rem !important;
  }
  .mt-xl-17,
  .my-xl-17 {
    margin-top: 9rem !important;
  }
  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 9rem !important;
  }
  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 9rem !important;
  }
  .m-xl-18 {
    margin: 10rem !important;
  }
  .mt-xl-18,
  .my-xl-18 {
    margin-top: 10rem !important;
  }
  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 10rem !important;
  }
  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 10rem !important;
  }
  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 10rem !important;
  }
  .m-xl-19 {
    margin: 10.6rem !important;
  }
  .mt-xl-19,
  .my-xl-19 {
    margin-top: 10.6rem !important;
  }
  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 10.6rem !important;
  }
  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 10.6rem !important;
  }
  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 10.6rem !important;
  }
  .m-xl-20 {
    margin: 12.4rem !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 12.4rem !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 12.4rem !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 12.4rem !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 12.4rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-0dot5 {
    padding: 0.143rem !important;
  }
  .pt-xl-0dot5,
  .py-xl-0dot5 {
    padding-top: 0.143rem !important;
  }
  .pr-xl-0dot5,
  .px-xl-0dot5 {
    padding-right: 0.143rem !important;
  }
  .pb-xl-0dot5,
  .py-xl-0dot5 {
    padding-bottom: 0.143rem !important;
  }
  .pl-xl-0dot5,
  .px-xl-0dot5 {
    padding-left: 0.143rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4rem !important;
  }
  .p-xl-10 {
    padding: 4.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 4.5rem !important;
  }
  .p-xl-11 {
    padding: 5rem !important;
  }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5rem !important;
  }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5rem !important;
  }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5rem !important;
  }
  .p-xl-12 {
    padding: 5.5rem !important;
  }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 5.5rem !important;
  }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 5.5rem !important;
  }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 5.5rem !important;
  }
  .p-xl-13 {
    padding: 6rem !important;
  }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 6rem !important;
  }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 6rem !important;
  }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 6rem !important;
  }
  .p-xl-14 {
    padding: 6.5rem !important;
  }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 6.5rem !important;
  }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 6.5rem !important;
  }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 6.5rem !important;
  }
  .p-xl-15 {
    padding: 7rem !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 7rem !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 7rem !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 7rem !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 7rem !important;
  }
  .p-xl-16 {
    padding: 8rem !important;
  }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 8rem !important;
  }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 8rem !important;
  }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 8rem !important;
  }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 8rem !important;
  }
  .p-xl-17 {
    padding: 9rem !important;
  }
  .pt-xl-17,
  .py-xl-17 {
    padding-top: 9rem !important;
  }
  .pr-xl-17,
  .px-xl-17 {
    padding-right: 9rem !important;
  }
  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-17,
  .px-xl-17 {
    padding-left: 9rem !important;
  }
  .p-xl-18 {
    padding: 10rem !important;
  }
  .pt-xl-18,
  .py-xl-18 {
    padding-top: 10rem !important;
  }
  .pr-xl-18,
  .px-xl-18 {
    padding-right: 10rem !important;
  }
  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 10rem !important;
  }
  .pl-xl-18,
  .px-xl-18 {
    padding-left: 10rem !important;
  }
  .p-xl-19 {
    padding: 10.6rem !important;
  }
  .pt-xl-19,
  .py-xl-19 {
    padding-top: 10.6rem !important;
  }
  .pr-xl-19,
  .px-xl-19 {
    padding-right: 10.6rem !important;
  }
  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 10.6rem !important;
  }
  .pl-xl-19,
  .px-xl-19 {
    padding-left: 10.6rem !important;
  }
  .p-xl-20 {
    padding: 12.4rem !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 12.4rem !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 12.4rem !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 12.4rem !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 12.4rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .m-xl-n10 {
    margin: -4.5rem !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -4.5rem !important;
  }
  .m-xl-n11 {
    margin: -5rem !important;
  }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -5rem !important;
  }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -5rem !important;
  }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -5rem !important;
  }
  .m-xl-n12 {
    margin: -5.5rem !important;
  }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -5.5rem !important;
  }
  .m-xl-n13 {
    margin: -6rem !important;
  }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -6rem !important;
  }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -6rem !important;
  }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -6rem !important;
  }
  .m-xl-n14 {
    margin: -6.5rem !important;
  }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -6.5rem !important;
  }
  .m-xl-n15 {
    margin: -7rem !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -7rem !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -7rem !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -7rem !important;
  }
  .m-xl-n16 {
    margin: -8rem !important;
  }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -8rem !important;
  }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -8rem !important;
  }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -8rem !important;
  }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -8rem !important;
  }
  .m-xl-n17 {
    margin: -9rem !important;
  }
  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -9rem !important;
  }
  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -9rem !important;
  }
  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -9rem !important;
  }
  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -9rem !important;
  }
  .m-xl-n18 {
    margin: -10rem !important;
  }
  .mt-xl-n18,
  .my-xl-n18 {
    margin-top: -10rem !important;
  }
  .mr-xl-n18,
  .mx-xl-n18 {
    margin-right: -10rem !important;
  }
  .mb-xl-n18,
  .my-xl-n18 {
    margin-bottom: -10rem !important;
  }
  .ml-xl-n18,
  .mx-xl-n18 {
    margin-left: -10rem !important;
  }
  .m-xl-n19 {
    margin: -10.6rem !important;
  }
  .mt-xl-n19,
  .my-xl-n19 {
    margin-top: -10.6rem !important;
  }
  .mr-xl-n19,
  .mx-xl-n19 {
    margin-right: -10.6rem !important;
  }
  .mb-xl-n19,
  .my-xl-n19 {
    margin-bottom: -10.6rem !important;
  }
  .ml-xl-n19,
  .mx-xl-n19 {
    margin-left: -10.6rem !important;
  }
  .m-xl-n20 {
    margin: -12.4rem !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -12.4rem !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -12.4rem !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -12.4rem !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -12.4rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1480px) {
  .m-wd-0 {
    margin: 0 !important;
  }
  .mt-wd-0,
  .my-wd-0 {
    margin-top: 0 !important;
  }
  .mr-wd-0,
  .mx-wd-0 {
    margin-right: 0 !important;
  }
  .mb-wd-0,
  .my-wd-0 {
    margin-bottom: 0 !important;
  }
  .ml-wd-0,
  .mx-wd-0 {
    margin-left: 0 !important;
  }
  .m-wd-1 {
    margin: 0.25rem !important;
  }
  .mt-wd-1,
  .my-wd-1 {
    margin-top: 0.25rem !important;
  }
  .mr-wd-1,
  .mx-wd-1 {
    margin-right: 0.25rem !important;
  }
  .mb-wd-1,
  .my-wd-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-wd-1,
  .mx-wd-1 {
    margin-left: 0.25rem !important;
  }
  .m-wd-2 {
    margin: 0.5rem !important;
  }
  .mt-wd-2,
  .my-wd-2 {
    margin-top: 0.5rem !important;
  }
  .mr-wd-2,
  .mx-wd-2 {
    margin-right: 0.5rem !important;
  }
  .mb-wd-2,
  .my-wd-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-wd-2,
  .mx-wd-2 {
    margin-left: 0.5rem !important;
  }
  .m-wd-3 {
    margin: 1rem !important;
  }
  .mt-wd-3,
  .my-wd-3 {
    margin-top: 1rem !important;
  }
  .mr-wd-3,
  .mx-wd-3 {
    margin-right: 1rem !important;
  }
  .mb-wd-3,
  .my-wd-3 {
    margin-bottom: 1rem !important;
  }
  .ml-wd-3,
  .mx-wd-3 {
    margin-left: 1rem !important;
  }
  .m-wd-4 {
    margin: 1.5rem !important;
  }
  .mt-wd-4,
  .my-wd-4 {
    margin-top: 1.5rem !important;
  }
  .mr-wd-4,
  .mx-wd-4 {
    margin-right: 1.5rem !important;
  }
  .mb-wd-4,
  .my-wd-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-wd-4,
  .mx-wd-4 {
    margin-left: 1.5rem !important;
  }
  .m-wd-5 {
    margin: 2rem !important;
  }
  .mt-wd-5,
  .my-wd-5 {
    margin-top: 2rem !important;
  }
  .mr-wd-5,
  .mx-wd-5 {
    margin-right: 2rem !important;
  }
  .mb-wd-5,
  .my-wd-5 {
    margin-bottom: 2rem !important;
  }
  .ml-wd-5,
  .mx-wd-5 {
    margin-left: 2rem !important;
  }
  .m-wd-0dot5 {
    margin: 0.143rem !important;
  }
  .mt-wd-0dot5,
  .my-wd-0dot5 {
    margin-top: 0.143rem !important;
  }
  .mr-wd-0dot5,
  .mx-wd-0dot5 {
    margin-right: 0.143rem !important;
  }
  .mb-wd-0dot5,
  .my-wd-0dot5 {
    margin-bottom: 0.143rem !important;
  }
  .ml-wd-0dot5,
  .mx-wd-0dot5 {
    margin-left: 0.143rem !important;
  }
  .m-wd-6 {
    margin: 2.5rem !important;
  }
  .mt-wd-6,
  .my-wd-6 {
    margin-top: 2.5rem !important;
  }
  .mr-wd-6,
  .mx-wd-6 {
    margin-right: 2.5rem !important;
  }
  .mb-wd-6,
  .my-wd-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-wd-6,
  .mx-wd-6 {
    margin-left: 2.5rem !important;
  }
  .m-wd-7 {
    margin: 3rem !important;
  }
  .mt-wd-7,
  .my-wd-7 {
    margin-top: 3rem !important;
  }
  .mr-wd-7,
  .mx-wd-7 {
    margin-right: 3rem !important;
  }
  .mb-wd-7,
  .my-wd-7 {
    margin-bottom: 3rem !important;
  }
  .ml-wd-7,
  .mx-wd-7 {
    margin-left: 3rem !important;
  }
  .m-wd-8 {
    margin: 3.5rem !important;
  }
  .mt-wd-8,
  .my-wd-8 {
    margin-top: 3.5rem !important;
  }
  .mr-wd-8,
  .mx-wd-8 {
    margin-right: 3.5rem !important;
  }
  .mb-wd-8,
  .my-wd-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-wd-8,
  .mx-wd-8 {
    margin-left: 3.5rem !important;
  }
  .m-wd-9 {
    margin: 4rem !important;
  }
  .mt-wd-9,
  .my-wd-9 {
    margin-top: 4rem !important;
  }
  .mr-wd-9,
  .mx-wd-9 {
    margin-right: 4rem !important;
  }
  .mb-wd-9,
  .my-wd-9 {
    margin-bottom: 4rem !important;
  }
  .ml-wd-9,
  .mx-wd-9 {
    margin-left: 4rem !important;
  }
  .m-wd-10 {
    margin: 4.5rem !important;
  }
  .mt-wd-10,
  .my-wd-10 {
    margin-top: 4.5rem !important;
  }
  .mr-wd-10,
  .mx-wd-10 {
    margin-right: 4.5rem !important;
  }
  .mb-wd-10,
  .my-wd-10 {
    margin-bottom: 4.5rem !important;
  }
  .ml-wd-10,
  .mx-wd-10 {
    margin-left: 4.5rem !important;
  }
  .m-wd-11 {
    margin: 5rem !important;
  }
  .mt-wd-11,
  .my-wd-11 {
    margin-top: 5rem !important;
  }
  .mr-wd-11,
  .mx-wd-11 {
    margin-right: 5rem !important;
  }
  .mb-wd-11,
  .my-wd-11 {
    margin-bottom: 5rem !important;
  }
  .ml-wd-11,
  .mx-wd-11 {
    margin-left: 5rem !important;
  }
  .m-wd-12 {
    margin: 5.5rem !important;
  }
  .mt-wd-12,
  .my-wd-12 {
    margin-top: 5.5rem !important;
  }
  .mr-wd-12,
  .mx-wd-12 {
    margin-right: 5.5rem !important;
  }
  .mb-wd-12,
  .my-wd-12 {
    margin-bottom: 5.5rem !important;
  }
  .ml-wd-12,
  .mx-wd-12 {
    margin-left: 5.5rem !important;
  }
  .m-wd-13 {
    margin: 6rem !important;
  }
  .mt-wd-13,
  .my-wd-13 {
    margin-top: 6rem !important;
  }
  .mr-wd-13,
  .mx-wd-13 {
    margin-right: 6rem !important;
  }
  .mb-wd-13,
  .my-wd-13 {
    margin-bottom: 6rem !important;
  }
  .ml-wd-13,
  .mx-wd-13 {
    margin-left: 6rem !important;
  }
  .m-wd-14 {
    margin: 6.5rem !important;
  }
  .mt-wd-14,
  .my-wd-14 {
    margin-top: 6.5rem !important;
  }
  .mr-wd-14,
  .mx-wd-14 {
    margin-right: 6.5rem !important;
  }
  .mb-wd-14,
  .my-wd-14 {
    margin-bottom: 6.5rem !important;
  }
  .ml-wd-14,
  .mx-wd-14 {
    margin-left: 6.5rem !important;
  }
  .m-wd-15 {
    margin: 7rem !important;
  }
  .mt-wd-15,
  .my-wd-15 {
    margin-top: 7rem !important;
  }
  .mr-wd-15,
  .mx-wd-15 {
    margin-right: 7rem !important;
  }
  .mb-wd-15,
  .my-wd-15 {
    margin-bottom: 7rem !important;
  }
  .ml-wd-15,
  .mx-wd-15 {
    margin-left: 7rem !important;
  }
  .m-wd-16 {
    margin: 8rem !important;
  }
  .mt-wd-16,
  .my-wd-16 {
    margin-top: 8rem !important;
  }
  .mr-wd-16,
  .mx-wd-16 {
    margin-right: 8rem !important;
  }
  .mb-wd-16,
  .my-wd-16 {
    margin-bottom: 8rem !important;
  }
  .ml-wd-16,
  .mx-wd-16 {
    margin-left: 8rem !important;
  }
  .m-wd-17 {
    margin: 9rem !important;
  }
  .mt-wd-17,
  .my-wd-17 {
    margin-top: 9rem !important;
  }
  .mr-wd-17,
  .mx-wd-17 {
    margin-right: 9rem !important;
  }
  .mb-wd-17,
  .my-wd-17 {
    margin-bottom: 9rem !important;
  }
  .ml-wd-17,
  .mx-wd-17 {
    margin-left: 9rem !important;
  }
  .m-wd-18 {
    margin: 10rem !important;
  }
  .mt-wd-18,
  .my-wd-18 {
    margin-top: 10rem !important;
  }
  .mr-wd-18,
  .mx-wd-18 {
    margin-right: 10rem !important;
  }
  .mb-wd-18,
  .my-wd-18 {
    margin-bottom: 10rem !important;
  }
  .ml-wd-18,
  .mx-wd-18 {
    margin-left: 10rem !important;
  }
  .m-wd-19 {
    margin: 10.6rem !important;
  }
  .mt-wd-19,
  .my-wd-19 {
    margin-top: 10.6rem !important;
  }
  .mr-wd-19,
  .mx-wd-19 {
    margin-right: 10.6rem !important;
  }
  .mb-wd-19,
  .my-wd-19 {
    margin-bottom: 10.6rem !important;
  }
  .ml-wd-19,
  .mx-wd-19 {
    margin-left: 10.6rem !important;
  }
  .m-wd-20 {
    margin: 12.4rem !important;
  }
  .mt-wd-20,
  .my-wd-20 {
    margin-top: 12.4rem !important;
  }
  .mr-wd-20,
  .mx-wd-20 {
    margin-right: 12.4rem !important;
  }
  .mb-wd-20,
  .my-wd-20 {
    margin-bottom: 12.4rem !important;
  }
  .ml-wd-20,
  .mx-wd-20 {
    margin-left: 12.4rem !important;
  }
  .p-wd-0 {
    padding: 0 !important;
  }
  .pt-wd-0,
  .py-wd-0 {
    padding-top: 0 !important;
  }
  .pr-wd-0,
  .px-wd-0 {
    padding-right: 0 !important;
  }
  .pb-wd-0,
  .py-wd-0 {
    padding-bottom: 0 !important;
  }
  .pl-wd-0,
  .px-wd-0 {
    padding-left: 0 !important;
  }
  .p-wd-1 {
    padding: 0.25rem !important;
  }
  .pt-wd-1,
  .py-wd-1 {
    padding-top: 0.25rem !important;
  }
  .pr-wd-1,
  .px-wd-1 {
    padding-right: 0.25rem !important;
  }
  .pb-wd-1,
  .py-wd-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-wd-1,
  .px-wd-1 {
    padding-left: 0.25rem !important;
  }
  .p-wd-2 {
    padding: 0.5rem !important;
  }
  .pt-wd-2,
  .py-wd-2 {
    padding-top: 0.5rem !important;
  }
  .pr-wd-2,
  .px-wd-2 {
    padding-right: 0.5rem !important;
  }
  .pb-wd-2,
  .py-wd-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-wd-2,
  .px-wd-2 {
    padding-left: 0.5rem !important;
  }
  .p-wd-3 {
    padding: 1rem !important;
  }
  .pt-wd-3,
  .py-wd-3 {
    padding-top: 1rem !important;
  }
  .pr-wd-3,
  .px-wd-3 {
    padding-right: 1rem !important;
  }
  .pb-wd-3,
  .py-wd-3 {
    padding-bottom: 1rem !important;
  }
  .pl-wd-3,
  .px-wd-3 {
    padding-left: 1rem !important;
  }
  .p-wd-4 {
    padding: 1.5rem !important;
  }
  .pt-wd-4,
  .py-wd-4 {
    padding-top: 1.5rem !important;
  }
  .pr-wd-4,
  .px-wd-4 {
    padding-right: 1.5rem !important;
  }
  .pb-wd-4,
  .py-wd-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-wd-4,
  .px-wd-4 {
    padding-left: 1.5rem !important;
  }
  .p-wd-5 {
    padding: 2rem !important;
  }
  .pt-wd-5,
  .py-wd-5 {
    padding-top: 2rem !important;
  }
  .pr-wd-5,
  .px-wd-5 {
    padding-right: 2rem !important;
  }
  .pb-wd-5,
  .py-wd-5 {
    padding-bottom: 2rem !important;
  }
  .pl-wd-5,
  .px-wd-5 {
    padding-left: 2rem !important;
  }
  .p-wd-0dot5 {
    padding: 0.143rem !important;
  }
  .pt-wd-0dot5,
  .py-wd-0dot5 {
    padding-top: 0.143rem !important;
  }
  .pr-wd-0dot5,
  .px-wd-0dot5 {
    padding-right: 0.143rem !important;
  }
  .pb-wd-0dot5,
  .py-wd-0dot5 {
    padding-bottom: 0.143rem !important;
  }
  .pl-wd-0dot5,
  .px-wd-0dot5 {
    padding-left: 0.143rem !important;
  }
  .p-wd-6 {
    padding: 2.5rem !important;
  }
  .pt-wd-6,
  .py-wd-6 {
    padding-top: 2.5rem !important;
  }
  .pr-wd-6,
  .px-wd-6 {
    padding-right: 2.5rem !important;
  }
  .pb-wd-6,
  .py-wd-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-wd-6,
  .px-wd-6 {
    padding-left: 2.5rem !important;
  }
  .p-wd-7 {
    padding: 3rem !important;
  }
  .pt-wd-7,
  .py-wd-7 {
    padding-top: 3rem !important;
  }
  .pr-wd-7,
  .px-wd-7 {
    padding-right: 3rem !important;
  }
  .pb-wd-7,
  .py-wd-7 {
    padding-bottom: 3rem !important;
  }
  .pl-wd-7,
  .px-wd-7 {
    padding-left: 3rem !important;
  }
  .p-wd-8 {
    padding: 3.5rem !important;
  }
  .pt-wd-8,
  .py-wd-8 {
    padding-top: 3.5rem !important;
  }
  .pr-wd-8,
  .px-wd-8 {
    padding-right: 3.5rem !important;
  }
  .pb-wd-8,
  .py-wd-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-wd-8,
  .px-wd-8 {
    padding-left: 3.5rem !important;
  }
  .p-wd-9 {
    padding: 4rem !important;
  }
  .pt-wd-9,
  .py-wd-9 {
    padding-top: 4rem !important;
  }
  .pr-wd-9,
  .px-wd-9 {
    padding-right: 4rem !important;
  }
  .pb-wd-9,
  .py-wd-9 {
    padding-bottom: 4rem !important;
  }
  .pl-wd-9,
  .px-wd-9 {
    padding-left: 4rem !important;
  }
  .p-wd-10 {
    padding: 4.5rem !important;
  }
  .pt-wd-10,
  .py-wd-10 {
    padding-top: 4.5rem !important;
  }
  .pr-wd-10,
  .px-wd-10 {
    padding-right: 4.5rem !important;
  }
  .pb-wd-10,
  .py-wd-10 {
    padding-bottom: 4.5rem !important;
  }
  .pl-wd-10,
  .px-wd-10 {
    padding-left: 4.5rem !important;
  }
  .p-wd-11 {
    padding: 5rem !important;
  }
  .pt-wd-11,
  .py-wd-11 {
    padding-top: 5rem !important;
  }
  .pr-wd-11,
  .px-wd-11 {
    padding-right: 5rem !important;
  }
  .pb-wd-11,
  .py-wd-11 {
    padding-bottom: 5rem !important;
  }
  .pl-wd-11,
  .px-wd-11 {
    padding-left: 5rem !important;
  }
  .p-wd-12 {
    padding: 5.5rem !important;
  }
  .pt-wd-12,
  .py-wd-12 {
    padding-top: 5.5rem !important;
  }
  .pr-wd-12,
  .px-wd-12 {
    padding-right: 5.5rem !important;
  }
  .pb-wd-12,
  .py-wd-12 {
    padding-bottom: 5.5rem !important;
  }
  .pl-wd-12,
  .px-wd-12 {
    padding-left: 5.5rem !important;
  }
  .p-wd-13 {
    padding: 6rem !important;
  }
  .pt-wd-13,
  .py-wd-13 {
    padding-top: 6rem !important;
  }
  .pr-wd-13,
  .px-wd-13 {
    padding-right: 6rem !important;
  }
  .pb-wd-13,
  .py-wd-13 {
    padding-bottom: 6rem !important;
  }
  .pl-wd-13,
  .px-wd-13 {
    padding-left: 6rem !important;
  }
  .p-wd-14 {
    padding: 6.5rem !important;
  }
  .pt-wd-14,
  .py-wd-14 {
    padding-top: 6.5rem !important;
  }
  .pr-wd-14,
  .px-wd-14 {
    padding-right: 6.5rem !important;
  }
  .pb-wd-14,
  .py-wd-14 {
    padding-bottom: 6.5rem !important;
  }
  .pl-wd-14,
  .px-wd-14 {
    padding-left: 6.5rem !important;
  }
  .p-wd-15 {
    padding: 7rem !important;
  }
  .pt-wd-15,
  .py-wd-15 {
    padding-top: 7rem !important;
  }
  .pr-wd-15,
  .px-wd-15 {
    padding-right: 7rem !important;
  }
  .pb-wd-15,
  .py-wd-15 {
    padding-bottom: 7rem !important;
  }
  .pl-wd-15,
  .px-wd-15 {
    padding-left: 7rem !important;
  }
  .p-wd-16 {
    padding: 8rem !important;
  }
  .pt-wd-16,
  .py-wd-16 {
    padding-top: 8rem !important;
  }
  .pr-wd-16,
  .px-wd-16 {
    padding-right: 8rem !important;
  }
  .pb-wd-16,
  .py-wd-16 {
    padding-bottom: 8rem !important;
  }
  .pl-wd-16,
  .px-wd-16 {
    padding-left: 8rem !important;
  }
  .p-wd-17 {
    padding: 9rem !important;
  }
  .pt-wd-17,
  .py-wd-17 {
    padding-top: 9rem !important;
  }
  .pr-wd-17,
  .px-wd-17 {
    padding-right: 9rem !important;
  }
  .pb-wd-17,
  .py-wd-17 {
    padding-bottom: 9rem !important;
  }
  .pl-wd-17,
  .px-wd-17 {
    padding-left: 9rem !important;
  }
  .p-wd-18 {
    padding: 10rem !important;
  }
  .pt-wd-18,
  .py-wd-18 {
    padding-top: 10rem !important;
  }
  .pr-wd-18,
  .px-wd-18 {
    padding-right: 10rem !important;
  }
  .pb-wd-18,
  .py-wd-18 {
    padding-bottom: 10rem !important;
  }
  .pl-wd-18,
  .px-wd-18 {
    padding-left: 10rem !important;
  }
  .p-wd-19 {
    padding: 10.6rem !important;
  }
  .pt-wd-19,
  .py-wd-19 {
    padding-top: 10.6rem !important;
  }
  .pr-wd-19,
  .px-wd-19 {
    padding-right: 10.6rem !important;
  }
  .pb-wd-19,
  .py-wd-19 {
    padding-bottom: 10.6rem !important;
  }
  .pl-wd-19,
  .px-wd-19 {
    padding-left: 10.6rem !important;
  }
  .p-wd-20 {
    padding: 12.4rem !important;
  }
  .pt-wd-20,
  .py-wd-20 {
    padding-top: 12.4rem !important;
  }
  .pr-wd-20,
  .px-wd-20 {
    padding-right: 12.4rem !important;
  }
  .pb-wd-20,
  .py-wd-20 {
    padding-bottom: 12.4rem !important;
  }
  .pl-wd-20,
  .px-wd-20 {
    padding-left: 12.4rem !important;
  }
  .m-wd-n1 {
    margin: -0.25rem !important;
  }
  .mt-wd-n1,
  .my-wd-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-wd-n1,
  .mx-wd-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-wd-n1,
  .my-wd-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-wd-n1,
  .mx-wd-n1 {
    margin-left: -0.25rem !important;
  }
  .m-wd-n2 {
    margin: -0.5rem !important;
  }
  .mt-wd-n2,
  .my-wd-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-wd-n2,
  .mx-wd-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-wd-n2,
  .my-wd-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-wd-n2,
  .mx-wd-n2 {
    margin-left: -0.5rem !important;
  }
  .m-wd-n3 {
    margin: -1rem !important;
  }
  .mt-wd-n3,
  .my-wd-n3 {
    margin-top: -1rem !important;
  }
  .mr-wd-n3,
  .mx-wd-n3 {
    margin-right: -1rem !important;
  }
  .mb-wd-n3,
  .my-wd-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-wd-n3,
  .mx-wd-n3 {
    margin-left: -1rem !important;
  }
  .m-wd-n4 {
    margin: -1.5rem !important;
  }
  .mt-wd-n4,
  .my-wd-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-wd-n4,
  .mx-wd-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-wd-n4,
  .my-wd-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-wd-n4,
  .mx-wd-n4 {
    margin-left: -1.5rem !important;
  }
  .m-wd-n5 {
    margin: -2rem !important;
  }
  .mt-wd-n5,
  .my-wd-n5 {
    margin-top: -2rem !important;
  }
  .mr-wd-n5,
  .mx-wd-n5 {
    margin-right: -2rem !important;
  }
  .mb-wd-n5,
  .my-wd-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-wd-n5,
  .mx-wd-n5 {
    margin-left: -2rem !important;
  }
  .m-wd-n6 {
    margin: -2.5rem !important;
  }
  .mt-wd-n6,
  .my-wd-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-wd-n6,
  .mx-wd-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-wd-n6,
  .my-wd-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-wd-n6,
  .mx-wd-n6 {
    margin-left: -2.5rem !important;
  }
  .m-wd-n7 {
    margin: -3rem !important;
  }
  .mt-wd-n7,
  .my-wd-n7 {
    margin-top: -3rem !important;
  }
  .mr-wd-n7,
  .mx-wd-n7 {
    margin-right: -3rem !important;
  }
  .mb-wd-n7,
  .my-wd-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-wd-n7,
  .mx-wd-n7 {
    margin-left: -3rem !important;
  }
  .m-wd-n8 {
    margin: -3.5rem !important;
  }
  .mt-wd-n8,
  .my-wd-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-wd-n8,
  .mx-wd-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-wd-n8,
  .my-wd-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-wd-n8,
  .mx-wd-n8 {
    margin-left: -3.5rem !important;
  }
  .m-wd-n9 {
    margin: -4rem !important;
  }
  .mt-wd-n9,
  .my-wd-n9 {
    margin-top: -4rem !important;
  }
  .mr-wd-n9,
  .mx-wd-n9 {
    margin-right: -4rem !important;
  }
  .mb-wd-n9,
  .my-wd-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-wd-n9,
  .mx-wd-n9 {
    margin-left: -4rem !important;
  }
  .m-wd-n10 {
    margin: -4.5rem !important;
  }
  .mt-wd-n10,
  .my-wd-n10 {
    margin-top: -4.5rem !important;
  }
  .mr-wd-n10,
  .mx-wd-n10 {
    margin-right: -4.5rem !important;
  }
  .mb-wd-n10,
  .my-wd-n10 {
    margin-bottom: -4.5rem !important;
  }
  .ml-wd-n10,
  .mx-wd-n10 {
    margin-left: -4.5rem !important;
  }
  .m-wd-n11 {
    margin: -5rem !important;
  }
  .mt-wd-n11,
  .my-wd-n11 {
    margin-top: -5rem !important;
  }
  .mr-wd-n11,
  .mx-wd-n11 {
    margin-right: -5rem !important;
  }
  .mb-wd-n11,
  .my-wd-n11 {
    margin-bottom: -5rem !important;
  }
  .ml-wd-n11,
  .mx-wd-n11 {
    margin-left: -5rem !important;
  }
  .m-wd-n12 {
    margin: -5.5rem !important;
  }
  .mt-wd-n12,
  .my-wd-n12 {
    margin-top: -5.5rem !important;
  }
  .mr-wd-n12,
  .mx-wd-n12 {
    margin-right: -5.5rem !important;
  }
  .mb-wd-n12,
  .my-wd-n12 {
    margin-bottom: -5.5rem !important;
  }
  .ml-wd-n12,
  .mx-wd-n12 {
    margin-left: -5.5rem !important;
  }
  .m-wd-n13 {
    margin: -6rem !important;
  }
  .mt-wd-n13,
  .my-wd-n13 {
    margin-top: -6rem !important;
  }
  .mr-wd-n13,
  .mx-wd-n13 {
    margin-right: -6rem !important;
  }
  .mb-wd-n13,
  .my-wd-n13 {
    margin-bottom: -6rem !important;
  }
  .ml-wd-n13,
  .mx-wd-n13 {
    margin-left: -6rem !important;
  }
  .m-wd-n14 {
    margin: -6.5rem !important;
  }
  .mt-wd-n14,
  .my-wd-n14 {
    margin-top: -6.5rem !important;
  }
  .mr-wd-n14,
  .mx-wd-n14 {
    margin-right: -6.5rem !important;
  }
  .mb-wd-n14,
  .my-wd-n14 {
    margin-bottom: -6.5rem !important;
  }
  .ml-wd-n14,
  .mx-wd-n14 {
    margin-left: -6.5rem !important;
  }
  .m-wd-n15 {
    margin: -7rem !important;
  }
  .mt-wd-n15,
  .my-wd-n15 {
    margin-top: -7rem !important;
  }
  .mr-wd-n15,
  .mx-wd-n15 {
    margin-right: -7rem !important;
  }
  .mb-wd-n15,
  .my-wd-n15 {
    margin-bottom: -7rem !important;
  }
  .ml-wd-n15,
  .mx-wd-n15 {
    margin-left: -7rem !important;
  }
  .m-wd-n16 {
    margin: -8rem !important;
  }
  .mt-wd-n16,
  .my-wd-n16 {
    margin-top: -8rem !important;
  }
  .mr-wd-n16,
  .mx-wd-n16 {
    margin-right: -8rem !important;
  }
  .mb-wd-n16,
  .my-wd-n16 {
    margin-bottom: -8rem !important;
  }
  .ml-wd-n16,
  .mx-wd-n16 {
    margin-left: -8rem !important;
  }
  .m-wd-n17 {
    margin: -9rem !important;
  }
  .mt-wd-n17,
  .my-wd-n17 {
    margin-top: -9rem !important;
  }
  .mr-wd-n17,
  .mx-wd-n17 {
    margin-right: -9rem !important;
  }
  .mb-wd-n17,
  .my-wd-n17 {
    margin-bottom: -9rem !important;
  }
  .ml-wd-n17,
  .mx-wd-n17 {
    margin-left: -9rem !important;
  }
  .m-wd-n18 {
    margin: -10rem !important;
  }
  .mt-wd-n18,
  .my-wd-n18 {
    margin-top: -10rem !important;
  }
  .mr-wd-n18,
  .mx-wd-n18 {
    margin-right: -10rem !important;
  }
  .mb-wd-n18,
  .my-wd-n18 {
    margin-bottom: -10rem !important;
  }
  .ml-wd-n18,
  .mx-wd-n18 {
    margin-left: -10rem !important;
  }
  .m-wd-n19 {
    margin: -10.6rem !important;
  }
  .mt-wd-n19,
  .my-wd-n19 {
    margin-top: -10.6rem !important;
  }
  .mr-wd-n19,
  .mx-wd-n19 {
    margin-right: -10.6rem !important;
  }
  .mb-wd-n19,
  .my-wd-n19 {
    margin-bottom: -10.6rem !important;
  }
  .ml-wd-n19,
  .mx-wd-n19 {
    margin-left: -10.6rem !important;
  }
  .m-wd-n20 {
    margin: -12.4rem !important;
  }
  .mt-wd-n20,
  .my-wd-n20 {
    margin-top: -12.4rem !important;
  }
  .mr-wd-n20,
  .mx-wd-n20 {
    margin-right: -12.4rem !important;
  }
  .mb-wd-n20,
  .my-wd-n20 {
    margin-bottom: -12.4rem !important;
  }
  .ml-wd-n20,
  .mx-wd-n20 {
    margin-left: -12.4rem !important;
  }
  .m-wd-auto {
    margin: auto !important;
  }
  .mt-wd-auto,
  .my-wd-auto {
    margin-top: auto !important;
  }
  .mr-wd-auto,
  .mx-wd-auto {
    margin-right: auto !important;
  }
  .mb-wd-auto,
  .my-wd-auto {
    margin-bottom: auto !important;
  }
  .ml-wd-auto,
  .mx-wd-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1480px) {
  .text-wd-left {
    text-align: left !important;
  }
  .text-wd-right {
    text-align: right !important;
  }
  .text-wd-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #297cbb !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1b527c !important;
}

.text-secondary {
  color: #77838f !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545d66 !important;
}

.text-success {
  color: #00c9a7 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #007d67 !important;
}

.text-info {
  color: #00dffc !important;
}

a.text-info:hover, a.text-info:focus {
  color: #009bb0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #de4437 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ac281c !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #3b444f !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1a1e23 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-indigo {
  color: #2d1582 !important;
}

a.text-indigo:hover, a.text-indigo:focus {
  color: #160a40 !important;
}

.text-gray {
  color: #ebf0f7 !important;
}

a.text-gray:hover, a.text-gray:focus {
  color: #b4c7e1 !important;
}

.text-violet {
  color: #532184 !important;
}

a.text-violet:hover, a.text-violet:focus {
  color: #2d1247 !important;
}

.text-violet-1 {
  color: #a864a8 !important;
}

a.text-violet-1:hover, a.text-violet-1:focus {
  color: #7b457b !important;
}

.text-violet-light {
  color: #6f13cd !important;
}

a.text-violet-light:hover, a.text-violet-light:focus {
  color: #490d87 !important;
}

.text-brown-light {
  color: #ceac61 !important;
}

a.text-brown-light:hover, a.text-brown-light:focus {
  color: #ad8836 !important;
}

.text-brown {
  color: #f8bd5b !important;
}

a.text-brown:hover, a.text-brown:focus {
  color: #f59f12 !important;
}

.text-maroon {
  color: #c72f74 !important;
}

a.text-maroon:hover, a.text-maroon:focus {
  color: #892050 !important;
}

.text-indigo-light {
  color: #080e7b !important;
}

a.text-indigo-light:hover, a.text-indigo-light:focus {
  color: #030633 !important;
}

.text-red-light-2 {
  color: #ff3c4e !important;
}

a.text-red-light-2:hover, a.text-red-light-2:focus {
  color: #ef0016 !important;
}

.text-blue-dark-1 {
  color: #045cff !important;
}

a.text-blue-dark-1:hover, a.text-blue-dark-1:focus {
  color: #0040b7 !important;
}

.text-gray-1 {
  color: #67747c !important;
}

a.text-gray-1:hover, a.text-gray-1:focus {
  color: #444d52 !important;
}

.text-gray-2 {
  color: #f7f8f9 !important;
}

a.text-gray-2:hover, a.text-gray-2:focus {
  color: #cbd2d8 !important;
}

.text-gray-3 {
  color: #3b444f !important;
}

a.text-gray-3:hover, a.text-gray-3:focus {
  color: #1a1e23 !important;
}

.text-gray-4 {
  color: #eceff3 !important;
}

a.text-gray-4:hover, a.text-gray-4:focus {
  color: #bdc8d5 !important;
}

.text-blue {
  color: #43c5db !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #229bb0 !important;
}

.text-blue-1 {
  color: #297cbb !important;
}

a.text-blue-1:hover, a.text-blue-1:focus {
  color: #1b527c !important;
}

.text-gray-5 {
  color: #18181b !important;
}

a.text-gray-5:hover, a.text-gray-5:focus {
  color: black !important;
}

.text-gray-6 {
  color: #18181a !important;
}

a.text-gray-6:hover, a.text-gray-6:focus {
  color: black !important;
}

.text-gray-7 {
  color: #5c6770 !important;
}

a.text-gray-7:hover, a.text-gray-7:focus {
  color: #3a4046 !important;
}

.text-gray-8 {
  color: #c5cdd8 !important;
}

a.text-gray-8:hover, a.text-gray-8:focus {
  color: #97a6b9 !important;
}

.text-gray-9 {
  color: #9fa9b8 !important;
}

a.text-gray-9:hover, a.text-gray-9:focus {
  color: #738297 !important;
}

.text-gray-10 {
  color: #c1c1c1 !important;
}

a.text-gray-10:hover, a.text-gray-10:focus {
  color: #9b9b9b !important;
}

.text-gray-11 {
  color: #484848 !important;
}

a.text-gray-11:hover, a.text-gray-11:focus {
  color: #222222 !important;
}

.text-gray-23 {
  color: #f4f4f4 !important;
}

a.text-gray-23:hover, a.text-gray-23:focus {
  color: #cecece !important;
}

.text-gray-33 {
  color: #ebf0f7 !important;
}

a.text-gray-33:hover, a.text-gray-33:focus {
  color: #b4c7e1 !important;
}

.text-sea-green {
  color: #1d508d !important;
}

a.text-sea-green:hover, a.text-sea-green:focus {
  color: #102c4e !important;
}

.text-dark-1 {
  color: #1e2022 !important;
}

a.text-dark-1:hover, a.text-dark-1:focus {
  color: black !important;
}

.text-navy {
  color: #0057ff !important;
}

a.text-navy:hover, a.text-navy:focus {
  color: #003db3 !important;
}

.text-pink {
  color: #ffe5ee !important;
}

a.text-pink:hover, a.text-pink:focus {
  color: #ff99bc !important;
}

.text-dark-2 {
  color: #1a2b49 !important;
}

a.text-dark-2:hover, a.text-dark-2:focus {
  color: #060a11 !important;
}

.text-yellow-lighter-2 {
  color: #f7bc5a !important;
}

a.text-yellow-lighter-2:hover, a.text-yellow-lighter-2:focus {
  color: #f39e11 !important;
}

.text-orange {
  color: #f05700 !important;
}

a.text-orange:hover, a.text-orange:focus {
  color: #a43b00 !important;
}

.text-green {
  color: #28871c !important;
}

a.text-green:hover, a.text-green:focus {
  color: #15480f !important;
}

.text-pink-1 {
  color: #ff0045 !important;
}

a.text-pink-1:hover, a.text-pink-1:focus {
  color: #b30030 !important;
}

.text-purple-1 {
  color: #232753 !important;
}

a.text-purple-1:hover, a.text-purple-1:focus {
  color: #0c0e1d !important;
}

.text-body {
  color: #3b444f !important;
}

.text-muted {
  color: #8c98a4 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/*-------------------------------------------*\
    1.34. Print
\*-------------------------------------------*/
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #97a4af;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e7eaf3 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e7eaf3;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e7eaf3;
  }
}

/*----------------------------------------------------------------------
  * Mytravel - Responsive Website Template version v1.0
  * Copyright 2020 Madrasthemes
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
/*-------------------------------------------*\
    2. MyTravel CSS
\*-------------------------------------------*/
/*-------------------------------------------*\
    2.1. Sizing
\*-------------------------------------------*/
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-85 {
    width: 85% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-15 {
    width: 15% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-85 {
    width: 85% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-15 {
    width: 15% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-15 {
    width: 15% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
}

@media (min-width: 1480px) {
  .w-wd-25 {
    width: 25% !important;
  }
  .w-wd-50 {
    width: 50% !important;
  }
  .w-wd-75 {
    width: 75% !important;
  }
  .w-wd-100 {
    width: 100% !important;
  }
  .w-wd-auto {
    width: auto !important;
  }
  .w-wd-15 {
    width: 15% !important;
  }
  .w-wd-30 {
    width: 30% !important;
  }
  .w-wd-35 {
    width: 35% !important;
  }
  .w-wd-40 {
    width: 40% !important;
  }
  .w-wd-60 {
    width: 60% !important;
  }
  .w-wd-65 {
    width: 65% !important;
  }
  .w-wd-70 {
    width: 70% !important;
  }
  .w-wd-80 {
    width: 80% !important;
  }
  .w-wd-85 {
    width: 85% !important;
  }
}

/*-------------------------------------------*\
    2.2. Spaces
\*-------------------------------------------*/
.space-0,
.space-top-0 {
  padding-top: 0 !important;
}

.space-0,
.space-bottom-0 {
  padding-bottom: 0 !important;
}

.space-1,
.space-top-1 {
  padding-top: 2rem !important;
}

.space-1,
.space-bottom-1 {
  padding-bottom: 2rem !important;
}

.space-2,
.space-top-2 {
  padding-top: 4rem !important;
}

.space-2,
.space-bottom-2 {
  padding-bottom: 4rem !important;
}

.space-3,
.space-top-3 {
  padding-top: 8rem !important;
}

.space-3,
.space-bottom-3 {
  padding-bottom: 8rem !important;
}

.space-4,
.space-top-4 {
  padding-top: 12.5rem !important;
}

.space-4,
.space-bottom-4 {
  padding-bottom: 12.5rem !important;
}

.space-5,
.space-top-5 {
  padding-top: 15rem !important;
}

.space-5,
.space-bottom-5 {
  padding-bottom: 15rem !important;
}

.space-6,
.space-top-6 {
  padding-top: 16rem !important;
}

.space-6,
.space-bottom-6 {
  padding-bottom: 16rem !important;
}

.space-8,
.space-top-8 {
  padding-top: 18rem !important;
}

.space-8,
.space-bottom-8 {
  padding-bottom: 18rem !important;
}

.space-9,
.space-top-9 {
  padding-top: 19rem !important;
}

.space-9,
.space-bottom-9 {
  padding-bottom: 19rem !important;
}

.space-10,
.space-top-10 {
  padding-top: 22rem !important;
}

.space-10,
.space-bottom-10 {
  padding-bottom: 22rem !important;
}

@media (min-width: 576px) {
  .space-sm-0,
  .space-top-sm-0 {
    padding-top: 0 !important;
  }
  .space-sm-0,
  .space-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
  .space-sm-1,
  .space-top-sm-1 {
    padding-top: 2rem !important;
  }
  .space-sm-1,
  .space-bottom-sm-1 {
    padding-bottom: 2rem !important;
  }
  .space-sm-2,
  .space-top-sm-2 {
    padding-top: 4rem !important;
  }
  .space-sm-2,
  .space-bottom-sm-2 {
    padding-bottom: 4rem !important;
  }
  .space-sm-3,
  .space-top-sm-3 {
    padding-top: 8rem !important;
  }
  .space-sm-3,
  .space-bottom-sm-3 {
    padding-bottom: 8rem !important;
  }
  .space-sm-4,
  .space-top-sm-4 {
    padding-top: 12.5rem !important;
  }
  .space-sm-4,
  .space-bottom-sm-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-sm-5,
  .space-top-sm-5 {
    padding-top: 15rem !important;
  }
  .space-sm-5,
  .space-bottom-sm-5 {
    padding-bottom: 15rem !important;
  }
  .space-sm-6,
  .space-top-sm-6 {
    padding-top: 16rem !important;
  }
  .space-sm-6,
  .space-bottom-sm-6 {
    padding-bottom: 16rem !important;
  }
  .space-sm-8,
  .space-top-sm-8 {
    padding-top: 18rem !important;
  }
  .space-sm-8,
  .space-bottom-sm-8 {
    padding-bottom: 18rem !important;
  }
  .space-sm-9,
  .space-top-sm-9 {
    padding-top: 19rem !important;
  }
  .space-sm-9,
  .space-bottom-sm-9 {
    padding-bottom: 19rem !important;
  }
  .space-sm-10,
  .space-top-sm-10 {
    padding-top: 22rem !important;
  }
  .space-sm-10,
  .space-bottom-sm-10 {
    padding-bottom: 22rem !important;
  }
}

@media (min-width: 768px) {
  .space-md-0,
  .space-top-md-0 {
    padding-top: 0 !important;
  }
  .space-md-0,
  .space-bottom-md-0 {
    padding-bottom: 0 !important;
  }
  .space-md-1,
  .space-top-md-1 {
    padding-top: 2rem !important;
  }
  .space-md-1,
  .space-bottom-md-1 {
    padding-bottom: 2rem !important;
  }
  .space-md-2,
  .space-top-md-2 {
    padding-top: 4rem !important;
  }
  .space-md-2,
  .space-bottom-md-2 {
    padding-bottom: 4rem !important;
  }
  .space-md-3,
  .space-top-md-3 {
    padding-top: 8rem !important;
  }
  .space-md-3,
  .space-bottom-md-3 {
    padding-bottom: 8rem !important;
  }
  .space-md-4,
  .space-top-md-4 {
    padding-top: 12.5rem !important;
  }
  .space-md-4,
  .space-bottom-md-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-md-5,
  .space-top-md-5 {
    padding-top: 15rem !important;
  }
  .space-md-5,
  .space-bottom-md-5 {
    padding-bottom: 15rem !important;
  }
  .space-md-6,
  .space-top-md-6 {
    padding-top: 16rem !important;
  }
  .space-md-6,
  .space-bottom-md-6 {
    padding-bottom: 16rem !important;
  }
  .space-md-8,
  .space-top-md-8 {
    padding-top: 18rem !important;
  }
  .space-md-8,
  .space-bottom-md-8 {
    padding-bottom: 18rem !important;
  }
  .space-md-9,
  .space-top-md-9 {
    padding-top: 19rem !important;
  }
  .space-md-9,
  .space-bottom-md-9 {
    padding-bottom: 19rem !important;
  }
  .space-md-10,
  .space-top-md-10 {
    padding-top: 22rem !important;
  }
  .space-md-10,
  .space-bottom-md-10 {
    padding-bottom: 22rem !important;
  }
}

@media (min-width: 992px) {
  .space-lg-0,
  .space-top-lg-0 {
    padding-top: 0 !important;
  }
  .space-lg-0,
  .space-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
  .space-lg-1,
  .space-top-lg-1 {
    padding-top: 2rem !important;
  }
  .space-lg-1,
  .space-bottom-lg-1 {
    padding-bottom: 2rem !important;
  }
  .space-lg-2,
  .space-top-lg-2 {
    padding-top: 4rem !important;
  }
  .space-lg-2,
  .space-bottom-lg-2 {
    padding-bottom: 4rem !important;
  }
  .space-lg-3,
  .space-top-lg-3 {
    padding-top: 8rem !important;
  }
  .space-lg-3,
  .space-bottom-lg-3 {
    padding-bottom: 8rem !important;
  }
  .space-lg-4,
  .space-top-lg-4 {
    padding-top: 12.5rem !important;
  }
  .space-lg-4,
  .space-bottom-lg-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-lg-5,
  .space-top-lg-5 {
    padding-top: 15rem !important;
  }
  .space-lg-5,
  .space-bottom-lg-5 {
    padding-bottom: 15rem !important;
  }
  .space-lg-6,
  .space-top-lg-6 {
    padding-top: 16rem !important;
  }
  .space-lg-6,
  .space-bottom-lg-6 {
    padding-bottom: 16rem !important;
  }
  .space-lg-8,
  .space-top-lg-8 {
    padding-top: 18rem !important;
  }
  .space-lg-8,
  .space-bottom-lg-8 {
    padding-bottom: 18rem !important;
  }
  .space-lg-9,
  .space-top-lg-9 {
    padding-top: 19rem !important;
  }
  .space-lg-9,
  .space-bottom-lg-9 {
    padding-bottom: 19rem !important;
  }
  .space-lg-10,
  .space-top-lg-10 {
    padding-top: 22rem !important;
  }
  .space-lg-10,
  .space-bottom-lg-10 {
    padding-bottom: 22rem !important;
  }
}

@media (min-width: 1200px) {
  .space-xl-0,
  .space-top-xl-0 {
    padding-top: 0 !important;
  }
  .space-xl-0,
  .space-bottom-xl-0 {
    padding-bottom: 0 !important;
  }
  .space-xl-1,
  .space-top-xl-1 {
    padding-top: 2rem !important;
  }
  .space-xl-1,
  .space-bottom-xl-1 {
    padding-bottom: 2rem !important;
  }
  .space-xl-2,
  .space-top-xl-2 {
    padding-top: 4rem !important;
  }
  .space-xl-2,
  .space-bottom-xl-2 {
    padding-bottom: 4rem !important;
  }
  .space-xl-3,
  .space-top-xl-3 {
    padding-top: 8rem !important;
  }
  .space-xl-3,
  .space-bottom-xl-3 {
    padding-bottom: 8rem !important;
  }
  .space-xl-4,
  .space-top-xl-4 {
    padding-top: 12.5rem !important;
  }
  .space-xl-4,
  .space-bottom-xl-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-xl-5,
  .space-top-xl-5 {
    padding-top: 15rem !important;
  }
  .space-xl-5,
  .space-bottom-xl-5 {
    padding-bottom: 15rem !important;
  }
  .space-xl-6,
  .space-top-xl-6 {
    padding-top: 16rem !important;
  }
  .space-xl-6,
  .space-bottom-xl-6 {
    padding-bottom: 16rem !important;
  }
  .space-xl-8,
  .space-top-xl-8 {
    padding-top: 18rem !important;
  }
  .space-xl-8,
  .space-bottom-xl-8 {
    padding-bottom: 18rem !important;
  }
  .space-xl-9,
  .space-top-xl-9 {
    padding-top: 19rem !important;
  }
  .space-xl-9,
  .space-bottom-xl-9 {
    padding-bottom: 19rem !important;
  }
  .space-xl-10,
  .space-top-xl-10 {
    padding-top: 22rem !important;
  }
  .space-xl-10,
  .space-bottom-xl-10 {
    padding-bottom: 22rem !important;
  }
}

@media (min-width: 1480px) {
  .space-wd-0,
  .space-top-wd-0 {
    padding-top: 0 !important;
  }
  .space-wd-0,
  .space-bottom-wd-0 {
    padding-bottom: 0 !important;
  }
  .space-wd-1,
  .space-top-wd-1 {
    padding-top: 2rem !important;
  }
  .space-wd-1,
  .space-bottom-wd-1 {
    padding-bottom: 2rem !important;
  }
  .space-wd-2,
  .space-top-wd-2 {
    padding-top: 4rem !important;
  }
  .space-wd-2,
  .space-bottom-wd-2 {
    padding-bottom: 4rem !important;
  }
  .space-wd-3,
  .space-top-wd-3 {
    padding-top: 8rem !important;
  }
  .space-wd-3,
  .space-bottom-wd-3 {
    padding-bottom: 8rem !important;
  }
  .space-wd-4,
  .space-top-wd-4 {
    padding-top: 12.5rem !important;
  }
  .space-wd-4,
  .space-bottom-wd-4 {
    padding-bottom: 12.5rem !important;
  }
  .space-wd-5,
  .space-top-wd-5 {
    padding-top: 15rem !important;
  }
  .space-wd-5,
  .space-bottom-wd-5 {
    padding-bottom: 15rem !important;
  }
  .space-wd-6,
  .space-top-wd-6 {
    padding-top: 16rem !important;
  }
  .space-wd-6,
  .space-bottom-wd-6 {
    padding-bottom: 16rem !important;
  }
  .space-wd-8,
  .space-top-wd-8 {
    padding-top: 18rem !important;
  }
  .space-wd-8,
  .space-bottom-wd-8 {
    padding-bottom: 18rem !important;
  }
  .space-wd-9,
  .space-top-wd-9 {
    padding-top: 19rem !important;
  }
  .space-wd-9,
  .space-bottom-wd-9 {
    padding-bottom: 19rem !important;
  }
  .space-wd-10,
  .space-top-wd-10 {
    padding-top: 22rem !important;
  }
  .space-wd-10,
  .space-bottom-wd-10 {
    padding-bottom: 22rem !important;
  }
}

/*-------------------------------------------*\
    2.3. Reboot
\*-------------------------------------------*/
body {
  -webkit-font-smoothing: antialiased;
}

main {
  position: relative;
}

p {
  color: #77838f;
  line-height: 1.7;
}

h4, h5, h6,
.h4, .h5, .h6 {
  font-weight: 500;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a,
.h1 > a, .h2 > a, .h3 > a, .h4 > a, .h5 > a, .h6 > a {
  color: #3b444f;
}

h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover,
.h1 > a:hover, .h2 > a:hover, .h3 > a:hover, .h4 > a:hover, .h5 > a:hover, .h6 > a:hover {
  color: #1b527c;
}

h1.text-white > a, h2.text-white > a, h3.text-white > a, h4.text-white > a, h5.text-white > a, h6.text-white > a,
.h1.text-white > a, .h2.text-white > a, .h3.text-white > a, .h4.text-white > a, .h5.text-white > a, .h6.text-white > a {
  color: #fff;
}

figure {
  margin-bottom: 0;
}

strong {
  font-weight: 500;
}

svg {
  margin-bottom: -0.4375rem;
  vertical-align: baseline;
}

table th {
  font-weight: 500;
}

.fa, .fas {
  font-weight: 900;
}

/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: #fff;
  background-color: #297cbb;
}

::selection {
  color: #fff;
  background-color: #297cbb;
}

.bg-primary ::-moz-selection {
  color: #297cbb;
  background-color: #fff;
}

.bg-primary ::selection {
  color: #297cbb;
  background-color: #fff;
}

/*------------------------------------
  Text-Decoration
------------------------------------*/
.text-decoration-on-hover:hover {
  text-decoration: underline !important;
}

/*------------------------------------
  Accessibility
------------------------------------*/
:focus,
a:focus,
button:focus {
  outline: 0;
}

.form-control:focus {
  box-shadow: 0 0 0 0 transparent;
}

.btn:focus, .btn.focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  box-shadow: 0 0 0 0 transparent;
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent;
}

.custom-select:focus,
.page-link:focus {
  box-shadow: 0 0 0 0 transparent;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 transparent;
}

.custom-file-input:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 transparent;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0 !important;
}

/*------------------------------------
  Print styles
------------------------------------*/
@media print {
  header, footer, .btn {
    display: none;
  }
}

/*------------------------------------
  Skippy
------------------------------------*/
.u-skippy {
  display: none;
}

/*-------------------------------------------*\
    2.4. List Group
\*-------------------------------------------*/
.list-group {
  list-style: none;
}

.list-group .list-group {
  margin-left: 1rem;
}

.list-group .list-group-item {
  font-size: 1rem;
}

.list-group .list-group-icon {
  min-width: 1rem;
  max-width: 1rem;
  color: #77838f;
  text-align: center;
  margin-right: .5rem;
}

.list-group .active > .list-group-item {
  color: #297cbb;
}

.list-group .active > .list-group-text {
  color: #fff;
}

.list-group .disabled .u-icon {
  color: #77838f;
  background-color: rgba(119, 131, 143, 0.1);
}

/* Border Radius */
.list-group-rounded-0 .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-rounded-0 .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* Transparent */
.list-group-transparent .list-group-item {
  border-color: transparent;
  background-color: transparent;
}

/* White */
.list-group-white .list-group-item,
.list-group-white .list-group-item-action {
  color: #c1c1c1;
}

.list-group-white .list-group-item[href],
.list-group-white .list-group-item-action[href] {
  color: #c1c1c1;
}

.list-group-white .list-group-item[href]:hover,
.list-group-white .list-group-item-action[href]:hover {
  color: #297cbb;
}

.list-group-white.list-group-striped .list-group-item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}

.list-group-white .active > .list-group-item {
  color: #fff;
}

/* Flush */
.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.list-group-flush .list-group-item.active {
  color: #297cbb;
  background-color: transparent;
  border-color: transparent;
}

/* Borderless */
.list-group-borderless .list-group-item {
  border: none;
}

/* Striped */
.list-group-striped .list-group-item:nth-child(even) {
  background-color: #f8fafd;
}

.custom-social-share a {
  transition: all 0.2s ease-in-out;
}

.custom-social-share a:hover {
  border-color: #297cbb !important;
  background-color: #297cbb !important;
}

.custom-social-share a:hover i {
  color: #fff !important;
}

/*-------------------------------------------*\
    2.5. Headers
\*-------------------------------------------*/
/*------------------------------------
  Header Default
------------------------------------*/
.u-header {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.u-header__section {
  position: relative;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
}

.u-header__section-divider {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.u-header__shadow-on-show-hide {
  box-shadow: none;
}

.js-header-fix-moment .u-header__shadow-on-show-hide {
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
}

.u-header__login-form .form-control {
  font-size: 0.875rem;
  height: 3.125rem;
  color: #67747c;
}

.u-header__login-form .form-control::-webkit-input-placeholder {
  color: #67747c;
}

.u-header__login-form .form-control::-moz-placeholder {
  color: #67747c;
}

.u-header__login-form .form-control:-ms-input-placeholder {
  color: #67747c;
}

.u-header__login-form .form-control::-ms-input-placeholder {
  color: #67747c;
}

.u-header__login-form .form-control::placeholder {
  color: #67747c;
}

.u-header__login-form .input-group-text {
  color: #dce3ec;
}

.u-header__login-form .input-group-text > span {
  line-height: 1;
}

.u-header__login-form .card-header {
  padding: 1.41rem;
  border-bottom-color: #ebf0f7;
}

.u-header__login-form .card-body,
.u-header__login-form .card-footer__top {
  padding-left: 4.375rem;
  padding-right: 4.375rem;
}

.u-header__login-form .card-footer {
  border-top-color: #ebf0f7;
}

.u-header__login-form .custom-control-label {
  color: #67747c;
}

.u-header__login-form .custom-control-label:before {
  border-width: 2px;
  border-color: #ebf0f7;
}

.u-header__topbar [class*="u-header__topbar-nav-divider"] .list-inline-item + .list-inline-item {
  position: relative;
}

.u-header__topbar [class*="u-header__topbar-nav-divider"] .list-inline-item + .list-inline-item .u-header__navbar-link:before {
  content: '';
  background-color: #fff;
  position: absolute;
  width: 1px;
  height: 30px;
  left: -1px;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

[class*="u-header--bg-transparent"] .u-header__topbar [class*="u-header__topbar-nav-divider"] .list-inline-item + .list-inline-item .u-header__navbar-link:before {
  opacity: 0.149;
}

.u-header__topbar [class*="u-header__topbar-divider"]:not(.list-inline) {
  position: relative;
}

.u-header__topbar [class*="u-header__topbar-divider"]:not(.list-inline):before {
  content: '';
  background-color: #fff;
  position: absolute;
  width: 1px;
  height: 30px;
  left: 0;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

[class*="u-header--bg-transparent"] .u-header__topbar [class*="u-header__topbar-divider"]:not(.list-inline):before {
  opacity: 0.149;
}

.u-header__topbar .u-header__topbar-divider--dark:not(.list-inline):before,
.u-header__topbar .u-header__topbar-nav-divider--dark .list-inline-item + .list-inline-item .u-header__navbar-link:before {
  background-color: #ebf0f7;
}

.u-header__topbar .list-inline-item:first-child .u-header__navbar-link {
  padding-left: 0;
}

.u-header__topbar .dropdown-menu {
  min-width: 4.375rem;
  padding: 0.438rem 0;
}

.u-header__topbar .dropdown-item {
  padding: 0.125rem 0.313rem;
  text-align: center;
}

.u-header__topbar[class*="bg-"] .u-header__navbar-link {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.u-header:not([class*="u-header--bg-transparent"]) [class*="u-header__topbar-divider"]:before,
.u-header:not([class*="u-header--bg-transparent"]) [class*="u-header__topbar-nav-divider"] .list-inline-item + .list-inline-item .u-header__navbar-link:before {
  height: 20px;
}

.u-header [class*="u-header__search"] .form-control:focus {
  padding-right: 0.5rem !important;
}

.u-header__divider {
  position: relative;
}

.u-header__divider:before {
  content: '';
  background-color: #ebf0f7;
  position: absolute;
  width: 1px;
  height: 50px;
  top: 50%;
  left: 0;
  display: block;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.u-header__divider.u-header__divider-right:before {
  left: unset;
  right: 0;
}

@media (min-width: 576px) {
  .u-header__divider-sm {
    position: relative;
  }
  .u-header__divider-sm:before {
    content: '';
    background-color: #ebf0f7;
    position: absolute;
    width: 1px;
    height: 50px;
    top: 50%;
    left: 0;
    display: block;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .u-header__divider-sm.u-header__divider-right:before {
    left: unset;
    right: 0;
  }
}

@media (min-width: 768px) {
  .u-header__divider-md {
    position: relative;
  }
  .u-header__divider-md:before {
    content: '';
    background-color: #ebf0f7;
    position: absolute;
    width: 1px;
    height: 50px;
    top: 50%;
    left: 0;
    display: block;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .u-header__divider-md.u-header__divider-right:before {
    left: unset;
    right: 0;
  }
}

@media (min-width: 992px) {
  .u-header__divider-lg {
    position: relative;
  }
  .u-header__divider-lg:before {
    content: '';
    background-color: #ebf0f7;
    position: absolute;
    width: 1px;
    height: 50px;
    top: 50%;
    left: 0;
    display: block;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .u-header__divider-lg.u-header__divider-right:before {
    left: unset;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .u-header__divider-xl {
    position: relative;
  }
  .u-header__divider-xl:before {
    content: '';
    background-color: #ebf0f7;
    position: absolute;
    width: 1px;
    height: 50px;
    top: 50%;
    left: 0;
    display: block;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .u-header__divider-xl.u-header__divider-right:before {
    left: unset;
    right: 0;
  }
}

@media (min-width: 1480px) {
  .u-header__divider-wd {
    position: relative;
  }
  .u-header__divider-wd:before {
    content: '';
    background-color: #ebf0f7;
    position: absolute;
    width: 1px;
    height: 50px;
    top: 50%;
    left: 0;
    display: block;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .u-header__divider-wd.u-header__divider-right:before {
    left: unset;
    right: 0;
  }
}

@media (max-width: 575.98px) {
  .u-header__search, .u-header__topbar, .u-header__last-item-btn {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-header__search-sm, .u-header__topbar-sm, .u-header__last-item-btn-sm {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-header__search-md, .u-header__topbar-md, .u-header__last-item-btn-md {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .u-header__search-lg, .u-header__topbar-lg, .u-header__last-item-btn-lg {
    display: none;
  }
}

@media (max-width: 1479.98px) {
  .u-header__search-xl, .u-header__topbar-xl, .u-header__last-item-btn-xl {
    display: none;
  }
}

.u-header__search-wd, .u-header__topbar-wd, .u-header__last-item-btn-wd {
  display: none;
}

.u-header .hs-sub-menu-right {
  right: 0;
  left: unset;
}

/* Navbar */
.u-header__navbar {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0;
  padding-right: 0;
}

/* Navbar Space */
@media (max-width: 991.98px) {
  .u-header .u-header__navbar.u-header__navbar--top-space {
    padding-top: 0.25rem;
  }
}

/* Navbar Brand */
@media (max-width: 767.98px) {
  .u-header__navbar-brand {
    margin-right: 0.625rem;
  }
}

.u-header__navbar-brand,
.u-header__navbar-brand > img {
  padding-top: 0;
  padding-bottom: 0;
  width: auto;
}

.u-header__navbar-brand-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.u-header__navbar-brand-vertical {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}

.u-header__navbar-brand-vertical .u-header__navbar-brand-text {
  display: block;
  margin-left: 0;
}

.u-header__navbar-brand-text {
  font-size: 1.75rem;
  margin-left: 0.875rem;
}

@media (max-width: 767.98px) {
  .u-header__navbar-brand-text {
    font-size: 1.438rem !important;
  }
}

.u-header__navbar-brand-text, .u-header__navbar-brand-text:focus, .u-header__navbar-brand-text:hover {
  color: #297cbb;
}

.u-header__navbar-brand-text-md .u-header__navbar-brand-text {
  font-size: 1.5rem;
  margin-left: 0.75rem;
}

.u-header__navbar-brand-text {
  font-weight: 700;
}

.u-header__navbar-brand-text-white .u-header__navbar-brand-text, .u-header__navbar-brand-text-white .u-header__navbar-brand-text:focus, .u-header__navbar-brand-text-white .u-header__navbar-brand-text:hover {
  color: #fff;
}

.u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark [class*="u-header__navbar-brand-text"], .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark [class*="u-header__navbar-brand-text"]:focus, .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark [class*="u-header__navbar-brand-text"]:hover {
  color: #3b444f;
}

@media (min-width: 576px) {
  .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-sm [class*="u-header__navbar-brand-text"], .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-sm [class*="u-header__navbar-brand-text"]:focus, .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-sm [class*="u-header__navbar-brand-text"]:hover {
    color: #3b444f;
  }
}

@media (min-width: 768px) {
  .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-md [class*="u-header__navbar-brand-text"], .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-md [class*="u-header__navbar-brand-text"]:focus, .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-md [class*="u-header__navbar-brand-text"]:hover {
    color: #3b444f;
  }
}

@media (min-width: 992px) {
  .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-lg [class*="u-header__navbar-brand-text"], .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-lg [class*="u-header__navbar-brand-text"]:focus, .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-lg [class*="u-header__navbar-brand-text"]:hover {
    color: #3b444f;
  }
}

@media (min-width: 1200px) {
  .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-xl [class*="u-header__navbar-brand-text"], .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-xl [class*="u-header__navbar-brand-text"]:focus, .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-xl [class*="u-header__navbar-brand-text"]:hover {
    color: #3b444f;
  }
}

@media (min-width: 1480px) {
  .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-wd [class*="u-header__navbar-brand-text"], .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-wd [class*="u-header__navbar-brand-text"]:focus, .u-header:not(.js-header-fix-moment) .u-header__navbar-brand-text-dark-wd [class*="u-header__navbar-brand-text"]:hover {
    color: #3b444f;
  }
}

/* Navbar Collapse */
.u-header__navbar-collapse {
  padding-top: 0;
  padding-bottom: 0;
}

/* Navbar Link */
.u-header__navbar-link {
  color: #77838f;
  font-size: 0.875rem;
  font-weight: 300;
  padding-left: 1.281rem;
  padding-right: 1.281rem;
  display: inline-block;
  padding-top: 0.594rem;
  padding-bottom: 0.594rem;
}

.list-inline-dark .u-header__navbar-link {
  color: #3b444f;
}

/* Nav Item */
.u-header .u-header__nav-item:first-child .u-header__nav-link {
  padding-left: 0;
}

.u-header__nav-item:hover .u-header__nav-link, .u-header__nav-item:focus .u-header__nav-link {
  color: #297cbb;
}

/* Nav Link */
.u-header .active > .u-header__nav-link {
  color: #297cbb;
}

.u-header__nav-link {
  color: #67747c;
  font-weight: 500;
}

.u-header--nav-links-normal .u-header__nav-link {
  font-weight: 400;
}

.u-header__nav-link-toggle {
  position: relative;
}

.u-header__nav-link-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
  font-weight: 900;
  content: "\f107";
  margin-left: 0.75rem;
  transition: 0.3s ease;
}

[class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .hs-sub-menu-opened .u-header__nav-link:before,
[class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .hs-mega-menu-opened .u-header__nav-link:before {
  position: absolute;
  display: block;
  pointer-events: none;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  content: '';
  height: 0;
  width: 0;
  left: 50%;
  top: 100%;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
  -webkit-animation-duration: 210ms;
          animation-duration: 210ms;
}

/* Sub Menu */
.u-header__sub-menu {
  width: 100%;
  padding-left: 0;
  list-style: none;
}

.u-header__sub-menu-nav-group {
  padding-left: 0;
  list-style: none;
}

.u-header__sub-menu-nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 1rem;
  color: #67747c;
}

.u-header__sub-menu-nav-link-toggle {
  position: relative;
}

.u-header__sub-menu-nav-link-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 80%;
  font-weight: 900;
  content: "\f105";
  margin-left: auto;
  margin-right: .04rem;
  transition: 0.3s ease;
}

.u-header__sub-menu .active > .u-header__sub-menu-nav-link {
  color: #297cbb;
}

.u-header__sub-menu-title {
  display: block;
  color: #3b444f;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

/* Promo */
.u-header__promo {
  background-color: #f8fafd;
}

.u-header__promo-inner {
  padding: 2rem;
}

.u-header__promo-item {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.u-header__promo-item ~ .u-header__promo-item {
  border-top: 1px solid #e7eaf3;
}

.u-header__promo-link {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.u-header__promo-link:hover .u-header__promo-title {
  color: #297cbb;
}

.u-header__promo-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.u-header__promo-card-deck {
  display: block;
}

.u-header__promo-card-deck:not(:last-child) .u-header__promo-item {
  border-bottom: 1px solid #e7eaf3;
}

.u-header__promo-card-deck .u-header__promo-item {
  border-top: none;
}

.u-header__promo-card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
}

.u-header__promo-icon {
  width: 100%;
  max-width: 2.75rem;
  margin-right: 1rem;
}

.u-header__promo-icon--sm {
  max-width: 1.75rem;
}

.u-header__promo-title {
  display: block;
  color: #3b444f;
  font-size: 1rem;
  font-weight: 500;
}

.u-header__promo-text {
  display: block;
  color: #77838f;
}

.u-header__promo-footer {
  background-color: #f8fafd;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.u-header__promo-footer-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.u-header__promo-footer-ver-divider {
  position: relative;
}

.u-header__promo-footer-ver-divider::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 1px solid #bdc5d1;
  content: "";
}

/* Banner */
.u-header__banner {
  position: relative;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  border-bottom-left-radius: 0.3125rem;
}

.u-header__banner:not(.u-header__banner-no-overlay)::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(59, 68, 79, 0.5);
  border-bottom-left-radius: 0.3125rem;
  content: "";
}

.u-header__banner-content {
  z-index: 1;
  width: 100%;
  padding: 1.438rem 1.25rem;
}

.u-header__banner-content:not(.u-header__banner-content-not-centered) {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.u-header__banner-content-not-centered .u-header__banner-text {
  padding-right: 3.313rem;
}

.u-header__banner-content .btn-sm, .u-header__banner-content .btn-group-sm > .btn {
  padding: 0.532rem 1.456rem;
}

.u-header__banner-title {
  display: block;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 0.875rem;
}

.u-header__banner-text {
  display: block;
  font-size: 0.875rem;
  color: #fff;
  margin-bottom: 1.375rem;
}

.u-header__banner-caption {
  display: block;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

/* Product Banner */
.u-header__product-banner {
  background-color: #f8fafd;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  padding-right: 1.5rem;
}

.u-header__product-banner-content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.u-header__product-banner-title {
  display: block;
  color: #297cbb;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: .5rem;
}

.u-header__product-banner-text {
  color: #3b444f;
  margin-bottom: 0;
}

/* Hide Content */
.u-header.js-header-fix-moment .u-header__hide-content {
  display: none;
}

.u-header.u-header--moved-up.u-header--hide-topbar {
  -webkit-transform: translate3d(0, -33px, 0);
          transform: translate3d(0, -33px, 0);
}

.hs-position-right.u-header__mega-menu-position-right-fix-10 {
  right: 10% !important;
}

.hs-position-right.u-header__mega-menu-position-right-fix-15 {
  right: 15% !important;
}

.hs-position-right.u-header__mega-menu-position-right-fix-20 {
  right: 20% !important;
}

.hs-position-right.u-header__mega-menu-position-right-fix-25 {
  right: 25% !important;
}

.hs-position-right.u-header__mega-menu-position-right-fix-30 {
  right: 30% !important;
}

.hs-position-right.u-header__mega-menu-position-right-fix-35 {
  right: 35% !important;
}

.hs-position-right.u-header__mega-menu-position-right-fix-40 {
  right: 40% !important;
}

.hs-position-right.u-header__mega-menu-position-right-fix-45 {
  right: 45% !important;
}

.hs-position-right.u-header__mega-menu-position-right-fix-50 {
  right: 50% !important;
}

@media (min-width: 576px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-sm-10 {
    right: 10% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-sm-15 {
    right: 15% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-sm-20 {
    right: 20% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-sm-25 {
    right: 25% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-sm-30 {
    right: 30% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-sm-35 {
    right: 35% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-sm-40 {
    right: 40% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-sm-45 {
    right: 45% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-sm-50 {
    right: 50% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-md-10 {
    right: 10% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-md-15 {
    right: 15% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-md-20 {
    right: 20% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-md-25 {
    right: 25% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-md-30 {
    right: 30% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-md-35 {
    right: 35% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-md-40 {
    right: 40% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-md-45 {
    right: 45% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-md-50 {
    right: 50% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-lg-10 {
    right: 10% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-lg-15 {
    right: 15% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-lg-20 {
    right: 20% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-lg-25 {
    right: 25% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-lg-30 {
    right: 30% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-lg-35 {
    right: 35% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-lg-40 {
    right: 40% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-lg-45 {
    right: 45% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-lg-50 {
    right: 50% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-xl-10 {
    right: 10% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-xl-15 {
    right: 15% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-xl-20 {
    right: 20% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-xl-25 {
    right: 25% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-xl-30 {
    right: 30% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-xl-35 {
    right: 35% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-xl-40 {
    right: 40% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-xl-45 {
    right: 45% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-xl-50 {
    right: 50% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-wd-10 {
    right: 10% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-wd-15 {
    right: 15% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-wd-20 {
    right: 20% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-wd-25 {
    right: 25% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-wd-30 {
    right: 30% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-wd-35 {
    right: 35% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-wd-40 {
    right: 40% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-wd-45 {
    right: 45% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-right.u-header__mega-menu-position-right-fix-wd-50 {
    right: 50% !important;
  }
}

.hs-position-left.u-header__mega-menu-position-left-fix-10 {
  left: 10% !important;
}

.hs-position-left.u-header__mega-menu-position-left-fix-15 {
  left: 15% !important;
}

.hs-position-left.u-header__mega-menu-position-left-fix-20 {
  left: 20% !important;
}

.hs-position-left.u-header__mega-menu-position-left-fix-25 {
  left: 25% !important;
}

.hs-position-left.u-header__mega-menu-position-left-fix-30 {
  left: 30% !important;
}

.hs-position-left.u-header__mega-menu-position-left-fix-35 {
  left: 35% !important;
}

.hs-position-left.u-header__mega-menu-position-left-fix-40 {
  left: 40% !important;
}

.hs-position-left.u-header__mega-menu-position-left-fix-45 {
  left: 45% !important;
}

.hs-position-left.u-header__mega-menu-position-left-fix-50 {
  left: 50% !important;
}

@media (min-width: 576px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-sm-10 {
    left: 10% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-sm-15 {
    left: 15% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-sm-20 {
    left: 20% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-sm-25 {
    left: 25% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-sm-30 {
    left: 30% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-sm-35 {
    left: 35% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-sm-40 {
    left: 40% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-sm-45 {
    left: 45% !important;
  }
}

@media (min-width: 576px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-sm-50 {
    left: 50% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-md-10 {
    left: 10% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-md-15 {
    left: 15% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-md-20 {
    left: 20% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-md-25 {
    left: 25% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-md-30 {
    left: 30% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-md-35 {
    left: 35% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-md-40 {
    left: 40% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-md-45 {
    left: 45% !important;
  }
}

@media (min-width: 768px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-md-50 {
    left: 50% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-lg-10 {
    left: 10% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-lg-15 {
    left: 15% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-lg-20 {
    left: 20% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-lg-25 {
    left: 25% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-lg-30 {
    left: 30% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-lg-35 {
    left: 35% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-lg-40 {
    left: 40% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-lg-45 {
    left: 45% !important;
  }
}

@media (min-width: 992px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-lg-50 {
    left: 50% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-xl-10 {
    left: 10% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-xl-15 {
    left: 15% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-xl-20 {
    left: 20% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-xl-25 {
    left: 25% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-xl-30 {
    left: 30% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-xl-35 {
    left: 35% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-xl-40 {
    left: 40% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-xl-45 {
    left: 45% !important;
  }
}

@media (min-width: 1200px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-xl-50 {
    left: 50% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-wd-10 {
    left: 10% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-wd-15 {
    left: 15% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-wd-20 {
    left: 20% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-wd-25 {
    left: 25% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-wd-30 {
    left: 30% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-wd-35 {
    left: 35% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-wd-40 {
    left: 40% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-wd-45 {
    left: 45% !important;
  }
}

@media (min-width: 1480px) {
  .hs-position-left.u-header__mega-menu-position-left-fix-wd-50 {
    left: 50% !important;
  }
}

/*------------------------------------
  Header Helpers
------------------------------------*/
.u-header--invisible {
  display: none;
}

.u-header--moved-up {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
}

.u-header--faded {
  opacity: 0;
  visibility: hidden;
}

.u-header__section--hidden {
  position: relative;
}

.u-header[data-header-fix-effect] {
  transition: 0.3s ease;
}

.u-header.u-header--untransitioned {
  transition: none;
}

.u-header.js-header-fix-moment {
  position: fixed;
  top: 0;
  bottom: auto;
}

.u-header--fix-top {
  position: fixed;
}

.u-header.u-header--fix-top[data-effect-compensation] {
  transition: none;
}

/*------------------------------------
  Nav Alignments
------------------------------------*/
@media (min-width: 992px) {
  .u-header__navbar-body,
  .u-header__navbar-nav {
    margin-left: auto;
  }
  .u-header-left-aligned-nav .u-header__navbar-body,
  .u-header-left-aligned-nav .u-header__navbar-nav {
    margin-left: 0;
    margin-right: auto;
  }
  .u-header-left-aligned-nav .u-header__navbar-nav {
    width: 100%;
  }
  .u-header-left-aligned-nav .u-header__navbar-brand {
    margin-left: 0;
    margin-right: 3rem;
  }
  .u-header-left-aligned-nav .u-header__nav-last-item {
    margin-left: auto;
  }
  .u-header-center-aligned-nav__col {
    -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .u-header-center-aligned-nav__col-last-item {
    text-align: right;
  }
  .u-header-center-aligned-nav .u-header__navbar-body,
  .u-header-center-aligned-nav .u-header__navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1199.98px) {
  .u-header-center-aligned-nav__col:not(.u-header-center-aligned-nav__col-last-item) {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
  .u-header-center-aligned-nav__col-last-item {
    -ms-flex-positive: 1;
        flex-grow: 1;
  }
}

@media (min-width: 768px) {
  .u-header-center-aligned-nav.u-header--abs-top-sm .u-header__navbar-body,
  .u-header-center-aligned-nav.u-header--abs-top-sm .u-header__navbar-nav {
    margin-left: auto;
    margin-right: auto;
  }
}

/*------------------------------------
  Header Default
------------------------------------*/
.u-header__navbar-nav-scroll {
  max-width: 100%;
  height: 4.25rem;
  overflow: hidden;
}

.u-header__navbar-nav-scroll .u-header__navbar-nav {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

/*------------------------------------
  Fullscreen
------------------------------------*/
.u-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
}

.u-fullscreen--toggler {
  z-index: 1001;
}

.u-fullscreen__container, .u-fullscreen__overlay {
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.u-fullscreen__container {
  position: relative;
  z-index: 1000;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  opacity: 0;
  visibility: hidden;
}

.u-fullscreen__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  background-image: linear-gradient(150deg, rgba(45, 21, 130, 0.95) 0%, rgba(25, 160, 255, 0.95) 100%);
  background-repeat: repeat-x;
  transition-delay: 0.5s;
}

.u-fullscreen.u-fullscreen--showed {
  visibility: visible;
}

.u-fullscreen.u-fullscreen--showed .u-fullscreen__container,
.u-fullscreen.u-fullscreen--showed .u-fullscreen__overlay {
  opacity: 1;
  visibility: visible;
}

.u-fullscreen.u-fullscreen--showed .u-fullscreen__overlay {
  transition-delay: 0s;
}

.u-fullscreen.u-fullscreen--showed .u-fullscreen__container {
  transition-delay: 0.5s;
}

/*------------------------------------
  Header Fullscreen Style
------------------------------------*/
.u-fullscreen__nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.u-fullscreen__nav-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.u-fullscreen__nav-link {
  display: inline-block;
  color: #fff;
  font-size: 1.375rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

@media (min-width: 992px) {
  .u-fullscreen__nav-link {
    font-size: 1.75rem;
  }
}

.u-fullscreen__nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}

.u-fullscreen__submenu {
  position: relative;
  transition: 0.3s ease-in-out;
}

.u-fullscreen__submenu .u-fullscreen__nav-list {
  border-left: 2px solid rgba(255, 255, 255, 0.6);
  padding-left: 1rem;
}

.u-fullscreen__submenu-nav-link {
  display: inline-block;
  color: #fff;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.u-fullscreen__submenu-nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}

.u-fullscreen__submenu-list {
  list-style: none;
  border-left: 2px solid rgba(255, 255, 255, 0.6);
  padding-left: 1rem;
  margin-bottom: 0;
}

.u-fullscreen__submenu-list-link {
  display: inline-block;
  color: rgba(255, 255, 255, 0.7);
}

.u-fullscreen__submenu-list-link:hover {
  color: #fff;
}

/*------------------------------------
  Header Navbar Background Colors
------------------------------------*/
.u-header--navbar-bg .u-header__navbar-brand-text {
  color: #fff;
}

.u-header--navbar-bg .u-header__nav-item:hover .u-header__nav-link {
  color: #fff;
}

.u-header--navbar-bg .active > .u-header__nav-link {
  color: #fff;
}

.u-header--navbar-bg .u-header__nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-header__nav-link:hover {
  color: #fff;
}

.u-header--navbar-bg .u-header__navbar-link,
.u-header--navbar-bg .dropdown-nav-link,
.u-header--navbar-bg .btn-text-secondary {
  color: rgba(255, 255, 255, 0.6);
}

.u-header--navbar-bg .u-header__navbar-link:hover,
.u-header--navbar-bg .dropdown-nav-link:hover,
.u-header--navbar-bg .btn-text-secondary:hover {
  color: #fff;
}

.u-header--navbar-bg .btn-text-secondary[href]:hover {
  color: #fff;
}

.u-header--navbar-bg .u-hamburger__inner, .u-header--navbar-bg .u-hamburger__inner::before, .u-header--navbar-bg .u-hamburger__inner::after {
  background-color: rgba(255, 255, 255, 0.5);
}

.u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner, .u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::before, .u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::after {
  background-color: #fff;
}

/*------------------------------------
  Header Submenu Background Colors
------------------------------------*/
.u-header--sub-menu-dark-bg .u-header__sub-menu,
.u-header--sub-menu-dark-bg .u-header__promo {
  background-color: #3b444f;
}

.u-header--sub-menu-dark-bg .u-header__sub-menu-title,
.u-header--sub-menu-dark-bg .u-header__promo-title {
  color: rgba(255, 255, 255, 0.8);
}

.u-header--sub-menu-dark-bg .u-header__promo-footer {
  background-color: #39414c;
}

.u-header--sub-menu-dark-bg .u-header__promo-footer-ver-divider::before {
  border-color: rgba(255, 255, 255, 0.1);
}

.u-header--sub-menu-dark-bg .u-header__promo-link::after {
  border-color: rgba(255, 255, 255, 0.1);
}

.u-header--sub-menu-dark-bg .u-header__promo-item {
  border-color: rgba(255, 255, 255, 0.1);
}

.u-header--sub-menu-dark-bg .u-header__sub-menu-nav-link {
  color: #8c98a4;
}

.u-header--sub-menu-dark-bg .u-header__sub-menu-nav-link:hover {
  color: #297cbb;
}

.u-header--sub-menu-dark-bg .u-header__product-banner {
  background-color: #39414c;
}

.u-header--sub-menu-dark-bg .u-header__product-banner-text {
  color: #8c98a4;
}

.u-header--sub-menu-dark-bg .dropdown-nav-link:hover {
  color: rgba(255, 255, 255, 0.8);
}

.u-header--sub-menu-dark-bg .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 576px) {
  .u-header--sub-menu-dark-bg-sm .u-header__sub-menu,
  .u-header--sub-menu-dark-bg-sm .u-header__promo {
    background-color: #3b444f;
  }
  .u-header--sub-menu-dark-bg-sm .u-header__sub-menu-title,
  .u-header--sub-menu-dark-bg-sm .u-header__promo-title {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__promo-footer {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-sm .u-header__promo-footer-ver-divider::before {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__promo-link::after {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__promo-item {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__sub-menu-nav-link {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-sm .u-header__sub-menu-nav-link:hover {
    color: #297cbb;
  }
  .u-header--sub-menu-dark-bg-sm .u-header__product-banner {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-sm .u-header__product-banner-text {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-sm .dropdown-nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-sm .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

@media (min-width: 768px) {
  .u-header--sub-menu-dark-bg-md .u-header__sub-menu,
  .u-header--sub-menu-dark-bg-md .u-header__promo {
    background-color: #3b444f;
  }
  .u-header--sub-menu-dark-bg-md .u-header__sub-menu-title,
  .u-header--sub-menu-dark-bg-md .u-header__promo-title {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-md .u-header__promo-footer {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-md .u-header__promo-footer-ver-divider::before {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-md .u-header__promo-link::after {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-md .u-header__promo-item {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-md .u-header__sub-menu-nav-link {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-md .u-header__sub-menu-nav-link:hover {
    color: #297cbb;
  }
  .u-header--sub-menu-dark-bg-md .u-header__product-banner {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-md .u-header__product-banner-text {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-md .dropdown-nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-md .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

@media (min-width: 992px) {
  .u-header--sub-menu-dark-bg-lg .u-header__sub-menu,
  .u-header--sub-menu-dark-bg-lg .u-header__promo {
    background-color: #3b444f;
  }
  .u-header--sub-menu-dark-bg-lg .u-header__sub-menu-title,
  .u-header--sub-menu-dark-bg-lg .u-header__promo-title {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__promo-footer {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-lg .u-header__promo-footer-ver-divider::before {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__promo-link::after {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__promo-item {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__sub-menu-nav-link {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-lg .u-header__sub-menu-nav-link:hover {
    color: #297cbb;
  }
  .u-header--sub-menu-dark-bg-lg .u-header__product-banner {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-lg .u-header__product-banner-text {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-lg .dropdown-nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-lg .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

@media (min-width: 1200px) {
  .u-header--sub-menu-dark-bg-xl .u-header__sub-menu,
  .u-header--sub-menu-dark-bg-xl .u-header__promo {
    background-color: #3b444f;
  }
  .u-header--sub-menu-dark-bg-xl .u-header__sub-menu-title,
  .u-header--sub-menu-dark-bg-xl .u-header__promo-title {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__promo-footer {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-xl .u-header__promo-footer-ver-divider::before {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__promo-link::after {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__promo-item {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__sub-menu-nav-link {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-xl .u-header__sub-menu-nav-link:hover {
    color: #297cbb;
  }
  .u-header--sub-menu-dark-bg-xl .u-header__product-banner {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-xl .u-header__product-banner-text {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-xl .dropdown-nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-xl .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

@media (min-width: 1480px) {
  .u-header--sub-menu-dark-bg-wd .u-header__sub-menu,
  .u-header--sub-menu-dark-bg-wd .u-header__promo {
    background-color: #3b444f;
  }
  .u-header--sub-menu-dark-bg-wd .u-header__sub-menu-title,
  .u-header--sub-menu-dark-bg-wd .u-header__promo-title {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-wd .u-header__promo-footer {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-wd .u-header__promo-footer-ver-divider::before {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-wd .u-header__promo-link::after {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-wd .u-header__promo-item {
    border-color: rgba(255, 255, 255, 0.1);
  }
  .u-header--sub-menu-dark-bg-wd .u-header__sub-menu-nav-link {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-wd .u-header__sub-menu-nav-link:hover {
    color: #297cbb;
  }
  .u-header--sub-menu-dark-bg-wd .u-header__product-banner {
    background-color: #39414c;
  }
  .u-header--sub-menu-dark-bg-wd .u-header__product-banner-text {
    color: #8c98a4;
  }
  .u-header--sub-menu-dark-bg-wd .dropdown-nav-link:hover {
    color: rgba(255, 255, 255, 0.8);
  }
  .u-header--sub-menu-dark-bg-wd .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.1);
  }
}

/*------------------------------------
  Primary Header
------------------------------------*/
.u-header--bg-primary:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section,
.u-header--bg-violet:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
  background-color: #fff;
}

.u-header--bg-primary:not(.js-header-fix-moment) .u-header__section,
.u-header--bg-violet:not(.js-header-fix-moment) .u-header__section {
  background-color: #6f13cd;
  box-shadow: none;
}

.u-header--bg-primary .u-header__navbar-brand-collapsed,
.u-header--bg-primary .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
.u-header--bg-primary .u-header__navbar-brand-on-scroll,
.u-header--bg-primary .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
.u-header--bg-violet .u-header__navbar-brand-collapsed,
.u-header--bg-violet .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
.u-header--bg-violet .u-header__navbar-brand-on-scroll,
.u-header--bg-violet .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
  display: none;
}

.u-header--bg-primary.js-header-fix-moment .u-header__navbar-brand-default,
.u-header--bg-primary.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text,
.u-header--bg-violet.js-header-fix-moment .u-header__navbar-brand-default,
.u-header--bg-violet.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
  display: none;
}

.u-header--bg-primary.js-header-fix-moment .u-header__navbar-brand-on-scroll,
.u-header--bg-violet.js-header-fix-moment .u-header__navbar-brand-on-scroll {
  display: -ms-flexbox;
  display: flex;
}

.u-header--bg-primary.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
.u-header--bg-violet.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
  display: inline-block;
}

@media (min-width: 576px) and (min-width: 576px) {
  .u-header--bg-primary-sm:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section,
  .u-header--bg-violet-sm:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-primary-sm:not(.js-header-fix-moment) .u-header__section,
  .u-header--bg-violet-sm:not(.js-header-fix-moment) .u-header__section {
    background-color: #6f13cd;
    box-shadow: none;
  }
}

@media (min-width: 576px) {
  .u-header--bg-primary-sm .u-header__navbar-brand-collapsed,
  .u-header--bg-primary-sm .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-primary-sm .u-header__navbar-brand-on-scroll,
  .u-header--bg-primary-sm .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-sm .u-header__navbar-brand-collapsed,
  .u-header--bg-violet-sm .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-violet-sm .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-sm .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-sm.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-primary-sm.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text,
  .u-header--bg-violet-sm.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-violet-sm.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-sm.js-header-fix-moment .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-sm.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-primary-sm.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-sm.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .u-header--bg-primary-md:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section,
  .u-header--bg-violet-md:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-primary-md:not(.js-header-fix-moment) .u-header__section,
  .u-header--bg-violet-md:not(.js-header-fix-moment) .u-header__section {
    background-color: #6f13cd;
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .u-header--bg-primary-md .u-header__navbar-brand-collapsed,
  .u-header--bg-primary-md .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-primary-md .u-header__navbar-brand-on-scroll,
  .u-header--bg-primary-md .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-md .u-header__navbar-brand-collapsed,
  .u-header--bg-violet-md .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-violet-md .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-md .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-md.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-primary-md.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text,
  .u-header--bg-violet-md.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-violet-md.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-md.js-header-fix-moment .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-md.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-primary-md.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-md.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .u-header--bg-primary-lg:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section,
  .u-header--bg-violet-lg:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-primary-lg:not(.js-header-fix-moment) .u-header__section,
  .u-header--bg-violet-lg:not(.js-header-fix-moment) .u-header__section {
    background-color: #6f13cd;
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .u-header--bg-primary-lg .u-header__navbar-brand-collapsed,
  .u-header--bg-primary-lg .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-primary-lg .u-header__navbar-brand-on-scroll,
  .u-header--bg-primary-lg .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-lg .u-header__navbar-brand-collapsed,
  .u-header--bg-violet-lg .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-violet-lg .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-lg .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-lg.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-primary-lg.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text,
  .u-header--bg-violet-lg.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-violet-lg.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-lg.js-header-fix-moment .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-lg.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-primary-lg.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-lg.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .u-header--bg-primary-xl:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section,
  .u-header--bg-violet-xl:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-primary-xl:not(.js-header-fix-moment) .u-header__section,
  .u-header--bg-violet-xl:not(.js-header-fix-moment) .u-header__section {
    background-color: #6f13cd;
    box-shadow: none;
  }
}

@media (min-width: 1200px) {
  .u-header--bg-primary-xl .u-header__navbar-brand-collapsed,
  .u-header--bg-primary-xl .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-primary-xl .u-header__navbar-brand-on-scroll,
  .u-header--bg-primary-xl .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-xl .u-header__navbar-brand-collapsed,
  .u-header--bg-violet-xl .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-violet-xl .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-xl .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-xl.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-primary-xl.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text,
  .u-header--bg-violet-xl.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-violet-xl.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-xl.js-header-fix-moment .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-xl.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-primary-xl.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-xl.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
}

@media (min-width: 1480px) and (min-width: 1480px) {
  .u-header--bg-primary-wd:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section,
  .u-header--bg-violet-wd:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-primary-wd:not(.js-header-fix-moment) .u-header__section,
  .u-header--bg-violet-wd:not(.js-header-fix-moment) .u-header__section {
    background-color: #6f13cd;
    box-shadow: none;
  }
}

@media (min-width: 1480px) {
  .u-header--bg-primary-wd .u-header__navbar-brand-collapsed,
  .u-header--bg-primary-wd .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-primary-wd .u-header__navbar-brand-on-scroll,
  .u-header--bg-primary-wd .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-wd .u-header__navbar-brand-collapsed,
  .u-header--bg-violet-wd .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-violet-wd .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-wd .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-wd.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-primary-wd.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text,
  .u-header--bg-violet-wd.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-violet-wd.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-primary-wd.js-header-fix-moment .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-wd.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-primary-wd.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text,
  .u-header--bg-violet-wd.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  .u-header--bg-primary-sm .u-header__navbar-brand-default,
  .u-header--bg-primary-sm .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-sm .u-header__navbar-brand-default,
  .u-header--bg-violet-sm .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-header--bg-primary-md .u-header__navbar-brand-default,
  .u-header--bg-primary-md .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-md .u-header__navbar-brand-default,
  .u-header--bg-violet-md .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-header--bg-primary-lg .u-header__navbar-brand-default,
  .u-header--bg-primary-lg .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-lg .u-header__navbar-brand-default,
  .u-header--bg-violet-lg .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .u-header--bg-primary-xl .u-header__navbar-brand-default,
  .u-header--bg-primary-xl .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-xl .u-header__navbar-brand-default,
  .u-header--bg-violet-xl .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 1479.98px) {
  .u-header--bg-primary-wd .u-header__navbar-brand-default,
  .u-header--bg-primary-wd .u-header__navbar-brand-on-scroll,
  .u-header--bg-violet-wd .u-header__navbar-brand-default,
  .u-header--bg-violet-wd .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

.u-header--bg-primary .u-header__navbar-brand-default,
.u-header--bg-primary .u-header__navbar-brand-on-scroll,
.u-header--bg-violet .u-header__navbar-brand-default,
.u-header--bg-violet .u-header__navbar-brand-on-scroll {
  display: none;
}

/*------------------------------------
  Transparent Header
------------------------------------*/
.u-header--bg-transparent:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
  background-color: #fff;
}

.u-header--bg-transparent:not(.js-header-fix-moment) .u-header__section {
  background-color: transparent;
  box-shadow: none;
}

.u-header--bg-transparent .u-header__navbar-brand-collapsed,
.u-header--bg-transparent .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
.u-header--bg-transparent .u-header__navbar-brand-on-scroll,
.u-header--bg-transparent .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
  display: none;
}

.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-default,
.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
  display: none;
}

.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-on-scroll {
  display: -ms-flexbox;
  display: flex;
}

.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
  display: inline-block;
}

.u-header--bg-transparent .u-header__navbar-link {
  padding-top: 1.219rem;
  padding-bottom: 1.219rem;
}

@media (min-width: 576px) and (min-width: 576px) {
  .u-header--bg-transparent-sm:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-transparent-sm:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 576px) {
  .u-header--bg-transparent-sm .u-header__navbar-brand-collapsed,
  .u-header--bg-transparent-sm .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-transparent-sm .u-header__navbar-brand-on-scroll,
  .u-header--bg-transparent-sm .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-sm.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-transparent-sm.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-sm.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-transparent-sm.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
  .u-header--bg-transparent-sm .u-header__navbar-link {
    padding-top: 1.219rem;
    padding-bottom: 1.219rem;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .u-header--bg-transparent-md:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-transparent-md:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 768px) {
  .u-header--bg-transparent-md .u-header__navbar-brand-collapsed,
  .u-header--bg-transparent-md .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-transparent-md .u-header__navbar-brand-on-scroll,
  .u-header--bg-transparent-md .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-md.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-transparent-md.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-md.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-transparent-md.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
  .u-header--bg-transparent-md .u-header__navbar-link {
    padding-top: 1.219rem;
    padding-bottom: 1.219rem;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .u-header--bg-transparent-lg:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-transparent-lg:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .u-header--bg-transparent-lg .u-header__navbar-brand-collapsed,
  .u-header--bg-transparent-lg .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-transparent-lg .u-header__navbar-brand-on-scroll,
  .u-header--bg-transparent-lg .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-lg.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-transparent-lg.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-lg.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-transparent-lg.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
  .u-header--bg-transparent-lg .u-header__navbar-link {
    padding-top: 1.219rem;
    padding-bottom: 1.219rem;
  }
}

@media (min-width: 1200px) and (min-width: 1200px) {
  .u-header--bg-transparent-xl:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-transparent-xl:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 1200px) {
  .u-header--bg-transparent-xl .u-header__navbar-brand-collapsed,
  .u-header--bg-transparent-xl .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-transparent-xl .u-header__navbar-brand-on-scroll,
  .u-header--bg-transparent-xl .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-xl.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-transparent-xl.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-xl.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-transparent-xl.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
  .u-header--bg-transparent-xl .u-header__navbar-link {
    padding-top: 1.219rem;
    padding-bottom: 1.219rem;
  }
}

@media (min-width: 1480px) and (min-width: 1480px) {
  .u-header--bg-transparent-wd:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"] + .u-header__section {
    background-color: #fff;
  }
  .u-header--bg-transparent-wd:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
  }
}

@media (min-width: 1480px) {
  .u-header--bg-transparent-wd .u-header__navbar-brand-collapsed,
  .u-header--bg-transparent-wd .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
  .u-header--bg-transparent-wd .u-header__navbar-brand-on-scroll,
  .u-header--bg-transparent-wd .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-wd.js-header-fix-moment .u-header__navbar-brand-default,
  .u-header--bg-transparent-wd.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
  }
  .u-header--bg-transparent-wd.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
  }
  .u-header--bg-transparent-wd.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
  }
  .u-header--bg-transparent-wd .u-header__navbar-link {
    padding-top: 1.219rem;
    padding-bottom: 1.219rem;
  }
}

@media (max-width: 575.98px) {
  .u-header--bg-transparent-sm .u-header__navbar-brand-default,
  .u-header--bg-transparent-sm .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-header--bg-transparent-md .u-header__navbar-brand-default,
  .u-header--bg-transparent-md .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-header--bg-transparent-lg .u-header__navbar-brand-default,
  .u-header--bg-transparent-lg .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .u-header--bg-transparent-xl .u-header__navbar-brand-default,
  .u-header--bg-transparent-xl .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

@media (max-width: 1479.98px) {
  .u-header--bg-transparent-wd .u-header__navbar-brand-default,
  .u-header--bg-transparent-wd .u-header__navbar-brand-on-scroll {
    display: none;
  }
}

.u-header--bg-transparent .u-header__navbar-brand-default,
.u-header--bg-transparent .u-header__navbar-brand-on-scroll {
  display: none;
}

.u-header--bg-transparent .u-header__navbar-brand-default {
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 1199.98px) {
  .u-header--bg-transparent .u-header__navbar-nav {
    background-color: #fff;
  }
  .u-header--bg-transparent .u-header__navbar-nav {
    padding: 0.875rem 1.5rem;
    padding-top: 1rem;
  }
  .u-header--bg-transparent.u-scrolled .scroll-icon i {
    color: #297cbb !important;
  }
}

/*------------------------------------
  Header On Scroll
------------------------------------*/
.u-header--white-bg-on-scroll.u-scrolled .u-header__section {
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
}

/*------------------------------------
  Header White Nav Links
------------------------------------*/
.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
  color: white;
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link,
.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
  color: white;
}

.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover,
.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
.u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
  color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 576px) {
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: white;
  }
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link,
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: white;
  }
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover,
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
  .u-header--white-nav-links-sm:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}

@media (min-width: 768px) {
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: white;
  }
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link,
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: white;
  }
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover,
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
  .u-header--white-nav-links-md:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}

@media (min-width: 992px) {
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: white;
  }
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link,
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: white;
  }
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover,
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
  .u-header--white-nav-links-lg:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}

@media (min-width: 1200px) {
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: white;
  }
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link,
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: white;
  }
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover,
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
  .u-header--white-nav-links-xl:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}

@media (min-width: 1480px) {
  .u-header--white-nav-links-wd:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-item:hover .u-header__nav-link {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-wd:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link {
    color: white;
  }
  .u-header--white-nav-links-wd:not(.bg-white):not(.js-header-fix-moment) .u-header__nav-link:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .u-header--white-nav-links-wd:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link,
  .u-header--white-nav-links-wd:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link,
  .u-header--white-nav-links-wd:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary {
    color: white;
  }
  .u-header--white-nav-links-wd:not(.bg-white):not(.js-header-fix-moment) .u-header__navbar-link:hover,
  .u-header--white-nav-links-wd:not(.bg-white):not(.js-header-fix-moment) .dropdown-nav-link:hover,
  .u-header--white-nav-links-wd:not(.bg-white):not(.js-header-fix-moment) .btn-text-secondary:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}

/*------------------------------------
  Header White Nav Links
------------------------------------*/
.u-header--dark-nav-links:not(.bg-dark) .u-header__nav-item:hover .u-header__nav-link {
  color: #297cbb;
}

.u-header--dark-nav-links:not(.bg-dark) .u-header__nav-link {
  color: #3b444f;
}

@media (min-width: 576px) {
  .u-header--dark-nav-links-sm:not(.bg-dark) .u-header__nav-item:hover .u-header__nav-link {
    color: #297cbb;
  }
  .u-header--dark-nav-links-sm:not(.bg-dark) .u-header__nav-link {
    color: #3b444f;
  }
}

@media (min-width: 768px) {
  .u-header--dark-nav-links-md:not(.bg-dark) .u-header__nav-item:hover .u-header__nav-link {
    color: #297cbb;
  }
  .u-header--dark-nav-links-md:not(.bg-dark) .u-header__nav-link {
    color: #3b444f;
  }
}

@media (min-width: 992px) {
  .u-header--dark-nav-links-lg:not(.bg-dark) .u-header__nav-item:hover .u-header__nav-link {
    color: #297cbb;
  }
  .u-header--dark-nav-links-lg:not(.bg-dark) .u-header__nav-link {
    color: #3b444f;
  }
}

@media (min-width: 1200px) {
  .u-header--dark-nav-links-xl:not(.bg-dark) .u-header__nav-item:hover .u-header__nav-link {
    color: #297cbb;
  }
  .u-header--dark-nav-links-xl:not(.bg-dark) .u-header__nav-link {
    color: #3b444f;
  }
}

@media (min-width: 1480px) {
  .u-header--dark-nav-links-wd:not(.bg-dark) .u-header__nav-item:hover .u-header__nav-link {
    color: #297cbb;
  }
  .u-header--dark-nav-links-wd:not(.bg-dark) .u-header__nav-link {
    color: #3b444f;
  }
}

/*------------------------------------
  Navbar Expand
------------------------------------*/
.navbar-expand {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.navbar-expand.u-header__navbar--no-space {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__navbar-nav {
  -ms-flex-align: center;
      align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__sub-menu-bordered > li + li .nav-link {
  border-top: 1px solid #ebf0f7;
}

.navbar-expand .hs-mega-menu {
  padding: 1.875rem;
}

.navbar-expand .u-header__navbar-nav .u-header__nav-last-item {
  text-align: right;
  padding-left: 1rem;
  padding-right: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__nav-link {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu {
  border-top: 0.1875rem solid #297cbb;
  border-bottom-right-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  box-shadow: 0 8px 20px rgba(41, 124, 187, 0.075);
}

[class*="u-header--bg-transparent"].u-header:not(.js-header-fix-moment) .navbar-expand .u-header__navbar-nav .u-header__sub-menu-rounded {
  border-top: unset;
  border-radius: 0.188rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
  padding-left: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
  padding: 0.813rem 1.875rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
  padding: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
  margin-top: -1.1875rem;
}

.navbar-expand .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
  right: 20%;
}

.navbar-expand .u-header__mega-menu-wrapper {
  padding: 2.5rem;
  margin-left: 0;
  margin-right: 0;
}

.navbar-expand .u-header__promo {
  border-bottom-right-radius: 0.3125rem;
}

.navbar-expand .u-header__promo-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 0 0%;
      flex: 1 0 0%;
  -ms-flex-direction: column;
      flex-direction: column;
}

.navbar-expand .u-header__promo-card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
}

.navbar-expand .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
  position: relative;
}

.navbar-expand .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1.5rem;
  border-right: 1px solid #e7eaf3;
  height: 100%;
  content: "";
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-expand-sm.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav {
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__sub-menu-bordered > li + li .nav-link {
    border-top: 1px solid #ebf0f7;
  }
  .navbar-expand-sm .hs-mega-menu {
    padding: 1.875rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid #297cbb;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(41, 124, 187, 0.075);
  }
  [class*="u-header--bg-transparent"].u-header:not(.js-header-fix-moment) .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-rounded {
    border-top: unset;
    border-radius: 0.188rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding: 0.813rem 1.875rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-sm .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand-sm .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-sm .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-sm .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-sm .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-sm .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
  }
  .navbar-expand-sm .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-expand-md.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__navbar-nav {
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__sub-menu-bordered > li + li .nav-link {
    border-top: 1px solid #ebf0f7;
  }
  .navbar-expand-md .hs-mega-menu {
    padding: 1.875rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid #297cbb;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(41, 124, 187, 0.075);
  }
  [class*="u-header--bg-transparent"].u-header:not(.js-header-fix-moment) .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-rounded {
    border-top: unset;
    border-radius: 0.188rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding: 0.813rem 1.875rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-md .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand-md .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-md .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-md .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-md .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-md .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
  }
  .navbar-expand-md .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-expand-lg.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav {
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__sub-menu-bordered > li + li .nav-link {
    border-top: 1px solid #ebf0f7;
  }
  .navbar-expand-lg .hs-mega-menu {
    padding: 1.875rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid #297cbb;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(41, 124, 187, 0.075);
  }
  [class*="u-header--bg-transparent"].u-header:not(.js-header-fix-moment) .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-rounded {
    border-top: unset;
    border-radius: 0.188rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding: 0.813rem 1.875rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-lg .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand-lg .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-lg .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-lg .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-lg .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-lg .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
  }
  .navbar-expand-lg .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-expand-xl.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav {
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__sub-menu-bordered > li + li .nav-link {
    border-top: 1px solid #ebf0f7;
  }
  .navbar-expand-xl .hs-mega-menu {
    padding: 1.875rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid #297cbb;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(41, 124, 187, 0.075);
  }
  [class*="u-header--bg-transparent"].u-header:not(.js-header-fix-moment) .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-rounded {
    border-top: unset;
    border-radius: 0.188rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding: 0.813rem 1.875rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-xl .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand-xl .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-xl .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-xl .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-xl .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-xl .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
  }
  .navbar-expand-xl .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (min-width: 1480px) {
  .navbar-expand-wd {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .navbar-expand-wd.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-wd .u-header__navbar-nav {
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-wd .u-header__sub-menu-bordered > li + li .nav-link {
    border-top: 1px solid #ebf0f7;
  }
  .navbar-expand-wd .hs-mega-menu {
    padding: 1.875rem;
  }
  .navbar-expand-wd .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .navbar-expand-wd .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-wd .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid #297cbb;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(41, 124, 187, 0.075);
  }
  [class*="u-header--bg-transparent"].u-header:not(.js-header-fix-moment) .navbar-expand-wd .u-header__navbar-nav .u-header__sub-menu-rounded {
    border-top: unset;
    border-radius: 0.188rem;
  }
  .navbar-expand-wd .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-wd .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand-wd .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding: 0.813rem 1.875rem;
  }
  .navbar-expand-wd .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-wd .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand-wd .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand-wd .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand-wd .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
  }
  .navbar-expand-wd .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
        flex: 1 0 0%;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .navbar-expand-wd .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
  }
  .navbar-expand-wd .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
  }
  .navbar-expand-wd .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .u-header__nav-last-item {
    display: none;
  }
  .navbar-expand-sm .u-header__nav-link,
  .navbar-expand-sm .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-sm .u-header__nav-link-toggle::after,
  .navbar-expand-sm .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-sm .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .navbar-expand-sm .hs-mega-menu-opened .u-header__nav-link-toggle::after,
  .navbar-expand-sm .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-sm .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-sm .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .navbar-expand-sm .u-header__sub-menu {
    min-width: 100% !important;
  }
  .navbar-expand-sm .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid #297cbb;
  }
  .navbar-expand-sm .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  .navbar-expand-sm .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-sm .u-header-center-aligned-nav__col-last-item {
    display: none;
  }
  .navbar-expand-sm .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .u-header__nav-last-item {
    display: none;
  }
  .navbar-expand-md .u-header__nav-link,
  .navbar-expand-md .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-md .u-header__nav-link-toggle::after,
  .navbar-expand-md .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-md .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .navbar-expand-md .hs-mega-menu-opened .u-header__nav-link-toggle::after,
  .navbar-expand-md .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-md .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-md .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .navbar-expand-md .u-header__sub-menu {
    min-width: 100% !important;
  }
  .navbar-expand-md .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid #297cbb;
  }
  .navbar-expand-md .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  .navbar-expand-md .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-md .u-header-center-aligned-nav__col-last-item {
    display: none;
  }
  .navbar-expand-md .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .u-header__nav-last-item {
    display: none;
  }
  .navbar-expand-lg .u-header__nav-link,
  .navbar-expand-lg .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-lg .u-header__nav-link-toggle::after,
  .navbar-expand-lg .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-lg .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .navbar-expand-lg .hs-mega-menu-opened .u-header__nav-link-toggle::after,
  .navbar-expand-lg .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-lg .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-lg .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .navbar-expand-lg .u-header__sub-menu {
    min-width: 100% !important;
  }
  .navbar-expand-lg .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid #297cbb;
  }
  .navbar-expand-lg .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  .navbar-expand-lg .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-lg .u-header-center-aligned-nav__col-last-item {
    display: none;
  }
  .navbar-expand-lg .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .u-header__nav-last-item {
    display: none;
  }
  .navbar-expand-xl .u-header__nav-link,
  .navbar-expand-xl .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-xl .u-header__nav-link-toggle::after,
  .navbar-expand-xl .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-xl .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .navbar-expand-xl .hs-mega-menu-opened .u-header__nav-link-toggle::after,
  .navbar-expand-xl .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-xl .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-xl .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .navbar-expand-xl .u-header__sub-menu {
    min-width: 100% !important;
  }
  .navbar-expand-xl .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid #297cbb;
  }
  .navbar-expand-xl .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  .navbar-expand-xl .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-xl .u-header-center-aligned-nav__col-last-item {
    display: none;
  }
  .navbar-expand-xl .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
  }
}

@media (max-width: 1479.98px) {
  .navbar-expand-wd .u-header__nav-last-item {
    display: none;
  }
  .navbar-expand-wd .u-header__nav-link,
  .navbar-expand-wd .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
  }
  .navbar-expand-wd .u-header__nav-link-toggle::after,
  .navbar-expand-wd .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
  }
  .navbar-expand-wd .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .navbar-expand-wd .hs-mega-menu-opened .u-header__nav-link-toggle::after,
  .navbar-expand-wd .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-wd .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-wd .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .navbar-expand-wd .u-header__sub-menu {
    min-width: 100% !important;
  }
  .navbar-expand-wd .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
  }
  .navbar-expand-wd .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid #297cbb;
  }
  .navbar-expand-wd .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
  }
  .navbar-expand-wd .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
  }
  .navbar-expand-wd .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-wd .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-wd .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-wd .u-header-center-aligned-nav__col-last-item {
    display: none;
  }
  .navbar-expand-wd .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
  }
}

.navbar-expand .u-header__nav-last-item {
  display: none;
}

.navbar-expand .u-header__nav-link,
.navbar-expand .u-header__sub-menu-nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.navbar-expand .u-header__nav-link-toggle::after,
.navbar-expand .u-header__sub-menu-nav-link-toggle::after {
  margin-left: auto;
}

.navbar-expand .u-header__sub-menu-nav-link-toggle::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.navbar-expand .hs-mega-menu-opened .u-header__nav-link-toggle::after,
.navbar-expand .hs-sub-menu-opened .u-header__nav-link-toggle::after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.navbar-expand .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle::after {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.navbar-expand .u-header__mega-menu-wrapper {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-expand .u-header__sub-menu {
  min-width: 100% !important;
}

.navbar-expand .u-header__sub-menu--spacer {
  padding-left: 1.5rem;
}

.navbar-expand .u-header__sub-menu:not(.u-header__promo) {
  border-left: 0.1875rem solid #297cbb;
}

.navbar-expand .u-header__sub-menu .u-header__sub-menu {
  padding-left: 1.5rem;
}

.navbar-expand .u-header__sub-menu-title {
  padding-left: 1.5rem;
  margin-bottom: 0;
}

.navbar-expand .u-header__sub-menu-nav-group {
  padding-top: .5rem;
  padding-left: 1.5rem;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu {
  padding-left: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
  -webkit-animation: none;
          animation: none;
}

.navbar-expand .u-header-center-aligned-nav__col-last-item {
  display: none;
}

.navbar-expand .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
  border-bottom: 1px solid #e7eaf3;
}

@media (max-width: 767.98px) {
  .navbar-expand-sm .u-header__nav-last-item {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-xs .u-header__banner,
  .navbar-expand-xs .u-header__product-banner,
  .navbar-expand-sm .u-header__banner,
  .navbar-expand-sm .u-header__product-banner,
  .navbar-expand-md .u-header__banner,
  .navbar-expand-md .u-header__product-banner,
  .navbar-expand-lg .u-header__banner,
  .navbar-expand-lg .u-header__product-banner {
    display: none;
  }
  .navbar-expand-xs .u-header__banner-caption,
  .navbar-expand-sm .u-header__banner-caption,
  .navbar-expand-md .u-header__banner-caption,
  .navbar-expand-lg .u-header__banner-caption {
    position: static;
    font-size: 1rem;
    color: #67747c;
    padding-left: 1.5rem;
    -webkit-transform: unset;
            transform: unset;
    text-align: left;
  }
  .navbar-expand-xs .u-header__banner-caption:hover, .navbar-expand-xs .u-header__banner-caption:focus,
  .navbar-expand-sm .u-header__banner-caption:hover,
  .navbar-expand-sm .u-header__banner-caption:focus,
  .navbar-expand-md .u-header__banner-caption:hover,
  .navbar-expand-md .u-header__banner-caption:focus,
  .navbar-expand-lg .u-header__banner-caption:hover,
  .navbar-expand-lg .u-header__banner-caption:focus {
    color: #297cbb;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .u-header__banner,
  .navbar-expand-xl .u-header__product-banner {
    display: none;
  }
  .navbar-expand-xl .u-header__banner-caption {
    position: static;
    font-size: 1rem;
    color: #67747c;
    padding-left: 1.5rem;
    -webkit-transform: unset;
            transform: unset;
    text-align: left;
  }
  .navbar-expand-xl .u-header__banner-caption:hover, .navbar-expand-xl .u-header__banner-caption:focus {
    color: #297cbb;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar-expand-md .u-header__navbar-brand {
    padding-top: .5rem;
  }
  .u-header .u-header__mega-menu-position-right-fix--md.hs-mega-menu.hs-position-right {
    right: 20%;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .navbar-expand-sm .u-header__navbar-brand {
    padding-top: .5rem;
  }
}

@media (min-width: 992px) {
  .navbar-expand .u-header__nav-last-item {
    display: inline-block;
  }
  [class*="navbar-expand"]:not(.navbar-expand-xl) {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}

/*------------------------------------
  Header Floating
------------------------------------*/
.u-header--floating {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 2rem;
}

.u-header--floating.js-header-fix-moment {
  position: fixed;
  background-color: #fff;
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
  margin-top: 0;
}

.u-header--floating.js-header-fix-moment .u-header--floating__inner {
  box-shadow: none;
}

.u-header--floating .u-header--floating__scrollbar {
  max-height: 50vh;
}

.u-header--floating .u-header--floating__scrollbar.mCS_destroyed {
  position: static !important;
}

.u-header--floating .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 0 24px;
}

.u-header--floating__inner {
  border-radius: 0.3125rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 576px) {
  .u-header--floating-sm {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-sm.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-sm.js-header-fix-moment .u-header--floating__inner {
    box-shadow: none;
  }
  .u-header--floating-sm .u-header--floating__scrollbar {
    max-height: 50vh;
  }
  .u-header--floating-sm .u-header--floating__scrollbar.mCS_destroyed {
    position: static !important;
  }
  .u-header--floating-sm .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
  .u-header--floating__inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 768px) {
  .u-header--floating-md {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-md.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-md.js-header-fix-moment .u-header--floating__inner {
    box-shadow: none;
  }
  .u-header--floating-md .u-header--floating__scrollbar {
    max-height: 50vh;
  }
  .u-header--floating-md .u-header--floating__scrollbar.mCS_destroyed {
    position: static !important;
  }
  .u-header--floating-md .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
  .u-header--floating__inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 992px) {
  .u-header--floating-lg {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-lg.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-lg.js-header-fix-moment .u-header--floating__inner {
    box-shadow: none;
  }
  .u-header--floating-lg .u-header--floating__scrollbar {
    max-height: 50vh;
  }
  .u-header--floating-lg .u-header--floating__scrollbar.mCS_destroyed {
    position: static !important;
  }
  .u-header--floating-lg .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
  .u-header--floating__inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .u-header--floating-xl {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-xl.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-xl.js-header-fix-moment .u-header--floating__inner {
    box-shadow: none;
  }
  .u-header--floating-xl .u-header--floating__scrollbar {
    max-height: 50vh;
  }
  .u-header--floating-xl .u-header--floating__scrollbar.mCS_destroyed {
    position: static !important;
  }
  .u-header--floating-xl .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
  .u-header--floating__inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1480px) {
  .u-header--floating-wd {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-wd.js-header-fix-moment {
    position: fixed;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
    margin-top: 0;
  }
  .u-header--floating-wd.js-header-fix-moment .u-header--floating__inner {
    box-shadow: none;
  }
  .u-header--floating-wd .u-header--floating__scrollbar {
    max-height: 50vh;
  }
  .u-header--floating-wd .u-header--floating__scrollbar.mCS_destroyed {
    position: static !important;
  }
  .u-header--floating-wd .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 24px;
  }
  .u-header--floating__inner {
    border-radius: 0.3125rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 575.98px) {
  .u-header--floating-sm .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-sm .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-header--floating-md .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-md .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-header--floating-lg .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-lg .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .u-header--floating-xl .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-xl .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}

@media (max-width: 1479.98px) {
  .u-header--floating-wd .u-header__section {
    box-shadow: none;
  }
  .u-header--floating-wd .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    display: none;
  }
}

.u-header--floating .u-header__section {
  box-shadow: none;
}

.u-header--floating .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  display: none;
}

/*------------------------------------
  Absolute Positions
------------------------------------*/
.u-header--abs-top, .u-header--abs-bottom, .u-header--abs-top-2nd-screen {
  position: absolute;
}

.u-header--abs-top {
  top: 0;
  bottom: auto;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) {
  top: 100%;
  bottom: auto;
}

.u-header--abs-top-2nd-screen[data-header-fix-effect] {
  transition: none;
}

.u-header--abs-bottom:not(.js-header-fix-moment) {
  top: auto;
  bottom: 0;
}

.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__sub-menu {
  top: auto;
  bottom: 100%;
}

.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
  top: 0;
  bottom: auto;
}

.u-header--abs-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
}

.u-header--abs-bottom:not(.js-header-fix-moment) .slideInUp {
  -webkit-animation-name: absoluteSlideInUp;
          animation-name: absoluteSlideInUp;
}

@media (min-width: 576px) {
  .u-header--abs-top-sm, .u-header--abs-bottom-sm, .u-header--abs-top-2nd-screen-sm {
    position: absolute;
  }
  .u-header--abs-top-sm {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-sm[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .slideInUp {
    -webkit-animation-name: absoluteSlideInUp;
            animation-name: absoluteSlideInUp;
  }
}

@media (min-width: 768px) {
  .u-header--abs-top-md, .u-header--abs-bottom-md, .u-header--abs-top-2nd-screen-md {
    position: absolute;
  }
  .u-header--abs-top-md {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-md[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .slideInUp {
    -webkit-animation-name: absoluteSlideInUp;
            animation-name: absoluteSlideInUp;
  }
}

@media (min-width: 992px) {
  .u-header--abs-top-lg, .u-header--abs-bottom-lg, .u-header--abs-top-2nd-screen-lg {
    position: absolute;
  }
  .u-header--abs-top-lg {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-lg[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .slideInUp {
    -webkit-animation-name: absoluteSlideInUp;
            animation-name: absoluteSlideInUp;
  }
}

@media (min-width: 1200px) {
  .u-header--abs-top-xl, .u-header--abs-bottom-xl, .u-header--abs-top-2nd-screen-xl {
    position: absolute;
  }
  .u-header--abs-top-xl {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-xl[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .slideInUp {
    -webkit-animation-name: absoluteSlideInUp;
            animation-name: absoluteSlideInUp;
  }
}

@media (min-width: 1480px) {
  .u-header--abs-top-wd, .u-header--abs-bottom-wd, .u-header--abs-top-2nd-screen-wd {
    position: absolute;
  }
  .u-header--abs-top-wd {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-wd:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-wd[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-wd:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-bottom-wd:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-bottom-wd:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-bottom-wd:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--abs-bottom-wd:not(.js-header-fix-moment) .slideInUp {
    -webkit-animation-name: absoluteSlideInUp;
            animation-name: absoluteSlideInUp;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu {
    bottom: 52.8%;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .u-header--abs-bottom:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu,
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu {
    bottom: 52.8%;
  }
}

.u-header--abs-top__inner,
.u-header--abs-bottom__inner,
.u-header--abs-top-2nd-screen__inner {
  max-height: 50vh;
}

.u-header--abs-top__inner.mCS_destroyed,
.u-header--abs-bottom__inner.mCS_destroyed,
.u-header--abs-top-2nd-screen__inner.mCS_destroyed {
  position: static !important;
}

[class*="u-header--abs"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 0 24px;
}

/* Disable box-shadow on mobile device */
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none;
}

@-webkit-keyframes absoluteSlideInUp {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
}

@keyframes absoluteSlideInUp {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
}

/*------------------------------------
  Header Sidebar
------------------------------------*/
.u-header-sidebar__footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 10.625rem;
}

.u-header-sidebar__content {
  padding: .6rem 1.5rem 3rem;
}

@media (min-width: 768px) {
  .u-header-sidebar__content {
    padding: .6rem 1.9rem 3rem;
  }
}

.u-header-sidebar__navbar-brand {
  display: block;
  width: 6.25rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}

.u-header-sidebar__footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem;
}

.u-header-sidebar__footer-link {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.75rem;
}

.u-header-sidebar__footer-link:hover {
  color: #fff;
}

/*------------------------------------
  Sticky Positions
------------------------------------*/
.u-header--sticky-top, .u-header--sticky-bottom {
  position: fixed;
}

.u-header--sticky-top {
  bottom: auto;
  top: 0;
}

.u-header--sticky-bottom {
  top: auto;
  bottom: 0;
}

.u-header--sticky-bottom .u-header__sub-menu {
  top: auto;
  bottom: 100%;
}

.u-header--sticky-bottom .u-header__sub-menu .u-header__sub-menu {
  top: 0;
  bottom: auto;
}

.u-header--sticky-bottom .navbar-nav .u-header__sub-menu {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
}

.u-header--sticky-bottom .slideInUp {
  -webkit-animation-name: stickySlideInUp;
          animation-name: stickySlideInUp;
}

@media (min-width: 576px) {
  .u-header--sticky-top-sm, .u-header--sticky-bottom-sm {
    position: fixed;
  }
  .u-header--sticky-top-sm {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-sm {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-sm .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-sm .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--sticky-bottom-sm .navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--sticky-bottom-sm .slideInUp {
    -webkit-animation-name: stickySlideInUp;
            animation-name: stickySlideInUp;
  }
}

@media (min-width: 768px) {
  .u-header--sticky-top-md, .u-header--sticky-bottom-md {
    position: fixed;
  }
  .u-header--sticky-top-md {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-md {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-md .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-md .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--sticky-bottom-md .navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--sticky-bottom-md .slideInUp {
    -webkit-animation-name: stickySlideInUp;
            animation-name: stickySlideInUp;
  }
}

@media (min-width: 992px) {
  .u-header--sticky-top-lg, .u-header--sticky-bottom-lg {
    position: fixed;
  }
  .u-header--sticky-top-lg {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-lg {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-lg .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-lg .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--sticky-bottom-lg .navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--sticky-bottom-lg .slideInUp {
    -webkit-animation-name: stickySlideInUp;
            animation-name: stickySlideInUp;
  }
}

@media (min-width: 1200px) {
  .u-header--sticky-top-xl, .u-header--sticky-bottom-xl {
    position: fixed;
  }
  .u-header--sticky-top-xl {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-xl {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-xl .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-xl .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--sticky-bottom-xl .navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--sticky-bottom-xl .slideInUp {
    -webkit-animation-name: stickySlideInUp;
            animation-name: stickySlideInUp;
  }
}

@media (min-width: 1480px) {
  .u-header--sticky-top-wd, .u-header--sticky-bottom-wd {
    position: fixed;
  }
  .u-header--sticky-top-wd {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-wd {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-wd .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-wd .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
  }
  .u-header--sticky-bottom-wd .navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
  }
  .u-header--sticky-bottom-wd .slideInUp {
    -webkit-animation-name: stickySlideInUp;
            animation-name: stickySlideInUp;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .u-header--sticky-bottom-md:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu {
    bottom: 52.8%;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .u-header--sticky-bottom:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu,
  .u-header--sticky-bottom-sm:not(.js-header-fix-moment) .navbar-expand-md .hs-mega-menu {
    bottom: 52.8%;
  }
}

.u-header--sticky-top__inner,
.u-header--sticky-bottom__inner {
  max-height: 50vh;
}

.u-header--sticky-top__inner.mCS_destroyed,
.u-header--sticky-bottom__inner.mCS_destroyed {
  position: static !important;
}

.u-header--sticky-top__inner .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.u-header--sticky-bottom__inner .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 0 24px;
}

/* Disable box-shadow on mobile device */
.u-header--sticky-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .navbar-nav .u-header__sub-menu {
  box-shadow: none;
}

@-webkit-keyframes stickySlideInUp {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
}

@keyframes stickySlideInUp {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
}

/*------------------------------------
  Header Center Aligned
------------------------------------*/
.u-header--center-aligned .u-hamburger {
  display: none;
}

.u-header--center-aligned .u-header--center-aligned__inner {
  -ms-flex-pack: center;
      justify-content: center;
  text-align: center;
  padding-top: .5rem;
}

.u-header--center-aligned .u-header__navbar-brand {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .u-header--center-aligned-sm .u-hamburger {
    display: none;
  }
  .u-header--center-aligned-sm .u-header--center-aligned__inner {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
  .u-header--center-aligned-sm .u-header__navbar-brand {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .u-header--center-aligned-md .u-hamburger {
    display: none;
  }
  .u-header--center-aligned-md .u-header--center-aligned__inner {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
  .u-header--center-aligned-md .u-header__navbar-brand {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .u-header--center-aligned-lg .u-hamburger {
    display: none;
  }
  .u-header--center-aligned-lg .u-header--center-aligned__inner {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
  .u-header--center-aligned-lg .u-header__navbar-brand {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .u-header--center-aligned-xl .u-hamburger {
    display: none;
  }
  .u-header--center-aligned-xl .u-header--center-aligned__inner {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
  .u-header--center-aligned-xl .u-header__navbar-brand {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1480px) {
  .u-header--center-aligned-wd .u-hamburger {
    display: none;
  }
  .u-header--center-aligned-wd .u-header--center-aligned__inner {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
  .u-header--center-aligned-wd .u-header__navbar-brand {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 575.98px) {
  .u-header--center-aligned-sm .u-header--center-aligned__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-top: 0.25rem;
  }
  .u-header--center-aligned-sm .u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 767.98px) {
  .u-header--center-aligned-md .u-header--center-aligned__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-top: 0.25rem;
  }
  .u-header--center-aligned-md .u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 991.98px) {
  .u-header--center-aligned-lg .u-header--center-aligned__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-top: 0.25rem;
  }
  .u-header--center-aligned-lg .u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 1199.98px) {
  .u-header--center-aligned-xl .u-header--center-aligned__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-top: 0.25rem;
  }
  .u-header--center-aligned-xl .u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 1479.98px) {
  .u-header--center-aligned-wd .u-header--center-aligned__inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-top: 0.25rem;
  }
  .u-header--center-aligned-wd .u-header__navbar {
    padding-top: 0;
  }
}

.u-header--center-aligned .u-header--center-aligned__inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-top: 0.25rem;
}

.u-header--center-aligned .u-header__navbar {
  padding-top: 0;
}

/*------------------------------------
  Header Collapse
------------------------------------*/
.u-header-collapse__nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.u-header-collapse__nav-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.u-header-collapse__nav-link {
  color: #3b444f;
}

.u-header-collapse__nav-pointer {
  position: relative;
}

.u-header-collapse__nav-pointer::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 65%;
  font-weight: 900;
  content: "\f107";
  margin-left: auto;
  transition: 0.3s ease;
}

.u-header-collapse__nav-pointer.active::after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.u-header-collapse__nav-link, .u-header-collapse__submenu-nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.u-header-collapse__nav-link.active, .u-header-collapse__submenu-nav-link.active {
  color: #297cbb;
}

.u-header-collapse__submenu {
  position: relative;
  transition: 0.3s ease-in-out;
}

.u-header-collapse__submenu .u-header-collapse__nav-list {
  border-left: 2px solid #297cbb;
  padding-left: 1rem;
}

.u-header-collapse__submenu .u-header-collapse__nav-list--no-spacer {
  padding-left: 0;
}

.u-header-collapse__submenu-nav-link {
  color: #77838f;
}

.u-header-collapse__submenu-list {
  list-style: none;
  border-left: 2px solid #297cbb;
  padding-left: 0;
  margin-bottom: 0;
}

.u-header-collapse__submenu-list-link {
  display: inline-block;
  color: #77838f;
}

/*-------------------------------------------*\
    2.6. Alert
\*-------------------------------------------*/
.alert-text {
  color: inherit;
}

/*-------------------------------------------*\
    2.7. Avatar
\*-------------------------------------------*/
.u-avatar {
  width: 3.125rem;
  height: 3.125rem;
}

.u-avatar-image {
  position: relative;
}

.u-avatar-image-overlay {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.u-avatar-image-social {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.u-avatar-image:hover .u-avatar-image-overlay {
  background-color: #297cbb;
  opacity: 0.851;
  transition: all 0.5s ease-in-out;
}

.u-xs-avatar {
  width: 1.375rem;
  height: 1.375rem;
}

.u-sm-avatar {
  width: 2rem;
  height: 2rem;
}

.u-lg-avatar {
  width: 5rem;
  height: 5rem;
}

@media (min-width: 576px) {
  .u-lg-avatar-sm {
    width: 5rem;
    height: 5rem;
  }
}

@media (min-width: 768px) {
  .u-lg-avatar-md {
    width: 5rem;
    height: 5rem;
  }
}

@media (min-width: 992px) {
  .u-lg-avatar-lg {
    width: 5rem;
    height: 5rem;
  }
}

@media (min-width: 1200px) {
  .u-lg-avatar-xl {
    width: 5rem;
    height: 5rem;
  }
}

@media (min-width: 1480px) {
  .u-lg-avatar-wd {
    width: 5rem;
    height: 5rem;
  }
}

.u-xl-avatar {
  width: 10rem;
  height: 10rem;
}

/*------------------------------------
  Avatar Bordered
------------------------------------*/
.u-sm-avatar--bordered {
  border: 2px solid #fff;
}

.u-xl-avatar--bordered {
  border: 7px solid #fff;
}

/*-------------------------------------------*\
    2.8. Badges
\*-------------------------------------------*/
[class*="badge-"] {
  z-index: 3;
}

.badge:empty {
  display: inherit;
}

.badge.badge-pos {
  position: absolute;
}

.btn .badge-pos {
  top: auto;
}

[class*="badge-outline"] {
  border-style: solid;
  border-width: 0.125rem;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}

/*------------------------------------
  Badge Sizes
------------------------------------*/
/* Extra Small */
.badge-xs {
  width: 0.875rem;
  height: 0.875rem;
  line-height: 1.4;
  font-size: 0.5rem;
}

/* Small */
.badge-sm {
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.4;
  font-size: 0.625rem;
}

.badge-sm[class*="badge-outline"] {
  line-height: 1;
}

/* Medium */
.badge-md {
  width: 1.375rem;
  height: 1.375rem;
  line-height: 1.7;
  font-size: 0.625rem;
}

.badge-md[class*="badge-outline"] {
  line-height: 1.3;
}

/* Bigger */
.badge-bigger {
  padding: 0.625rem;
}

/*------------------------------------
  Badge Styles
------------------------------------*/
/* primary badge */
.badge-outline-primary {
  color: #fff;
  background: #297cbb;
  border-color: #fff;
}

/* secondary badge */
.badge-outline-secondary {
  color: #fff;
  background: #77838f;
  border-color: #fff;
}

/* success badge */
.badge-outline-success {
  color: #fff;
  background: #00c9a7;
  border-color: #fff;
}

/* info badge */
.badge-outline-info {
  color: #3b444f;
  background: #00dffc;
  border-color: #fff;
}

/* warning badge */
.badge-outline-warning {
  color: #3b444f;
  background: #ffc107;
  border-color: #fff;
}

/* danger badge */
.badge-outline-danger {
  color: #fff;
  background: #de4437;
  border-color: #fff;
}

/* light badge */
.badge-outline-light {
  color: #3b444f;
  background: #f8f9fa;
  border-color: #fff;
}

/* dark badge */
.badge-outline-dark {
  color: #fff;
  background: #3b444f;
  border-color: #fff;
}

/* white badge */
.badge-outline-white {
  color: #3b444f;
  background: #fff;
  border-color: #fff;
}

/* indigo badge */
.badge-outline-indigo {
  color: #fff;
  background: #2d1582;
  border-color: #fff;
}

/* gray badge */
.badge-outline-gray {
  color: #3b444f;
  background: #ebf0f7;
  border-color: #fff;
}

/* violet badge */
.badge-outline-violet {
  color: #fff;
  background: #532184;
  border-color: #fff;
}

/* violet-1 badge */
.badge-outline-violet-1 {
  color: #fff;
  background: #a864a8;
  border-color: #fff;
}

/* violet-light badge */
.badge-outline-violet-light {
  color: #fff;
  background: #6f13cd;
  border-color: #fff;
}

/* brown-light badge */
.badge-outline-brown-light {
  color: #3b444f;
  background: #ceac61;
  border-color: #fff;
}

/* brown badge */
.badge-outline-brown {
  color: #3b444f;
  background: #f8bd5b;
  border-color: #fff;
}

/* maroon badge */
.badge-outline-maroon {
  color: #fff;
  background: #c72f74;
  border-color: #fff;
}

/* indigo-light badge */
.badge-outline-indigo-light {
  color: #fff;
  background: #080e7b;
  border-color: #fff;
}

/* red-light-2 badge */
.badge-outline-red-light-2 {
  color: #fff;
  background: #ff3c4e;
  border-color: #fff;
}

/* blue-dark-1 badge */
.badge-outline-blue-dark-1 {
  color: #fff;
  background: #045cff;
  border-color: #fff;
}

/* gray-1 badge */
.badge-outline-gray-1 {
  color: #fff;
  background: #67747c;
  border-color: #fff;
}

/* gray-2 badge */
.badge-outline-gray-2 {
  color: #3b444f;
  background: #f7f8f9;
  border-color: #fff;
}

/* gray-3 badge */
.badge-outline-gray-3 {
  color: #fff;
  background: #3b444f;
  border-color: #fff;
}

/* gray-4 badge */
.badge-outline-gray-4 {
  color: #3b444f;
  background: #eceff3;
  border-color: #fff;
}

/* blue badge */
.badge-outline-blue {
  color: #3b444f;
  background: #43c5db;
  border-color: #fff;
}

/* blue-1 badge */
.badge-outline-blue-1 {
  color: #fff;
  background: #297cbb;
  border-color: #fff;
}

/* gray-5 badge */
.badge-outline-gray-5 {
  color: #fff;
  background: #18181b;
  border-color: #fff;
}

/* gray-6 badge */
.badge-outline-gray-6 {
  color: #fff;
  background: #18181a;
  border-color: #fff;
}

/* gray-7 badge */
.badge-outline-gray-7 {
  color: #fff;
  background: #5c6770;
  border-color: #fff;
}

/* gray-8 badge */
.badge-outline-gray-8 {
  color: #3b444f;
  background: #c5cdd8;
  border-color: #fff;
}

/* gray-9 badge */
.badge-outline-gray-9 {
  color: #3b444f;
  background: #9fa9b8;
  border-color: #fff;
}

/* gray-10 badge */
.badge-outline-gray-10 {
  color: #3b444f;
  background: #c1c1c1;
  border-color: #fff;
}

/* gray-11 badge */
.badge-outline-gray-11 {
  color: #fff;
  background: #484848;
  border-color: #fff;
}

/* gray-23 badge */
.badge-outline-gray-23 {
  color: #3b444f;
  background: #f4f4f4;
  border-color: #fff;
}

/* gray-33 badge */
.badge-outline-gray-33 {
  color: #3b444f;
  background: #ebf0f7;
  border-color: #fff;
}

/* sea-green badge */
.badge-outline-sea-green {
  color: #fff;
  background: #1d508d;
  border-color: #fff;
}

/* dark-1 badge */
.badge-outline-dark-1 {
  color: #fff;
  background: #1e2022;
  border-color: #fff;
}

/* navy badge */
.badge-outline-navy {
  color: #fff;
  background: #0057ff;
  border-color: #fff;
}

/* pink badge */
.badge-outline-pink {
  color: #3b444f;
  background: #ffe5ee;
  border-color: #fff;
}

/* dark-2 badge */
.badge-outline-dark-2 {
  color: #fff;
  background: #1a2b49;
  border-color: #fff;
}

/* yellow-lighter-2 badge */
.badge-outline-yellow-lighter-2 {
  color: #3b444f;
  background: #f7bc5a;
  border-color: #fff;
}

/* orange badge */
.badge-outline-orange {
  color: #fff;
  background: #f05700;
  border-color: #fff;
}

/* green badge */
.badge-outline-green {
  color: #fff;
  background: #28871c;
  border-color: #fff;
}

/* pink-1 badge */
.badge-outline-pink-1 {
  color: #fff;
  background: #ff0045;
  border-color: #fff;
}

/* purple-1 badge */
.badge-outline-purple-1 {
  color: #fff;
  background: #232753;
  border-color: #fff;
}

.badge-outline-white {
  border-color: #fff;
}

/*------------------------------------
  Badge Positions
------------------------------------*/
.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.badge-pos--top-left {
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.badge-pos--bottom-left {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}

.badge-pos--bottom-right {
  bottom: 0;
  right: 0;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
}

/*------------------------------------
  Badge in Avatar Component
------------------------------------*/
/* Default Size Avatar */
.u-avatar .rounded-circle + .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(20%, -20%);
          transform: translate(20%, -20%);
}

.u-avatar .rounded-circle + .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(-20%, -20%);
          transform: translate(-20%, -20%);
}

.u-avatar .rounded-circle + .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(-20%, 20%);
          transform: translate(-20%, 20%);
}

.u-avatar .rounded-circle + .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(20%, 20%);
          transform: translate(20%, 20%);
}

.u-avatar .rounded-circle + .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(25%, -25%);
          transform: translate(25%, -25%);
}

.u-avatar .rounded-circle + .badge-sm.badge-pos--top-left {
  -webkit-transform: translate(-25%, -25%);
          transform: translate(-25%, -25%);
}

.u-avatar .rounded-circle + .badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(-25%, 25%);
          transform: translate(-25%, 25%);
}

.u-avatar .rounded-circle + .badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(25%, 25%);
          transform: translate(25%, 25%);
}

.u-avatar .rounded-circle + .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(30%, -30%);
          transform: translate(30%, -30%);
}

.u-avatar .rounded-circle + .badge-md.badge-pos--top-left {
  -webkit-transform: translate(-30%, -30%);
          transform: translate(-30%, -30%);
}

.u-avatar .rounded-circle + .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(-30%, 30%);
          transform: translate(-30%, 30%);
}

.u-avatar .rounded-circle + .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(30%, 30%);
          transform: translate(30%, 30%);
}

/* Small Size Avatar */
.u-sm-avatar .rounded-circle + .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(25%, -25%);
          transform: translate(25%, -25%);
}

.u-sm-avatar .rounded-circle + .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(-25%, -25%);
          transform: translate(-25%, -25%);
}

.u-sm-avatar .rounded-circle + .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(-25%, 25%);
          transform: translate(-25%, 25%);
}

.u-sm-avatar .rounded-circle + .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(25%, 25%);
          transform: translate(25%, 25%);
}

.u-sm-avatar .rounded-circle + .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]),
.u-sm-avatar .rounded-circle + .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(35%, -35%);
          transform: translate(35%, -35%);
}

.u-sm-avatar .rounded-circle + .badge-sm.badge-pos--top-left,
.u-sm-avatar .rounded-circle + .badge-md.badge-pos--top-left {
  -webkit-transform: translate(-35%, -35%);
          transform: translate(-35%, -35%);
}

.u-sm-avatar .rounded-circle + .badge-sm.badge-pos--bottom-left,
.u-sm-avatar .rounded-circle + .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(-35%, 35%);
          transform: translate(-35%, 35%);
}

.u-sm-avatar .rounded-circle + .badge-sm.badge-pos--bottom-right,
.u-sm-avatar .rounded-circle + .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(35%, 35%);
          transform: translate(35%, 35%);
}

/* Large Size Avatar */
.u-lg-avatar .rounded-circle + .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-35%, 35%);
          transform: translate(-35%, 35%);
}

.u-lg-avatar .rounded-circle + .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(35%, 35%);
          transform: translate(35%, 35%);
}

.u-lg-avatar .rounded-circle + .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(35%, -35%);
          transform: translate(35%, -35%);
}

.u-lg-avatar .rounded-circle + .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(-35%, -35%);
          transform: translate(-35%, -35%);
}

.u-lg-avatar .rounded-circle + .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-15%, 15%);
          transform: translate(-15%, 15%);
}

.u-lg-avatar .rounded-circle + .badge-sm.badge-pos--top-left {
  -webkit-transform: translate(15%, 15%);
          transform: translate(15%, 15%);
}

.u-lg-avatar .rounded-circle + .badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(15%, -15%);
          transform: translate(15%, -15%);
}

.u-lg-avatar .rounded-circle + .badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(-15%, -15%);
          transform: translate(-15%, -15%);
}

.u-lg-avatar .rounded-circle + .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

.u-lg-avatar .rounded-circle + .badge-md.badge-pos--top-left {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

.u-lg-avatar .rounded-circle + .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

.u-lg-avatar .rounded-circle + .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

/* Extra Large Size Avatar */
.u-xl-avatar .rounded-circle + .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-100%, 100%);
          transform: translate(-100%, 100%);
}

.u-xl-avatar .rounded-circle + .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(100%, 100%);
          transform: translate(100%, 100%);
}

.u-xl-avatar .rounded-circle + .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(100%, -100%);
          transform: translate(100%, -100%);
}

.u-xl-avatar .rounded-circle + .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(-100%, -100%);
          transform: translate(-100%, -100%);
}

.u-xl-avatar .rounded-circle + .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-65%, 65%);
          transform: translate(-65%, 65%);
}

.u-xl-avatar .rounded-circle + .badge-sm.badge-pos--top-left {
  -webkit-transform: translate(65%, 65%);
          transform: translate(65%, 65%);
}

.u-xl-avatar .rounded-circle + .badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(65%, -65%);
          transform: translate(65%, -65%);
}

.u-xl-avatar .rounded-circle + .badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(-65%, -65%);
          transform: translate(-65%, -65%);
}

.u-xl-avatar .rounded-circle + .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-45%, 45%);
          transform: translate(-45%, 45%);
}

.u-xl-avatar .rounded-circle + .badge-md.badge-pos--top-left {
  -webkit-transform: translate(45%, 45%);
          transform: translate(45%, 45%);
}

.u-xl-avatar .rounded-circle + .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(45%, -45%);
          transform: translate(45%, -45%);
}

.u-xl-avatar .rounded-circle + .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(-45%, -45%);
          transform: translate(-45%, -45%);
}

/*------------------------------------
  Badge in Icon Component
------------------------------------*/
/* Default Size Icon */
.btn-icon.rounded-circle .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(20%, -20%);
          transform: translate(20%, -20%);
}

.btn-icon.rounded-circle .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(-20%, -20%);
          transform: translate(-20%, -20%);
}

.btn-icon.rounded-circle .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(-20%, 20%);
          transform: translate(-20%, 20%);
}

.btn-icon.rounded-circle .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(20%, 20%);
          transform: translate(20%, 20%);
}

.btn-icon.rounded-circle .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(25%, -25%);
          transform: translate(25%, -25%);
}

.btn-icon.rounded-circle .badge-sm.badge-pos--top-left {
  -webkit-transform: translate(-25%, -25%);
          transform: translate(-25%, -25%);
}

.btn-icon.rounded-circle .badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(-25%, 25%);
          transform: translate(-25%, 25%);
}

.btn-icon.rounded-circle .badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(25%, 25%);
          transform: translate(25%, 25%);
}

.btn-icon.rounded-circle .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(30%, -30%);
          transform: translate(30%, -30%);
}

.btn-icon.rounded-circle .badge-md.badge-pos--top-left {
  -webkit-transform: translate(-30%, -30%);
          transform: translate(-30%, -30%);
}

.btn-icon.rounded-circle .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(-30%, 30%);
          transform: translate(-30%, 30%);
}

.btn-icon.rounded-circle .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(30%, 30%);
          transform: translate(30%, 30%);
}

/* Small Size Icon */
.btn-sm.rounded-circle .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-sm > .rounded-circle.btn .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(25%, -25%);
          transform: translate(25%, -25%);
}

.btn-sm.rounded-circle .badge-xs.badge-pos--top-left, .btn-group-sm > .rounded-circle.btn .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(-25%, -25%);
          transform: translate(-25%, -25%);
}

.btn-sm.rounded-circle .badge-xs.badge-pos--bottom-left, .btn-group-sm > .rounded-circle.btn .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(-25%, 25%);
          transform: translate(-25%, 25%);
}

.btn-sm.rounded-circle .badge-xs.badge-pos--bottom-right, .btn-group-sm > .rounded-circle.btn .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(25%, 25%);
          transform: translate(25%, 25%);
}

.btn-sm.rounded-circle .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-sm > .rounded-circle.btn .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]),
.btn-sm.rounded-circle .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]),
.btn-group-sm > .rounded-circle.btn .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(35%, -35%);
          transform: translate(35%, -35%);
}

.btn-sm.rounded-circle .badge-sm.badge-pos--top-left, .btn-group-sm > .rounded-circle.btn .badge-sm.badge-pos--top-left,
.btn-sm.rounded-circle .badge-md.badge-pos--top-left,
.btn-group-sm > .rounded-circle.btn .badge-md.badge-pos--top-left {
  -webkit-transform: translate(-35%, -35%);
          transform: translate(-35%, -35%);
}

.btn-sm.rounded-circle .badge-sm.badge-pos--bottom-left, .btn-group-sm > .rounded-circle.btn .badge-sm.badge-pos--bottom-left,
.btn-sm.rounded-circle .badge-md.badge-pos--bottom-left,
.btn-group-sm > .rounded-circle.btn .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(-35%, 35%);
          transform: translate(-35%, 35%);
}

.btn-sm.rounded-circle .badge-sm.badge-pos--bottom-right, .btn-group-sm > .rounded-circle.btn .badge-sm.badge-pos--bottom-right,
.btn-sm.rounded-circle .badge-md.badge-pos--bottom-right,
.btn-group-sm > .rounded-circle.btn .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(35%, 35%);
          transform: translate(35%, 35%);
}

/* Large Size Icon */
.btn-lg.rounded-circle .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-lg > .rounded-circle.btn .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-35%, 35%);
          transform: translate(-35%, 35%);
}

.btn-lg.rounded-circle .badge-xs.badge-pos--top-left, .btn-group-lg > .rounded-circle.btn .badge-xs.badge-pos--top-left {
  -webkit-transform: translate(35%, 35%);
          transform: translate(35%, 35%);
}

.btn-lg.rounded-circle .badge-xs.badge-pos--bottom-left, .btn-group-lg > .rounded-circle.btn .badge-xs.badge-pos--bottom-left {
  -webkit-transform: translate(35%, -35%);
          transform: translate(35%, -35%);
}

.btn-lg.rounded-circle .badge-xs.badge-pos--bottom-right, .btn-group-lg > .rounded-circle.btn .badge-xs.badge-pos--bottom-right {
  -webkit-transform: translate(-35%, -35%);
          transform: translate(-35%, -35%);
}

.btn-lg.rounded-circle .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-lg > .rounded-circle.btn .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(-15%, 15%);
          transform: translate(-15%, 15%);
}

.btn-lg.rounded-circle .badge-sm.badge-pos--top-left, .btn-group-lg > .rounded-circle.btn .badge-sm.badge-pos--top-left {
  -webkit-transform: translate(15%, 15%);
          transform: translate(15%, 15%);
}

.btn-lg.rounded-circle .badge-sm.badge-pos--bottom-left, .btn-group-lg > .rounded-circle.btn .badge-sm.badge-pos--bottom-left {
  -webkit-transform: translate(15%, -15%);
          transform: translate(15%, -15%);
}

.btn-lg.rounded-circle .badge-sm.badge-pos--bottom-right, .btn-group-lg > .rounded-circle.btn .badge-sm.badge-pos--bottom-right {
  -webkit-transform: translate(-15%, -15%);
          transform: translate(-15%, -15%);
}

.btn-lg.rounded-circle .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-lg > .rounded-circle.btn .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

.btn-lg.rounded-circle .badge-md.badge-pos--top-left, .btn-group-lg > .rounded-circle.btn .badge-md.badge-pos--top-left {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

.btn-lg.rounded-circle .badge-md.badge-pos--bottom-left, .btn-group-lg > .rounded-circle.btn .badge-md.badge-pos--bottom-left {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

.btn-lg.rounded-circle .badge-md.badge-pos--bottom-right, .btn-group-lg > .rounded-circle.btn .badge-md.badge-pos--bottom-right {
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
}

/*-------------------------------------------*\
    2.9. Buttons
\*-------------------------------------------*/
/*------------------------------------
  Buttons Bootstrap Style
------------------------------------*/
/* facebook */
.btn-facebook {
  color: #fff;
  background-color: #395899;
  border-color: #395899;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #2f487d;
  border-color: #2b4374;
}

.btn-facebook:focus, .btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 113, 168, 0.5);
}

.btn-facebook.disabled, .btn-facebook:disabled {
  color: #fff;
  background-color: #395899;
  border-color: #395899;
}

.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2b4374;
  border-color: #283d6b;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 113, 168, 0.5);
}

/* google */
.btn-google {
  color: #fff;
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:hover {
  color: #fff;
  background-color: #b33628;
  border-color: #a93325;
}

.btn-google:focus, .btn-google.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 94, 79, 0.5);
}

.btn-google.disabled, .btn-google:disabled {
  color: #fff;
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
.show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #a93325;
  border-color: #9e3023;
}

.btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 94, 79, 0.5);
}

/* twitter */
.btn-twitter {
  color: #fff;
  background-color: #03a9e0;
  border-color: #03a9e0;
}

.btn-twitter:hover {
  color: #fff;
  background-color: #028dba;
  border-color: #0283ae;
}

.btn-twitter:focus, .btn-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 182, 229, 0.5);
}

.btn-twitter.disabled, .btn-twitter:disabled {
  color: #fff;
  background-color: #03a9e0;
  border-color: #03a9e0;
}

.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0283ae;
  border-color: #027aa1;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 182, 229, 0.5);
}

/* instagram */
.btn-instagram {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:hover {
  color: #fff;
  background-color: #345e80;
  border-color: #305777;
}

.btn-instagram:focus, .btn-instagram.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 135, 170, 0.5);
}

.btn-instagram.disabled, .btn-instagram:disabled {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #305777;
  border-color: #2d516e;
}

.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 135, 170, 0.5);
}

/* github */
.btn-github {
  color: #fff;
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:hover {
  color: #fff;
  background-color: #131619;
  border-color: #0e1011;
}

.btn-github:focus, .btn-github.focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 73, 77, 0.5);
}

.btn-github.disabled, .btn-github:disabled {
  color: #fff;
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #0e1011;
  border-color: #08090a;
}

.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 73, 77, 0.5);
}

/* gray */
.btn-gray {
  color: #3b444f;
  background-color: #f8fafd;
  border-color: #f8fafd;
}

.btn-gray:hover {
  color: #3b444f;
  background-color: #dae5f5;
  border-color: #d0def2;
}

.btn-gray:focus, .btn-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 223, 227, 0.5);
}

.btn-gray.disabled, .btn-gray:disabled {
  color: #3b444f;
  background-color: #f8fafd;
  border-color: #f8fafd;
}

.btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: #3b444f;
  background-color: #d0def2;
  border-color: #c6d7ef;
}

.btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 223, 227, 0.5);
}

/* purple */
.btn-purple {
  color: #fff;
  background-color: #8023df;
  border-color: #8023df;
}

.btn-purple:hover {
  color: #fff;
  background-color: #6d1cc0;
  border-color: #671ab5;
}

.btn-purple:focus, .btn-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 68, 228, 0.5);
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #8023df;
  border-color: #8023df;
}

.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #671ab5;
  border-color: #6019aa;
}

.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 68, 228, 0.5);
}

/* blue */
.btn-blue {
  color: #3b444f;
  background-color: #4cbfd8;
  border-color: #4cbfd8;
}

.btn-blue:hover {
  color: #fff;
  background-color: #2db3d0;
  border-color: #2baac6;
}

.btn-blue:focus, .btn-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 173, 195, 0.5);
}

.btn-blue.disabled, .btn-blue:disabled {
  color: #3b444f;
  background-color: #4cbfd8;
  border-color: #4cbfd8;
}

.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #2baac6;
  border-color: #29a1bb;
}

.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 173, 195, 0.5);
}

/* violet */
.btn-violet {
  color: #fff;
  background-color: #8c689d;
  border-color: #8c689d;
}

.btn-violet:hover {
  color: #fff;
  background-color: #785887;
  border-color: #71537f;
}

.btn-violet:focus, .btn-violet.focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 127, 172, 0.5);
}

.btn-violet.disabled, .btn-violet:disabled {
  color: #fff;
  background-color: #8c689d;
  border-color: #8c689d;
}

.btn-violet:not(:disabled):not(.disabled):active, .btn-violet:not(:disabled):not(.disabled).active,
.show > .btn-violet.dropdown-toggle {
  color: #fff;
  background-color: #71537f;
  border-color: #6a4e78;
}

.btn-violet:not(:disabled):not(.disabled):active:focus, .btn-violet:not(:disabled):not(.disabled).active:focus,
.show > .btn-violet.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(157, 127, 172, 0.5);
}

/* coral */
.btn-coral {
  color: #3b444f;
  background-color: #ff8480;
  border-color: #ff8480;
}

.btn-coral:hover {
  color: #fff;
  background-color: #ff5f5a;
  border-color: #ff534d;
}

.btn-coral:focus, .btn-coral.focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 122, 121, 0.5);
}

.btn-coral.disabled, .btn-coral:disabled {
  color: #3b444f;
  background-color: #ff8480;
  border-color: #ff8480;
}

.btn-coral:not(:disabled):not(.disabled):active, .btn-coral:not(:disabled):not(.disabled).active,
.show > .btn-coral.dropdown-toggle {
  color: #fff;
  background-color: #ff534d;
  border-color: #ff4640;
}

.btn-coral:not(:disabled):not(.disabled):active:focus, .btn-coral:not(:disabled):not(.disabled).active:focus,
.show > .btn-coral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 122, 121, 0.5);
}

/* yellow-light */
.btn-yellow-light {
  color: #3b444f;
  background-color: #ffb874;
  border-color: #ffb874;
}

.btn-yellow-light:hover {
  color: #3b444f;
  background-color: #ffa44e;
  border-color: #ff9e41;
}

.btn-yellow-light:focus, .btn-yellow-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 167, 110, 0.5);
}

.btn-yellow-light.disabled, .btn-yellow-light:disabled {
  color: #3b444f;
  background-color: #ffb874;
  border-color: #ffb874;
}

.btn-yellow-light:not(:disabled):not(.disabled):active, .btn-yellow-light:not(:disabled):not(.disabled).active,
.show > .btn-yellow-light.dropdown-toggle {
  color: #3b444f;
  background-color: #ff9e41;
  border-color: #ff9734;
}

.btn-yellow-light:not(:disabled):not(.disabled):active:focus, .btn-yellow-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-yellow-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 167, 110, 0.5);
}

/* green-light */
.btn-green-light {
  color: #fff;
  background-color: #35c2a5;
  border-color: #35c2a5;
}

.btn-green-light:hover {
  color: #fff;
  background-color: #2da48b;
  border-color: #2a9a83;
}

.btn-green-light:focus, .btn-green-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 203, 179, 0.5);
}

.btn-green-light.disabled, .btn-green-light:disabled {
  color: #fff;
  background-color: #35c2a5;
  border-color: #35c2a5;
}

.btn-green-light:not(:disabled):not(.disabled):active, .btn-green-light:not(:disabled):not(.disabled).active,
.show > .btn-green-light.dropdown-toggle {
  color: #fff;
  background-color: #2a9a83;
  border-color: #27907a;
}

.btn-green-light:not(:disabled):not(.disabled):active:focus, .btn-green-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-green-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(83, 203, 179, 0.5);
}

/* blue-1 */
.btn-blue-1 {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-blue-1:hover {
  color: #fff;
  background-color: #004ad9;
  border-color: #0046cc;
}

.btn-blue-1:focus, .btn-blue-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
}

.btn-blue-1.disabled, .btn-blue-1:disabled {
  color: #fff;
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-blue-1:not(:disabled):not(.disabled):active, .btn-blue-1:not(:disabled):not(.disabled).active,
.show > .btn-blue-1.dropdown-toggle {
  color: #fff;
  background-color: #0046cc;
  border-color: #0041bf;
}

.btn-blue-1:not(:disabled):not(.disabled):active:focus, .btn-blue-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 112, 255, 0.5);
}

/* gray-1 */
.btn-gray-1 {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-gray-1:hover {
  color: #3b444f;
  background-color: #d0dcec;
  border-color: #c7d5e8;
}

.btn-gray-1:focus, .btn-gray-1.focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 214, 222, 0.5);
}

.btn-gray-1.disabled, .btn-gray-1:disabled {
  color: #3b444f;
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-gray-1:not(:disabled):not(.disabled):active, .btn-gray-1:not(:disabled):not(.disabled).active,
.show > .btn-gray-1.dropdown-toggle {
  color: #3b444f;
  background-color: #c7d5e8;
  border-color: #bdcee5;
}

.btn-gray-1:not(:disabled):not(.disabled):active:focus, .btn-gray-1:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-1.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 214, 222, 0.5);
}

/*------------------------------------
  Custom Buttons
------------------------------------*/
/* primary button :hover, :focus, :active effects */
.btn-primary[href]:hover, .u-header.js-header-fix-moment .u-header__last-item-btn .btn[href]:hover[class*="-white"], .btn-primary[href]:focus, .u-header.js-header-fix-moment .u-header__last-item-btn .btn[href]:focus[class*="-white"], .btn-primary[href]:active, .u-header.js-header-fix-moment .u-header__last-item-btn .btn[href]:active[class*="-white"], .btn-primary[type]:hover, .u-header.js-header-fix-moment .u-header__last-item-btn .btn[type]:hover[class*="-white"], .btn-primary[type]:focus, .u-header.js-header-fix-moment .u-header__last-item-btn .btn[type]:focus[class*="-white"], .btn-primary[type]:active, .u-header.js-header-fix-moment .u-header__last-item-btn .btn[type]:active[class*="-white"] {
  box-shadow: 0 4px 11px rgba(41, 124, 187, 0.35);
}

.btn-primary:not(label.btn), .u-header.js-header-fix-moment .u-header__last-item-btn .btn:not(label.btn)[class*="-white"], .btn-primary:not(label.btn):not([href]):not(:disabled):not(.disabled), .u-header.js-header-fix-moment .u-header__last-item-btn .btn:not(label.btn):not([href]):not(:disabled):not(.disabled)[class*="-white"], .btn-primary:not([href]), .u-header.js-header-fix-moment .u-header__last-item-btn .btn:not([href])[class*="-white"], .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled), .u-header.js-header-fix-moment .u-header__last-item-btn .btn:not([href]):not([href]):not(:disabled):not(.disabled)[class*="-white"] {
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-primary:hover, .u-header.js-header-fix-moment .u-header__last-item-btn .btn:hover[class*="-white"] {
  background-color: #297cbb;
  border-color: #297cbb;
}

/* secondary button :hover, :focus, :active effects */
.btn-secondary[href]:hover, .btn-secondary[href]:focus, .btn-secondary[href]:active, .btn-secondary[type]:hover, .btn-secondary[type]:focus, .btn-secondary[type]:active {
  box-shadow: 0 4px 11px rgba(119, 131, 143, 0.35);
}

.btn-secondary:not(label.btn), .btn-secondary:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-secondary:not([href]), .btn-secondary:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #77838f;
  border-color: #77838f;
}

.btn-secondary:hover {
  background-color: #77838f;
  border-color: #77838f;
}

/* success button :hover, :focus, :active effects */
.btn-success[href]:hover, .btn-success[href]:focus, .btn-success[href]:active, .btn-success[type]:hover, .btn-success[type]:focus, .btn-success[type]:active {
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
}

.btn-success:not(label.btn), .btn-success:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-success:not([href]), .btn-success:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #00c9a7;
  border-color: #00c9a7;
}

.btn-success:hover {
  background-color: #00c9a7;
  border-color: #00c9a7;
}

/* info button :hover, :focus, :active effects */
.btn-info[href]:hover, .btn-info[href]:focus, .btn-info[href]:active, .btn-info[type]:hover, .btn-info[type]:focus, .btn-info[type]:active {
  box-shadow: 0 4px 11px rgba(0, 223, 252, 0.35);
}

.btn-info:not(label.btn), .btn-info:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-info:not([href]), .btn-info:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-info:hover {
  background-color: #00dffc;
  border-color: #00dffc;
}

/* warning button :hover, :focus, :active effects */
.btn-warning[href]:hover, .btn-warning[href]:focus, .btn-warning[href]:active, .btn-warning[type]:hover, .btn-warning[type]:focus, .btn-warning[type]:active {
  box-shadow: 0 4px 11px rgba(255, 193, 7, 0.35);
}

.btn-warning:not(label.btn), .btn-warning:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-warning:not([href]), .btn-warning:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  background-color: #ffc107;
  border-color: #ffc107;
}

/* danger button :hover, :focus, :active effects */
.btn-danger[href]:hover, .btn-danger[href]:focus, .btn-danger[href]:active, .btn-danger[type]:hover, .btn-danger[type]:focus, .btn-danger[type]:active {
  box-shadow: 0 4px 11px rgba(222, 68, 55, 0.35);
}

.btn-danger:not(label.btn), .btn-danger:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-danger:not([href]), .btn-danger:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #de4437;
  border-color: #de4437;
}

.btn-danger:hover {
  background-color: #de4437;
  border-color: #de4437;
}

/* light button :hover, :focus, :active effects */
.btn-light[href]:hover, .btn-light[href]:focus, .btn-light[href]:active, .btn-light[type]:hover, .btn-light[type]:focus, .btn-light[type]:active {
  box-shadow: 0 4px 11px rgba(248, 249, 250, 0.35);
}

.btn-light:not(label.btn), .btn-light:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-light:not([href]), .btn-light:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

/* dark button :hover, :focus, :active effects */
.btn-dark[href]:hover, .btn-dark[href]:focus, .btn-dark[href]:active, .btn-dark[type]:hover, .btn-dark[type]:focus, .btn-dark[type]:active {
  box-shadow: 0 4px 11px rgba(59, 68, 79, 0.35);
}

.btn-dark:not(label.btn), .btn-dark:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-dark:not([href]), .btn-dark:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-dark:hover {
  background-color: #3b444f;
  border-color: #3b444f;
}

/* white button :hover, :focus, :active effects */
.btn-white[href]:hover, .btn-white[href]:focus, .btn-white[href]:active, .btn-white[type]:hover, .btn-white[type]:focus, .btn-white[type]:active {
  box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
}

.btn-white:not(label.btn), .btn-white:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-white:not([href]), .btn-white:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  background-color: #fff;
  border-color: #fff;
}

/* indigo button :hover, :focus, :active effects */
.btn-indigo[href]:hover, .btn-indigo[href]:focus, .btn-indigo[href]:active, .btn-indigo[type]:hover, .btn-indigo[type]:focus, .btn-indigo[type]:active {
  box-shadow: 0 4px 11px rgba(45, 21, 130, 0.35);
}

.btn-indigo:not(label.btn), .btn-indigo:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-indigo:not([href]), .btn-indigo:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #2d1582;
  border-color: #2d1582;
}

.btn-indigo:hover {
  background-color: #2d1582;
  border-color: #2d1582;
}

/* gray button :hover, :focus, :active effects */
.btn-gray[href]:hover, .btn-gray[href]:focus, .btn-gray[href]:active, .btn-gray[type]:hover, .btn-gray[type]:focus, .btn-gray[type]:active {
  box-shadow: 0 4px 11px rgba(235, 240, 247, 0.35);
}

.btn-gray:not(label.btn), .btn-gray:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray:not([href]), .btn-gray:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-gray:hover {
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

/* violet button :hover, :focus, :active effects */
.btn-violet[href]:hover, .btn-violet[href]:focus, .btn-violet[href]:active, .btn-violet[type]:hover, .btn-violet[type]:focus, .btn-violet[type]:active {
  box-shadow: 0 4px 11px rgba(83, 33, 132, 0.35);
}

.btn-violet:not(label.btn), .btn-violet:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-violet:not([href]), .btn-violet:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #532184;
  border-color: #532184;
}

.btn-violet:hover {
  background-color: #532184;
  border-color: #532184;
}

/* violet-1 button :hover, :focus, :active effects */
.btn-violet-1[href]:hover, .btn-violet-1[href]:focus, .btn-violet-1[href]:active, .btn-violet-1[type]:hover, .btn-violet-1[type]:focus, .btn-violet-1[type]:active {
  box-shadow: 0 4px 11px rgba(168, 100, 168, 0.35);
}

.btn-violet-1:not(label.btn), .btn-violet-1:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-violet-1:not([href]), .btn-violet-1:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #a864a8;
  border-color: #a864a8;
}

.btn-violet-1:hover {
  background-color: #a864a8;
  border-color: #a864a8;
}

/* violet-light button :hover, :focus, :active effects */
.btn-violet-light[href]:hover, .btn-violet-light[href]:focus, .btn-violet-light[href]:active, .btn-violet-light[type]:hover, .btn-violet-light[type]:focus, .btn-violet-light[type]:active {
  box-shadow: 0 4px 11px rgba(111, 19, 205, 0.35);
}

.btn-violet-light:not(label.btn), .btn-violet-light:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-violet-light:not([href]), .btn-violet-light:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #6f13cd;
  border-color: #6f13cd;
}

.btn-violet-light:hover {
  background-color: #6f13cd;
  border-color: #6f13cd;
}

/* brown-light button :hover, :focus, :active effects */
.btn-brown-light[href]:hover, .btn-brown-light[href]:focus, .btn-brown-light[href]:active, .btn-brown-light[type]:hover, .btn-brown-light[type]:focus, .btn-brown-light[type]:active {
  box-shadow: 0 4px 11px rgba(206, 172, 97, 0.35);
}

.btn-brown-light:not(label.btn), .btn-brown-light:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-brown-light:not([href]), .btn-brown-light:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ceac61;
  border-color: #ceac61;
}

.btn-brown-light:hover {
  background-color: #ceac61;
  border-color: #ceac61;
}

/* brown button :hover, :focus, :active effects */
.btn-brown[href]:hover, .btn-brown[href]:focus, .btn-brown[href]:active, .btn-brown[type]:hover, .btn-brown[type]:focus, .btn-brown[type]:active {
  box-shadow: 0 4px 11px rgba(248, 189, 91, 0.35);
}

.btn-brown:not(label.btn), .btn-brown:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-brown:not([href]), .btn-brown:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f8bd5b;
  border-color: #f8bd5b;
}

.btn-brown:hover {
  background-color: #f8bd5b;
  border-color: #f8bd5b;
}

/* maroon button :hover, :focus, :active effects */
.btn-maroon[href]:hover, .btn-maroon[href]:focus, .btn-maroon[href]:active, .btn-maroon[type]:hover, .btn-maroon[type]:focus, .btn-maroon[type]:active {
  box-shadow: 0 4px 11px rgba(199, 47, 116, 0.35);
}

.btn-maroon:not(label.btn), .btn-maroon:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-maroon:not([href]), .btn-maroon:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #c72f74;
  border-color: #c72f74;
}

.btn-maroon:hover {
  background-color: #c72f74;
  border-color: #c72f74;
}

/* indigo-light button :hover, :focus, :active effects */
.btn-indigo-light[href]:hover, .btn-indigo-light[href]:focus, .btn-indigo-light[href]:active, .btn-indigo-light[type]:hover, .btn-indigo-light[type]:focus, .btn-indigo-light[type]:active {
  box-shadow: 0 4px 11px rgba(8, 14, 123, 0.35);
}

.btn-indigo-light:not(label.btn), .btn-indigo-light:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-indigo-light:not([href]), .btn-indigo-light:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #080e7b;
  border-color: #080e7b;
}

.btn-indigo-light:hover {
  background-color: #080e7b;
  border-color: #080e7b;
}

/* red-light-2 button :hover, :focus, :active effects */
.btn-red-light-2[href]:hover, .btn-red-light-2[href]:focus, .btn-red-light-2[href]:active, .btn-red-light-2[type]:hover, .btn-red-light-2[type]:focus, .btn-red-light-2[type]:active {
  box-shadow: 0 4px 11px rgba(255, 60, 78, 0.35);
}

.btn-red-light-2:not(label.btn), .btn-red-light-2:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-red-light-2:not([href]), .btn-red-light-2:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ff3c4e;
  border-color: #ff3c4e;
}

.btn-red-light-2:hover {
  background-color: #ff3c4e;
  border-color: #ff3c4e;
}

/* blue-dark-1 button :hover, :focus, :active effects */
.btn-blue-dark-1[href]:hover, .btn-blue-dark-1[href]:focus, .btn-blue-dark-1[href]:active, .btn-blue-dark-1[type]:hover, .btn-blue-dark-1[type]:focus, .btn-blue-dark-1[type]:active {
  box-shadow: 0 4px 11px rgba(4, 92, 255, 0.35);
}

.btn-blue-dark-1:not(label.btn), .btn-blue-dark-1:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-blue-dark-1:not([href]), .btn-blue-dark-1:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #045cff;
  border-color: #045cff;
}

.btn-blue-dark-1:hover {
  background-color: #045cff;
  border-color: #045cff;
}

/* gray-1 button :hover, :focus, :active effects */
.btn-gray-1[href]:hover, .btn-gray-1[href]:focus, .btn-gray-1[href]:active, .btn-gray-1[type]:hover, .btn-gray-1[type]:focus, .btn-gray-1[type]:active {
  box-shadow: 0 4px 11px rgba(103, 116, 124, 0.35);
}

.btn-gray-1:not(label.btn), .btn-gray-1:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-1:not([href]), .btn-gray-1:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #67747c;
  border-color: #67747c;
}

.btn-gray-1:hover {
  background-color: #67747c;
  border-color: #67747c;
}

/* gray-2 button :hover, :focus, :active effects */
.btn-gray-2[href]:hover, .btn-gray-2[href]:focus, .btn-gray-2[href]:active, .btn-gray-2[type]:hover, .btn-gray-2[type]:focus, .btn-gray-2[type]:active {
  box-shadow: 0 4px 11px rgba(247, 248, 249, 0.35);
}

.btn-gray-2:not(label.btn), .btn-gray-2:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-2:not([href]), .btn-gray-2:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-gray-2:hover {
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

/* gray-3 button :hover, :focus, :active effects */
.btn-gray-3[href]:hover, .btn-gray-3[href]:focus, .btn-gray-3[href]:active, .btn-gray-3[type]:hover, .btn-gray-3[type]:focus, .btn-gray-3[type]:active {
  box-shadow: 0 4px 11px rgba(59, 68, 79, 0.35);
}

.btn-gray-3:not(label.btn), .btn-gray-3:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-3:not([href]), .btn-gray-3:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #3b444f;
  border-color: #3b444f;
}

.btn-gray-3:hover {
  background-color: #3b444f;
  border-color: #3b444f;
}

/* gray-4 button :hover, :focus, :active effects */
.btn-gray-4[href]:hover, .btn-gray-4[href]:focus, .btn-gray-4[href]:active, .btn-gray-4[type]:hover, .btn-gray-4[type]:focus, .btn-gray-4[type]:active {
  box-shadow: 0 4px 11px rgba(236, 239, 243, 0.35);
}

.btn-gray-4:not(label.btn), .btn-gray-4:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-4:not([href]), .btn-gray-4:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #eceff3;
  border-color: #eceff3;
}

.btn-gray-4:hover {
  background-color: #eceff3;
  border-color: #eceff3;
}

/* blue button :hover, :focus, :active effects */
.btn-blue[href]:hover, .btn-blue[href]:focus, .btn-blue[href]:active, .btn-blue[type]:hover, .btn-blue[type]:focus, .btn-blue[type]:active {
  box-shadow: 0 4px 11px rgba(67, 197, 219, 0.35);
}

.btn-blue:not(label.btn), .btn-blue:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-blue:not([href]), .btn-blue:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #43c5db;
  border-color: #43c5db;
}

.btn-blue:hover {
  background-color: #43c5db;
  border-color: #43c5db;
}

/* blue-1 button :hover, :focus, :active effects */
.btn-blue-1[href]:hover, .btn-blue-1[href]:focus, .btn-blue-1[href]:active, .btn-blue-1[type]:hover, .btn-blue-1[type]:focus, .btn-blue-1[type]:active {
  box-shadow: 0 4px 11px rgba(41, 124, 187, 0.35);
}

.btn-blue-1:not(label.btn), .btn-blue-1:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-blue-1:not([href]), .btn-blue-1:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-blue-1:hover {
  background-color: #297cbb;
  border-color: #297cbb;
}

/* gray-5 button :hover, :focus, :active effects */
.btn-gray-5[href]:hover, .btn-gray-5[href]:focus, .btn-gray-5[href]:active, .btn-gray-5[type]:hover, .btn-gray-5[type]:focus, .btn-gray-5[type]:active {
  box-shadow: 0 4px 11px rgba(24, 24, 27, 0.35);
}

.btn-gray-5:not(label.btn), .btn-gray-5:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-5:not([href]), .btn-gray-5:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #18181b;
  border-color: #18181b;
}

.btn-gray-5:hover {
  background-color: #18181b;
  border-color: #18181b;
}

/* gray-6 button :hover, :focus, :active effects */
.btn-gray-6[href]:hover, .btn-gray-6[href]:focus, .btn-gray-6[href]:active, .btn-gray-6[type]:hover, .btn-gray-6[type]:focus, .btn-gray-6[type]:active {
  box-shadow: 0 4px 11px rgba(24, 24, 26, 0.35);
}

.btn-gray-6:not(label.btn), .btn-gray-6:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-6:not([href]), .btn-gray-6:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #18181a;
  border-color: #18181a;
}

.btn-gray-6:hover {
  background-color: #18181a;
  border-color: #18181a;
}

/* gray-7 button :hover, :focus, :active effects */
.btn-gray-7[href]:hover, .btn-gray-7[href]:focus, .btn-gray-7[href]:active, .btn-gray-7[type]:hover, .btn-gray-7[type]:focus, .btn-gray-7[type]:active {
  box-shadow: 0 4px 11px rgba(92, 103, 112, 0.35);
}

.btn-gray-7:not(label.btn), .btn-gray-7:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-7:not([href]), .btn-gray-7:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #5c6770;
  border-color: #5c6770;
}

.btn-gray-7:hover {
  background-color: #5c6770;
  border-color: #5c6770;
}

/* gray-8 button :hover, :focus, :active effects */
.btn-gray-8[href]:hover, .btn-gray-8[href]:focus, .btn-gray-8[href]:active, .btn-gray-8[type]:hover, .btn-gray-8[type]:focus, .btn-gray-8[type]:active {
  box-shadow: 0 4px 11px rgba(197, 205, 216, 0.35);
}

.btn-gray-8:not(label.btn), .btn-gray-8:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-8:not([href]), .btn-gray-8:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #c5cdd8;
  border-color: #c5cdd8;
}

.btn-gray-8:hover {
  background-color: #c5cdd8;
  border-color: #c5cdd8;
}

/* gray-9 button :hover, :focus, :active effects */
.btn-gray-9[href]:hover, .btn-gray-9[href]:focus, .btn-gray-9[href]:active, .btn-gray-9[type]:hover, .btn-gray-9[type]:focus, .btn-gray-9[type]:active {
  box-shadow: 0 4px 11px rgba(159, 169, 184, 0.35);
}

.btn-gray-9:not(label.btn), .btn-gray-9:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-9:not([href]), .btn-gray-9:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #9fa9b8;
  border-color: #9fa9b8;
}

.btn-gray-9:hover {
  background-color: #9fa9b8;
  border-color: #9fa9b8;
}

/* gray-10 button :hover, :focus, :active effects */
.btn-gray-10[href]:hover, .btn-gray-10[href]:focus, .btn-gray-10[href]:active, .btn-gray-10[type]:hover, .btn-gray-10[type]:focus, .btn-gray-10[type]:active {
  box-shadow: 0 4px 11px rgba(193, 193, 193, 0.35);
}

.btn-gray-10:not(label.btn), .btn-gray-10:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-10:not([href]), .btn-gray-10:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #c1c1c1;
  border-color: #c1c1c1;
}

.btn-gray-10:hover {
  background-color: #c1c1c1;
  border-color: #c1c1c1;
}

/* gray-11 button :hover, :focus, :active effects */
.btn-gray-11[href]:hover, .btn-gray-11[href]:focus, .btn-gray-11[href]:active, .btn-gray-11[type]:hover, .btn-gray-11[type]:focus, .btn-gray-11[type]:active {
  box-shadow: 0 4px 11px rgba(72, 72, 72, 0.35);
}

.btn-gray-11:not(label.btn), .btn-gray-11:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-11:not([href]), .btn-gray-11:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #484848;
  border-color: #484848;
}

.btn-gray-11:hover {
  background-color: #484848;
  border-color: #484848;
}

/* gray-23 button :hover, :focus, :active effects */
.btn-gray-23[href]:hover, .btn-gray-23[href]:focus, .btn-gray-23[href]:active, .btn-gray-23[type]:hover, .btn-gray-23[type]:focus, .btn-gray-23[type]:active {
  box-shadow: 0 4px 11px rgba(244, 244, 244, 0.35);
}

.btn-gray-23:not(label.btn), .btn-gray-23:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-23:not([href]), .btn-gray-23:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.btn-gray-23:hover {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

/* gray-33 button :hover, :focus, :active effects */
.btn-gray-33[href]:hover, .btn-gray-33[href]:focus, .btn-gray-33[href]:active, .btn-gray-33[type]:hover, .btn-gray-33[type]:focus, .btn-gray-33[type]:active {
  box-shadow: 0 4px 11px rgba(235, 240, 247, 0.35);
}

.btn-gray-33:not(label.btn), .btn-gray-33:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-33:not([href]), .btn-gray-33:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-gray-33:hover {
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

/* sea-green button :hover, :focus, :active effects */
.btn-sea-green[href]:hover, .btn-sea-green[href]:focus, .btn-sea-green[href]:active, .btn-sea-green[type]:hover, .btn-sea-green[type]:focus, .btn-sea-green[type]:active {
  box-shadow: 0 4px 11px rgba(29, 80, 141, 0.35);
}

.btn-sea-green:not(label.btn), .btn-sea-green:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-sea-green:not([href]), .btn-sea-green:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #1d508d;
  border-color: #1d508d;
}

.btn-sea-green:hover {
  background-color: #1d508d;
  border-color: #1d508d;
}

/* dark-1 button :hover, :focus, :active effects */
.btn-dark-1[href]:hover, .btn-dark-1[href]:focus, .btn-dark-1[href]:active, .btn-dark-1[type]:hover, .btn-dark-1[type]:focus, .btn-dark-1[type]:active {
  box-shadow: 0 4px 11px rgba(30, 32, 34, 0.35);
}

.btn-dark-1:not(label.btn), .btn-dark-1:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-dark-1:not([href]), .btn-dark-1:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #1e2022;
  border-color: #1e2022;
}

.btn-dark-1:hover {
  background-color: #1e2022;
  border-color: #1e2022;
}

/* navy button :hover, :focus, :active effects */
.btn-navy[href]:hover, .btn-navy[href]:focus, .btn-navy[href]:active, .btn-navy[type]:hover, .btn-navy[type]:focus, .btn-navy[type]:active {
  box-shadow: 0 4px 11px rgba(0, 87, 255, 0.35);
}

.btn-navy:not(label.btn), .btn-navy:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-navy:not([href]), .btn-navy:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-navy:hover {
  background-color: #0057ff;
  border-color: #0057ff;
}

/* pink button :hover, :focus, :active effects */
.btn-pink[href]:hover, .btn-pink[href]:focus, .btn-pink[href]:active, .btn-pink[type]:hover, .btn-pink[type]:focus, .btn-pink[type]:active {
  box-shadow: 0 4px 11px rgba(255, 229, 238, 0.35);
}

.btn-pink:not(label.btn), .btn-pink:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-pink:not([href]), .btn-pink:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ffe5ee;
  border-color: #ffe5ee;
}

.btn-pink:hover {
  background-color: #ffe5ee;
  border-color: #ffe5ee;
}

/* dark-2 button :hover, :focus, :active effects */
.btn-dark-2[href]:hover, .btn-dark-2[href]:focus, .btn-dark-2[href]:active, .btn-dark-2[type]:hover, .btn-dark-2[type]:focus, .btn-dark-2[type]:active {
  box-shadow: 0 4px 11px rgba(26, 43, 73, 0.35);
}

.btn-dark-2:not(label.btn), .btn-dark-2:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-dark-2:not([href]), .btn-dark-2:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #1a2b49;
  border-color: #1a2b49;
}

.btn-dark-2:hover {
  background-color: #1a2b49;
  border-color: #1a2b49;
}

/* yellow-lighter-2 button :hover, :focus, :active effects */
.btn-yellow-lighter-2[href]:hover, .btn-yellow-lighter-2[href]:focus, .btn-yellow-lighter-2[href]:active, .btn-yellow-lighter-2[type]:hover, .btn-yellow-lighter-2[type]:focus, .btn-yellow-lighter-2[type]:active {
  box-shadow: 0 4px 11px rgba(247, 188, 90, 0.35);
}

.btn-yellow-lighter-2:not(label.btn), .btn-yellow-lighter-2:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-yellow-lighter-2:not([href]), .btn-yellow-lighter-2:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f7bc5a;
  border-color: #f7bc5a;
}

.btn-yellow-lighter-2:hover {
  background-color: #f7bc5a;
  border-color: #f7bc5a;
}

/* orange button :hover, :focus, :active effects */
.btn-orange[href]:hover, .btn-orange[href]:focus, .btn-orange[href]:active, .btn-orange[type]:hover, .btn-orange[type]:focus, .btn-orange[type]:active {
  box-shadow: 0 4px 11px rgba(240, 87, 0, 0.35);
}

.btn-orange:not(label.btn), .btn-orange:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-orange:not([href]), .btn-orange:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f05700;
  border-color: #f05700;
}

.btn-orange:hover {
  background-color: #f05700;
  border-color: #f05700;
}

/* green button :hover, :focus, :active effects */
.btn-green[href]:hover, .btn-green[href]:focus, .btn-green[href]:active, .btn-green[type]:hover, .btn-green[type]:focus, .btn-green[type]:active {
  box-shadow: 0 4px 11px rgba(40, 135, 28, 0.35);
}

.btn-green:not(label.btn), .btn-green:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-green:not([href]), .btn-green:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #28871c;
  border-color: #28871c;
}

.btn-green:hover {
  background-color: #28871c;
  border-color: #28871c;
}

/* pink-1 button :hover, :focus, :active effects */
.btn-pink-1[href]:hover, .btn-pink-1[href]:focus, .btn-pink-1[href]:active, .btn-pink-1[type]:hover, .btn-pink-1[type]:focus, .btn-pink-1[type]:active {
  box-shadow: 0 4px 11px rgba(255, 0, 69, 0.35);
}

.btn-pink-1:not(label.btn), .btn-pink-1:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-pink-1:not([href]), .btn-pink-1:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ff0045;
  border-color: #ff0045;
}

.btn-pink-1:hover {
  background-color: #ff0045;
  border-color: #ff0045;
}

/* purple-1 button :hover, :focus, :active effects */
.btn-purple-1[href]:hover, .btn-purple-1[href]:focus, .btn-purple-1[href]:active, .btn-purple-1[type]:hover, .btn-purple-1[type]:focus, .btn-purple-1[type]:active {
  box-shadow: 0 4px 11px rgba(35, 39, 83, 0.35);
}

.btn-purple-1:not(label.btn), .btn-purple-1:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-purple-1:not([href]), .btn-purple-1:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #232753;
  border-color: #232753;
}

.btn-purple-1:hover {
  background-color: #232753;
  border-color: #232753;
}

/* facebook button :hover, :focus, :active effects */
.btn-facebook[href]:hover, .btn-facebook[href]:focus, .btn-facebook[href]:active, .btn-facebook[type]:hover, .btn-facebook[type]:focus, .btn-facebook[type]:active {
  box-shadow: 0 4px 11px rgba(57, 88, 153, 0.35);
}

.btn-facebook:not(label.btn), .btn-facebook:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-facebook:not([href]), .btn-facebook:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #395899;
  border-color: #395899;
}

.btn-facebook:hover {
  background-color: #395899;
  border-color: #395899;
}

/* google button :hover, :focus, :active effects */
.btn-google[href]:hover, .btn-google[href]:focus, .btn-google[href]:active, .btn-google[type]:hover, .btn-google[type]:focus, .btn-google[type]:active {
  box-shadow: 0 4px 11px rgba(209, 65, 48, 0.35);
}

.btn-google:not(label.btn), .btn-google:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-google:not([href]), .btn-google:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:hover {
  background-color: #d14130;
  border-color: #d14130;
}

/* twitter button :hover, :focus, :active effects */
.btn-twitter[href]:hover, .btn-twitter[href]:focus, .btn-twitter[href]:active, .btn-twitter[type]:hover, .btn-twitter[type]:focus, .btn-twitter[type]:active {
  box-shadow: 0 4px 11px rgba(3, 169, 224, 0.35);
}

.btn-twitter:not(label.btn), .btn-twitter:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-twitter:not([href]), .btn-twitter:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #03a9e0;
  border-color: #03a9e0;
}

.btn-twitter:hover {
  background-color: #03a9e0;
  border-color: #03a9e0;
}

/* instagram button :hover, :focus, :active effects */
.btn-instagram[href]:hover, .btn-instagram[href]:focus, .btn-instagram[href]:active, .btn-instagram[type]:hover, .btn-instagram[type]:focus, .btn-instagram[type]:active {
  box-shadow: 0 4px 11px rgba(63, 114, 155, 0.35);
}

.btn-instagram:not(label.btn), .btn-instagram:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-instagram:not([href]), .btn-instagram:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:hover {
  background-color: #3f729b;
  border-color: #3f729b;
}

/* github button :hover, :focus, :active effects */
.btn-github[href]:hover, .btn-github[href]:focus, .btn-github[href]:active, .btn-github[type]:hover, .btn-github[type]:focus, .btn-github[type]:active {
  box-shadow: 0 4px 11px rgba(36, 41, 46, 0.35);
}

.btn-github:not(label.btn), .btn-github:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-github:not([href]), .btn-github:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:hover {
  background-color: #24292e;
  border-color: #24292e;
}

/* gray button :hover, :focus, :active effects */
.btn-gray[href]:hover, .btn-gray[href]:focus, .btn-gray[href]:active, .btn-gray[type]:hover, .btn-gray[type]:focus, .btn-gray[type]:active {
  box-shadow: 0 4px 11px rgba(248, 250, 253, 0.35);
}

.btn-gray:not(label.btn), .btn-gray:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray:not([href]), .btn-gray:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #f8fafd;
  border-color: #f8fafd;
}

.btn-gray:hover {
  background-color: #f8fafd;
  border-color: #f8fafd;
}

/* purple button :hover, :focus, :active effects */
.btn-purple[href]:hover, .btn-purple[href]:focus, .btn-purple[href]:active, .btn-purple[type]:hover, .btn-purple[type]:focus, .btn-purple[type]:active {
  box-shadow: 0 4px 11px rgba(128, 35, 223, 0.35);
}

.btn-purple:not(label.btn), .btn-purple:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-purple:not([href]), .btn-purple:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #8023df;
  border-color: #8023df;
}

.btn-purple:hover {
  background-color: #8023df;
  border-color: #8023df;
}

/* blue button :hover, :focus, :active effects */
.btn-blue[href]:hover, .btn-blue[href]:focus, .btn-blue[href]:active, .btn-blue[type]:hover, .btn-blue[type]:focus, .btn-blue[type]:active {
  box-shadow: 0 4px 11px rgba(76, 191, 216, 0.35);
}

.btn-blue:not(label.btn), .btn-blue:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-blue:not([href]), .btn-blue:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #4cbfd8;
  border-color: #4cbfd8;
}

.btn-blue:hover {
  background-color: #4cbfd8;
  border-color: #4cbfd8;
}

/* violet button :hover, :focus, :active effects */
.btn-violet[href]:hover, .btn-violet[href]:focus, .btn-violet[href]:active, .btn-violet[type]:hover, .btn-violet[type]:focus, .btn-violet[type]:active {
  box-shadow: 0 4px 11px rgba(140, 104, 157, 0.35);
}

.btn-violet:not(label.btn), .btn-violet:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-violet:not([href]), .btn-violet:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #8c689d;
  border-color: #8c689d;
}

.btn-violet:hover {
  background-color: #8c689d;
  border-color: #8c689d;
}

/* coral button :hover, :focus, :active effects */
.btn-coral[href]:hover, .btn-coral[href]:focus, .btn-coral[href]:active, .btn-coral[type]:hover, .btn-coral[type]:focus, .btn-coral[type]:active {
  box-shadow: 0 4px 11px rgba(255, 132, 128, 0.35);
}

.btn-coral:not(label.btn), .btn-coral:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-coral:not([href]), .btn-coral:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ff8480;
  border-color: #ff8480;
}

.btn-coral:hover {
  background-color: #ff8480;
  border-color: #ff8480;
}

/* yellow-light button :hover, :focus, :active effects */
.btn-yellow-light[href]:hover, .btn-yellow-light[href]:focus, .btn-yellow-light[href]:active, .btn-yellow-light[type]:hover, .btn-yellow-light[type]:focus, .btn-yellow-light[type]:active {
  box-shadow: 0 4px 11px rgba(255, 184, 116, 0.35);
}

.btn-yellow-light:not(label.btn), .btn-yellow-light:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-yellow-light:not([href]), .btn-yellow-light:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ffb874;
  border-color: #ffb874;
}

.btn-yellow-light:hover {
  background-color: #ffb874;
  border-color: #ffb874;
}

/* green-light button :hover, :focus, :active effects */
.btn-green-light[href]:hover, .btn-green-light[href]:focus, .btn-green-light[href]:active, .btn-green-light[type]:hover, .btn-green-light[type]:focus, .btn-green-light[type]:active {
  box-shadow: 0 4px 11px rgba(53, 194, 165, 0.35);
}

.btn-green-light:not(label.btn), .btn-green-light:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-green-light:not([href]), .btn-green-light:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #35c2a5;
  border-color: #35c2a5;
}

.btn-green-light:hover {
  background-color: #35c2a5;
  border-color: #35c2a5;
}

/* blue-1 button :hover, :focus, :active effects */
.btn-blue-1[href]:hover, .btn-blue-1[href]:focus, .btn-blue-1[href]:active, .btn-blue-1[type]:hover, .btn-blue-1[type]:focus, .btn-blue-1[type]:active {
  box-shadow: 0 4px 11px rgba(0, 87, 255, 0.35);
}

.btn-blue-1:not(label.btn), .btn-blue-1:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-blue-1:not([href]), .btn-blue-1:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #0057ff;
  border-color: #0057ff;
}

.btn-blue-1:hover {
  background-color: #0057ff;
  border-color: #0057ff;
}

/* gray-1 button :hover, :focus, :active effects */
.btn-gray-1[href]:hover, .btn-gray-1[href]:focus, .btn-gray-1[href]:active, .btn-gray-1[type]:hover, .btn-gray-1[type]:focus, .btn-gray-1[type]:active {
  box-shadow: 0 4px 11px rgba(235, 240, 247, 0.35);
}

.btn-gray-1:not(label.btn), .btn-gray-1:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-gray-1:not([href]), .btn-gray-1:not([href]):not([href]):not(:disabled):not(.disabled) {
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

.btn-gray-1:hover {
  background-color: #ebf0f7;
  border-color: #ebf0f7;
}

/*------------------------------------
  Custom Button Toggle
------------------------------------*/
.btn-custom-toggle-primary {
  color: #8c98a4;
  border-color: #d5dae2;
}

.btn-custom-toggle-primary:hover {
  color: #297cbb;
  background-color: transparent;
  border-color: #297cbb;
}

.btn-custom-toggle-primary:not(:disabled):not(.disabled):active, .btn-custom-toggle-primary:not(:disabled):not(.disabled).active, .btn-custom-toggle-primary:not(:disabled):not(.disabled):active, .btn-custom-toggle-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #297cbb;
  border-color: #297cbb;
}

.btn-custom-toggle-white:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}

.btn-custom-toggle-white:not(:disabled):not(.disabled):active, .btn-custom-toggle-white:not(:disabled):not(.disabled).active, .btn-custom-toggle-white:not(:disabled):not(.disabled):active, .btn-custom-toggle-white:not(:disabled):not(.disabled).active {
  color: #297cbb;
}

/*------------------------------------
  Air Button Styles
------------------------------------*/
/* primary air */
.btn-soft-primary {
  color: #297cbb;
  background: rgba(41, 124, 187, 0.1);
  border-color: transparent;
}

.btn-soft-primary[href]:hover, .btn-soft-primary[href]:focus, .btn-soft-primary[href]:active, .btn-soft-primary[href].active, .btn-soft-primary[type]:hover, .btn-soft-primary[type]:focus, .btn-soft-primary[type]:active, .btn-soft-primary[type].active {
  color: #fff;
  background: #297cbb;
  box-shadow: 0 4px 11px rgba(41, 124, 187, 0.35);
}

.btn-soft-primary[href].disabled, .btn-soft-primary[href]:disabled, .btn-soft-primary[type].disabled, .btn-soft-primary[type]:disabled {
  color: #297cbb;
  background-color: rgba(41, 124, 187, 0.1);
  box-shadow: none;
}

.btn-soft-primary[href]:not(:disabled):not(.disabled):active, .btn-soft-primary[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-primary[href].dropdown-toggle, .btn-soft-primary[type]:not(:disabled):not(.disabled):active, .btn-soft-primary[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-primary[type].dropdown-toggle {
  color: #fff;
  background-color: #297cbb;
}

.btn.btn-soft-primary {
  color: #297cbb;
}

/* secondary air */
.btn-soft-secondary {
  color: #77838f;
  background: rgba(119, 131, 143, 0.1);
  border-color: transparent;
}

.btn-soft-secondary[href]:hover, .btn-soft-secondary[href]:focus, .btn-soft-secondary[href]:active, .btn-soft-secondary[href].active, .btn-soft-secondary[type]:hover, .btn-soft-secondary[type]:focus, .btn-soft-secondary[type]:active, .btn-soft-secondary[type].active {
  color: #fff;
  background: #77838f;
  box-shadow: 0 4px 11px rgba(119, 131, 143, 0.35);
}

.btn-soft-secondary[href].disabled, .btn-soft-secondary[href]:disabled, .btn-soft-secondary[type].disabled, .btn-soft-secondary[type]:disabled {
  color: #77838f;
  background-color: rgba(119, 131, 143, 0.1);
  box-shadow: none;
}

.btn-soft-secondary[href]:not(:disabled):not(.disabled):active, .btn-soft-secondary[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-secondary[href].dropdown-toggle, .btn-soft-secondary[type]:not(:disabled):not(.disabled):active, .btn-soft-secondary[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-secondary[type].dropdown-toggle {
  color: #fff;
  background-color: #77838f;
}

.btn.btn-soft-secondary {
  color: #77838f;
}

/* success air */
.btn-soft-success {
  color: #00c9a7;
  background: rgba(0, 201, 167, 0.1);
  border-color: transparent;
}

.btn-soft-success[href]:hover, .btn-soft-success[href]:focus, .btn-soft-success[href]:active, .btn-soft-success[href].active, .btn-soft-success[type]:hover, .btn-soft-success[type]:focus, .btn-soft-success[type]:active, .btn-soft-success[type].active {
  color: #fff;
  background: #00c9a7;
  box-shadow: 0 4px 11px rgba(0, 201, 167, 0.35);
}

.btn-soft-success[href].disabled, .btn-soft-success[href]:disabled, .btn-soft-success[type].disabled, .btn-soft-success[type]:disabled {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
  box-shadow: none;
}

.btn-soft-success[href]:not(:disabled):not(.disabled):active, .btn-soft-success[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-success[href].dropdown-toggle, .btn-soft-success[type]:not(:disabled):not(.disabled):active, .btn-soft-success[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-success[type].dropdown-toggle {
  color: #fff;
  background-color: #00c9a7;
}

.btn.btn-soft-success {
  color: #00c9a7;
}

/* info air */
.btn-soft-info {
  color: #00dffc;
  background: rgba(0, 223, 252, 0.1);
  border-color: transparent;
}

.btn-soft-info[href]:hover, .btn-soft-info[href]:focus, .btn-soft-info[href]:active, .btn-soft-info[href].active, .btn-soft-info[type]:hover, .btn-soft-info[type]:focus, .btn-soft-info[type]:active, .btn-soft-info[type].active {
  color: #3b444f;
  background: #00dffc;
  box-shadow: 0 4px 11px rgba(0, 223, 252, 0.35);
}

.btn-soft-info[href].disabled, .btn-soft-info[href]:disabled, .btn-soft-info[type].disabled, .btn-soft-info[type]:disabled {
  color: #00dffc;
  background-color: rgba(0, 223, 252, 0.1);
  box-shadow: none;
}

.btn-soft-info[href]:not(:disabled):not(.disabled):active, .btn-soft-info[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-info[href].dropdown-toggle, .btn-soft-info[type]:not(:disabled):not(.disabled):active, .btn-soft-info[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-info[type].dropdown-toggle {
  color: #3b444f;
  background-color: #00dffc;
}

.btn.btn-soft-info {
  color: #00dffc;
}

/* warning air */
.btn-soft-warning {
  color: #ffc107;
  background: rgba(255, 193, 7, 0.1);
  border-color: transparent;
}

.btn-soft-warning[href]:hover, .btn-soft-warning[href]:focus, .btn-soft-warning[href]:active, .btn-soft-warning[href].active, .btn-soft-warning[type]:hover, .btn-soft-warning[type]:focus, .btn-soft-warning[type]:active, .btn-soft-warning[type].active {
  color: #3b444f;
  background: #ffc107;
  box-shadow: 0 4px 11px rgba(255, 193, 7, 0.35);
}

.btn-soft-warning[href].disabled, .btn-soft-warning[href]:disabled, .btn-soft-warning[type].disabled, .btn-soft-warning[type]:disabled {
  color: #ffc107;
  background-color: rgba(255, 193, 7, 0.1);
  box-shadow: none;
}

.btn-soft-warning[href]:not(:disabled):not(.disabled):active, .btn-soft-warning[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-warning[href].dropdown-toggle, .btn-soft-warning[type]:not(:disabled):not(.disabled):active, .btn-soft-warning[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-warning[type].dropdown-toggle {
  color: #3b444f;
  background-color: #ffc107;
}

.btn.btn-soft-warning {
  color: #ffc107;
}

/* danger air */
.btn-soft-danger {
  color: #de4437;
  background: rgba(222, 68, 55, 0.1);
  border-color: transparent;
}

.btn-soft-danger[href]:hover, .btn-soft-danger[href]:focus, .btn-soft-danger[href]:active, .btn-soft-danger[href].active, .btn-soft-danger[type]:hover, .btn-soft-danger[type]:focus, .btn-soft-danger[type]:active, .btn-soft-danger[type].active {
  color: #fff;
  background: #de4437;
  box-shadow: 0 4px 11px rgba(222, 68, 55, 0.35);
}

.btn-soft-danger[href].disabled, .btn-soft-danger[href]:disabled, .btn-soft-danger[type].disabled, .btn-soft-danger[type]:disabled {
  color: #de4437;
  background-color: rgba(222, 68, 55, 0.1);
  box-shadow: none;
}

.btn-soft-danger[href]:not(:disabled):not(.disabled):active, .btn-soft-danger[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-danger[href].dropdown-toggle, .btn-soft-danger[type]:not(:disabled):not(.disabled):active, .btn-soft-danger[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-danger[type].dropdown-toggle {
  color: #fff;
  background-color: #de4437;
}

.btn.btn-soft-danger {
  color: #de4437;
}

/* light air */
.btn-soft-light {
  color: #f8f9fa;
  background: rgba(248, 249, 250, 0.1);
  border-color: transparent;
}

.btn-soft-light[href]:hover, .btn-soft-light[href]:focus, .btn-soft-light[href]:active, .btn-soft-light[href].active, .btn-soft-light[type]:hover, .btn-soft-light[type]:focus, .btn-soft-light[type]:active, .btn-soft-light[type].active {
  color: #3b444f;
  background: #f8f9fa;
  box-shadow: 0 4px 11px rgba(248, 249, 250, 0.35);
}

.btn-soft-light[href].disabled, .btn-soft-light[href]:disabled, .btn-soft-light[type].disabled, .btn-soft-light[type]:disabled {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.1);
  box-shadow: none;
}

.btn-soft-light[href]:not(:disabled):not(.disabled):active, .btn-soft-light[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-light[href].dropdown-toggle, .btn-soft-light[type]:not(:disabled):not(.disabled):active, .btn-soft-light[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-light[type].dropdown-toggle {
  color: #3b444f;
  background-color: #f8f9fa;
}

.btn.btn-soft-light {
  color: #f8f9fa;
}

/* dark air */
.btn-soft-dark {
  color: #3b444f;
  background: rgba(59, 68, 79, 0.1);
  border-color: transparent;
}

.btn-soft-dark[href]:hover, .btn-soft-dark[href]:focus, .btn-soft-dark[href]:active, .btn-soft-dark[href].active, .btn-soft-dark[type]:hover, .btn-soft-dark[type]:focus, .btn-soft-dark[type]:active, .btn-soft-dark[type].active {
  color: #fff;
  background: #3b444f;
  box-shadow: 0 4px 11px rgba(59, 68, 79, 0.35);
}

.btn-soft-dark[href].disabled, .btn-soft-dark[href]:disabled, .btn-soft-dark[type].disabled, .btn-soft-dark[type]:disabled {
  color: #3b444f;
  background-color: rgba(59, 68, 79, 0.1);
  box-shadow: none;
}

.btn-soft-dark[href]:not(:disabled):not(.disabled):active, .btn-soft-dark[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-dark[href].dropdown-toggle, .btn-soft-dark[type]:not(:disabled):not(.disabled):active, .btn-soft-dark[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-dark[type].dropdown-toggle {
  color: #fff;
  background-color: #3b444f;
}

.btn.btn-soft-dark {
  color: #3b444f;
}

/* white air */
.btn-soft-white {
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}

.btn-soft-white[href]:hover, .btn-soft-white[href]:focus, .btn-soft-white[href]:active, .btn-soft-white[href].active, .btn-soft-white[type]:hover, .btn-soft-white[type]:focus, .btn-soft-white[type]:active, .btn-soft-white[type].active {
  color: #3b444f;
  background: #fff;
  box-shadow: 0 4px 11px rgba(255, 255, 255, 0.35);
}

.btn-soft-white[href].disabled, .btn-soft-white[href]:disabled, .btn-soft-white[type].disabled, .btn-soft-white[type]:disabled {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
}

.btn-soft-white[href]:not(:disabled):not(.disabled):active, .btn-soft-white[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-white[href].dropdown-toggle, .btn-soft-white[type]:not(:disabled):not(.disabled):active, .btn-soft-white[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-white[type].dropdown-toggle {
  color: #3b444f;
  background-color: #fff;
}

.btn.btn-soft-white {
  color: #fff;
}

/* indigo air */
.btn-soft-indigo {
  color: #2d1582;
  background: rgba(45, 21, 130, 0.1);
  border-color: transparent;
}

.btn-soft-indigo[href]:hover, .btn-soft-indigo[href]:focus, .btn-soft-indigo[href]:active, .btn-soft-indigo[href].active, .btn-soft-indigo[type]:hover, .btn-soft-indigo[type]:focus, .btn-soft-indigo[type]:active, .btn-soft-indigo[type].active {
  color: #fff;
  background: #2d1582;
  box-shadow: 0 4px 11px rgba(45, 21, 130, 0.35);
}

.btn-soft-indigo[href].disabled, .btn-soft-indigo[href]:disabled, .btn-soft-indigo[type].disabled, .btn-soft-indigo[type]:disabled {
  color: #2d1582;
  background-color: rgba(45, 21, 130, 0.1);
  box-shadow: none;
}

.btn-soft-indigo[href]:not(:disabled):not(.disabled):active, .btn-soft-indigo[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-indigo[href].dropdown-toggle, .btn-soft-indigo[type]:not(:disabled):not(.disabled):active, .btn-soft-indigo[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-indigo[type].dropdown-toggle {
  color: #fff;
  background-color: #2d1582;
}

.btn.btn-soft-indigo {
  color: #2d1582;
}

/* gray air */
.btn-soft-gray {
  color: #ebf0f7;
  background: rgba(235, 240, 247, 0.1);
  border-color: transparent;
}

.btn-soft-gray[href]:hover, .btn-soft-gray[href]:focus, .btn-soft-gray[href]:active, .btn-soft-gray[href].active, .btn-soft-gray[type]:hover, .btn-soft-gray[type]:focus, .btn-soft-gray[type]:active, .btn-soft-gray[type].active {
  color: #3b444f;
  background: #ebf0f7;
  box-shadow: 0 4px 11px rgba(235, 240, 247, 0.35);
}

.btn-soft-gray[href].disabled, .btn-soft-gray[href]:disabled, .btn-soft-gray[type].disabled, .btn-soft-gray[type]:disabled {
  color: #ebf0f7;
  background-color: rgba(235, 240, 247, 0.1);
  box-shadow: none;
}

.btn-soft-gray[href]:not(:disabled):not(.disabled):active, .btn-soft-gray[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray[href].dropdown-toggle, .btn-soft-gray[type]:not(:disabled):not(.disabled):active, .btn-soft-gray[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray[type].dropdown-toggle {
  color: #3b444f;
  background-color: #ebf0f7;
}

.btn.btn-soft-gray {
  color: #ebf0f7;
}

/* violet air */
.btn-soft-violet {
  color: #532184;
  background: rgba(83, 33, 132, 0.1);
  border-color: transparent;
}

.btn-soft-violet[href]:hover, .btn-soft-violet[href]:focus, .btn-soft-violet[href]:active, .btn-soft-violet[href].active, .btn-soft-violet[type]:hover, .btn-soft-violet[type]:focus, .btn-soft-violet[type]:active, .btn-soft-violet[type].active {
  color: #fff;
  background: #532184;
  box-shadow: 0 4px 11px rgba(83, 33, 132, 0.35);
}

.btn-soft-violet[href].disabled, .btn-soft-violet[href]:disabled, .btn-soft-violet[type].disabled, .btn-soft-violet[type]:disabled {
  color: #532184;
  background-color: rgba(83, 33, 132, 0.1);
  box-shadow: none;
}

.btn-soft-violet[href]:not(:disabled):not(.disabled):active, .btn-soft-violet[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-violet[href].dropdown-toggle, .btn-soft-violet[type]:not(:disabled):not(.disabled):active, .btn-soft-violet[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-violet[type].dropdown-toggle {
  color: #fff;
  background-color: #532184;
}

.btn.btn-soft-violet {
  color: #532184;
}

/* violet-1 air */
.btn-soft-violet-1 {
  color: #a864a8;
  background: rgba(168, 100, 168, 0.1);
  border-color: transparent;
}

.btn-soft-violet-1[href]:hover, .btn-soft-violet-1[href]:focus, .btn-soft-violet-1[href]:active, .btn-soft-violet-1[href].active, .btn-soft-violet-1[type]:hover, .btn-soft-violet-1[type]:focus, .btn-soft-violet-1[type]:active, .btn-soft-violet-1[type].active {
  color: #fff;
  background: #a864a8;
  box-shadow: 0 4px 11px rgba(168, 100, 168, 0.35);
}

.btn-soft-violet-1[href].disabled, .btn-soft-violet-1[href]:disabled, .btn-soft-violet-1[type].disabled, .btn-soft-violet-1[type]:disabled {
  color: #a864a8;
  background-color: rgba(168, 100, 168, 0.1);
  box-shadow: none;
}

.btn-soft-violet-1[href]:not(:disabled):not(.disabled):active, .btn-soft-violet-1[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-violet-1[href].dropdown-toggle, .btn-soft-violet-1[type]:not(:disabled):not(.disabled):active, .btn-soft-violet-1[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-violet-1[type].dropdown-toggle {
  color: #fff;
  background-color: #a864a8;
}

.btn.btn-soft-violet-1 {
  color: #a864a8;
}

/* violet-light air */
.btn-soft-violet-light {
  color: #6f13cd;
  background: rgba(111, 19, 205, 0.1);
  border-color: transparent;
}

.btn-soft-violet-light[href]:hover, .btn-soft-violet-light[href]:focus, .btn-soft-violet-light[href]:active, .btn-soft-violet-light[href].active, .btn-soft-violet-light[type]:hover, .btn-soft-violet-light[type]:focus, .btn-soft-violet-light[type]:active, .btn-soft-violet-light[type].active {
  color: #fff;
  background: #6f13cd;
  box-shadow: 0 4px 11px rgba(111, 19, 205, 0.35);
}

.btn-soft-violet-light[href].disabled, .btn-soft-violet-light[href]:disabled, .btn-soft-violet-light[type].disabled, .btn-soft-violet-light[type]:disabled {
  color: #6f13cd;
  background-color: rgba(111, 19, 205, 0.1);
  box-shadow: none;
}

.btn-soft-violet-light[href]:not(:disabled):not(.disabled):active, .btn-soft-violet-light[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-violet-light[href].dropdown-toggle, .btn-soft-violet-light[type]:not(:disabled):not(.disabled):active, .btn-soft-violet-light[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-violet-light[type].dropdown-toggle {
  color: #fff;
  background-color: #6f13cd;
}

.btn.btn-soft-violet-light {
  color: #6f13cd;
}

/* brown-light air */
.btn-soft-brown-light {
  color: #ceac61;
  background: rgba(206, 172, 97, 0.1);
  border-color: transparent;
}

.btn-soft-brown-light[href]:hover, .btn-soft-brown-light[href]:focus, .btn-soft-brown-light[href]:active, .btn-soft-brown-light[href].active, .btn-soft-brown-light[type]:hover, .btn-soft-brown-light[type]:focus, .btn-soft-brown-light[type]:active, .btn-soft-brown-light[type].active {
  color: #3b444f;
  background: #ceac61;
  box-shadow: 0 4px 11px rgba(206, 172, 97, 0.35);
}

.btn-soft-brown-light[href].disabled, .btn-soft-brown-light[href]:disabled, .btn-soft-brown-light[type].disabled, .btn-soft-brown-light[type]:disabled {
  color: #ceac61;
  background-color: rgba(206, 172, 97, 0.1);
  box-shadow: none;
}

.btn-soft-brown-light[href]:not(:disabled):not(.disabled):active, .btn-soft-brown-light[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-brown-light[href].dropdown-toggle, .btn-soft-brown-light[type]:not(:disabled):not(.disabled):active, .btn-soft-brown-light[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-brown-light[type].dropdown-toggle {
  color: #3b444f;
  background-color: #ceac61;
}

.btn.btn-soft-brown-light {
  color: #ceac61;
}

/* brown air */
.btn-soft-brown {
  color: #f8bd5b;
  background: rgba(248, 189, 91, 0.1);
  border-color: transparent;
}

.btn-soft-brown[href]:hover, .btn-soft-brown[href]:focus, .btn-soft-brown[href]:active, .btn-soft-brown[href].active, .btn-soft-brown[type]:hover, .btn-soft-brown[type]:focus, .btn-soft-brown[type]:active, .btn-soft-brown[type].active {
  color: #3b444f;
  background: #f8bd5b;
  box-shadow: 0 4px 11px rgba(248, 189, 91, 0.35);
}

.btn-soft-brown[href].disabled, .btn-soft-brown[href]:disabled, .btn-soft-brown[type].disabled, .btn-soft-brown[type]:disabled {
  color: #f8bd5b;
  background-color: rgba(248, 189, 91, 0.1);
  box-shadow: none;
}

.btn-soft-brown[href]:not(:disabled):not(.disabled):active, .btn-soft-brown[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-brown[href].dropdown-toggle, .btn-soft-brown[type]:not(:disabled):not(.disabled):active, .btn-soft-brown[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-brown[type].dropdown-toggle {
  color: #3b444f;
  background-color: #f8bd5b;
}

.btn.btn-soft-brown {
  color: #f8bd5b;
}

/* maroon air */
.btn-soft-maroon {
  color: #c72f74;
  background: rgba(199, 47, 116, 0.1);
  border-color: transparent;
}

.btn-soft-maroon[href]:hover, .btn-soft-maroon[href]:focus, .btn-soft-maroon[href]:active, .btn-soft-maroon[href].active, .btn-soft-maroon[type]:hover, .btn-soft-maroon[type]:focus, .btn-soft-maroon[type]:active, .btn-soft-maroon[type].active {
  color: #fff;
  background: #c72f74;
  box-shadow: 0 4px 11px rgba(199, 47, 116, 0.35);
}

.btn-soft-maroon[href].disabled, .btn-soft-maroon[href]:disabled, .btn-soft-maroon[type].disabled, .btn-soft-maroon[type]:disabled {
  color: #c72f74;
  background-color: rgba(199, 47, 116, 0.1);
  box-shadow: none;
}

.btn-soft-maroon[href]:not(:disabled):not(.disabled):active, .btn-soft-maroon[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-maroon[href].dropdown-toggle, .btn-soft-maroon[type]:not(:disabled):not(.disabled):active, .btn-soft-maroon[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-maroon[type].dropdown-toggle {
  color: #fff;
  background-color: #c72f74;
}

.btn.btn-soft-maroon {
  color: #c72f74;
}

/* indigo-light air */
.btn-soft-indigo-light {
  color: #080e7b;
  background: rgba(8, 14, 123, 0.1);
  border-color: transparent;
}

.btn-soft-indigo-light[href]:hover, .btn-soft-indigo-light[href]:focus, .btn-soft-indigo-light[href]:active, .btn-soft-indigo-light[href].active, .btn-soft-indigo-light[type]:hover, .btn-soft-indigo-light[type]:focus, .btn-soft-indigo-light[type]:active, .btn-soft-indigo-light[type].active {
  color: #fff;
  background: #080e7b;
  box-shadow: 0 4px 11px rgba(8, 14, 123, 0.35);
}

.btn-soft-indigo-light[href].disabled, .btn-soft-indigo-light[href]:disabled, .btn-soft-indigo-light[type].disabled, .btn-soft-indigo-light[type]:disabled {
  color: #080e7b;
  background-color: rgba(8, 14, 123, 0.1);
  box-shadow: none;
}

.btn-soft-indigo-light[href]:not(:disabled):not(.disabled):active, .btn-soft-indigo-light[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-indigo-light[href].dropdown-toggle, .btn-soft-indigo-light[type]:not(:disabled):not(.disabled):active, .btn-soft-indigo-light[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-indigo-light[type].dropdown-toggle {
  color: #fff;
  background-color: #080e7b;
}

.btn.btn-soft-indigo-light {
  color: #080e7b;
}

/* red-light-2 air */
.btn-soft-red-light-2 {
  color: #ff3c4e;
  background: rgba(255, 60, 78, 0.1);
  border-color: transparent;
}

.btn-soft-red-light-2[href]:hover, .btn-soft-red-light-2[href]:focus, .btn-soft-red-light-2[href]:active, .btn-soft-red-light-2[href].active, .btn-soft-red-light-2[type]:hover, .btn-soft-red-light-2[type]:focus, .btn-soft-red-light-2[type]:active, .btn-soft-red-light-2[type].active {
  color: #fff;
  background: #ff3c4e;
  box-shadow: 0 4px 11px rgba(255, 60, 78, 0.35);
}

.btn-soft-red-light-2[href].disabled, .btn-soft-red-light-2[href]:disabled, .btn-soft-red-light-2[type].disabled, .btn-soft-red-light-2[type]:disabled {
  color: #ff3c4e;
  background-color: rgba(255, 60, 78, 0.1);
  box-shadow: none;
}

.btn-soft-red-light-2[href]:not(:disabled):not(.disabled):active, .btn-soft-red-light-2[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-red-light-2[href].dropdown-toggle, .btn-soft-red-light-2[type]:not(:disabled):not(.disabled):active, .btn-soft-red-light-2[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-red-light-2[type].dropdown-toggle {
  color: #fff;
  background-color: #ff3c4e;
}

.btn.btn-soft-red-light-2 {
  color: #ff3c4e;
}

/* blue-dark-1 air */
.btn-soft-blue-dark-1 {
  color: #045cff;
  background: rgba(4, 92, 255, 0.1);
  border-color: transparent;
}

.btn-soft-blue-dark-1[href]:hover, .btn-soft-blue-dark-1[href]:focus, .btn-soft-blue-dark-1[href]:active, .btn-soft-blue-dark-1[href].active, .btn-soft-blue-dark-1[type]:hover, .btn-soft-blue-dark-1[type]:focus, .btn-soft-blue-dark-1[type]:active, .btn-soft-blue-dark-1[type].active {
  color: #fff;
  background: #045cff;
  box-shadow: 0 4px 11px rgba(4, 92, 255, 0.35);
}

.btn-soft-blue-dark-1[href].disabled, .btn-soft-blue-dark-1[href]:disabled, .btn-soft-blue-dark-1[type].disabled, .btn-soft-blue-dark-1[type]:disabled {
  color: #045cff;
  background-color: rgba(4, 92, 255, 0.1);
  box-shadow: none;
}

.btn-soft-blue-dark-1[href]:not(:disabled):not(.disabled):active, .btn-soft-blue-dark-1[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue-dark-1[href].dropdown-toggle, .btn-soft-blue-dark-1[type]:not(:disabled):not(.disabled):active, .btn-soft-blue-dark-1[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue-dark-1[type].dropdown-toggle {
  color: #fff;
  background-color: #045cff;
}

.btn.btn-soft-blue-dark-1 {
  color: #045cff;
}

/* gray-1 air */
.btn-soft-gray-1 {
  color: #67747c;
  background: rgba(103, 116, 124, 0.1);
  border-color: transparent;
}

.btn-soft-gray-1[href]:hover, .btn-soft-gray-1[href]:focus, .btn-soft-gray-1[href]:active, .btn-soft-gray-1[href].active, .btn-soft-gray-1[type]:hover, .btn-soft-gray-1[type]:focus, .btn-soft-gray-1[type]:active, .btn-soft-gray-1[type].active {
  color: #fff;
  background: #67747c;
  box-shadow: 0 4px 11px rgba(103, 116, 124, 0.35);
}

.btn-soft-gray-1[href].disabled, .btn-soft-gray-1[href]:disabled, .btn-soft-gray-1[type].disabled, .btn-soft-gray-1[type]:disabled {
  color: #67747c;
  background-color: rgba(103, 116, 124, 0.1);
  box-shadow: none;
}

.btn-soft-gray-1[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-1[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-1[href].dropdown-toggle, .btn-soft-gray-1[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-1[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-1[type].dropdown-toggle {
  color: #fff;
  background-color: #67747c;
}

.btn.btn-soft-gray-1 {
  color: #67747c;
}

/* gray-2 air */
.btn-soft-gray-2 {
  color: #f7f8f9;
  background: rgba(247, 248, 249, 0.1);
  border-color: transparent;
}

.btn-soft-gray-2[href]:hover, .btn-soft-gray-2[href]:focus, .btn-soft-gray-2[href]:active, .btn-soft-gray-2[href].active, .btn-soft-gray-2[type]:hover, .btn-soft-gray-2[type]:focus, .btn-soft-gray-2[type]:active, .btn-soft-gray-2[type].active {
  color: #3b444f;
  background: #f7f8f9;
  box-shadow: 0 4px 11px rgba(247, 248, 249, 0.35);
}

.btn-soft-gray-2[href].disabled, .btn-soft-gray-2[href]:disabled, .btn-soft-gray-2[type].disabled, .btn-soft-gray-2[type]:disabled {
  color: #f7f8f9;
  background-color: rgba(247, 248, 249, 0.1);
  box-shadow: none;
}

.btn-soft-gray-2[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-2[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-2[href].dropdown-toggle, .btn-soft-gray-2[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-2[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-2[type].dropdown-toggle {
  color: #3b444f;
  background-color: #f7f8f9;
}

.btn.btn-soft-gray-2 {
  color: #f7f8f9;
}

/* gray-3 air */
.btn-soft-gray-3 {
  color: #3b444f;
  background: rgba(59, 68, 79, 0.1);
  border-color: transparent;
}

.btn-soft-gray-3[href]:hover, .btn-soft-gray-3[href]:focus, .btn-soft-gray-3[href]:active, .btn-soft-gray-3[href].active, .btn-soft-gray-3[type]:hover, .btn-soft-gray-3[type]:focus, .btn-soft-gray-3[type]:active, .btn-soft-gray-3[type].active {
  color: #fff;
  background: #3b444f;
  box-shadow: 0 4px 11px rgba(59, 68, 79, 0.35);
}

.btn-soft-gray-3[href].disabled, .btn-soft-gray-3[href]:disabled, .btn-soft-gray-3[type].disabled, .btn-soft-gray-3[type]:disabled {
  color: #3b444f;
  background-color: rgba(59, 68, 79, 0.1);
  box-shadow: none;
}

.btn-soft-gray-3[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-3[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-3[href].dropdown-toggle, .btn-soft-gray-3[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-3[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-3[type].dropdown-toggle {
  color: #fff;
  background-color: #3b444f;
}

.btn.btn-soft-gray-3 {
  color: #3b444f;
}

/* gray-4 air */
.btn-soft-gray-4 {
  color: #eceff3;
  background: rgba(236, 239, 243, 0.1);
  border-color: transparent;
}

.btn-soft-gray-4[href]:hover, .btn-soft-gray-4[href]:focus, .btn-soft-gray-4[href]:active, .btn-soft-gray-4[href].active, .btn-soft-gray-4[type]:hover, .btn-soft-gray-4[type]:focus, .btn-soft-gray-4[type]:active, .btn-soft-gray-4[type].active {
  color: #3b444f;
  background: #eceff3;
  box-shadow: 0 4px 11px rgba(236, 239, 243, 0.35);
}

.btn-soft-gray-4[href].disabled, .btn-soft-gray-4[href]:disabled, .btn-soft-gray-4[type].disabled, .btn-soft-gray-4[type]:disabled {
  color: #eceff3;
  background-color: rgba(236, 239, 243, 0.1);
  box-shadow: none;
}

.btn-soft-gray-4[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-4[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-4[href].dropdown-toggle, .btn-soft-gray-4[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-4[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-4[type].dropdown-toggle {
  color: #3b444f;
  background-color: #eceff3;
}

.btn.btn-soft-gray-4 {
  color: #eceff3;
}

/* blue air */
.btn-soft-blue {
  color: #43c5db;
  background: rgba(67, 197, 219, 0.1);
  border-color: transparent;
}

.btn-soft-blue[href]:hover, .btn-soft-blue[href]:focus, .btn-soft-blue[href]:active, .btn-soft-blue[href].active, .btn-soft-blue[type]:hover, .btn-soft-blue[type]:focus, .btn-soft-blue[type]:active, .btn-soft-blue[type].active {
  color: #3b444f;
  background: #43c5db;
  box-shadow: 0 4px 11px rgba(67, 197, 219, 0.35);
}

.btn-soft-blue[href].disabled, .btn-soft-blue[href]:disabled, .btn-soft-blue[type].disabled, .btn-soft-blue[type]:disabled {
  color: #43c5db;
  background-color: rgba(67, 197, 219, 0.1);
  box-shadow: none;
}

.btn-soft-blue[href]:not(:disabled):not(.disabled):active, .btn-soft-blue[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue[href].dropdown-toggle, .btn-soft-blue[type]:not(:disabled):not(.disabled):active, .btn-soft-blue[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue[type].dropdown-toggle {
  color: #3b444f;
  background-color: #43c5db;
}

.btn.btn-soft-blue {
  color: #43c5db;
}

/* blue-1 air */
.btn-soft-blue-1 {
  color: #297cbb;
  background: rgba(41, 124, 187, 0.1);
  border-color: transparent;
}

.btn-soft-blue-1[href]:hover, .btn-soft-blue-1[href]:focus, .btn-soft-blue-1[href]:active, .btn-soft-blue-1[href].active, .btn-soft-blue-1[type]:hover, .btn-soft-blue-1[type]:focus, .btn-soft-blue-1[type]:active, .btn-soft-blue-1[type].active {
  color: #fff;
  background: #297cbb;
  box-shadow: 0 4px 11px rgba(41, 124, 187, 0.35);
}

.btn-soft-blue-1[href].disabled, .btn-soft-blue-1[href]:disabled, .btn-soft-blue-1[type].disabled, .btn-soft-blue-1[type]:disabled {
  color: #297cbb;
  background-color: rgba(41, 124, 187, 0.1);
  box-shadow: none;
}

.btn-soft-blue-1[href]:not(:disabled):not(.disabled):active, .btn-soft-blue-1[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue-1[href].dropdown-toggle, .btn-soft-blue-1[type]:not(:disabled):not(.disabled):active, .btn-soft-blue-1[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue-1[type].dropdown-toggle {
  color: #fff;
  background-color: #297cbb;
}

.btn.btn-soft-blue-1 {
  color: #297cbb;
}

/* gray-5 air */
.btn-soft-gray-5 {
  color: #18181b;
  background: rgba(24, 24, 27, 0.1);
  border-color: transparent;
}

.btn-soft-gray-5[href]:hover, .btn-soft-gray-5[href]:focus, .btn-soft-gray-5[href]:active, .btn-soft-gray-5[href].active, .btn-soft-gray-5[type]:hover, .btn-soft-gray-5[type]:focus, .btn-soft-gray-5[type]:active, .btn-soft-gray-5[type].active {
  color: #fff;
  background: #18181b;
  box-shadow: 0 4px 11px rgba(24, 24, 27, 0.35);
}

.btn-soft-gray-5[href].disabled, .btn-soft-gray-5[href]:disabled, .btn-soft-gray-5[type].disabled, .btn-soft-gray-5[type]:disabled {
  color: #18181b;
  background-color: rgba(24, 24, 27, 0.1);
  box-shadow: none;
}

.btn-soft-gray-5[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-5[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-5[href].dropdown-toggle, .btn-soft-gray-5[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-5[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-5[type].dropdown-toggle {
  color: #fff;
  background-color: #18181b;
}

.btn.btn-soft-gray-5 {
  color: #18181b;
}

/* gray-6 air */
.btn-soft-gray-6 {
  color: #18181a;
  background: rgba(24, 24, 26, 0.1);
  border-color: transparent;
}

.btn-soft-gray-6[href]:hover, .btn-soft-gray-6[href]:focus, .btn-soft-gray-6[href]:active, .btn-soft-gray-6[href].active, .btn-soft-gray-6[type]:hover, .btn-soft-gray-6[type]:focus, .btn-soft-gray-6[type]:active, .btn-soft-gray-6[type].active {
  color: #fff;
  background: #18181a;
  box-shadow: 0 4px 11px rgba(24, 24, 26, 0.35);
}

.btn-soft-gray-6[href].disabled, .btn-soft-gray-6[href]:disabled, .btn-soft-gray-6[type].disabled, .btn-soft-gray-6[type]:disabled {
  color: #18181a;
  background-color: rgba(24, 24, 26, 0.1);
  box-shadow: none;
}

.btn-soft-gray-6[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-6[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-6[href].dropdown-toggle, .btn-soft-gray-6[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-6[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-6[type].dropdown-toggle {
  color: #fff;
  background-color: #18181a;
}

.btn.btn-soft-gray-6 {
  color: #18181a;
}

/* gray-7 air */
.btn-soft-gray-7 {
  color: #5c6770;
  background: rgba(92, 103, 112, 0.1);
  border-color: transparent;
}

.btn-soft-gray-7[href]:hover, .btn-soft-gray-7[href]:focus, .btn-soft-gray-7[href]:active, .btn-soft-gray-7[href].active, .btn-soft-gray-7[type]:hover, .btn-soft-gray-7[type]:focus, .btn-soft-gray-7[type]:active, .btn-soft-gray-7[type].active {
  color: #fff;
  background: #5c6770;
  box-shadow: 0 4px 11px rgba(92, 103, 112, 0.35);
}

.btn-soft-gray-7[href].disabled, .btn-soft-gray-7[href]:disabled, .btn-soft-gray-7[type].disabled, .btn-soft-gray-7[type]:disabled {
  color: #5c6770;
  background-color: rgba(92, 103, 112, 0.1);
  box-shadow: none;
}

.btn-soft-gray-7[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-7[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-7[href].dropdown-toggle, .btn-soft-gray-7[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-7[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-7[type].dropdown-toggle {
  color: #fff;
  background-color: #5c6770;
}

.btn.btn-soft-gray-7 {
  color: #5c6770;
}

/* gray-8 air */
.btn-soft-gray-8 {
  color: #c5cdd8;
  background: rgba(197, 205, 216, 0.1);
  border-color: transparent;
}

.btn-soft-gray-8[href]:hover, .btn-soft-gray-8[href]:focus, .btn-soft-gray-8[href]:active, .btn-soft-gray-8[href].active, .btn-soft-gray-8[type]:hover, .btn-soft-gray-8[type]:focus, .btn-soft-gray-8[type]:active, .btn-soft-gray-8[type].active {
  color: #3b444f;
  background: #c5cdd8;
  box-shadow: 0 4px 11px rgba(197, 205, 216, 0.35);
}

.btn-soft-gray-8[href].disabled, .btn-soft-gray-8[href]:disabled, .btn-soft-gray-8[type].disabled, .btn-soft-gray-8[type]:disabled {
  color: #c5cdd8;
  background-color: rgba(197, 205, 216, 0.1);
  box-shadow: none;
}

.btn-soft-gray-8[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-8[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-8[href].dropdown-toggle, .btn-soft-gray-8[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-8[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-8[type].dropdown-toggle {
  color: #3b444f;
  background-color: #c5cdd8;
}

.btn.btn-soft-gray-8 {
  color: #c5cdd8;
}

/* gray-9 air */
.btn-soft-gray-9 {
  color: #9fa9b8;
  background: rgba(159, 169, 184, 0.1);
  border-color: transparent;
}

.btn-soft-gray-9[href]:hover, .btn-soft-gray-9[href]:focus, .btn-soft-gray-9[href]:active, .btn-soft-gray-9[href].active, .btn-soft-gray-9[type]:hover, .btn-soft-gray-9[type]:focus, .btn-soft-gray-9[type]:active, .btn-soft-gray-9[type].active {
  color: #3b444f;
  background: #9fa9b8;
  box-shadow: 0 4px 11px rgba(159, 169, 184, 0.35);
}

.btn-soft-gray-9[href].disabled, .btn-soft-gray-9[href]:disabled, .btn-soft-gray-9[type].disabled, .btn-soft-gray-9[type]:disabled {
  color: #9fa9b8;
  background-color: rgba(159, 169, 184, 0.1);
  box-shadow: none;
}

.btn-soft-gray-9[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-9[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-9[href].dropdown-toggle, .btn-soft-gray-9[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-9[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-9[type].dropdown-toggle {
  color: #3b444f;
  background-color: #9fa9b8;
}

.btn.btn-soft-gray-9 {
  color: #9fa9b8;
}

/* gray-10 air */
.btn-soft-gray-10 {
  color: #c1c1c1;
  background: rgba(193, 193, 193, 0.1);
  border-color: transparent;
}

.btn-soft-gray-10[href]:hover, .btn-soft-gray-10[href]:focus, .btn-soft-gray-10[href]:active, .btn-soft-gray-10[href].active, .btn-soft-gray-10[type]:hover, .btn-soft-gray-10[type]:focus, .btn-soft-gray-10[type]:active, .btn-soft-gray-10[type].active {
  color: #3b444f;
  background: #c1c1c1;
  box-shadow: 0 4px 11px rgba(193, 193, 193, 0.35);
}

.btn-soft-gray-10[href].disabled, .btn-soft-gray-10[href]:disabled, .btn-soft-gray-10[type].disabled, .btn-soft-gray-10[type]:disabled {
  color: #c1c1c1;
  background-color: rgba(193, 193, 193, 0.1);
  box-shadow: none;
}

.btn-soft-gray-10[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-10[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-10[href].dropdown-toggle, .btn-soft-gray-10[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-10[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-10[type].dropdown-toggle {
  color: #3b444f;
  background-color: #c1c1c1;
}

.btn.btn-soft-gray-10 {
  color: #c1c1c1;
}

/* gray-11 air */
.btn-soft-gray-11 {
  color: #484848;
  background: rgba(72, 72, 72, 0.1);
  border-color: transparent;
}

.btn-soft-gray-11[href]:hover, .btn-soft-gray-11[href]:focus, .btn-soft-gray-11[href]:active, .btn-soft-gray-11[href].active, .btn-soft-gray-11[type]:hover, .btn-soft-gray-11[type]:focus, .btn-soft-gray-11[type]:active, .btn-soft-gray-11[type].active {
  color: #fff;
  background: #484848;
  box-shadow: 0 4px 11px rgba(72, 72, 72, 0.35);
}

.btn-soft-gray-11[href].disabled, .btn-soft-gray-11[href]:disabled, .btn-soft-gray-11[type].disabled, .btn-soft-gray-11[type]:disabled {
  color: #484848;
  background-color: rgba(72, 72, 72, 0.1);
  box-shadow: none;
}

.btn-soft-gray-11[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-11[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-11[href].dropdown-toggle, .btn-soft-gray-11[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-11[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-11[type].dropdown-toggle {
  color: #fff;
  background-color: #484848;
}

.btn.btn-soft-gray-11 {
  color: #484848;
}

/* gray-23 air */
.btn-soft-gray-23 {
  color: #f4f4f4;
  background: rgba(244, 244, 244, 0.1);
  border-color: transparent;
}

.btn-soft-gray-23[href]:hover, .btn-soft-gray-23[href]:focus, .btn-soft-gray-23[href]:active, .btn-soft-gray-23[href].active, .btn-soft-gray-23[type]:hover, .btn-soft-gray-23[type]:focus, .btn-soft-gray-23[type]:active, .btn-soft-gray-23[type].active {
  color: #3b444f;
  background: #f4f4f4;
  box-shadow: 0 4px 11px rgba(244, 244, 244, 0.35);
}

.btn-soft-gray-23[href].disabled, .btn-soft-gray-23[href]:disabled, .btn-soft-gray-23[type].disabled, .btn-soft-gray-23[type]:disabled {
  color: #f4f4f4;
  background-color: rgba(244, 244, 244, 0.1);
  box-shadow: none;
}

.btn-soft-gray-23[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-23[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-23[href].dropdown-toggle, .btn-soft-gray-23[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-23[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-23[type].dropdown-toggle {
  color: #3b444f;
  background-color: #f4f4f4;
}

.btn.btn-soft-gray-23 {
  color: #f4f4f4;
}

/* gray-33 air */
.btn-soft-gray-33 {
  color: #ebf0f7;
  background: rgba(235, 240, 247, 0.1);
  border-color: transparent;
}

.btn-soft-gray-33[href]:hover, .btn-soft-gray-33[href]:focus, .btn-soft-gray-33[href]:active, .btn-soft-gray-33[href].active, .btn-soft-gray-33[type]:hover, .btn-soft-gray-33[type]:focus, .btn-soft-gray-33[type]:active, .btn-soft-gray-33[type].active {
  color: #3b444f;
  background: #ebf0f7;
  box-shadow: 0 4px 11px rgba(235, 240, 247, 0.35);
}

.btn-soft-gray-33[href].disabled, .btn-soft-gray-33[href]:disabled, .btn-soft-gray-33[type].disabled, .btn-soft-gray-33[type]:disabled {
  color: #ebf0f7;
  background-color: rgba(235, 240, 247, 0.1);
  box-shadow: none;
}

.btn-soft-gray-33[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-33[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-33[href].dropdown-toggle, .btn-soft-gray-33[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-33[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-33[type].dropdown-toggle {
  color: #3b444f;
  background-color: #ebf0f7;
}

.btn.btn-soft-gray-33 {
  color: #ebf0f7;
}

/* sea-green air */
.btn-soft-sea-green {
  color: #1d508d;
  background: rgba(29, 80, 141, 0.1);
  border-color: transparent;
}

.btn-soft-sea-green[href]:hover, .btn-soft-sea-green[href]:focus, .btn-soft-sea-green[href]:active, .btn-soft-sea-green[href].active, .btn-soft-sea-green[type]:hover, .btn-soft-sea-green[type]:focus, .btn-soft-sea-green[type]:active, .btn-soft-sea-green[type].active {
  color: #fff;
  background: #1d508d;
  box-shadow: 0 4px 11px rgba(29, 80, 141, 0.35);
}

.btn-soft-sea-green[href].disabled, .btn-soft-sea-green[href]:disabled, .btn-soft-sea-green[type].disabled, .btn-soft-sea-green[type]:disabled {
  color: #1d508d;
  background-color: rgba(29, 80, 141, 0.1);
  box-shadow: none;
}

.btn-soft-sea-green[href]:not(:disabled):not(.disabled):active, .btn-soft-sea-green[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-sea-green[href].dropdown-toggle, .btn-soft-sea-green[type]:not(:disabled):not(.disabled):active, .btn-soft-sea-green[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-sea-green[type].dropdown-toggle {
  color: #fff;
  background-color: #1d508d;
}

.btn.btn-soft-sea-green {
  color: #1d508d;
}

/* dark-1 air */
.btn-soft-dark-1 {
  color: #1e2022;
  background: rgba(30, 32, 34, 0.1);
  border-color: transparent;
}

.btn-soft-dark-1[href]:hover, .btn-soft-dark-1[href]:focus, .btn-soft-dark-1[href]:active, .btn-soft-dark-1[href].active, .btn-soft-dark-1[type]:hover, .btn-soft-dark-1[type]:focus, .btn-soft-dark-1[type]:active, .btn-soft-dark-1[type].active {
  color: #fff;
  background: #1e2022;
  box-shadow: 0 4px 11px rgba(30, 32, 34, 0.35);
}

.btn-soft-dark-1[href].disabled, .btn-soft-dark-1[href]:disabled, .btn-soft-dark-1[type].disabled, .btn-soft-dark-1[type]:disabled {
  color: #1e2022;
  background-color: rgba(30, 32, 34, 0.1);
  box-shadow: none;
}

.btn-soft-dark-1[href]:not(:disabled):not(.disabled):active, .btn-soft-dark-1[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-dark-1[href].dropdown-toggle, .btn-soft-dark-1[type]:not(:disabled):not(.disabled):active, .btn-soft-dark-1[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-dark-1[type].dropdown-toggle {
  color: #fff;
  background-color: #1e2022;
}

.btn.btn-soft-dark-1 {
  color: #1e2022;
}

/* navy air */
.btn-soft-navy {
  color: #0057ff;
  background: rgba(0, 87, 255, 0.1);
  border-color: transparent;
}

.btn-soft-navy[href]:hover, .btn-soft-navy[href]:focus, .btn-soft-navy[href]:active, .btn-soft-navy[href].active, .btn-soft-navy[type]:hover, .btn-soft-navy[type]:focus, .btn-soft-navy[type]:active, .btn-soft-navy[type].active {
  color: #fff;
  background: #0057ff;
  box-shadow: 0 4px 11px rgba(0, 87, 255, 0.35);
}

.btn-soft-navy[href].disabled, .btn-soft-navy[href]:disabled, .btn-soft-navy[type].disabled, .btn-soft-navy[type]:disabled {
  color: #0057ff;
  background-color: rgba(0, 87, 255, 0.1);
  box-shadow: none;
}

.btn-soft-navy[href]:not(:disabled):not(.disabled):active, .btn-soft-navy[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-navy[href].dropdown-toggle, .btn-soft-navy[type]:not(:disabled):not(.disabled):active, .btn-soft-navy[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-navy[type].dropdown-toggle {
  color: #fff;
  background-color: #0057ff;
}

.btn.btn-soft-navy {
  color: #0057ff;
}

/* pink air */
.btn-soft-pink {
  color: #ffe5ee;
  background: rgba(255, 229, 238, 0.1);
  border-color: transparent;
}

.btn-soft-pink[href]:hover, .btn-soft-pink[href]:focus, .btn-soft-pink[href]:active, .btn-soft-pink[href].active, .btn-soft-pink[type]:hover, .btn-soft-pink[type]:focus, .btn-soft-pink[type]:active, .btn-soft-pink[type].active {
  color: #3b444f;
  background: #ffe5ee;
  box-shadow: 0 4px 11px rgba(255, 229, 238, 0.35);
}

.btn-soft-pink[href].disabled, .btn-soft-pink[href]:disabled, .btn-soft-pink[type].disabled, .btn-soft-pink[type]:disabled {
  color: #ffe5ee;
  background-color: rgba(255, 229, 238, 0.1);
  box-shadow: none;
}

.btn-soft-pink[href]:not(:disabled):not(.disabled):active, .btn-soft-pink[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-pink[href].dropdown-toggle, .btn-soft-pink[type]:not(:disabled):not(.disabled):active, .btn-soft-pink[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-pink[type].dropdown-toggle {
  color: #3b444f;
  background-color: #ffe5ee;
}

.btn.btn-soft-pink {
  color: #ffe5ee;
}

/* dark-2 air */
.btn-soft-dark-2 {
  color: #1a2b49;
  background: rgba(26, 43, 73, 0.1);
  border-color: transparent;
}

.btn-soft-dark-2[href]:hover, .btn-soft-dark-2[href]:focus, .btn-soft-dark-2[href]:active, .btn-soft-dark-2[href].active, .btn-soft-dark-2[type]:hover, .btn-soft-dark-2[type]:focus, .btn-soft-dark-2[type]:active, .btn-soft-dark-2[type].active {
  color: #fff;
  background: #1a2b49;
  box-shadow: 0 4px 11px rgba(26, 43, 73, 0.35);
}

.btn-soft-dark-2[href].disabled, .btn-soft-dark-2[href]:disabled, .btn-soft-dark-2[type].disabled, .btn-soft-dark-2[type]:disabled {
  color: #1a2b49;
  background-color: rgba(26, 43, 73, 0.1);
  box-shadow: none;
}

.btn-soft-dark-2[href]:not(:disabled):not(.disabled):active, .btn-soft-dark-2[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-dark-2[href].dropdown-toggle, .btn-soft-dark-2[type]:not(:disabled):not(.disabled):active, .btn-soft-dark-2[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-dark-2[type].dropdown-toggle {
  color: #fff;
  background-color: #1a2b49;
}

.btn.btn-soft-dark-2 {
  color: #1a2b49;
}

/* yellow-lighter-2 air */
.btn-soft-yellow-lighter-2 {
  color: #f7bc5a;
  background: rgba(247, 188, 90, 0.1);
  border-color: transparent;
}

.btn-soft-yellow-lighter-2[href]:hover, .btn-soft-yellow-lighter-2[href]:focus, .btn-soft-yellow-lighter-2[href]:active, .btn-soft-yellow-lighter-2[href].active, .btn-soft-yellow-lighter-2[type]:hover, .btn-soft-yellow-lighter-2[type]:focus, .btn-soft-yellow-lighter-2[type]:active, .btn-soft-yellow-lighter-2[type].active {
  color: #3b444f;
  background: #f7bc5a;
  box-shadow: 0 4px 11px rgba(247, 188, 90, 0.35);
}

.btn-soft-yellow-lighter-2[href].disabled, .btn-soft-yellow-lighter-2[href]:disabled, .btn-soft-yellow-lighter-2[type].disabled, .btn-soft-yellow-lighter-2[type]:disabled {
  color: #f7bc5a;
  background-color: rgba(247, 188, 90, 0.1);
  box-shadow: none;
}

.btn-soft-yellow-lighter-2[href]:not(:disabled):not(.disabled):active, .btn-soft-yellow-lighter-2[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-yellow-lighter-2[href].dropdown-toggle, .btn-soft-yellow-lighter-2[type]:not(:disabled):not(.disabled):active, .btn-soft-yellow-lighter-2[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-yellow-lighter-2[type].dropdown-toggle {
  color: #3b444f;
  background-color: #f7bc5a;
}

.btn.btn-soft-yellow-lighter-2 {
  color: #f7bc5a;
}

/* orange air */
.btn-soft-orange {
  color: #f05700;
  background: rgba(240, 87, 0, 0.1);
  border-color: transparent;
}

.btn-soft-orange[href]:hover, .btn-soft-orange[href]:focus, .btn-soft-orange[href]:active, .btn-soft-orange[href].active, .btn-soft-orange[type]:hover, .btn-soft-orange[type]:focus, .btn-soft-orange[type]:active, .btn-soft-orange[type].active {
  color: #fff;
  background: #f05700;
  box-shadow: 0 4px 11px rgba(240, 87, 0, 0.35);
}

.btn-soft-orange[href].disabled, .btn-soft-orange[href]:disabled, .btn-soft-orange[type].disabled, .btn-soft-orange[type]:disabled {
  color: #f05700;
  background-color: rgba(240, 87, 0, 0.1);
  box-shadow: none;
}

.btn-soft-orange[href]:not(:disabled):not(.disabled):active, .btn-soft-orange[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-orange[href].dropdown-toggle, .btn-soft-orange[type]:not(:disabled):not(.disabled):active, .btn-soft-orange[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-orange[type].dropdown-toggle {
  color: #fff;
  background-color: #f05700;
}

.btn.btn-soft-orange {
  color: #f05700;
}

/* green air */
.btn-soft-green {
  color: #28871c;
  background: rgba(40, 135, 28, 0.1);
  border-color: transparent;
}

.btn-soft-green[href]:hover, .btn-soft-green[href]:focus, .btn-soft-green[href]:active, .btn-soft-green[href].active, .btn-soft-green[type]:hover, .btn-soft-green[type]:focus, .btn-soft-green[type]:active, .btn-soft-green[type].active {
  color: #fff;
  background: #28871c;
  box-shadow: 0 4px 11px rgba(40, 135, 28, 0.35);
}

.btn-soft-green[href].disabled, .btn-soft-green[href]:disabled, .btn-soft-green[type].disabled, .btn-soft-green[type]:disabled {
  color: #28871c;
  background-color: rgba(40, 135, 28, 0.1);
  box-shadow: none;
}

.btn-soft-green[href]:not(:disabled):not(.disabled):active, .btn-soft-green[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-green[href].dropdown-toggle, .btn-soft-green[type]:not(:disabled):not(.disabled):active, .btn-soft-green[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-green[type].dropdown-toggle {
  color: #fff;
  background-color: #28871c;
}

.btn.btn-soft-green {
  color: #28871c;
}

/* pink-1 air */
.btn-soft-pink-1 {
  color: #ff0045;
  background: rgba(255, 0, 69, 0.1);
  border-color: transparent;
}

.btn-soft-pink-1[href]:hover, .btn-soft-pink-1[href]:focus, .btn-soft-pink-1[href]:active, .btn-soft-pink-1[href].active, .btn-soft-pink-1[type]:hover, .btn-soft-pink-1[type]:focus, .btn-soft-pink-1[type]:active, .btn-soft-pink-1[type].active {
  color: #fff;
  background: #ff0045;
  box-shadow: 0 4px 11px rgba(255, 0, 69, 0.35);
}

.btn-soft-pink-1[href].disabled, .btn-soft-pink-1[href]:disabled, .btn-soft-pink-1[type].disabled, .btn-soft-pink-1[type]:disabled {
  color: #ff0045;
  background-color: rgba(255, 0, 69, 0.1);
  box-shadow: none;
}

.btn-soft-pink-1[href]:not(:disabled):not(.disabled):active, .btn-soft-pink-1[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-pink-1[href].dropdown-toggle, .btn-soft-pink-1[type]:not(:disabled):not(.disabled):active, .btn-soft-pink-1[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-pink-1[type].dropdown-toggle {
  color: #fff;
  background-color: #ff0045;
}

.btn.btn-soft-pink-1 {
  color: #ff0045;
}

/* purple-1 air */
.btn-soft-purple-1 {
  color: #232753;
  background: rgba(35, 39, 83, 0.1);
  border-color: transparent;
}

.btn-soft-purple-1[href]:hover, .btn-soft-purple-1[href]:focus, .btn-soft-purple-1[href]:active, .btn-soft-purple-1[href].active, .btn-soft-purple-1[type]:hover, .btn-soft-purple-1[type]:focus, .btn-soft-purple-1[type]:active, .btn-soft-purple-1[type].active {
  color: #fff;
  background: #232753;
  box-shadow: 0 4px 11px rgba(35, 39, 83, 0.35);
}

.btn-soft-purple-1[href].disabled, .btn-soft-purple-1[href]:disabled, .btn-soft-purple-1[type].disabled, .btn-soft-purple-1[type]:disabled {
  color: #232753;
  background-color: rgba(35, 39, 83, 0.1);
  box-shadow: none;
}

.btn-soft-purple-1[href]:not(:disabled):not(.disabled):active, .btn-soft-purple-1[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-purple-1[href].dropdown-toggle, .btn-soft-purple-1[type]:not(:disabled):not(.disabled):active, .btn-soft-purple-1[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-purple-1[type].dropdown-toggle {
  color: #fff;
  background-color: #232753;
}

.btn.btn-soft-purple-1 {
  color: #232753;
}

/* facebook air */
.btn-soft-facebook {
  color: #395899;
  background: rgba(57, 88, 153, 0.1);
  border-color: transparent;
}

.btn-soft-facebook[href]:hover, .btn-soft-facebook[href]:focus, .btn-soft-facebook[href]:active, .btn-soft-facebook[href].active, .btn-soft-facebook[type]:hover, .btn-soft-facebook[type]:focus, .btn-soft-facebook[type]:active, .btn-soft-facebook[type].active {
  color: #fff;
  background: #395899;
  box-shadow: 0 4px 11px rgba(57, 88, 153, 0.35);
}

.btn-soft-facebook[href].disabled, .btn-soft-facebook[href]:disabled, .btn-soft-facebook[type].disabled, .btn-soft-facebook[type]:disabled {
  color: #395899;
  background-color: rgba(57, 88, 153, 0.1);
  box-shadow: none;
}

.btn-soft-facebook[href]:not(:disabled):not(.disabled):active, .btn-soft-facebook[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-facebook[href].dropdown-toggle, .btn-soft-facebook[type]:not(:disabled):not(.disabled):active, .btn-soft-facebook[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-facebook[type].dropdown-toggle {
  color: #fff;
  background-color: #395899;
}

.btn.btn-soft-facebook {
  color: #395899;
}

/* google air */
.btn-soft-google {
  color: #d14130;
  background: rgba(209, 65, 48, 0.1);
  border-color: transparent;
}

.btn-soft-google[href]:hover, .btn-soft-google[href]:focus, .btn-soft-google[href]:active, .btn-soft-google[href].active, .btn-soft-google[type]:hover, .btn-soft-google[type]:focus, .btn-soft-google[type]:active, .btn-soft-google[type].active {
  color: #fff;
  background: #d14130;
  box-shadow: 0 4px 11px rgba(209, 65, 48, 0.35);
}

.btn-soft-google[href].disabled, .btn-soft-google[href]:disabled, .btn-soft-google[type].disabled, .btn-soft-google[type]:disabled {
  color: #d14130;
  background-color: rgba(209, 65, 48, 0.1);
  box-shadow: none;
}

.btn-soft-google[href]:not(:disabled):not(.disabled):active, .btn-soft-google[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-google[href].dropdown-toggle, .btn-soft-google[type]:not(:disabled):not(.disabled):active, .btn-soft-google[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-google[type].dropdown-toggle {
  color: #fff;
  background-color: #d14130;
}

.btn.btn-soft-google {
  color: #d14130;
}

/* twitter air */
.btn-soft-twitter {
  color: #03a9e0;
  background: rgba(3, 169, 224, 0.1);
  border-color: transparent;
}

.btn-soft-twitter[href]:hover, .btn-soft-twitter[href]:focus, .btn-soft-twitter[href]:active, .btn-soft-twitter[href].active, .btn-soft-twitter[type]:hover, .btn-soft-twitter[type]:focus, .btn-soft-twitter[type]:active, .btn-soft-twitter[type].active {
  color: #fff;
  background: #03a9e0;
  box-shadow: 0 4px 11px rgba(3, 169, 224, 0.35);
}

.btn-soft-twitter[href].disabled, .btn-soft-twitter[href]:disabled, .btn-soft-twitter[type].disabled, .btn-soft-twitter[type]:disabled {
  color: #03a9e0;
  background-color: rgba(3, 169, 224, 0.1);
  box-shadow: none;
}

.btn-soft-twitter[href]:not(:disabled):not(.disabled):active, .btn-soft-twitter[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-twitter[href].dropdown-toggle, .btn-soft-twitter[type]:not(:disabled):not(.disabled):active, .btn-soft-twitter[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-twitter[type].dropdown-toggle {
  color: #fff;
  background-color: #03a9e0;
}

.btn.btn-soft-twitter {
  color: #03a9e0;
}

/* instagram air */
.btn-soft-instagram {
  color: #3f729b;
  background: rgba(63, 114, 155, 0.1);
  border-color: transparent;
}

.btn-soft-instagram[href]:hover, .btn-soft-instagram[href]:focus, .btn-soft-instagram[href]:active, .btn-soft-instagram[href].active, .btn-soft-instagram[type]:hover, .btn-soft-instagram[type]:focus, .btn-soft-instagram[type]:active, .btn-soft-instagram[type].active {
  color: #fff;
  background: #3f729b;
  box-shadow: 0 4px 11px rgba(63, 114, 155, 0.35);
}

.btn-soft-instagram[href].disabled, .btn-soft-instagram[href]:disabled, .btn-soft-instagram[type].disabled, .btn-soft-instagram[type]:disabled {
  color: #3f729b;
  background-color: rgba(63, 114, 155, 0.1);
  box-shadow: none;
}

.btn-soft-instagram[href]:not(:disabled):not(.disabled):active, .btn-soft-instagram[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-instagram[href].dropdown-toggle, .btn-soft-instagram[type]:not(:disabled):not(.disabled):active, .btn-soft-instagram[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-instagram[type].dropdown-toggle {
  color: #fff;
  background-color: #3f729b;
}

.btn.btn-soft-instagram {
  color: #3f729b;
}

/* github air */
.btn-soft-github {
  color: #24292e;
  background: rgba(36, 41, 46, 0.1);
  border-color: transparent;
}

.btn-soft-github[href]:hover, .btn-soft-github[href]:focus, .btn-soft-github[href]:active, .btn-soft-github[href].active, .btn-soft-github[type]:hover, .btn-soft-github[type]:focus, .btn-soft-github[type]:active, .btn-soft-github[type].active {
  color: #fff;
  background: #24292e;
  box-shadow: 0 4px 11px rgba(36, 41, 46, 0.35);
}

.btn-soft-github[href].disabled, .btn-soft-github[href]:disabled, .btn-soft-github[type].disabled, .btn-soft-github[type]:disabled {
  color: #24292e;
  background-color: rgba(36, 41, 46, 0.1);
  box-shadow: none;
}

.btn-soft-github[href]:not(:disabled):not(.disabled):active, .btn-soft-github[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-github[href].dropdown-toggle, .btn-soft-github[type]:not(:disabled):not(.disabled):active, .btn-soft-github[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-github[type].dropdown-toggle {
  color: #fff;
  background-color: #24292e;
}

.btn.btn-soft-github {
  color: #24292e;
}

/* gray air */
.btn-soft-gray {
  color: #f8fafd;
  background: rgba(248, 250, 253, 0.1);
  border-color: transparent;
}

.btn-soft-gray[href]:hover, .btn-soft-gray[href]:focus, .btn-soft-gray[href]:active, .btn-soft-gray[href].active, .btn-soft-gray[type]:hover, .btn-soft-gray[type]:focus, .btn-soft-gray[type]:active, .btn-soft-gray[type].active {
  color: #3b444f;
  background: #f8fafd;
  box-shadow: 0 4px 11px rgba(248, 250, 253, 0.35);
}

.btn-soft-gray[href].disabled, .btn-soft-gray[href]:disabled, .btn-soft-gray[type].disabled, .btn-soft-gray[type]:disabled {
  color: #f8fafd;
  background-color: rgba(248, 250, 253, 0.1);
  box-shadow: none;
}

.btn-soft-gray[href]:not(:disabled):not(.disabled):active, .btn-soft-gray[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray[href].dropdown-toggle, .btn-soft-gray[type]:not(:disabled):not(.disabled):active, .btn-soft-gray[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray[type].dropdown-toggle {
  color: #3b444f;
  background-color: #f8fafd;
}

.btn.btn-soft-gray {
  color: #f8fafd;
}

/* purple air */
.btn-soft-purple {
  color: #8023df;
  background: rgba(128, 35, 223, 0.1);
  border-color: transparent;
}

.btn-soft-purple[href]:hover, .btn-soft-purple[href]:focus, .btn-soft-purple[href]:active, .btn-soft-purple[href].active, .btn-soft-purple[type]:hover, .btn-soft-purple[type]:focus, .btn-soft-purple[type]:active, .btn-soft-purple[type].active {
  color: #fff;
  background: #8023df;
  box-shadow: 0 4px 11px rgba(128, 35, 223, 0.35);
}

.btn-soft-purple[href].disabled, .btn-soft-purple[href]:disabled, .btn-soft-purple[type].disabled, .btn-soft-purple[type]:disabled {
  color: #8023df;
  background-color: rgba(128, 35, 223, 0.1);
  box-shadow: none;
}

.btn-soft-purple[href]:not(:disabled):not(.disabled):active, .btn-soft-purple[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-purple[href].dropdown-toggle, .btn-soft-purple[type]:not(:disabled):not(.disabled):active, .btn-soft-purple[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-purple[type].dropdown-toggle {
  color: #fff;
  background-color: #8023df;
}

.btn.btn-soft-purple {
  color: #8023df;
}

/* blue air */
.btn-soft-blue {
  color: #4cbfd8;
  background: rgba(76, 191, 216, 0.1);
  border-color: transparent;
}

.btn-soft-blue[href]:hover, .btn-soft-blue[href]:focus, .btn-soft-blue[href]:active, .btn-soft-blue[href].active, .btn-soft-blue[type]:hover, .btn-soft-blue[type]:focus, .btn-soft-blue[type]:active, .btn-soft-blue[type].active {
  color: #3b444f;
  background: #4cbfd8;
  box-shadow: 0 4px 11px rgba(76, 191, 216, 0.35);
}

.btn-soft-blue[href].disabled, .btn-soft-blue[href]:disabled, .btn-soft-blue[type].disabled, .btn-soft-blue[type]:disabled {
  color: #4cbfd8;
  background-color: rgba(76, 191, 216, 0.1);
  box-shadow: none;
}

.btn-soft-blue[href]:not(:disabled):not(.disabled):active, .btn-soft-blue[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue[href].dropdown-toggle, .btn-soft-blue[type]:not(:disabled):not(.disabled):active, .btn-soft-blue[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue[type].dropdown-toggle {
  color: #3b444f;
  background-color: #4cbfd8;
}

.btn.btn-soft-blue {
  color: #4cbfd8;
}

/* violet air */
.btn-soft-violet {
  color: #8c689d;
  background: rgba(140, 104, 157, 0.1);
  border-color: transparent;
}

.btn-soft-violet[href]:hover, .btn-soft-violet[href]:focus, .btn-soft-violet[href]:active, .btn-soft-violet[href].active, .btn-soft-violet[type]:hover, .btn-soft-violet[type]:focus, .btn-soft-violet[type]:active, .btn-soft-violet[type].active {
  color: #fff;
  background: #8c689d;
  box-shadow: 0 4px 11px rgba(140, 104, 157, 0.35);
}

.btn-soft-violet[href].disabled, .btn-soft-violet[href]:disabled, .btn-soft-violet[type].disabled, .btn-soft-violet[type]:disabled {
  color: #8c689d;
  background-color: rgba(140, 104, 157, 0.1);
  box-shadow: none;
}

.btn-soft-violet[href]:not(:disabled):not(.disabled):active, .btn-soft-violet[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-violet[href].dropdown-toggle, .btn-soft-violet[type]:not(:disabled):not(.disabled):active, .btn-soft-violet[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-violet[type].dropdown-toggle {
  color: #fff;
  background-color: #8c689d;
}

.btn.btn-soft-violet {
  color: #8c689d;
}

/* coral air */
.btn-soft-coral {
  color: #ff8480;
  background: rgba(255, 132, 128, 0.1);
  border-color: transparent;
}

.btn-soft-coral[href]:hover, .btn-soft-coral[href]:focus, .btn-soft-coral[href]:active, .btn-soft-coral[href].active, .btn-soft-coral[type]:hover, .btn-soft-coral[type]:focus, .btn-soft-coral[type]:active, .btn-soft-coral[type].active {
  color: #3b444f;
  background: #ff8480;
  box-shadow: 0 4px 11px rgba(255, 132, 128, 0.35);
}

.btn-soft-coral[href].disabled, .btn-soft-coral[href]:disabled, .btn-soft-coral[type].disabled, .btn-soft-coral[type]:disabled {
  color: #ff8480;
  background-color: rgba(255, 132, 128, 0.1);
  box-shadow: none;
}

.btn-soft-coral[href]:not(:disabled):not(.disabled):active, .btn-soft-coral[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-coral[href].dropdown-toggle, .btn-soft-coral[type]:not(:disabled):not(.disabled):active, .btn-soft-coral[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-coral[type].dropdown-toggle {
  color: #3b444f;
  background-color: #ff8480;
}

.btn.btn-soft-coral {
  color: #ff8480;
}

/* yellow-light air */
.btn-soft-yellow-light {
  color: #ffb874;
  background: rgba(255, 184, 116, 0.1);
  border-color: transparent;
}

.btn-soft-yellow-light[href]:hover, .btn-soft-yellow-light[href]:focus, .btn-soft-yellow-light[href]:active, .btn-soft-yellow-light[href].active, .btn-soft-yellow-light[type]:hover, .btn-soft-yellow-light[type]:focus, .btn-soft-yellow-light[type]:active, .btn-soft-yellow-light[type].active {
  color: #3b444f;
  background: #ffb874;
  box-shadow: 0 4px 11px rgba(255, 184, 116, 0.35);
}

.btn-soft-yellow-light[href].disabled, .btn-soft-yellow-light[href]:disabled, .btn-soft-yellow-light[type].disabled, .btn-soft-yellow-light[type]:disabled {
  color: #ffb874;
  background-color: rgba(255, 184, 116, 0.1);
  box-shadow: none;
}

.btn-soft-yellow-light[href]:not(:disabled):not(.disabled):active, .btn-soft-yellow-light[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-yellow-light[href].dropdown-toggle, .btn-soft-yellow-light[type]:not(:disabled):not(.disabled):active, .btn-soft-yellow-light[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-yellow-light[type].dropdown-toggle {
  color: #3b444f;
  background-color: #ffb874;
}

.btn.btn-soft-yellow-light {
  color: #ffb874;
}

/* green-light air */
.btn-soft-green-light {
  color: #35c2a5;
  background: rgba(53, 194, 165, 0.1);
  border-color: transparent;
}

.btn-soft-green-light[href]:hover, .btn-soft-green-light[href]:focus, .btn-soft-green-light[href]:active, .btn-soft-green-light[href].active, .btn-soft-green-light[type]:hover, .btn-soft-green-light[type]:focus, .btn-soft-green-light[type]:active, .btn-soft-green-light[type].active {
  color: #fff;
  background: #35c2a5;
  box-shadow: 0 4px 11px rgba(53, 194, 165, 0.35);
}

.btn-soft-green-light[href].disabled, .btn-soft-green-light[href]:disabled, .btn-soft-green-light[type].disabled, .btn-soft-green-light[type]:disabled {
  color: #35c2a5;
  background-color: rgba(53, 194, 165, 0.1);
  box-shadow: none;
}

.btn-soft-green-light[href]:not(:disabled):not(.disabled):active, .btn-soft-green-light[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-green-light[href].dropdown-toggle, .btn-soft-green-light[type]:not(:disabled):not(.disabled):active, .btn-soft-green-light[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-green-light[type].dropdown-toggle {
  color: #fff;
  background-color: #35c2a5;
}

.btn.btn-soft-green-light {
  color: #35c2a5;
}

/* blue-1 air */
.btn-soft-blue-1 {
  color: #0057ff;
  background: rgba(0, 87, 255, 0.1);
  border-color: transparent;
}

.btn-soft-blue-1[href]:hover, .btn-soft-blue-1[href]:focus, .btn-soft-blue-1[href]:active, .btn-soft-blue-1[href].active, .btn-soft-blue-1[type]:hover, .btn-soft-blue-1[type]:focus, .btn-soft-blue-1[type]:active, .btn-soft-blue-1[type].active {
  color: #fff;
  background: #0057ff;
  box-shadow: 0 4px 11px rgba(0, 87, 255, 0.35);
}

.btn-soft-blue-1[href].disabled, .btn-soft-blue-1[href]:disabled, .btn-soft-blue-1[type].disabled, .btn-soft-blue-1[type]:disabled {
  color: #0057ff;
  background-color: rgba(0, 87, 255, 0.1);
  box-shadow: none;
}

.btn-soft-blue-1[href]:not(:disabled):not(.disabled):active, .btn-soft-blue-1[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue-1[href].dropdown-toggle, .btn-soft-blue-1[type]:not(:disabled):not(.disabled):active, .btn-soft-blue-1[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-blue-1[type].dropdown-toggle {
  color: #fff;
  background-color: #0057ff;
}

.btn.btn-soft-blue-1 {
  color: #0057ff;
}

/* gray-1 air */
.btn-soft-gray-1 {
  color: #ebf0f7;
  background: rgba(235, 240, 247, 0.1);
  border-color: transparent;
}

.btn-soft-gray-1[href]:hover, .btn-soft-gray-1[href]:focus, .btn-soft-gray-1[href]:active, .btn-soft-gray-1[href].active, .btn-soft-gray-1[type]:hover, .btn-soft-gray-1[type]:focus, .btn-soft-gray-1[type]:active, .btn-soft-gray-1[type].active {
  color: #3b444f;
  background: #ebf0f7;
  box-shadow: 0 4px 11px rgba(235, 240, 247, 0.35);
}

.btn-soft-gray-1[href].disabled, .btn-soft-gray-1[href]:disabled, .btn-soft-gray-1[type].disabled, .btn-soft-gray-1[type]:disabled {
  color: #ebf0f7;
  background-color: rgba(235, 240, 247, 0.1);
  box-shadow: none;
}

.btn-soft-gray-1[href]:not(:disabled):not(.disabled):active, .btn-soft-gray-1[href]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-1[href].dropdown-toggle, .btn-soft-gray-1[type]:not(:disabled):not(.disabled):active, .btn-soft-gray-1[type]:not(:disabled):not(.disabled).active,
.show > .btn-soft-gray-1[type].dropdown-toggle {
  color: #3b444f;
  background-color: #ebf0f7;
}

.btn.btn-soft-gray-1 {
  color: #ebf0f7;
}

/*------------------------------------
  Button Sizes
------------------------------------*/
/* Wide */
.btn-wide {
  min-width: 13.75rem;
}

.btn-wide-normal {
  min-width: 11.375rem;
}

.btn-md-wide {
  min-width: 12rem;
}

.btn-sm-wide {
  min-width: 8.4375rem;
}

/* Extra Small */
.btn-xs {
  font-size: 0.6875rem;
  font-weight: 400;
  padding: 0.4375rem 0.9375rem;
}

@media (min-width: 768px) {
  .btn-normal {
    min-width: 10.375rem;
    height: 3.75rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .btn-normal {
    min-width: 5rem;
    height: 3.75rem;
  }
}

@media (min-width: 768px) {
  .btn-md {
    min-width: 11.375rem;
    height: 3.75rem;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .btn-md {
    min-width: 2.188rem;
    height: 3.75rem;
  }
}

/* Icons */
.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
}

.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  font-size: 1rem;
  width: 1.7rem;
  height: 1.7rem;
}

.btn-icon.btn-medium {
  font-size: 1rem;
  width: 2.188rem;
  height: 2.188rem;
}

.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  font-size: 1.75rem;
  width: 5rem;
  height: 5rem;
}

/*------------------------------------
  Text Buttons Styles
------------------------------------*/
/* primary text button */
.btn-text-primary {
  color: #297cbb;
  background: transparent;
}

.btn-text-primary[href]:hover, .btn-text-primary[href]:focus, .btn-text-primary[href]:active, .btn-text-primary[type]:hover, .btn-text-primary[type]:focus, .btn-text-primary[type]:active {
  color: #3b444f;
}

/* secondary text button */
.btn-text-secondary {
  color: #77838f;
  background: transparent;
}

.btn-text-secondary[href]:hover, .btn-text-secondary[href]:focus, .btn-text-secondary[href]:active, .btn-text-secondary[type]:hover, .btn-text-secondary[type]:focus, .btn-text-secondary[type]:active {
  color: #3b444f;
}

/* success text button */
.btn-text-success {
  color: #00c9a7;
  background: transparent;
}

.btn-text-success[href]:hover, .btn-text-success[href]:focus, .btn-text-success[href]:active, .btn-text-success[type]:hover, .btn-text-success[type]:focus, .btn-text-success[type]:active {
  color: #3b444f;
}

/* info text button */
.btn-text-info {
  color: #00dffc;
  background: transparent;
}

.btn-text-info[href]:hover, .btn-text-info[href]:focus, .btn-text-info[href]:active, .btn-text-info[type]:hover, .btn-text-info[type]:focus, .btn-text-info[type]:active {
  color: #3b444f;
}

/* warning text button */
.btn-text-warning {
  color: #ffc107;
  background: transparent;
}

.btn-text-warning[href]:hover, .btn-text-warning[href]:focus, .btn-text-warning[href]:active, .btn-text-warning[type]:hover, .btn-text-warning[type]:focus, .btn-text-warning[type]:active {
  color: #3b444f;
}

/* danger text button */
.btn-text-danger {
  color: #de4437;
  background: transparent;
}

.btn-text-danger[href]:hover, .btn-text-danger[href]:focus, .btn-text-danger[href]:active, .btn-text-danger[type]:hover, .btn-text-danger[type]:focus, .btn-text-danger[type]:active {
  color: #3b444f;
}

/* light text button */
.btn-text-light {
  color: #f8f9fa;
  background: transparent;
}

.btn-text-light[href]:hover, .btn-text-light[href]:focus, .btn-text-light[href]:active, .btn-text-light[type]:hover, .btn-text-light[type]:focus, .btn-text-light[type]:active {
  color: #bdc5d1;
}

/* dark text button */
.btn-text-dark {
  color: #3b444f;
  background: transparent;
}

.btn-text-dark[href]:hover, .btn-text-dark[href]:focus, .btn-text-dark[href]:active, .btn-text-dark[type]:hover, .btn-text-dark[type]:focus, .btn-text-dark[type]:active {
  color: #3b444f;
}

/* white text button */
.btn-text-white {
  color: #fff;
  background: transparent;
}

.btn-text-white[href]:hover, .btn-text-white[href]:focus, .btn-text-white[href]:active, .btn-text-white[type]:hover, .btn-text-white[type]:focus, .btn-text-white[type]:active {
  color: #3b444f;
}

/* indigo text button */
.btn-text-indigo {
  color: #2d1582;
  background: transparent;
}

.btn-text-indigo[href]:hover, .btn-text-indigo[href]:focus, .btn-text-indigo[href]:active, .btn-text-indigo[type]:hover, .btn-text-indigo[type]:focus, .btn-text-indigo[type]:active {
  color: #3b444f;
}

/* gray text button */
.btn-text-gray {
  color: #ebf0f7;
  background: transparent;
}

.btn-text-gray[href]:hover, .btn-text-gray[href]:focus, .btn-text-gray[href]:active, .btn-text-gray[type]:hover, .btn-text-gray[type]:focus, .btn-text-gray[type]:active {
  color: #3b444f;
}

/* violet text button */
.btn-text-violet {
  color: #532184;
  background: transparent;
}

.btn-text-violet[href]:hover, .btn-text-violet[href]:focus, .btn-text-violet[href]:active, .btn-text-violet[type]:hover, .btn-text-violet[type]:focus, .btn-text-violet[type]:active {
  color: #3b444f;
}

/* violet-1 text button */
.btn-text-violet-1 {
  color: #a864a8;
  background: transparent;
}

.btn-text-violet-1[href]:hover, .btn-text-violet-1[href]:focus, .btn-text-violet-1[href]:active, .btn-text-violet-1[type]:hover, .btn-text-violet-1[type]:focus, .btn-text-violet-1[type]:active {
  color: #3b444f;
}

/* violet-light text button */
.btn-text-violet-light {
  color: #6f13cd;
  background: transparent;
}

.btn-text-violet-light[href]:hover, .btn-text-violet-light[href]:focus, .btn-text-violet-light[href]:active, .btn-text-violet-light[type]:hover, .btn-text-violet-light[type]:focus, .btn-text-violet-light[type]:active {
  color: #3b444f;
}

/* brown-light text button */
.btn-text-brown-light {
  color: #ceac61;
  background: transparent;
}

.btn-text-brown-light[href]:hover, .btn-text-brown-light[href]:focus, .btn-text-brown-light[href]:active, .btn-text-brown-light[type]:hover, .btn-text-brown-light[type]:focus, .btn-text-brown-light[type]:active {
  color: #3b444f;
}

/* brown text button */
.btn-text-brown {
  color: #f8bd5b;
  background: transparent;
}

.btn-text-brown[href]:hover, .btn-text-brown[href]:focus, .btn-text-brown[href]:active, .btn-text-brown[type]:hover, .btn-text-brown[type]:focus, .btn-text-brown[type]:active {
  color: #3b444f;
}

/* maroon text button */
.btn-text-maroon {
  color: #c72f74;
  background: transparent;
}

.btn-text-maroon[href]:hover, .btn-text-maroon[href]:focus, .btn-text-maroon[href]:active, .btn-text-maroon[type]:hover, .btn-text-maroon[type]:focus, .btn-text-maroon[type]:active {
  color: #3b444f;
}

/* indigo-light text button */
.btn-text-indigo-light {
  color: #080e7b;
  background: transparent;
}

.btn-text-indigo-light[href]:hover, .btn-text-indigo-light[href]:focus, .btn-text-indigo-light[href]:active, .btn-text-indigo-light[type]:hover, .btn-text-indigo-light[type]:focus, .btn-text-indigo-light[type]:active {
  color: #3b444f;
}

/* red-light-2 text button */
.btn-text-red-light-2 {
  color: #ff3c4e;
  background: transparent;
}

.btn-text-red-light-2[href]:hover, .btn-text-red-light-2[href]:focus, .btn-text-red-light-2[href]:active, .btn-text-red-light-2[type]:hover, .btn-text-red-light-2[type]:focus, .btn-text-red-light-2[type]:active {
  color: #3b444f;
}

/* blue-dark-1 text button */
.btn-text-blue-dark-1 {
  color: #045cff;
  background: transparent;
}

.btn-text-blue-dark-1[href]:hover, .btn-text-blue-dark-1[href]:focus, .btn-text-blue-dark-1[href]:active, .btn-text-blue-dark-1[type]:hover, .btn-text-blue-dark-1[type]:focus, .btn-text-blue-dark-1[type]:active {
  color: #3b444f;
}

/* gray-1 text button */
.btn-text-gray-1 {
  color: #67747c;
  background: transparent;
}

.btn-text-gray-1[href]:hover, .btn-text-gray-1[href]:focus, .btn-text-gray-1[href]:active, .btn-text-gray-1[type]:hover, .btn-text-gray-1[type]:focus, .btn-text-gray-1[type]:active {
  color: #3b444f;
}

/* gray-2 text button */
.btn-text-gray-2 {
  color: #f7f8f9;
  background: transparent;
}

.btn-text-gray-2[href]:hover, .btn-text-gray-2[href]:focus, .btn-text-gray-2[href]:active, .btn-text-gray-2[type]:hover, .btn-text-gray-2[type]:focus, .btn-text-gray-2[type]:active {
  color: #3b444f;
}

/* gray-3 text button */
.btn-text-gray-3 {
  color: #3b444f;
  background: transparent;
}

.btn-text-gray-3[href]:hover, .btn-text-gray-3[href]:focus, .btn-text-gray-3[href]:active, .btn-text-gray-3[type]:hover, .btn-text-gray-3[type]:focus, .btn-text-gray-3[type]:active {
  color: #3b444f;
}

/* gray-4 text button */
.btn-text-gray-4 {
  color: #eceff3;
  background: transparent;
}

.btn-text-gray-4[href]:hover, .btn-text-gray-4[href]:focus, .btn-text-gray-4[href]:active, .btn-text-gray-4[type]:hover, .btn-text-gray-4[type]:focus, .btn-text-gray-4[type]:active {
  color: #3b444f;
}

/* blue text button */
.btn-text-blue {
  color: #43c5db;
  background: transparent;
}

.btn-text-blue[href]:hover, .btn-text-blue[href]:focus, .btn-text-blue[href]:active, .btn-text-blue[type]:hover, .btn-text-blue[type]:focus, .btn-text-blue[type]:active {
  color: #3b444f;
}

/* blue-1 text button */
.btn-text-blue-1 {
  color: #297cbb;
  background: transparent;
}

.btn-text-blue-1[href]:hover, .btn-text-blue-1[href]:focus, .btn-text-blue-1[href]:active, .btn-text-blue-1[type]:hover, .btn-text-blue-1[type]:focus, .btn-text-blue-1[type]:active {
  color: #3b444f;
}

/* gray-5 text button */
.btn-text-gray-5 {
  color: #18181b;
  background: transparent;
}

.btn-text-gray-5[href]:hover, .btn-text-gray-5[href]:focus, .btn-text-gray-5[href]:active, .btn-text-gray-5[type]:hover, .btn-text-gray-5[type]:focus, .btn-text-gray-5[type]:active {
  color: #3b444f;
}

/* gray-6 text button */
.btn-text-gray-6 {
  color: #18181a;
  background: transparent;
}

.btn-text-gray-6[href]:hover, .btn-text-gray-6[href]:focus, .btn-text-gray-6[href]:active, .btn-text-gray-6[type]:hover, .btn-text-gray-6[type]:focus, .btn-text-gray-6[type]:active {
  color: #3b444f;
}

/* gray-7 text button */
.btn-text-gray-7 {
  color: #5c6770;
  background: transparent;
}

.btn-text-gray-7[href]:hover, .btn-text-gray-7[href]:focus, .btn-text-gray-7[href]:active, .btn-text-gray-7[type]:hover, .btn-text-gray-7[type]:focus, .btn-text-gray-7[type]:active {
  color: #3b444f;
}

/* gray-8 text button */
.btn-text-gray-8 {
  color: #c5cdd8;
  background: transparent;
}

.btn-text-gray-8[href]:hover, .btn-text-gray-8[href]:focus, .btn-text-gray-8[href]:active, .btn-text-gray-8[type]:hover, .btn-text-gray-8[type]:focus, .btn-text-gray-8[type]:active {
  color: #3b444f;
}

/* gray-9 text button */
.btn-text-gray-9 {
  color: #9fa9b8;
  background: transparent;
}

.btn-text-gray-9[href]:hover, .btn-text-gray-9[href]:focus, .btn-text-gray-9[href]:active, .btn-text-gray-9[type]:hover, .btn-text-gray-9[type]:focus, .btn-text-gray-9[type]:active {
  color: #3b444f;
}

/* gray-10 text button */
.btn-text-gray-10 {
  color: #c1c1c1;
  background: transparent;
}

.btn-text-gray-10[href]:hover, .btn-text-gray-10[href]:focus, .btn-text-gray-10[href]:active, .btn-text-gray-10[type]:hover, .btn-text-gray-10[type]:focus, .btn-text-gray-10[type]:active {
  color: #3b444f;
}

/* gray-11 text button */
.btn-text-gray-11 {
  color: #484848;
  background: transparent;
}

.btn-text-gray-11[href]:hover, .btn-text-gray-11[href]:focus, .btn-text-gray-11[href]:active, .btn-text-gray-11[type]:hover, .btn-text-gray-11[type]:focus, .btn-text-gray-11[type]:active {
  color: #3b444f;
}

/* gray-23 text button */
.btn-text-gray-23 {
  color: #f4f4f4;
  background: transparent;
}

.btn-text-gray-23[href]:hover, .btn-text-gray-23[href]:focus, .btn-text-gray-23[href]:active, .btn-text-gray-23[type]:hover, .btn-text-gray-23[type]:focus, .btn-text-gray-23[type]:active {
  color: #3b444f;
}

/* gray-33 text button */
.btn-text-gray-33 {
  color: #ebf0f7;
  background: transparent;
}

.btn-text-gray-33[href]:hover, .btn-text-gray-33[href]:focus, .btn-text-gray-33[href]:active, .btn-text-gray-33[type]:hover, .btn-text-gray-33[type]:focus, .btn-text-gray-33[type]:active {
  color: #3b444f;
}

/* sea-green text button */
.btn-text-sea-green {
  color: #1d508d;
  background: transparent;
}

.btn-text-sea-green[href]:hover, .btn-text-sea-green[href]:focus, .btn-text-sea-green[href]:active, .btn-text-sea-green[type]:hover, .btn-text-sea-green[type]:focus, .btn-text-sea-green[type]:active {
  color: #3b444f;
}

/* dark-1 text button */
.btn-text-dark-1 {
  color: #1e2022;
  background: transparent;
}

.btn-text-dark-1[href]:hover, .btn-text-dark-1[href]:focus, .btn-text-dark-1[href]:active, .btn-text-dark-1[type]:hover, .btn-text-dark-1[type]:focus, .btn-text-dark-1[type]:active {
  color: #3b444f;
}

/* navy text button */
.btn-text-navy {
  color: #0057ff;
  background: transparent;
}

.btn-text-navy[href]:hover, .btn-text-navy[href]:focus, .btn-text-navy[href]:active, .btn-text-navy[type]:hover, .btn-text-navy[type]:focus, .btn-text-navy[type]:active {
  color: #3b444f;
}

/* pink text button */
.btn-text-pink {
  color: #ffe5ee;
  background: transparent;
}

.btn-text-pink[href]:hover, .btn-text-pink[href]:focus, .btn-text-pink[href]:active, .btn-text-pink[type]:hover, .btn-text-pink[type]:focus, .btn-text-pink[type]:active {
  color: #3b444f;
}

/* dark-2 text button */
.btn-text-dark-2 {
  color: #1a2b49;
  background: transparent;
}

.btn-text-dark-2[href]:hover, .btn-text-dark-2[href]:focus, .btn-text-dark-2[href]:active, .btn-text-dark-2[type]:hover, .btn-text-dark-2[type]:focus, .btn-text-dark-2[type]:active {
  color: #3b444f;
}

/* yellow-lighter-2 text button */
.btn-text-yellow-lighter-2 {
  color: #f7bc5a;
  background: transparent;
}

.btn-text-yellow-lighter-2[href]:hover, .btn-text-yellow-lighter-2[href]:focus, .btn-text-yellow-lighter-2[href]:active, .btn-text-yellow-lighter-2[type]:hover, .btn-text-yellow-lighter-2[type]:focus, .btn-text-yellow-lighter-2[type]:active {
  color: #3b444f;
}

/* orange text button */
.btn-text-orange {
  color: #f05700;
  background: transparent;
}

.btn-text-orange[href]:hover, .btn-text-orange[href]:focus, .btn-text-orange[href]:active, .btn-text-orange[type]:hover, .btn-text-orange[type]:focus, .btn-text-orange[type]:active {
  color: #3b444f;
}

/* green text button */
.btn-text-green {
  color: #28871c;
  background: transparent;
}

.btn-text-green[href]:hover, .btn-text-green[href]:focus, .btn-text-green[href]:active, .btn-text-green[type]:hover, .btn-text-green[type]:focus, .btn-text-green[type]:active {
  color: #3b444f;
}

/* pink-1 text button */
.btn-text-pink-1 {
  color: #ff0045;
  background: transparent;
}

.btn-text-pink-1[href]:hover, .btn-text-pink-1[href]:focus, .btn-text-pink-1[href]:active, .btn-text-pink-1[type]:hover, .btn-text-pink-1[type]:focus, .btn-text-pink-1[type]:active {
  color: #3b444f;
}

/* purple-1 text button */
.btn-text-purple-1 {
  color: #232753;
  background: transparent;
}

.btn-text-purple-1[href]:hover, .btn-text-purple-1[href]:focus, .btn-text-purple-1[href]:active, .btn-text-purple-1[type]:hover, .btn-text-purple-1[type]:focus, .btn-text-purple-1[type]:active {
  color: #3b444f;
}

/*------------------------------------
  Buttons
------------------------------------*/
.btn[href] {
  cursor: pointer;
}

.btn:not([href]):not([type]):not(:disabled):not(.disabled) {
  cursor: default;
}

label.btn {
  cursor: pointer !important;
}

/* Pill */
.btn-pill {
  border-radius: 6.1875rem;
}

.btn-social {
  color: #67747c;
  border-color: #ebf0f7;
  border-width: 2px;
  border-radius: 6.1875rem;
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.btn-social:hover {
  color: #fff;
  background: #297cbb;
  border-color: #297cbb;
}

.btn-social-dark {
  color: #67747c;
  border-color: #3c3c3c;
  border-width: 2px;
  border-radius: 6.1875rem;
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.btn-social-dark:hover {
  color: #fff;
  background: #297cbb;
  border-color: #297cbb;
}

/* Icon */
.btn-icon {
  position: relative;
  line-height: 0;
  font-size: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
}

.btn-icon__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.btn-icon__inner-bottom-minus {
  top: 80%;
}

/* Background Transparent */
[class*="btn-soft-"].btn-bg-transparent {
  background-color: transparent;
}

/* Toggle */
.btn__toggle-default {
  display: inline-block;
}

.btn__toggle-toggled {
  display: none;
}

.btn.toggled .btn__toggle-default {
  display: none;
}

.btn.toggled .btn__toggle-toggled {
  display: inline-block;
}

.close.close-rounded {
  font-size: 0.75rem;
  border: 1px solid #dce3ec;
  border-radius: 50%;
  padding: 0.656rem;
}

.button-inline-group {
  display: -ms-flexbox;
  display: flex;
}

.button-inline-group .btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

@media (min-width: 576px) {
  .button-inline-group-sm {
    display: -ms-flexbox;
    display: flex;
  }
  .button-inline-group-sm .btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
}

@media (min-width: 768px) {
  .button-inline-group-md {
    display: -ms-flexbox;
    display: flex;
  }
  .button-inline-group-md .btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
}

@media (min-width: 992px) {
  .button-inline-group-lg {
    display: -ms-flexbox;
    display: flex;
  }
  .button-inline-group-lg .btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
}

@media (min-width: 1200px) {
  .button-inline-group-xl {
    display: -ms-flexbox;
    display: flex;
  }
  .button-inline-group-xl .btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
}

@media (min-width: 1480px) {
  .button-inline-group-wd {
    display: -ms-flexbox;
    display: flex;
  }
  .button-inline-group-wd .btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
}

/*-------------------------------------------*\
    2.10. Brand
\*-------------------------------------------*/
.brand {
  font-size: 1.75rem;
  font-weight: 500;
  margin-left: .5rem;
}

.brand-primary, .brand-primary:focus, .brand-primary:hover {
  color: #297cbb;
}

.brand-light, .brand-light:focus, .brand-light:hover {
  color: #fff;
}

.brand-dark, .brand-dark:focus, .brand-dark:hover {
  color: #3b444f;
}

/*-------------------------------------------*\
    2.11. Breadcrumb
\*-------------------------------------------*/
.breadcrumb-white {
  background-color: transparent;
}

.breadcrumb-white .breadcrumb-item {
  color: #fff;
}

.breadcrumb-white .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.7);
}

.breadcrumb-white .breadcrumb-link {
  color: rgba(255, 255, 255, 0.7);
}

.breadcrumb-no-gutter {
  padding: 0;
}

.breadcrumb-item {
  color: #67747c;
  font-size: 0.875rem;
}

.breadcrumb-item a {
  color: #67747c;
}

.breadcrumb-item:not(.active):hover {
  color: #297cbb;
}

.breadcrumb-item:not(.active):hover a {
  color: #297cbb;
}

.custom-breadcrumb-item:before {
  color: #fff !important;
}

/*-------------------------------------------*\
    2.12. Card
\*-------------------------------------------*/
.card-2-count {
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
}

.card-3-count {
  -webkit-column-count: 3;
     -moz-column-count: 3;
          column-count: 3;
}

.card-4-count {
  -webkit-column-count: 4;
     -moz-column-count: 4;
          column-count: 4;
}

.card-columns {
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
  orphans: 1;
  widows: 1;
}

.card-columns .card {
  display: inline-block;
  width: 100%;
}

@media (min-width: 576px) {
  .card-sm-2-count {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .card-sm-3-count {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .card-sm-4-count {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .card-sm-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-sm-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .card-md-2-count {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .card-md-3-count {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .card-md-4-count {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .card-md-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-md-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .card-lg-2-count {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .card-lg-3-count {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .card-lg-4-count {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .card-lg-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-lg-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .card-xl-2-count {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .card-xl-3-count {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .card-xl-4-count {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .card-xl-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-xl-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 1480px) {
  .card-wd-2-count {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
  .card-wd-3-count {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
  }
  .card-wd-4-count {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
  }
  .card-wd-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-wd-columns .card {
    display: inline-block;
    width: 100%;
  }
}

/* Background Color */
.card-bg-light {
  background-color: #f8fafd;
}

.card-bg-light:hover {
  background-color: #f4f7fc;
}

/* Frame */
.card-frame {
  border: 1px solid #e7eaf3;
  transition: 0.3s;
}

.card-frame-highlighted, .card-frame:hover {
  border-color: rgba(41, 124, 187, 0.3);
  box-shadow: 0 0 35px rgba(41, 124, 187, 0.125);
}

.card-text-dark {
  color: #3b444f;
}

.card-text-dark:hover {
  color: #297cbb;
}

/* Collapse */
.card-collapse {
  padding: 0;
  transition: 0.3s ease-in-out;
}

.card-collapse:hover {
  background-color: #f8fafd;
}

.card-collapse .card-body {
  color: #77838f;
}

.card-btn {
  color: #3b444f;
  text-align: left;
  white-space: inherit;
}

.card-btn-arrow {
  display: inline-block;
  color: #297cbb;
  margin-left: 1rem;
  transition: 0.3s ease-in-out;
}

.collapsed .card-btn-arrow {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.shopping-cart .media-body span {
  line-height: 1;
}

.card-img {
  position: absolute;
  bottom: 100%;
  -webkit-transform: translateY(60px);
          transform: translateY(60px);
}

/* IE image rendering fix */
.card-img-top,
.card-img-bottom {
  min-height: 1px;
}

/*------------------------------------
  Card Gutters
------------------------------------*/
@media (min-width: 576px) {
  .card-sm-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-sm-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-sm-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-sm-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-sm-gutters-3 {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
  }
  .card-sm-gutters-3 .card {
    margin-right: .9375rem;
    margin-left: .9375rem;
  }
}

@media (min-width: 768px) {
  .card-md-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-md-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-md-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-md-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-md-gutters-3 {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
  }
  .card-md-gutters-3 .card {
    margin-right: .9375rem;
    margin-left: .9375rem;
  }
}

@media (min-width: 992px) {
  .card-lg-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-lg-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-lg-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-lg-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-lg-gutters-3 {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
  }
  .card-lg-gutters-3 .card {
    margin-right: .9375rem;
    margin-left: .9375rem;
  }
}

/*------------------------------------
  Card Group - Break
------------------------------------*/
@media (max-width: 767.98px) {
  .card-group-sm-break {
    display: block;
  }
  .card-group-sm-break > .card:not(:last-child) {
    margin-bottom: -1px;
  }
  .card-group-sm-break > .card + .card {
    border-left: 1px solid #e7eaf3;
  }
  .card-group-sm-break > .card:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:first-child .card-img-top,
  .card-group-sm-break > .card:first-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group-sm-break > .card:first-child .card-img-bottom,
  .card-group-sm-break > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-sm-break > .card:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-img-top,
  .card-group-sm-break > .card:last-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-sm-break > .card:last-child .card-img-bottom,
  .card-group-sm-break > .card:last-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}

@media (max-width: 991.98px) {
  .card-group-md-break {
    display: block;
  }
  .card-group-md-break > .card:not(:last-child) {
    margin-bottom: -1px;
  }
  .card-group-md-break > .card + .card {
    border-left: 1px solid #e7eaf3;
  }
  .card-group-md-break > .card:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:first-child .card-img-top,
  .card-group-md-break > .card:first-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group-md-break > .card:first-child .card-img-bottom,
  .card-group-md-break > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-md-break > .card:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-img-top,
  .card-group-md-break > .card:last-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-md-break > .card:last-child .card-img-bottom,
  .card-group-md-break > .card:last-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}

@media (max-width: 1199.98px) {
  .card-group-lg-break {
    display: block;
  }
  .card-group-lg-break > .card:not(:last-child) {
    margin-bottom: -1px;
  }
  .card-group-lg-break > .card + .card {
    border-left: 1px solid #e7eaf3;
  }
  .card-group-lg-break > .card:first-child {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:first-child .card-img-top,
  .card-group-lg-break > .card:first-child .card-header {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
  }
  .card-group-lg-break > .card:first-child .card-img-bottom,
  .card-group-lg-break > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group-lg-break > .card:last-child {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-img-top,
  .card-group-lg-break > .card:last-child .card-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group-lg-break > .card:last-child .card-img-bottom,
  .card-group-lg-break > .card:last-child .card-footer {
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
  }
}

/*-------------------------------------------*\
    2.13. Sidebar
\*-------------------------------------------*/
/*------------------------------------
  Sidebar Navigation
------------------------------------*/
/* Disable content revealing on page load */
.u-sidebar[aria-labelledby] {
  opacity: 0;
}

.u-sidebar[aria-labelledby][role="tabpanel"] {
  opacity: 1;
}

.u-sidebar[aria-labelledby].u-unfold--css-animation, .u-sidebar[aria-labelledby].u-unfold--jquery-slide {
  opacity: 1;
}

.u-sidebar.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1002;
  width: 18.75rem;
  height: 100%;
  background-color: #fff;
  box-shadow: -13px 0 20px rgba(119, 131, 143, 0.08);
}

@media (min-width: 768px) {
  .u-sidebar {
    width: 23.4375rem;
  }
}

.u-sidebar__body, .u-sidebar__content {
  height: 100%;
}

.u-sidebar--left {
  right: auto;
  left: 0;
  box-shadow: 13px 0 20px rgba(119, 131, 143, 0.08);
}

.u-sidebar__scroller {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.u-sidebar__container {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.u-sidebar__footer {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.sidenav .from,
.sidenav .to {
  border: 2px solid #297cbb;
}

.sidenav .card-btn-arrow {
  color: #3b444f !important;
}

.sidenav .collapsed .card-btn-arrow {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.sidenav .card-collapse:hover {
  background: transparent;
}

/*------------------------------------
  Sidebar Account
------------------------------------*/
.u-sidebar--account__footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 10.3125rem;
}

.u-sidebar__content--account {
  padding: 1.5rem 3rem;
}

/* Holder */
.u-sidebar--account__holder {
  background-color: #f8fafd;
  padding: 1.5rem 3rem;
}

.u-sidebar--account__holder-img {
  width: 3rem;
  border-radius: 0.3125rem;
}

.u-sidebar--account__holder-text {
  display: block;
  font-size: .75rem;
  color: #8c98a4;
}

/* Toggle */
.u-sidebar--account__toggle-bg {
  position: relative;
  top: 1px;
  padding: .25rem .25rem .25rem .75rem;
  background-color: rgba(41, 124, 187, 0.1);
  border-radius: 6.1875rem;
}

@media (max-width: 767.98px) {
  .u-sidebar--account__toggle-bg {
    padding-left: .25rem;
  }
}

.u-sidebar--account__toggle-bg:hover .u-sidebar--account__toggle-text {
  color: #297cbb;
}

.u-sidebar--account__toggle-text {
  color: #3b444f;
  font-size: .75rem;
  vertical-align: middle;
  margin-right: .3125rem;
}

@media (max-width: 767.98px) {
  .u-sidebar--account__toggle-text {
    display: none;
  }
}

.u-sidebar--account__toggle-img {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

/* List */
.u-sidebar--account__list {
  margin-bottom: 0;
}

.u-sidebar--account__list-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.u-sidebar--account__list-link {
  display: block;
  font-size: .875rem;
  color: #97a4af;
  border-radius: 0.3125rem;
  padding: .625rem;
  transition: 0.2s ease-in-out;
}

.u-sidebar--account__list-link.active, .u-sidebar--account__list-link:hover {
  color: #297cbb;
  background-color: rgba(41, 124, 187, 0.1);
}

.u-sidebar--account__list-link.active .u-sidebar--account__list-icon, .u-sidebar--account__list-link:hover .u-sidebar--account__list-icon {
  color: #297cbb;
}

.u-sidebar--account__list-icon {
  display: inline-block;
  text-align: center;
  width: 1.125rem;
  font-size: .8125rem;
  color: #8c98a4;
  transition: 0.2s ease-in-out;
}

.u-sidebar--account__list-divider {
  border-top: 1px solid #e4ecf7;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* Footer Links */
.u-sidebar__footer--account {
  padding: 0 3rem 1.5rem;
}

.u-sidebar__footer--account__text {
  position: relative;
  z-index: 1;
  font-size: .875rem;
  color: rgba(255, 255, 255, 0.7);
}

.u-sidebar__footer--account__text:hover {
  color: #fff;
}

/*------------------------------------
  Sidebar Shopping Cart
------------------------------------*/
.u-sidebar__cart-footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 13.4375rem;
}

/*-------------------------------------------*\
    2.14. Clients
\*-------------------------------------------*/
.u-clients {
  width: 100%;
  max-width: 6rem;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .inner-md-right {
    padding-right: 75px;
  }
}

@media (min-width: 768px) {
  .inner-md-left {
    padding-left: 75px;
  }
}

.inner-bottom-sm {
  padding-bottom: 60px;
}

/*-------------------------------------------*\
    2.15. Close
\*-------------------------------------------*/
.close-light {
  color: rgba(255, 255, 255, 0.7);
}

.close-light:not(:disabled):not(.disabled):hover, .close-light:not(:disabled):not(.disabled):focus {
  color: #fff;
}

/*-------------------------------------------*\
    2.16. Divider
\*-------------------------------------------*/
.u-divider {
  position: relative;
  display: inline-block;
}

.u-divider::before, .u-divider::after {
  position: absolute;
  top: 50%;
  content: "";
}

.u-divider::before {
  right: 100%;
  background-image: linear-gradient(45deg, transparent 0%, #bdc5d1 100%);
  background-repeat: repeat-x;
}

.u-divider::after {
  left: 100%;
  background-image: linear-gradient(45deg, #bdc5d1 0%, transparent 100%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Divider Sizes
------------------------------------*/
/* Extra Small */
.u-divider--xs {
  font-size: 0.75rem;
}

.u-divider--xs::before, .u-divider--xs::after {
  width: 2rem;
  height: 0.0625rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.u-divider--xs::before {
  margin-right: .625rem;
}

.u-divider--xs::after {
  margin-left: .625rem;
}

.u-divider--xs::before, .u-divider--xs::after {
  width: 2rem;
}

/*------------------------------------
  Divider Styles
------------------------------------*/
/* Text */
.u-divider--text {
  color: #8c98a4;
}

/*------------------------------------
  Vertical Divider
------------------------------------*/
.u-ver-divider {
  position: relative;
}

.u-ver-divider::after {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid #e7eaf3;
  content: "";
}

/* Breakpoint */
@media (max-width: 575.98px) {
  .u-ver-divider--none-sm::after {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-ver-divider--none-md::after {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-ver-divider--none-lg::after {
    display: none;
  }
}

/*------------------------------------
  Divider Sizes
------------------------------------*/
/* Extra Small */
.u-ver-divider--xs::after {
  top: 50%;
  height: 1rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* Small */
.u-ver-divider--sm::after {
  top: 50%;
  height: 2rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* Large */
.u-ver-divider--lg::after {
  top: 50%;
  height: 3rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* Extra Large */
.u-ver-divider--xl::after {
  top: 50%;
  height: 4rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/*-------------------------------------------*\
    2.17. Dropdown
\*-------------------------------------------*/
/* Disable content revealing on page load */
.dropdown-unfold[aria-labelledby] {
  opacity: 0;
}

.dropdown-unfold[aria-labelledby][role="tabpanel"] {
  display: block;
  opacity: 1;
}

.dropdown-unfold[aria-labelledby].u-unfold--css-animation, .dropdown-unfold[aria-labelledby].u-unfold--jquery-slide {
  display: block;
  opacity: 1;
}

.dropdown-unfold.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.dropdown-unfold.show {
  display: block;
  opacity: 1;
}

/* Menu */
.dropdown-menu {
  margin-top: .5rem;
  font-size: 0.875rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 5px 9px rgba(41, 124, 187, 0.075), 0 5px 9px rgba(119, 131, 143, 0.075);
}

/* Nav Link */
.dropdown-nav-link {
  color: #67747c;
  font-weight: 400;
  font-size: 0.875rem;
}

.dropdown-nav-link:hover {
  color: #3b444f;
}

.dropdown-nav-link-dark {
  color: #3b444f;
}

/* Item */
.dropdown-item {
  font-size: 0.875rem;
}

.dropdown-item:hover {
  color: #297cbb;
}

.dropdown-item.active {
  color: #297cbb;
}

.dropdown-item-icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 0.8125rem;
  min-width: 1rem;
  max-width: 1rem;
  margin-right: .5rem;
}

/* Toggle */
.dropdown-toggle::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 80%;
  font-weight: 900;
  content: "";
  margin-left: .5rem;
}

.dropdown-toggle-collapse::after {
  transition: 0.3s;
}

.dropdown-toggle-collapse[aria-expanded="true"]::after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

/* Positions */
.dropdown-menu-right {
  right: 0;
  left: auto !important;
}

.dropdown-menu-bottom {
  top: auto;
  bottom: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto !important;
  }
}

.dropdown-menu-right.dropdown-menu-right-fix[class*="dropdown-menu-right-fix"] {
  right: 0 !important;
}

.dropdown-menu-right.dropdown-menu-right-fix-10 {
  right: 10% !important;
}

.dropdown-menu-right.dropdown-menu-right-fix-15 {
  right: 15% !important;
}

.dropdown-menu-right.dropdown-menu-right-fix-20 {
  right: 20% !important;
}

.dropdown-menu-right.dropdown-menu-right-fix-25 {
  right: 25% !important;
}

.dropdown-menu-right.dropdown-menu-right-fix-30 {
  right: 30% !important;
}

.dropdown-menu-right.dropdown-menu-right-fix-35 {
  right: 35% !important;
}

.dropdown-menu-right.dropdown-menu-right-fix-40 {
  right: 40% !important;
}

.dropdown-menu-right.dropdown-menu-right-fix-45 {
  right: 45% !important;
}

.dropdown-menu-right.dropdown-menu-right-fix-50 {
  right: 50% !important;
}

@media (min-width: 576px) {
  .dropdown-menu-right.dropdown-menu-right-fix-sm-10 {
    right: 10% !important;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-right.dropdown-menu-right-fix-sm-15 {
    right: 15% !important;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-right.dropdown-menu-right-fix-sm-20 {
    right: 20% !important;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-right.dropdown-menu-right-fix-sm-25 {
    right: 25% !important;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-right.dropdown-menu-right-fix-sm-30 {
    right: 30% !important;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-right.dropdown-menu-right-fix-sm-35 {
    right: 35% !important;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-right.dropdown-menu-right-fix-sm-40 {
    right: 40% !important;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-right.dropdown-menu-right-fix-sm-45 {
    right: 45% !important;
  }
}

@media (min-width: 576px) {
  .dropdown-menu-right.dropdown-menu-right-fix-sm-50 {
    right: 50% !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-right.dropdown-menu-right-fix-md-10 {
    right: 10% !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-right.dropdown-menu-right-fix-md-15 {
    right: 15% !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-right.dropdown-menu-right-fix-md-20 {
    right: 20% !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-right.dropdown-menu-right-fix-md-25 {
    right: 25% !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-right.dropdown-menu-right-fix-md-30 {
    right: 30% !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-right.dropdown-menu-right-fix-md-35 {
    right: 35% !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-right.dropdown-menu-right-fix-md-40 {
    right: 40% !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-right.dropdown-menu-right-fix-md-45 {
    right: 45% !important;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-right.dropdown-menu-right-fix-md-50 {
    right: 50% !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-right.dropdown-menu-right-fix-lg-10 {
    right: 10% !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-right.dropdown-menu-right-fix-lg-15 {
    right: 15% !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-right.dropdown-menu-right-fix-lg-20 {
    right: 20% !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-right.dropdown-menu-right-fix-lg-25 {
    right: 25% !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-right.dropdown-menu-right-fix-lg-30 {
    right: 30% !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-right.dropdown-menu-right-fix-lg-35 {
    right: 35% !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-right.dropdown-menu-right-fix-lg-40 {
    right: 40% !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-right.dropdown-menu-right-fix-lg-45 {
    right: 45% !important;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-right.dropdown-menu-right-fix-lg-50 {
    right: 50% !important;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-right.dropdown-menu-right-fix-xl-10 {
    right: 10% !important;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-right.dropdown-menu-right-fix-xl-15 {
    right: 15% !important;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-right.dropdown-menu-right-fix-xl-20 {
    right: 20% !important;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-right.dropdown-menu-right-fix-xl-25 {
    right: 25% !important;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-right.dropdown-menu-right-fix-xl-30 {
    right: 30% !important;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-right.dropdown-menu-right-fix-xl-35 {
    right: 35% !important;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-right.dropdown-menu-right-fix-xl-40 {
    right: 40% !important;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-right.dropdown-menu-right-fix-xl-45 {
    right: 45% !important;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-right.dropdown-menu-right-fix-xl-50 {
    right: 50% !important;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-right.dropdown-menu-right-fix-wd-10 {
    right: 10% !important;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-right.dropdown-menu-right-fix-wd-15 {
    right: 15% !important;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-right.dropdown-menu-right-fix-wd-20 {
    right: 20% !important;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-right.dropdown-menu-right-fix-wd-25 {
    right: 25% !important;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-right.dropdown-menu-right-fix-wd-30 {
    right: 30% !important;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-right.dropdown-menu-right-fix-wd-35 {
    right: 35% !important;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-right.dropdown-menu-right-fix-wd-40 {
    right: 40% !important;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-right.dropdown-menu-right-fix-wd-45 {
    right: 45% !important;
  }
}

@media (min-width: 1480px) {
  .dropdown-menu-right.dropdown-menu-right-fix-wd-50 {
    right: 50% !important;
  }
}

.destination-dropdown {
  display: none;
}

.destination-dropdown a {
  font-size: 1rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: #fff;
}

.dropdown:hover .destination-dropdown {
  display: block;
}

.footer .list-group-item-action {
  color: #67747c;
}

.footer .list-group-item-action:hover {
  color: #297cbb;
}

.bootstrap-select__custom .filter-option-inner-inner {
  color: #67747c;
}

@media (min-width: 768px) {
  .bootstrap-select__custom .dropdown-menu {
    left: auto !important;
    right: 0;
  }
}

.bootstrap-select__custom .dropdown-menu {
  overflow: unset !important;
  margin-bottom: 1.25rem;
  min-width: 15rem;
  border-radius: 2px;
}

@media (min-width: 1200px) {
  .bootstrap-select__custom .dropdown-menu::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    right: 24px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
}

.bootstrap-select__custom li {
  margin: 0 2rem;
}

.bootstrap-select__custom a {
  padding: 2rem 0;
}

.bootstrap-select__custom .active a span {
  width: 100%;
}

.bootstrap-select__custom .active a::after {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 80%;
  font-weight: 900;
  content: "\f00c";
  margin-left: -0.5rem;
}

.bootstrap-select__custom-nav .dropdown-toggle .filter-option {
  position: relative;
  top: 0;
  left: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  height: 100%;
  width: 100%;
  text-align: left;
  padding: 0;
  margin-right: 25px;
}

[class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector > a.active:before {
  position: absolute;
  display: block;
  pointer-events: none;
  border: 10px solid transparent;
  border-bottom-color: #fff;
  content: '';
  height: 0;
  left: 50%;
  width: 0;
  top: 100%;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
  -webkit-animation-duration: 210ms;
          animation-duration: 210ms;
}

[class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector.shopping-cart > a.active:before {
  left: 0;
}

@media (min-width: 576px) {
  [class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-sm > a.active:before {
    position: absolute;
    display: block;
    pointer-events: none;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    content: '';
    height: 0;
    left: 50%;
    width: 0;
    top: 100%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-animation-name: slideInUp;
            animation-name: slideInUp;
    -webkit-animation-duration: 210ms;
            animation-duration: 210ms;
  }
}

[class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-sm.shopping-cart > a.active:before {
  left: 0;
}

@media (min-width: 768px) {
  [class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-md > a.active:before {
    position: absolute;
    display: block;
    pointer-events: none;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    content: '';
    height: 0;
    left: 50%;
    width: 0;
    top: 100%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-animation-name: slideInUp;
            animation-name: slideInUp;
    -webkit-animation-duration: 210ms;
            animation-duration: 210ms;
  }
}

[class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-md.shopping-cart > a.active:before {
  left: 0;
}

@media (min-width: 992px) {
  [class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-lg > a.active:before {
    position: absolute;
    display: block;
    pointer-events: none;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    content: '';
    height: 0;
    left: 50%;
    width: 0;
    top: 100%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-animation-name: slideInUp;
            animation-name: slideInUp;
    -webkit-animation-duration: 210ms;
            animation-duration: 210ms;
  }
}

[class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-lg.shopping-cart > a.active:before {
  left: 0;
}

@media (min-width: 1200px) {
  [class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-xl > a.active:before {
    position: absolute;
    display: block;
    pointer-events: none;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    content: '';
    height: 0;
    left: 50%;
    width: 0;
    top: 100%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-animation-name: slideInUp;
            animation-name: slideInUp;
    -webkit-animation-duration: 210ms;
            animation-duration: 210ms;
  }
}

[class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-xl.shopping-cart > a.active:before {
  left: 0;
}

@media (min-width: 1480px) {
  [class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-wd > a.active:before {
    position: absolute;
    display: block;
    pointer-events: none;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    content: '';
    height: 0;
    left: 50%;
    width: 0;
    top: 100%;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-animation-name: slideInUp;
            animation-name: slideInUp;
    -webkit-animation-duration: 210ms;
            animation-duration: 210ms;
  }
}

[class*="u-header--bg-transparent"]:not(.js-header-fix-moment) .dropdown-connector-wd.shopping-cart > a.active:before {
  left: 0;
}

.tab-dropdown {
  border-bottom: 2px solid #eaeaea;
}

.tab-dropdown.show {
  border-bottom: 2px solid #297cbb;
}

.tab-dropdown .dropdown-toggle::after {
  display: none;
}

.tab-dropdown .dropdown-toggle.bs-placeholder {
  color: #3b444f;
}

.tab-dropdown .dropdown-item {
  color: #67747c;
}

.tab-dropdown .dropdown-item:hover {
  color: #297cbb;
}

.tab-dropdown .dropdown-menu li {
  margin: 0 2rem;
}

.tab-dropdown .dropdown-menu a {
  padding: 0.8rem 0;
}

.dropdown-custom .dropdown-menu {
  left: auto !important;
}

.dropdown-custom .dropdown-nav-link {
  border-bottom: 2px solid #eaeaea;
}

.dropdown-custom .dropdown-nav-link.active {
  border-bottom: 2px solid #297cbb;
}

.dropdown-custom .dropdown-toggle::after {
  margin-left: auto !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.dropdown-custom_white .dropdown-toggle::after {
  color: #fff;
}

.dropdown-custom_white .dropdown-nav-link.active {
  border-color: #fff;
}

.collapse_custom .collapsed .card-btn-arrow {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.custom-dropdown .dropdown-toggle {
  padding: .475rem 1.7rem !important;
}

.custom-dropdown .dropdown-toggle::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: 70%;
  font-weight: 900;
  content: "";
  color: #3b444f;
  margin-right: .4rem;
}

.custom-dropdown .dropdown-toggle::after {
  display: none;
}

.custom-dropdown .dropdown-toggle-collapse::before {
  transition: 0.3s;
}

.custom-dropdown .dropdown-toggle-collapse[aria-expanded="true"] {
  border-left: 2px solid #297cbb !important;
}

.custom-dropdown .dropdown-toggle-collapse[aria-expanded="true"] span {
  color: #297cbb !important;
}

.custom-dropdown .dropdown-toggle-collapse[aria-expanded="true"]::before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  color: #297cbb;
}

.custom-dropdown .dropdown-item {
  padding: .475rem 2.5rem;
}

.custom-dropdown .list-item {
  margin-bottom: .5rem;
}

/*------------------------------------
  Dropdown Card
------------------------------------*/
.dropdown-card {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .dropdown-card {
    min-width: 25rem;
  }
}

/*------------------------------------
  Dropdown Sizes
------------------------------------*/
@media (min-width: 576px) {
  .dropdown-menu-size-md {
    width: 440px;
  }
  .dropdown-menu-size-lg {
    width: 340px;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-size-lg {
    width: 440px;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-size-lg {
    width: 740px;
  }
}

/*-------------------------------------------*\
    2.18. Form Controls
\*-------------------------------------------*/
.custom-select:focus,
.form-control:focus {
  box-shadow: 0 0 25px rgba(41, 124, 187, 0.1);
  border-color: rgba(41, 124, 187, 0.5);
}

.form-control.text-white::-webkit-input-placeholder {
  color: #fff;
}

.form-control.text-white::-moz-placeholder {
  color: #fff;
}

.form-control.text-white:-ms-input-placeholder {
  color: #fff;
}

.form-control.text-white::-ms-input-placeholder {
  color: #fff;
}

.form-control.text-white::placeholder {
  color: #fff;
}

.form-control.hero-form::-webkit-input-placeholder {
  color: #3b444f;
}

.form-control.hero-form::-moz-placeholder {
  color: #3b444f;
}

.form-control.hero-form:-ms-input-placeholder {
  color: #3b444f;
}

.form-control.hero-form::-ms-input-placeholder {
  color: #3b444f;
}

.form-control.hero-form::placeholder {
  color: #3b444f;
}

.u-header__search .form-label {
  display: block;
  text-transform: uppercase;
  font-size: 80%;
  font-weight: 500;
}

/* Form Borderless */
.input-group-borderless .form-control,
.input-group-borderless .input-group-text,
.input-group-borderless .form-control,
.input-group-borderless button,
.input-group-borderless .btn {
  border: none;
}

.input-group-borderless .form-control:focus,
.input-group-borderless .input-group-text:focus,
.input-group-borderless .form-control:focus,
.input-group-borderless button:focus,
.input-group-borderless .btn:focus {
  box-shadow: none;
}

.input-group-radiusless .form-control,
.input-group-radiusless .input-group-text,
.input-group-radiusless .form-control,
.input-group-radiusless button,
.input-group-radiusless .btn {
  border-radius: unset;
}

/* Form Transpatent */
.input-group-tranparent .form-control,
.input-group-tranparent .input-group-text,
.input-group-tranparent button,
.input-group-tranparent .btn {
  background-color: transparent;
}

.placeholder-1::-webkit-input-placeholder {
  color: #5c6770;
}

.placeholder-1::-moz-placeholder {
  color: #5c6770;
}

.placeholder-1:-ms-input-placeholder {
  color: #5c6770;
}

.placeholder-1::-ms-input-placeholder {
  color: #5c6770;
}

.placeholder-1::placeholder {
  color: #5c6770;
}

.form-control {
  border-radius: 0.3125rem;
  font-size: 0.875rem;
}

/*------------------------------------
  Form Pill
------------------------------------*/
.input-group-pill {
  border-radius: 6.1875rem;
}

.input-group-pill > .form-control:first-child {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.input-group-pill > .form-control:last-child {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.input-group-pill .input-group-prepend > .input-group-text,
.input-group-pill .input-group-prepend > .btn {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.input-group-pill .input-group-append > .input-group-text,
.input-group-pill .input-group-append > .btn {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

/*------------------------------------
  Form Sizes
------------------------------------*/
/* Default Size */
.input-group.input-group-pill > .form-control:first-child {
  padding-left: 1.5rem;
}

.input-group.input-group-pill .input-group-prepend > .input-group-text,
.input-group.input-group-pill .input-group-prepend > .btn {
  padding-left: 1.25rem;
}

.input-group.input-group-pill .input-group-append > .input-group-text,
.input-group.input-group-pill .input-group-append > .btn {
  padding-right: 1.25rem;
}

.input-group .input-group-prepend > .input-group-text,
.input-group .input-group-append > .input-group-text {
  font-size: 0.875rem;
}

/* Extra Small Size */
.input-group-xs.input-group-pill > .form-control:first-child {
  padding-left: 1.375rem;
}

.input-group-xs.input-group-pill .input-group-prepend > .input-group-text,
.input-group-xs.input-group-pill .input-group-prepend > .btn {
  padding-left: 1.25rem;
}

.input-group-xs.input-group-pill .input-group-append > .input-group-text,
.input-group-xs.input-group-pill .input-group-append > .btn {
  padding-right: 1.25rem;
}

.input-group-xs .input-group-prepend > .input-group-text,
.input-group-xs .input-group-append > .input-group-text {
  font-size: 0.625rem;
}

.form-control-xs {
  height: calc(1.925rem + 1px);
  padding: 0.4375rem 0.9375rem;
  font-size: 0.75rem;
  line-height: 1.4;
}

/* Small Size */
.input-group-sm.input-group-pill > .form-control:first-child {
  padding-left: 1rem;
}

.input-group-sm.input-group-pill .input-group-prepend > .input-group-text,
.input-group-sm.input-group-pill .input-group-prepend > .btn {
  padding-left: 1rem;
}

.input-group-sm.input-group-pill .input-group-append > .input-group-text,
.input-group-sm.input-group-pill .input-group-append > .btn {
  padding-right: 1rem;
}

.input-group-sm .input-group-prepend > .input-group-text,
.input-group-sm .input-group-append > .input-group-text {
  font-size: 0.75rem;
}

/* Large Size */
.input-group-lg.input-group-pill > .form-control:first-child {
  padding-left: 2rem;
}

.input-group-lg.input-group-pill .input-group-prepend > .input-group-text,
.input-group-lg.input-group-pill .input-group-prepend > .btn {
  padding-left: 1.375rem;
}

.input-group-lg.input-group-pill .input-group-append > .input-group-text,
.input-group-lg.input-group-pill .input-group-append > .btn {
  padding-right: 1.5rem;
}

.input-group-lg .input-group-prepend > .input-group-text,
.input-group-lg .input-group-append > .input-group-text {
  font-size: 1rem;
}

/*------------------------------------
  Focus States
------------------------------------*/
.u-focus-state .form-label + .form-control,
.u-focus-state .custom-select,
.u-focus-state .input-group,
.u-focus-state .form-control:first-child:last-child {
  box-shadow: 0 0 25px rgba(41, 124, 187, 0.1);
}

.u-focus-state .input-group-text {
  color: #297cbb;
}

.u-focus-state:focus,
.u-focus-state *:focus,
.u-focus-state .input-group-text,
.u-focus-state .form-control {
  border-color: rgba(41, 124, 187, 0.5);
}

.u-focus-state .form-control {
  box-shadow: none;
}

/*------------------------------------
  Success State
------------------------------------*/
.u-has-success .form-label + .form-control,
.u-has-success .custom-select,
.u-has-success .input-group,
.u-has-success .form-control:first-child:last-child {
  box-shadow: 0 0 25px rgba(0, 201, 167, 0.1);
}

.u-has-success .input-group-text {
  color: #00c9a7;
}

.u-has-success:focus,
.u-has-success *:focus,
.u-has-success .input-group-text,
.u-has-success .form-control {
  border-color: rgba(0, 201, 167, 0.5);
}

/*------------------------------------
  Error State
------------------------------------*/
.u-has-error .form-label + .form-control,
.u-has-error .custom-select,
.u-has-error .input-group,
.u-has-error .form-control:first-child:last-child {
  box-shadow: 0 0 25px rgba(222, 68, 55, 0.1);
}

.u-has-error .input-group-text {
  color: #de4437;
}

.u-has-error:focus,
.u-has-error *:focus,
.u-has-error .input-group-text,
.u-has-error .form-control {
  border-color: rgba(222, 68, 55, 0.5);
}

/*------------------------------------
  Bookmark Checkbox
------------------------------------*/
.bookmark-checkbox {
  position: relative;
  display: block;
  line-height: 0;
}

.bookmark-checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.bookmark-checkbox-label {
  position: relative;
  width: 2rem;
  height: 2rem;
  font-size: 0.8175rem;
  color: #77838f;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 0;
  transition: 0.3s;
}

.bookmark-checkbox-label::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  content: "";
  font-family: "Font Awesome 5 Free";
}

.bookmark-checkbox-input:checked ~ .bookmark-checkbox-label {
  color: #fff;
  background-color: #297cbb;
  box-shadow: 0 3px 6px 0 rgba(41, 124, 187, 0.25);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*------------------------------------
  Checkbox Bordered
------------------------------------*/
.checkbox-outline {
  padding-left: 0;
  margin-right: 0;
}

.checkbox-outline__label {
  border: 1px solid #e7eaf3;
  cursor: pointer;
}

.checkbox-outline__input:checked ~ .checkbox-outline__label {
  border-color: #297cbb;
}

/*------------------------------------
  Checkbox Switch
------------------------------------*/
.custom-switch {
  padding-left: 4.75rem;
}

.custom-switch .custom-control-label::before {
  left: -4.75rem;
  height: 1.875rem;
  border: none;
  background-color: #e7eaf3;
  border-radius: 6.1875rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.3861rem);
  left: calc(-4.42rem);
  background-color: #fff;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(2.187rem);
          transform: translateX(2.187rem);
}

.switch-custom .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(0.9rem) !important;
          transform: translateX(0.9rem) !important;
}

.switch-custom .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #8394ac !important;
}

.map-collapse.show + .map-collapse-hidden {
  display: none;
}

.map-lable-list .map-show {
  display: none;
}

.map-lable[aria-expanded="true"] + .map-lable-list .map-hide {
  display: none;
}

.map-lable[aria-expanded="true"] + .map-lable-list .map-show {
  display: inline;
}

.map-switch.custom-switch {
  padding-left: 64px;
}

.map-switch.custom-switch .custom-control-label::before {
  left: 0;
}

.map-switch.custom-switch .custom-control-label::after {
  left: 5px;
}

.map-switch .custom-control-label {
  position: static;
}

.map-switch .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #8394ac;
  background-color: #8394ac;
}

/*------------------------------------
  Custom Checkbox
------------------------------------*/
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: rgba(41, 124, 187, 0);
}

/*------------------------------------
  Range Slider
------------------------------------*/
.u-range-slider {
  height: 1.25rem;
}

.u-range-slider .irs {
  height: 1.25rem;
}

.u-range-slider .irs-line {
  top: .5rem;
  height: 0.438rem;
}

.u-range-slider .irs-line-left {
  left: 0;
  height: 0.438rem;
  background-color: #e7eaf3;
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.u-range-slider .irs-line-mid {
  height: 0.438rem;
  background-color: #e7eaf3;
}

.u-range-slider .irs-line-right {
  right: 0;
  height: 0.438rem;
  background-color: #e7eaf3;
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.u-range-slider .irs-bar {
  top: .5rem;
  height: 0.438rem;
  background-color: #297cbb;
}

.u-range-slider .irs-bar-edge {
  top: .5rem;
  left: .0625rem;
  height: 0.25rem;
  width: .6875rem;
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
  background-color: #297cbb;
}

.u-range-slider .irs-slider {
  top: -.02rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0.1875rem 0.75rem rgba(140, 152, 164, 0.3125);
}

.u-range-slider .irs-slider.state_hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.u-range-slider .irs-from,
.u-range-slider .irs-to,
.u-range-slider .irs-single {
  display: inline-block;
  min-width: 2.5rem;
  background-color: #fff;
  color: #3b444f;
  font-size: 0.875rem;
  text-shadow: none;
  text-align: center;
  border-radius: 0.3125rem;
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
  padding: .5rem;
}

/* Indicator */
.u-range-slider-indicator {
  height: 5rem;
}

.u-range-slider-indicator .irs {
  height: 5rem;
}

.u-range-slider-indicator.u-range-slider-grid {
  height: 8rem;
}

.u-range-slider-indicator.u-range-slider-grid .irs {
  height: 8rem;
}

.u-range-slider-indicator .irs-line {
  top: 3.875rem;
}

.u-range-slider-indicator .irs-bar {
  top: 3.875rem;
}

.u-range-slider-indicator .irs-bar-edge {
  top: 3.875rem;
}

.u-range-slider-indicator .irs-slider {
  top: 3.1875rem;
}

/* Grid */
.u-range-slider-grid {
  height: 4.5rem;
}

.u-range-slider-grid .irs {
  height: 4.5rem;
}

.u-range-slider-grid .irs-grid {
  height: 2.5rem;
}

.u-range-slider-grid .irs-grid-text {
  display: inline-block;
  min-width: 2.5rem;
  line-height: 1;
  font-size: 0.875rem;
  color: #77838f;
  border-radius: 6.1875rem;
  padding: 0.25rem;
}

.u-range-slider-grid .irs-grid-text.current {
  background: rgba(41, 124, 187, 0.1);
  color: #297cbb;
}

.u-range-slider-grid .irs-grid-pol {
  height: .75rem;
  background-color: #e7eaf3;
}

.u-range-slider-grid .irs-grid-pol.small {
  display: none;
}

/*------------------------------------
  File Attachment Button
------------------------------------*/
.file-attachment-btn {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.file-attachment-btn__label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}

/*------------------------------------
  File Attachment Input
------------------------------------*/
.file-attachment-input {
  position: relative;
  display: block;
  background-color: #f8fafd;
  border: 2px dashed #e7eaf3;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0.3125rem;
  padding: 4rem;
  margin-bottom: 0;
}

.file-attachment-input__label {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  cursor: inherit;
  opacity: 0;
}

.file-attachment-input:hover {
  background-color: #f5f8fc;
}

/*------------------------------------
  File Attachment Link
------------------------------------*/
.file-attachment-link {
  position: relative;
  top: .4375rem;
  overflow: hidden;
}

.file-attachment-link input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.file-attachment-link__label {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}

.file-attachment-link:hover .u-file-attachment-link__label {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.7);
}

/*-------------------------------------------*\
    2.19. Google Map
\*-------------------------------------------*/
.u-gmap-interactive {
  height: 15.625rem;
  border: 4px solid #fff;
  border-radius: 0.3125rem;
  box-shadow: 0 0 45px rgba(140, 152, 164, 0.125);
}

.u-gmap-interactive .gm-style > div:first-child > div + div > div:last-child > div > div:first-child > div:first-child {
  display: none;
}

.u-gmap-interactive .gm-style-iw {
  min-width: 11.0625rem;
  background-color: #fff;
  text-align: center;
  overflow: inherit;
  box-shadow: 0 0 45px rgba(140, 152, 164, 0.4);
  border-radius: 0.3125rem;
  padding-top: 1.5rem;
  padding-bottom: .5rem;
}

.u-gmap-interactive .gm-style-iw::before {
  position: absolute;
  bottom: -.75rem;
  left: 50%;
  width: 1rem;
  height: .875rem;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.1 14.5'%3E %3Cpath fill='#fff' d='M9.8,13.5L15.9,3c0.8-1.3-0.2-3-1.7-3H2C0.5,0-0.5,1.7,0.3,3l6.1,10.5C7.1,14.8,9,14.8,9.8,13.5z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  content: "";
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.u-gmap-interactive .gm-style-iw img {
  max-width: 100%;
}

.u-gmap-interactive__info {
  padding-right: .5rem;
  padding-bottom: .5rem;
  padding-left: .5rem;
}

/*-------------------------------------------*\
    2.20. Go to Section
\*-------------------------------------------*/
.u-go-to {
  display: none;
  position: relative;
  z-index: 100;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #297cbb;
  color: #fff;
  font-size: 0.75rem;
  border-radius: 0.3125rem;
  opacity: 0.5;
  transition: 0.3s ease-out;
}

.u-go-to__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-go-to:hover, .u-go-to:focus:hover {
  color: #fff;
  opacity: 1;
}

/*------------------------------------
  Got to Section - Vertical Arrow
------------------------------------*/
.u-go-to-ver-arrow {
  display: inline-block;
  color: #297cbb;
  background-color: rgba(41, 124, 187, 0.1);
  padding: 2rem 1rem;
  border-radius: 6.1875rem;
}

.u-go-to-ver-arrow:hover .u-go-to-ver-arrow__inner {
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
}

.u-go-to-ver-arrow__inner {
  transition: 0.2s ease-in-out;
}

/*------------------------------------
  Got to Section - Modern
------------------------------------*/
.u-go-to-modern {
  position: relative;
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 3.125rem;
  height: 3.125rem;
  font-size: 1rem;
  color: #297cbb;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 1.25rem rgba(140, 152, 164, 0.2);
}

.u-go-to-modern .u-go-to-modern__inner {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*------------------------------------
  Got to Section - Wave
------------------------------------*/
.u-go-to-wave {
  width: 13.125rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575.98px) {
  .u-go-to-wave {
    width: 10rem;
  }
}

.u-go-to-wave__icon {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/*-------------------------------------------*\
    2.21. Hamburger
\*-------------------------------------------*/
.u-hamburger {
  font-size: inherit;
  line-height: 0;
  padding: 0.25rem;
}

.u-hamburger:hover .u-hamburger__inner, .u-hamburger:hover .u-hamburger__inner::before, .u-hamburger:hover .u-hamburger__inner::after {
  background-color: #297cbb;
}

.u-hamburger__box {
  width: 1.5625rem;
  height: 0.875rem;
  display: inline-block;
  position: relative;
}

.u-hamburger__inner {
  display: block;
  top: 0.0625rem;
  margin: top, -0.0625rem;
}

.u-hamburger__inner, .u-hamburger__inner::before, .u-hamburger__inner::after {
  width: 1.5625rem;
  height: 0.125rem;
  background-color: #8c98a4;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.u-hamburger__inner::before, .u-hamburger__inner::after {
  content: "";
  display: block;
}

.u-hamburger__inner::before {
  top: 0.375rem;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.u-hamburger__inner::after {
  top: 0.75rem;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner,
.u-hamburger .is-active .u-hamburger__inner {
  -webkit-transform: translate3d(0, 0.375rem, 0) rotate(45deg);
          transform: translate3d(0, 0.375rem, 0) rotate(45deg);
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::before,
.u-hamburger .is-active .u-hamburger__inner::before {
  -webkit-transform: rotate(-45deg) translate3d(-0.22321rem, -0.25rem, 0);
          transform: rotate(-45deg) translate3d(-0.22321rem, -0.25rem, 0);
  opacity: 0;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::after,
.u-hamburger .is-active .u-hamburger__inner::after {
  -webkit-transform: translate3d(0, -0.75rem, 0) rotate(-90deg);
          transform: translate3d(0, -0.75rem, 0) rotate(-90deg);
}

.u-hamburger--white .u-hamburger__inner, .u-hamburger--white .u-hamburger__inner::before, .u-hamburger--white .u-hamburger__inner::after {
  background-color: rgba(255, 255, 255, 0.8);
}

.u-hamburger--white:hover .u-hamburger__inner, .u-hamburger--white:hover .u-hamburger__inner::before, .u-hamburger--white:hover .u-hamburger__inner::after {
  background-color: #fff;
}

.u-hamburger--primary .u-hamburger__inner, .u-hamburger--primary .u-hamburger__inner::before, .u-hamburger--primary .u-hamburger__inner::after {
  background-color: #297cbb;
}

.u-hamburger--primary:hover .u-hamburger__inner, .u-hamburger--primary:hover .u-hamburger__inner::before, .u-hamburger--primary:hover .u-hamburger__inner::after {
  background-color: #297cbb;
}

.js-header-fix-moment .u-hamburger--white .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner::before, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner::after {
  background-color: #8c98a4;
}

.js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::before, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::after {
  background-color: #297cbb;
}

/*-------------------------------------------*\
    2.22. Hero Blocks
\*-------------------------------------------*/
/*------------------------------------
  Hero v1
------------------------------------*/
.u-hero-v1 {
  position: relative;
}

.u-hero-v1__main {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 992px) {
  .u-hero-v1__main {
    height: 70vh;
  }
}

.u-hero-v1__main::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(54, 85, 111, 0.3);
  content: "";
}

.u-hero-v1__secondary {
  position: relative;
}

@media (min-width: 992px) {
  .u-hero-v1__secondary {
    height: 30vh;
  }
}

.u-hero-v1__last {
  position: absolute;
  bottom: 0;
  left: 58.333333%;
}

.u-hero-v1__last-inner {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 30%;
  padding-left: 3rem;
  padding-right: 3rem;
}

.u-hero-v1__last-next {
  color: #174567;
}

.u-hero-v1__last-prev {
  color: #962319;
}

/*-------------------------------------------*\
    2.23. Indicators
\*-------------------------------------------*/
/*------------------------------------
  Indicator Dots
------------------------------------*/
.u-indicator-dots {
  position: relative;
}

@media (min-width: 768px) {
  .u-indicator-dots::after {
    position: absolute;
    right: -2.1875rem;
    top: 50%;
    width: 2.4375rem;
    height: .75rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 16'%3E %3Ccircle fill='%23e7eaf3' cx='27.7' cy='8.1' r='7.7'/%3E %3Ccircle fill='%23e7eaf3' cx='5' cy='8.1' r='5'/%3E %3Ccircle fill='%23e7eaf3' cx='50' cy='8.1' r='5'/%3E %3C/svg%3E");
    background-repeat: no-repeat;
    content: "";
    margin-top: -.375rem;
  }
}

/*------------------------------------
  Indicator - Vertical Dashed
------------------------------------*/
.u-indicator-vertical-dashed-item:not(:last-child) {
  position: relative;
  margin-bottom: 1rem;
}

.u-indicator-vertical-dashed-item:not(:last-child)::after {
  position: absolute;
  top: 1.625rem;
  bottom: -.8125rem;
  left: .625rem;
  border-left: 2px dashed #e7eaf3;
  content: "";
}

/*------------------------------------
  Indicator Steps
------------------------------------*/
.u-indicator-steps {
  position: relative;
  padding-left: 2.5rem;
}

.u-indicator-steps::before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -.25rem;
  width: 2rem;
  height: .0625rem;
  border-top: 1px solid #e7eaf3;
  margin: auto auto auto 0;
  content: "";
}

.u-indicator-steps::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -.3125rem;
  width: .0625rem;
  height: 100%;
  border-right: 1px solid #e7eaf3;
  margin: auto auto auto 0;
  content: "";
}

.u-indicator-steps:first-child::after {
  height: 50%;
  top: auto;
}

.u-indicator-steps:last-child::after {
  height: 50%;
  bottom: auto;
}

.u-indicator-steps__inner {
  min-width: 3rem;
}

/*-------------------------------------------*\
    2.24. Media
\*-------------------------------------------*/
/*------------------------------------
  Media Player
------------------------------------*/
.u-media-player {
  color: #3b444f;
  transition: all 0.2s ease-in-out;
}

.u-media-player__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 3.75rem;
  height: 3.75rem;
  font-size: 0.8125rem;
  border-radius: 50%;
  color: #3b444f;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.u-media-player__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: .125rem;
}

.u-media-player__icon--box-shadow {
  box-shadow: 0 0 2.5rem rgba(140, 152, 164, 0.3);
}

.u-media-player:hover .u-media-player__icon, .u-media-player:focus .u-media-player__icon {
  color: #297cbb;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.u-media-player:hover .u-media-player__icon--primary, .u-media-player:focus .u-media-player__icon--primary {
  background-color: #297cbb;
}

.u-media-player:hover .u-media-player__icon--success, .u-media-player:focus .u-media-player__icon--success {
  background-color: #00c9a7;
}

/* Colors */
.u-media-player:hover .u-media-player__icon--primary, .u-media-player:hover .u-media-player__icon--success, .u-media-player:focus .u-media-player__icon--primary, .u-media-player:focus .u-media-player__icon--success {
  color: #fff;
}

.u-media-player__icon--primary {
  color: #297cbb;
  background-color: rgba(41, 124, 187, 0.1);
}

.u-media-player__icon--success {
  color: #00c9a7;
  background-color: rgba(0, 201, 167, 0.1);
}

/* Large Size */
.u-media-player__icon--lg {
  width: 5rem;
  height: 5rem;
  font-size: 1rem;
}

/* Extra Large Size */
.u-media-player__icon--xl {
  width: 6.25rem;
  height: 6.25rem;
  font-size: 1rem;
}

/* Positions */
.u-media-player--centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  .u-media-player--left-minus-50x-top-50x-lg {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

/*------------------------------------
  Media Viewer
------------------------------------*/
.u-media-viewer {
  position: relative;
  display: block;
}

.u-media-viewer:hover .u-media-viewer__icon {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.u-media-viewer__container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-media-viewer__icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #297cbb;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  transition: 0.3s ease-in-out;
}

.u-media-viewer__icon:hover, .u-media-viewer__icon:focus {
  color: #fff;
}

.u-media-viewer__icon--active {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.u-media-viewer__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*------------------------------------
  Video Player
------------------------------------*/
.u-video-player {
  position: relative;
  background-color: #000;
}

.u-video-player__preview {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 1;
  -o-object-fit: cover;
     object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.u-video-player__played .u-video-player__preview {
  opacity: 0;
  pointer-events: none;
}

.u-video-player__btn {
  z-index: 3;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.u-video-player__played .u-video-player__btn {
  -webkit-animation: videoPlayerButton 0.4s ease-in-out forwards;
          animation: videoPlayerButton 0.4s ease-in-out forwards;
  pointer-events: none;
}

.u-video-player__btn:hover .u-video-player__icon, .u-video-player__btn:focus .u-video-player__icon {
  color: #297cbb;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.u-video-player__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.8125rem;
  border-radius: 50%;
  color: #3b444f;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.u-video-player__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: .125rem;
}

/* Large Size */
.u-video-player__icon--lg {
  width: 5rem;
  height: 5rem;
  font-size: 1rem;
}

/* Positions */
.u-video-player__centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@-webkit-keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
            transform: translate(-50%, -50%) scale(1.3);
  }
}

@keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
            transform: translate(-50%, -50%) scale(1.3);
  }
}

/*-------------------------------------------*\
    2.25. Mockups
\*-------------------------------------------*/
/*------------------------------------
  Mockup Browser v1
------------------------------------*/
.u-browser-v1 {
  position: relative;
  max-width: 53.125rem;
}

.u-browser-v1__svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  max-width: 85%;
  border-radius: 0.5rem;
  box-shadow: 0 0.625rem 2.5rem 0.625rem rgba(140, 152, 164, 0.175);
}

/*------------------------------------
  Mockup Devices v1
------------------------------------*/
.u-devices-v1 {
  position: absolute;
  top: 3rem;
  left: 50%;
  width: 40.625rem;
  -webkit-transform: rotate(-17deg);
          transform: rotate(-17deg);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}

.u-devices-v1__tablet-svg, .u-devices-v1__phone-svg {
  border-radius: 2.125rem;
  box-shadow: 0 0 10px 6px rgba(59, 68, 79, 0.05);
}

.u-devices-v1__tablet {
  width: 26.875rem;
  height: 34.3125rem;
}

.u-devices-v1__phone {
  width: 13.75rem;
  height: 27.5rem;
  margin-right: 1.25rem;
}

/*------------------------------------
  Devices v2
------------------------------------*/
.u-devices-v2 {
  position: relative;
  overflow: hidden;
}

.u-devices-v2__tablet {
  position: absolute;
  left: 0;
  top: 0%;
  z-index: 1;
  width: 34.375rem;
  height: 50.875rem;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
  -webkit-transform-origin: 90% -20%;
          transform-origin: 90% -20%;
}

@media (max-width: 991.98px) {
  .u-devices-v2__tablet {
    -webkit-transform-origin: 100% -40%;
            transform-origin: 100% -40%;
  }
}

.u-devices-v2__tablet-svg {
  border-radius: 1.85rem;
  box-shadow: 0 0 0.75rem 0.5rem rgba(59, 68, 79, 0.1);
}

.u-devices-v2__phone {
  position: absolute;
  right: 0;
  bottom: -25%;
  z-index: 1;
  width: 19.8125rem;
  height: 41.5625rem;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}

.u-devices-v2__phone--left-position {
  position: absolute;
  right: auto;
  left: 0;
  -webkit-transform-origin: 100% 40%;
          transform-origin: 100% 40%;
}

@media (max-width: 1199.98px) {
  .u-devices-v2__phone--left-position {
    -webkit-transform-origin: 100% 65%;
            transform-origin: 100% 65%;
  }
}

.u-devices-v2__phone-svg {
  border-radius: 3rem;
  box-shadow: 0 0 0.75rem 0.5rem rgba(59, 68, 79, 0.1);
}

/*------------------------------------
  Devices v3
------------------------------------*/
.u-devices-v3 {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: 0;
  width: 40%;
  border-radius: 4.0625rem;
  box-shadow: 10px 15px 55px 15px rgba(140, 152, 164, 0.1);
}

/*------------------------------------
  Mockup Devices v4
------------------------------------*/
.u-devices-v4 {
  -webkit-transform: rotate(-25deg);
          transform: rotate(-25deg);
}

.u-devices-v4__content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 700px;
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.u-devices-v4__laptop {
  width: 35.9375rem;
  height: 20.75rem;
}

.u-devices-v4__tablet-svg, .u-devices-v4__tablet-ver-svg {
  border-radius: 1.375rem;
  box-shadow: 0 0 0.625rem 0.375rem rgba(59, 68, 79, 0.05);
}

.u-devices-v4__tablet {
  width: 17.1875rem;
  height: 25.4375rem;
  margin-left: 1.5625rem;
}

.u-devices-v4__tablet-ver {
  width: 25rem;
  height: 16.875rem;
}

.u-devices-v4__phone {
  width: 6.25rem;
  height: 13.0625rem;
  margin-left: 1.5625rem;
}

.u-devices-v4__phone-svg {
  border-radius: 1rem;
  box-shadow: 0 0 0.625rem 0.375rem rgba(59, 68, 79, 0.05);
}

/*-------------------------------------------*\
    2.26. Modal Window
\*-------------------------------------------*/
/*------------------------------------
  Modal Window
------------------------------------*/
.u-modal-window {
  display: none;
  max-height: 85vh;
}

.u-modal-window-custom.u-modal-window {
  display: block !important;
  height: 0 !important;
  opacity: 0 !important;
}

.custombox-open .u-modal-window-custom.u-modal-window {
  height: auto !important;
  opacity: 1 !important;
}

/*------------------------------------
  Modal Window - Facebook
------------------------------------*/
.u-modal--facebook {
  width: 31.25rem;
  border-radius: 0.3125rem;
}

.u-modal--facebook__body {
  position: relative;
  background-color: #395899;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.u-modal--facebook__body::after {
  position: absolute;
  left: 5rem;
  top: 100%;
  display: block;
  border-style: solid;
  border-width: 1.125rem 1.375rem 0 0;
  border-color: #395899 transparent transparent transparent;
  content: "";
}

.u-modal--facebook__space {
  padding: 2rem;
}

.u-modal--facebook__content {
  background-color: #fff;
  padding: 2rem;
}

.u-modal--facebook__navbar {
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.u-modal--facebook__navbar-logo {
  width: 7.5rem;
}

.u-modal--facebook__text {
  font-weight: 300;
  color: #fff;
  margin-bottom: 0;
}

/*-------------------------------------------*\
    2.27. Page Preloader
\*-------------------------------------------*/
.page-preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  background-color: #fff;
}

.page-preloader__content-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
}

/*-------------------------------------------*\
    2.28. Pagination
\*-------------------------------------------*/
.page-link {
  text-align: center;
  min-width: 2.25rem;
}

.page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.page-item .page-link {
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.page-item .page-link {
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.list-pagination .page-item .page-link:hover {
  background-color: #297cbb;
  color: #fff !important;
  transition: .3s;
}

.list-pagination-1 .page-item .page-link:hover {
  background-color: #297cbb;
  color: #fff !important;
  transition: .3s;
  border-radius: .3125rem !important;
}

.list-pagination-1 .page-item:first-child .page-link, .list-pagination-1 .page-item:last-child .page-link {
  display: -ms-flexbox;
  display: flex;
  line-height: 1.6;
}

.custom-pagination .page-link {
  color: #67747c;
  padding: .4rem;
  border-radius: 3px;
}

.custom-pagination .page-link:hover {
  background: #297cbb !important;
  color: #fff !important;
}

.custom-pagination .page-item:first-child .page-link {
  color: #3b444f;
  border-right: 1px solid #e4e4e4;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.custom-pagination .page-item:last-child .page-link {
  color: #3b444f;
  border-left: 1px solid #e4e4e4;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/*-------------------------------------------*\
    2.29. Nav
\*-------------------------------------------*/
.nav-classic {
  border-bottom: 1px solid #e7eaf3;
}

.nav-classic .nav-link {
  color: #77838f;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  padding: 1rem 1.5rem;
  margin-bottom: -.125rem;
  transition: 0.3s;
}

.nav-classic .nav-link:hover {
  color: #297cbb;
}

.nav-classic .nav-link.active {
  color: #297cbb;
  border-bottom-color: #297cbb;
}

.nav-borderless {
  border-color: transparent;
}

.nav-rounded {
  border-radius: 1.25rem;
}

.nav-rounded .nav-item:first-child .nav-link {
  border-bottom-left-radius: 1.25rem;
}

.nav-rounded .nav-item:last-child .nav-link {
  border-bottom-right-radius: 1.25rem;
}

.nav-rounded-pill .nav-item .nav-link {
  border-radius: 1.25rem;
}

.nav-default .nav-item .nav-link {
  min-height: 2.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.nav-default .nav-item .nav-link:not(.active) {
  color: #3b444f;
}

.nav-default .nav-item .nav-link.active {
  background-color: #045cff;
}

.nav-shadow {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25);
}

.nav-choose .nav-link .rounded-circle {
  opacity: 0;
}

.nav-choose .nav-link.active {
  border-color: #297cbb !important;
  position: relative;
}

.nav-choose .nav-link.active .rounded-circle {
  opacity: 1;
}

.nav .nav-item:not(:first-child) {
  margin-left: .25rem;
}

.nav .nav-item:not(:last-child) {
  margin-right: .25rem;
}

@media (max-width: 1479.98px) {
  .tab-nav {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.tab-nav-rounded .nav-link {
  padding: 0.5rem 1.5rem;
}

.tab-nav-rounded .nav-link.active .icon::before {
  border: 1px solid #297cbb;
  border-radius: 50%;
  padding: 14px;
  background-color: #297cbb;
}

.tab-nav-rounded .nav-link.active .icon::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -33px;
  left: 24px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

@media (max-width: 767.98px) {
  .tab-nav-rounded .nav-link.active .icon::after {
    bottom: -16px;
  }
}

.tab-nav-boxed .nav-link {
  padding: 0.3rem 1.5rem;
}

.tab-nav-boxed .nav-link.active {
  border: 1px solid #408fb9;
  background-color: #408fb9;
  border-radius: 3px;
}

.tab-nav-boxed .nav-link.active .icon::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -30px;
  left: 24px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

@media (max-width: 767.98px) {
  .tab-nav-boxed .nav-link.active .icon::after {
    left: 13px;
  }
}

.tab-nav-pill .nav-link {
  padding: 0.5rem 1.5rem;
}

.tab-nav-pill .nav-link.active {
  background-color: #1d508d;
  border-radius: 20px;
}

.tab-nav-pill .nav-link.active .tabtext {
  color: #fff;
}

.tab-nav-pill .nav-item.active {
  background-color: #1d508d;
  border-radius: 20px;
}

.tab-nav-pill .nav-item.active .tabtext {
  color: #fff;
}

.tab-nav-square .nav-link {
  padding: 0.5rem 1.5rem;
}

.tab-nav-square .nav-link.active {
  background-color: #297cbb;
  border-radius: 3px;
}

.tab-nav-square .nav-link.active .tabtext {
  color: #fff;
}

.tab-nav-line .nav-link {
  padding: 0.5rem 1.5rem;
}

.tab-nav-line .nav-link.active .tabtext {
  color: #297cbb;
}

.tab-nav-line .nav-link.active .tabtext::after {
  display: block;
  height: 0.125rem;
  background-color: #377dff;
  content: " ";
  margin-top: 0.313rem;
}

.tab-nav-shadow .nav-link {
  padding: 0.3rem 1.5rem;
}

.tab-nav-shadow .nav-link.active {
  position: relative;
  z-index: 1;
}

.tab-nav-shadow .nav-link.active::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #ffffff;
  opacity: 0.15;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.tab-nav-inner .nav-link {
  padding: 0.3rem 1rem;
}

.tab-nav-inner .nav-link.active {
  border: 1px solid #fddc95;
  background-color: #fddc95;
  border-radius: 3px;
}

.tab-nav-shop .nav-link {
  color: #67747c;
  padding: 0.5rem 0.5rem;
}

.tab-nav-shop .nav-link.active {
  color: #297cbb;
}

.tab-nav-list .nav-link {
  padding: .5rem 0;
}

.tab-nav-list .nav-link.active {
  border-left: 2px solid #297cbb !important;
}

.tab-nav-list .nav-link.active i, .tab-nav-list .nav-link.active span {
  color: #297cbb !important;
}

@media (max-width: 1479.98px) {
  .tab-nav-1 {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.tab-nav-1-rounded .nav-link {
  padding: 0.5rem 1.5rem;
}

.tab-nav-1-rounded .nav-link.active .icon::before {
  border: 1px solid #297cbb;
  border-radius: 50%;
  padding: 14px;
  background-color: #297cbb;
}

.tab-nav-1-rounded .nav-link.active .icon::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -33px;
  left: 24px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

@media (max-width: 767.98px) {
  .tab-nav-1-rounded .nav-link.active .icon::after {
    bottom: -16px;
  }
}

.tab-nav-1-boxed .nav-link {
  padding: 0.3rem 1.5rem;
}

.tab-nav-1-boxed .nav-link.active {
  border: 1px solid #408fb9;
  background-color: #408fb9;
  border-radius: 3px;
}

.tab-nav-1-boxed .nav-link.active .icon::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -30px;
  left: 24px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

@media (max-width: 767.98px) {
  .tab-nav-1-boxed .nav-link.active .icon::after {
    left: 13px;
  }
}

.tab-nav-1-pill .nav-link {
  padding: 0.5rem 1.5rem;
}

.tab-nav-1-pill .nav-link.active {
  background-color: #1d508d;
  border-radius: 20px;
}

.tab-nav-1-pill .nav-link.active .tabtext {
  color: #fff;
}

.tab-nav-1-pill .nav-item.active {
  background-color: #1d508d;
  border-radius: 20px;
}

.tab-nav-1-pill .nav-item.active .tabtext {
  color: #fff;
}

.tab-nav-1-square .nav-link {
  padding: 0.5rem 1.5rem;
}

.tab-nav-1-square .nav-link.active {
  background-color: #297cbb;
  border-radius: 3px;
}

.tab-nav-1-square .nav-link.active .tabtext {
  color: #fff;
}

.tab-nav-1-line .nav-link {
  padding: 0.5rem 1.5rem;
}

.tab-nav-1-line .nav-link.active .tabtext {
  color: #297cbb;
}

.tab-nav-1-line .nav-link.active .tabtext::after {
  display: block;
  height: 0.125rem;
  background-color: #377dff;
  content: " ";
  margin-top: 0.313rem;
}

.tab-nav-1-shadow .nav-link {
  padding: 0.3rem 1.5rem;
}

.tab-nav-1-shadow .nav-link.active {
  position: relative;
  z-index: 1;
}

.tab-nav-1-shadow .nav-link.active::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #ffffff;
  opacity: 0.15;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.tab-nav-1-inner .nav-link {
  padding: 0.3rem 1rem;
}

.tab-nav-1-inner .nav-link.active {
  border: 1px solid #297cbb !important;
  background-color: #297cbb !important;
  border-radius: 3px;
}

.tab-nav-1-inner .nav-link.active span {
  color: #fff !important;
}

.tab-nav-1-shop .nav-link {
  color: #67747c;
  padding: 0.5rem 0.5rem;
}

.tab-nav-1-shop .nav-link.active {
  color: #297cbb;
}

.tab-nav-1-list .nav-link {
  padding: .5rem 0;
}

.tab-nav-1-list .nav-link.active {
  border-left: 2px solid #297cbb !important;
}

.tab-nav-1-list .nav-link.active i, .tab-nav-1-list .nav-link.active span {
  color: #297cbb !important;
}

.collapse-link .plus,
.collapse-link .minus {
  display: none;
}

.collapse-link[aria-expanded="false"] .plus {
  display: -ms-flexbox;
  display: flex;
  padding: .2rem .4rem;
}

.collapse-link[aria-expanded="true"] .minus {
  display: -ms-flexbox;
  display: flex;
  padding: .7rem .4rem;
}

/* Nav Steps */
.nav-icon .nav-item {
  color: #77838f;
}

.nav-icon .nav-item.active {
  color: #297cbb;
}

.nav-icon .nav-item.active .nav-icon-action {
  color: #fff;
  background-color: #297cbb;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.nav-icon-action {
  position: relative;
  display: block;
  vertical-align: middle;
  text-align: center;
  z-index: 1;
  line-height: 0.7;
  width: 4rem;
  height: 4rem;
  font-size: 1.375rem;
  color: #77838f;
  background-color: rgba(119, 131, 143, 0.1);
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: .5rem;
  transition: 0.3s ease-in-out;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.nav-icon-action-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*-------------------------------------------*\
    2.30. News Blogs
\*-------------------------------------------*/
/*------------------------------------
  Blog Thumb Minimal
------------------------------------*/
.u-blog-thumb-minimal {
  padding: .5rem;
  transition: 0.3s ease-in-out;
}

.u-blog-thumb-minimal, .u-blog-thumb-minimal__img {
  border-radius: 0.3125rem;
}

.u-blog-thumb-minimal:hover {
  background-color: #196eff;
}

.u-blog-thumb-minimal__img-wrapper {
  width: 5rem;
}

/*-------------------------------------------*\
    2.31. Progress
\*-------------------------------------------*/
.progress-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
  -ms-flex-pack: end;
      justify-content: flex-end;
  background-color: #f8fafd;
  height: 12.5rem;
}

/*-------------------------------------------*\
    2.32. Paging
\*-------------------------------------------*/
/*------------------------------------
  Paging Modern
------------------------------------*/
.u-paging-modern .u-paging-modern__arrow-icon-prev, .u-paging-modern .u-paging-modern__arrow-icon-next {
  transition: 0.3s;
}

.u-paging-modern .u-paging-modern__arrow-icon-prev {
  margin-right: 1rem;
}

.u-paging-modern .u-paging-modern__arrow-icon-next {
  margin-left: 1rem;
}

.u-paging-modern:hover .u-paging-modern__arrow-icon-prev {
  -webkit-transform: translateX(-8px);
          transform: translateX(-8px);
}

.u-paging-modern:hover .u-paging-modern__arrow-icon-next {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}

.u-paging-modern-view-all {
  color: #fff;
  text-align: center;
  background-color: #ffc107;
}

.u-paging-modern-view-all__icon {
  display: block;
  font-size: 2rem;
  margin-bottom: .5rem;
  transition: 0.3s;
}

.u-paging-modern-view-all:hover {
  color: #fff;
}

.u-paging-modern-view-all:hover .u-paging-modern-view-all__icon {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}

/*-------------------------------------------*\
    2.33. Quantity
\*-------------------------------------------*/
.u-quantity {
  width: 4rem;
}

.u-quantity > .u-quantity__input {
  padding-right: 1.875rem;
}

.u-quantity > .u-quantity__input:not(:last-child) {
  border-radius: 0.3125rem;
}

.u-quantity__arrows {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: .75rem;
  z-index: 3;
}

.u-quantity__arrows-inner {
  display: block;
  color: #77838f;
  cursor: pointer;
}

.u-quantity__arrows-inner:hover {
  color: #297cbb;
}

/*-------------------------------------------*\
    2.34. Search Forms
\*-------------------------------------------*/
/*------------------------------------
  Search Form - Push Top
------------------------------------*/
.u-search-push-top {
  display: none;
  background-color: #f8fafd;
  padding-top: 3rem;
  padding-bottom: 3rem;
  box-shadow: inset 0 -10px 20px -10px rgba(151, 164, 175, 0.05);
}

.u-search-push-top__content {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.u-search-push-top__close-btn {
  position: absolute;
  top: -1.5625rem;
  right: .4375rem;
}

@media (max-width: 575.98px) {
  .u-search-push-top {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

/* Banner */
.u-search-push-top__banner {
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(151, 164, 175, 0.05);
  padding-right: 1.5625rem;
  padding-top: 1.875rem;
  overflow: hidden;
}

.u-search-push-top__banner-container {
  position: relative;
  width: 75%;
  min-height: 11.5625rem;
}

.u-search-push-top__banner-img {
  position: absolute;
  bottom: -.625rem;
  left: -.625rem;
  transition: all .3s ease-in-out;
}

.u-search-push-top__banner:hover .u-search-push-top__banner-img:first-child {
  bottom: 0;
  left: 0;
}

/*------------------------------------
  Search Form - Slide Down
------------------------------------*/
.u-search-slide-down {
  position: absolute;
  z-index: 1003;
  top: 0;
  left: 0;
  width: 23.75rem;
}

.u-search-slide-down-trigger.active .u-search-slide-down-trigger__icon::before {
  content: "\f00d";
}

.u-search-slide-down .input-group {
  background-color: #ebf0f7;
}

.u-search-slide-down .form-control {
  min-height: 3.75rem;
}

.u-search-slide-down .form-control::-webkit-input-placeholder {
  color: #3b444f;
}

.u-search-slide-down .form-control::-moz-placeholder {
  color: #3b444f;
}

.u-search-slide-down .form-control:-ms-input-placeholder {
  color: #3b444f;
}

.u-search-slide-down .form-control::-ms-input-placeholder {
  color: #3b444f;
}

.u-search-slide-down .form-control::placeholder {
  color: #3b444f;
}

.u-search-slide-down .btn-icon__inner {
  color: #fff;
}

.u-search-slide-down .btn.btn-icon {
  margin-right: 0.688rem;
  cursor: pointer !important;
}

.u-search-slide-down .list-group-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.469rem 1.438rem;
}

.u-search-slide-down .list-group-item:hover, .u-search-slide-down .list-group-item:focus {
  background-color: #f9fafc;
}

.u-search-slide-down .list-group-item-action {
  color: #3b444f;
}

.u-search-slide-down-bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: none;
  width: 100%;
  height: 100%;
}

.u-search-slide-down .u-search-slide-down__input {
  opacity: 0;
  -webkit-transform: translateY(-2rem);
          transform: translateY(-2rem);
  transition: opacity 0.2s, -webkit-transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98);
  transition: transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s;
  transition: transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s, -webkit-transform 0.3s cubic-bezier(0.37, 0.41, 0.55, 0.98);
}

.u-search-slide-down .u-search-slide-down__suggestions {
  opacity: 0;
  -webkit-transform: translateY(-3rem);
          transform: translateY(-3rem);
  transition: opacity 0.2s, -webkit-transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98);
  transition: transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s;
  transition: transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98), opacity 0.2s, -webkit-transform 0.4s cubic-bezier(0.37, 0.41, 0.55, 0.98);
}

.u-search-slide-down.active .u-search-slide-down__input,
.u-search-slide-down.active .u-search-slide-down__suggestions {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}

.u-search-slide-down.active .u-search-slide-down__suggestions {
  transition-delay: 0.2s;
}

/*-------------------------------------------*\
    2.35. Slick
\*-------------------------------------------*/
.u-slick {
  position: relative;
}

.u-slick__img-overlay .slick-slide:not(.slick-center) {
  position: relative;
}

.u-slick__img-overlay .slick-slide:not(.slick-center)::before {
  background-color: rgba(59, 68, 79, 0.8);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  content: " ";
}

.u-slick__tab .tab-pane:not(.active) {
  display: block !important;
  height: 0 !important;
  overflow: hidden;
  padding: 0 !important;
}

.u-slick__tab .tab-pane.active {
  height: auto !important;
}

.u-slick--transform-off.slick-transform-off .slick-track {
  -webkit-transform: none !important;
          transform: none !important;
}

.testimonial-quote text,
.testimonial-quote path {
  fill: #ebf0f7;
}

.testimonial-quote i {
  color: #ebf0f7;
}

.u-slick-bordered-primary .slick-current .card {
  border-color: #297cbb !important;
}

.u-slick-bordered-primary .slick-current .card .testimonial-quote text,
.u-slick-bordered-primary .slick-current .card .testimonial-quote path {
  fill: #297cbb;
}

.u-slick-bordered-primary .slick-current .card .testimonial-quote i {
  color: #297cbb;
}

.u-slick-bordered-dark .slick-current .card {
  border-color: #1a2b49 !important;
}

.u-slick-bordered-dark .slick-current .card .testimonial-quote text,
.u-slick-bordered-dark .slick-current .card .testimonial-quote path {
  fill: #1a2b49;
}

.u-slick-bordered-dark .slick-current .card .testimonial-quote i {
  color: #1a2b49;
}

/*------------------------------------
  Slick Equal Height
------------------------------------*/
.u-slick--equal-height .slick-list {
  height: 100%;
}

.u-slick--equal-height .slick-track {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.u-slick--equal-height .slick-track .slick-slide {
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

/*------------------------------------
  Slick Gutters
------------------------------------*/
/* Gutters X */
.u-slick--gutters-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.u-slick--gutters-1 .slick-slide {
  margin-left: .25rem;
  margin-right: .25rem;
}

.u-slick--gutters-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.u-slick--gutters-2 .slick-slide {
  margin-left: .5rem;
  margin-right: .5rem;
}

.u-slick--gutters-3 {
  margin-left: -.9375rem;
  margin-right: -.9375rem;
}

.u-slick--gutters-3 .slick-slide {
  margin-left: .9375rem;
  margin-right: .9375rem;
}

.u-slick--gutters-4 {
  margin-left: -.4rem;
  margin-right: -.4rem;
}

.u-slick--gutters-4 .slick-slide {
  margin-left: .4rem;
  margin-right: .4rem;
}

/*------------------------------------
  Slick Zoom
------------------------------------*/
.u-slick-zoom .slick-slide .u-slick-zoom__slide {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: all 0.5s ease-in-out;
}

.u-slick-zoom .slick-center .u-slick-zoom__slide {
  -webkit-transform: scale(1);
          transform: scale(1);
}

/*------------------------------------
  Slick Arrows
------------------------------------*/
.u-slick__arrow {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #297cbb;
  background-color: rgba(41, 124, 187, 0.1);
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.u-slick__arrow:hover {
  color: #fff;
  background-color: #297cbb;
}

.u-slick__arrow-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-slick__arrow-inner--left {
  left: 0;
}

.u-slick__arrow-inner--right {
  right: 0;
}

/* Flat */
.u-slick__arrow--flat {
  color: #fff;
  background-color: rgba(41, 124, 187, 0.8);
}

/* Offset */
@media (min-width: 992px) {
  .u-slick__arrow--offset.u-slick__arrow-inner--left {
    left: -2.5rem;
  }
  .u-slick__arrow--offset.u-slick__arrow-inner--right {
    right: -2.5rem;
  }
}

/* Vertical Center Alignment */
.u-slick__arrow-centered--y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

/*------------------------------------
  Slick Arrows Classic
------------------------------------*/
.u-slick__arrow-classic {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #297cbb;
  background-color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.u-slick__arrow-classic:hover {
  color: #fff;
  background-color: #297cbb;
}

.u-slick__arrow-classic-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-slick__arrow-classic-inner--left {
  left: 0;
}

.u-slick__arrow-classic-inner--left.v1 {
  left: -5px;
}

.u-slick__arrow-classic-inner--left.v4 {
  left: 25px;
}

.u-slick__arrow-classic-inner--right {
  right: 0;
}

.u-slick__arrow-classic-inner--right.v1 {
  right: -5px;
}

.u-slick__arrow-classic-inner--right.v4 {
  right: 25px;
}

.u-slick__arrow-classic--v1 {
  color: #3d88c1;
  background-color: #fff;
}

.u-slick__arrow-classic--v2 {
  color: #297cbb;
  background-color: #ebf0f7;
}

.u-slick__arrow-classic--v3 {
  color: #3b444f;
  background-color: transparent;
  font-size: 20px;
  font-weight: 800;
  color: #cad4e1;
}

.u-slick__arrow-classic--v3:hover {
  color: #3b444f;
  background-color: transparent;
}

.u-slick__arrow-classic--v4 {
  background-color: transparent;
  color: #fff;
  font-size: 20px;
}

.u-slick__arrow-classic--v4:hover {
  background-color: transparent;
}

/*------------------------------------
  Slick Paging
------------------------------------*/
.u-slick__paging {
  position: absolute;
  bottom: 2rem;
  right: 0;
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.u-slick__paging .u-paging__current {
  color: #fff;
  font-size: 3.875rem;
  font-weight: 300;
  line-height: 1;
}

.u-slick__paging .u-paging__divider {
  position: relative;
  margin-right: .5rem;
  margin-left: .25rem;
}

.u-slick__paging .u-paging__divider::before {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
  content: "\002f";
}

.u-slick__paging .u-paging__total {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Slick Pagination
------------------------------------*/
.u-slick__pagination {
  padding-left: 0;
}

.u-slick__pagination:not(.u-slick__pagination--block) {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  pointer-events: none;
}

.u-slick__pagination li {
  display: -ms-flexbox;
  display: flex;
  pointer-events: all;
  margin: 0 .25rem;
  cursor: pointer;
}

.u-slick__pagination li span {
  display: inline-block;
  width: .9375rem;
  height: .9375rem;
  box-sizing: border-box;
  background-color: #cad4e1;
  border: 2px solid transparent;
  border-radius: 50%;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  transition: 0.3s ease-in-out;
}

.u-slick__pagination li.slick-active span {
  background-color: transparent;
  border-color: #297cbb;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

/* White Version */
.u-slick__pagination--white li span {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transform: none;
          transform: none;
  border-width: 0;
}

.u-slick__pagination--white li.slick-active span {
  border-color: none;
  background-color: white;
}

/* Block */
.u-slick__pagination--block li {
  display: block;
}

/* Vertical Center Alignment */
.u-slick__pagination-centered--y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

@media (min-width: 992px) {
  /* Vertical Option */
  .u-slick__pagination--vertical-lg li {
    display: block;
    margin: .25rem 0;
  }
  /* Vertical Option Positions */
  .u-slick__pagination--vertical-pos-v1-lg {
    position: absolute;
    top: 50%;
    left: -15.7%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

.u-slick__pagination-dark {
  padding-left: 0;
}

.u-slick__pagination-dark:not(.u-slick__pagination--block) {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  pointer-events: none;
}

.u-slick__pagination-dark li {
  display: -ms-flexbox;
  display: flex;
  pointer-events: all;
  margin: 0 .25rem;
  cursor: pointer;
}

.u-slick__pagination-dark li span {
  display: inline-block;
  width: .9375rem;
  height: .9375rem;
  box-sizing: border-box;
  background-color: #cad4e1;
  border: 2px solid transparent;
  border-radius: 50%;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  transition: 0.3s ease-in-out;
}

.u-slick__pagination-dark li.slick-active span {
  background-color: transparent;
  border-color: #1a2b49 !important;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

/*------------------------------------
  Slick Pagination Modern
------------------------------------*/
.u-slick--pagination-modern {
  width: 100%;
  max-width: 12.5rem;
}

.u-slick--pagination-modern .slick-slide {
  cursor: pointer;
  opacity: 0.7;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: 0.3s ease-in-out;
}

.u-slick--pagination-modern .slick-current {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

/*------------------------------------
  Slick Pagination Interactive
------------------------------------*/
.u-slick--pagination-interactive__text {
  color: rgba(255, 255, 255, 0.7);
}

.u-slick--pagination-interactive .slick-slide {
  cursor: pointer;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

.u-slick--pagination-interactive .slick-slide:hover {
  background: rgba(255, 255, 255, 0.15);
}

.u-slick--pagination-interactive .slick-slide:hover.slick-center {
  background: #fff;
}

.u-slick--pagination-interactive .slick-center {
  background: #fff;
}

.u-slick--pagination-interactive .slick-center .u-slick--pagination-interactive__title {
  color: #297cbb;
}

.u-slick--pagination-interactive .slick-center .u-slick--pagination-interactive__text {
  color: #77838f;
}

/*-------------------------------------------*\
    2.36. Spinner
\*-------------------------------------------*/
.spinner-border,
.spinner-grow {
  vertical-align: middle;
}

/*-------------------------------------------*\
    2.37. Stats
\*-------------------------------------------*/
/*------------------------------------
  Stats Progress
------------------------------------*/
.u-stats-progress {
  position: relative;
  width: 13.75rem;
  height: 13.75rem;
  border-radius: 50%;
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125);
  margin-left: auto;
  margin-right: auto;
}

.u-stats-progress__info {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  left: 0;
  right: 0;
  padding: 2rem;
  text-align: center;
}

/*-------------------------------------------*\
    2.38. SVG
\*-------------------------------------------*/
.svg-preloader {
  z-index: -1;
  background: #fff require("./svg/preloaders/circle-preloader.svg") center no-repeat !important;
  overflow: hidden;
  transition: all 0.4s ease-in;
}

.svg-preloader *:not(.u-icon):not(.btn):not(.u-media-player):not(.u-media-player__icon):not(.u-label) {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

/*------------------------------------
  SVG IE10+ specific styles go here
------------------------------------*/
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .u-header__promo-icon {
    height: 48px;
  }
  .ie-height-24 {
    height: 24px;
  }
  .ie-height-40 {
    height: 40px;
  }
  .ie-height-48 {
    height: 48px;
  }
  .ie-height-56 {
    height: 56px;
  }
  .ie-height-72 {
    height: 72px;
  }
  .ie-main-hero {
    height: 686px;
  }
  .ie-soft-triangle-shape {
    height: 610px;
  }
  .ie-browser {
    height: 565px;
  }
  .ie-wave-1-bottom {
    height: 100px;
  }
  .ie-vault,
  .ie-responsive,
  .ie-easy-payment {
    height: 210px;
  }
  .ie-marketing-strategy,
  .ie-app-development,
  .ie-business-analysis {
    height: 328px;
  }
  .ie-devices-v1-tablet {
    height: 38.5rem;
  }
  .ie-devices-v1-phone {
    height: 27.5rem;
  }
  .ie-devices-v2-tablet {
    height: 37.875rem;
  }
  .ie-devices-v2-iphone {
    height: 31rem;
  }
  .ie-devices-v3-iphone {
    height: 633px;
  }
  .ie-devices-v4-tablet-horizontal {
    height: 16.875rem;
  }
  .ie-devices-v4-tablet {
    height: 25.4375rem;
  }
  .ie-devices-v4-laptop {
    height: 20.75rem;
  }
  .ie-devices-v4-iphone {
    height: 13.0625rem;
  }
  .ie-wave-6-top-left {
    height: 379px;
  }
  .ie-double-ellipse-top-right {
    height: 431px;
  }
  .ie-double-ellipse-top-left {
    height: 788px;
  }
  .ie-double-ellipse-bottom-right {
    height: 252px;
  }
  .ie-ellipse-mockup {
    height: 656px;
  }
  .ie-irregular-shape-2-right {
    height: 660px;
  }
  .ie-irregular-shape-3-bottom {
    height: 255px;
  }
  .ie-circle-chart {
    height: 219px;
  }
  .ie-curved-shape {
    height: 55.8px;
  }
  .ie-subscribe-illustration {
    height: 329px;
  }
  .ie-subscribe-1 {
    height: 315px;
  }
  .ie-subscribe-2 {
    height: 295px;
  }
  .ie-subscribe-2-flat-icons {
    height: 200px;
  }
  .ie-color-gradient {
    height: 566px;
  }
  .ie-for-sale,
  .ie-buyer {
    height: 208px;
  }
  .ie-events,
  .ie-data-report,
  .ie-image-upload {
    height: 219px;
  }
  .ie-analysis,
  .ie-in-the-office,
  .ie-make-it-rain {
    height: 200px;
  }
  .ie-house-agency {
    height: 381px;
  }
  .ie-laptop-and-iphone {
    height: 421px;
  }
  .ie-get-answered {
    height: 386px;
  }
  .ie-bg-elements-1 {
    height: 420px;
  }
  .ie-bg-elements-2 {
    height: 374px;
  }
  .ie-bg-elements-3 {
    height: 583px;
  }
  .ie-bg-elements-4 {
    height: 850px;
  }
  .ie-circle-1 {
    height: 379px;
  }
  .ie-go-to-wave {
    height: 46px;
  }
  .ie-graphic-illustration-1 {
    height: 270px;
  }
  .ie-app-development {
    height: 328px;
  }
  .ie-we-have-an-idea {
    height: 335px;
  }
  .ie-chatting-girl,
  .ie-chatting-boy {
    height: 328px;
  }
  .ie-virtual-reality {
    height: 320px;
  }
  .ie-maintenance-mode {
    height: 200px;
  }
  .ie-non-standard-hero-shape {
    height: 556px;
  }
  .ie-enterprise-2 {
    height: 267px;
  }
  .ie-abstract-shapes-1 {
    height: 554px;
  }
  .ie-abstract-shapes-2,
  .ie-abstract-shapes-3,
  .ie-abstract-shapes-4 {
    height: 532px;
  }
  .ie-abstract-shapes-6 {
    height: 187px;
  }
  .ie-abstract-shapes-7 {
    height: 624px;
  }
  .ie-abstract-shapes-9 {
    height: 182px;
  }
  .ie-abstract-shapes-10 {
    height: 573px;
  }
  .ie-abstract-shapes-11 {
    height: 192px;
  }
  .ie-showcase-mockup-1 {
    height: 384px;
  }
  .ie-showcase-mockup-2 {
    height: 371px;
  }
  .ie-showcase-mockup-3 {
    height: 535px;
  }
  .ie-knowledgebase-community {
    height: 447px;
  }
  .ie-knowledgebase-community-2 {
    height: 542px;
  }
  .ie-files {
    height: 293px;
  }
}

/*-------------------------------------------*\
    2.39. Table
\*-------------------------------------------*/
.table-heighlighted thead th {
  border-bottom-color: #3b444f;
  border-top: none;
}

.table-heighlighted tfoot td {
  border-top: 2px solid #3b444f;
}

/*-------------------------------------------*\
    2.40. Timeline
\*-------------------------------------------*/
.u-timeline {
  list-style: none;
  padding-left: 1rem;
  margin-bottom: 0;
}

.u-timeline__item {
  position: relative;
  padding-right: .9375rem;
  padding-left: 2rem;
}

.u-timeline__item:not(:last-child) {
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.u-timeline__item::before {
  position: absolute;
  top: 3.5rem;
  bottom: 0;
  left: 0;
  height: auto;
  border-left: 0.1875rem solid #e7eaf3;
  content: "";
}

.u-timeline__icon {
  position: absolute;
  left: -.875rem;
}

.u-timeline__heading {
  position: relative;
  display: block;
  font-size: 1.25rem;
  font-weight: 500;
  padding-bottom: .75rem;
  margin-bottom: 1rem;
}

.u-timeline__heading::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 0.1rem solid #e7eaf3;
  content: "";
}

/*-------------------------------------------*\
    2.41. Sticky Block
\*-------------------------------------------*/
[class*="sticky-block"] {
  max-width: 100%;
}

/*-------------------------------------------*\
    2.42. Utilities
\*-------------------------------------------*/
/*------------------------------------
  Background Gradients
------------------------------------*/
.gradient-half-primary-body-v1 {
  background-image: linear-gradient(150deg, #2d1582 0%, #19a0ff 100%);
  background-repeat: repeat-x;
  background-attachment: fixed;
}

.gradient-half-primary-v1 {
  background-image: linear-gradient(150deg, #2d1582 0%, #19a0ff 100%);
  background-repeat: repeat-x;
}

.gradient-half-primary-v2 {
  background-image: linear-gradient(0deg, rgba(41, 124, 187, 0.05) 0%, transparent 100%);
  background-repeat: repeat-x;
}

.gradient-half-primary-v3 {
  background-image: linear-gradient(0deg, rgba(41, 124, 187, 0.1) 0%, transparent 100%);
  background-repeat: repeat-x;
}

.gradient-half-primary-v4 {
  background-image: linear-gradient(150deg, #2d1582 0%, #19a0ff 85%);
  background-repeat: repeat-x;
}

.gradient-half-primary-v5 {
  background-image: linear-gradient(150deg, #297cbb 0%, #2d1582 100%);
  background-repeat: repeat-x;
}

.gradient-half-info-v1 {
  background-image: linear-gradient(0deg, #297cbb 0%, #00dffc 100%);
  background-repeat: repeat-x;
}

.gradient-half-warning-v1 {
  background-image: linear-gradient(25deg, #ffc107 30%, #de4437 100%);
  background-repeat: repeat-x;
}

.gradient-half-warning-v2 {
  background-image: linear-gradient(150deg, #ffc107 0%, #efa02e 100%);
  background-repeat: repeat-x;
}

.gradient-half-warning-v3 {
  background-image: linear-gradient(150deg, #ffc107 0%, #de4437 100%);
  background-repeat: repeat-x;
}

/*------------------------------------
 Grid
------------------------------------*/
[class*="gdot"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-2dot2 {
  -ms-flex: 0 0 18.33333%;
      flex: 0 0 18.33333%;
  max-width: 18.33333%;
}

.col-xs-2dot3 {
  -ms-flex: 0 0 19.16667%;
      flex: 0 0 19.16667%;
  max-width: 19.16667%;
}

.col-xs-2dot4 {
  -ms-flex: 0 0 20%;
      flex: 0 0 20%;
  max-width: 20%;
}

.col-xs-2gdot8 {
  -ms-flex: 0 0 23.66667%;
      flex: 0 0 23.66667%;
  max-width: 23.66667%;
}

.col-xs-3dot64 {
  -ms-flex: 0 0 30.35833%;
      flex: 0 0 30.35833%;
  max-width: 30.35833%;
}

.col-xs-3dot7 {
  -ms-flex: 0 0 30.83333%;
      flex: 0 0 30.83333%;
  max-width: 30.83333%;
}

.col-xs-3dot2 {
  -ms-flex: 0 0 25.83333%;
      flex: 0 0 25.83333%;
  max-width: 25.83333%;
}

.col-xs-3dot1 {
  -ms-flex: 0 0 25.83333%;
      flex: 0 0 25.83333%;
  max-width: 25.83333%;
}

.col-xs-3dot6 {
  -ms-flex: 0 0 30%;
      flex: 0 0 30%;
  max-width: 30%;
}

.col-xs-2dot8 {
  -ms-flex: 0 0 23.33333%;
      flex: 0 0 23.33333%;
  max-width: 23.33333%;
}

.col-xs-2dot6 {
  -ms-flex: 0 0 21.66667%;
      flex: 0 0 21.66667%;
  max-width: 21.66667%;
}

.col-xs-2dot5 {
  -ms-flex: 0 0 20.83333%;
      flex: 0 0 20.83333%;
  max-width: 20.83333%;
}

.col-xs-2dot7 {
  -ms-flex: 0 0 22.5%;
      flex: 0 0 22.5%;
  max-width: 22.5%;
}

.col-xs-1dot6 {
  -ms-flex: 0 0 13.33333%;
      flex: 0 0 13.33333%;
  max-width: 13.33333%;
}

.col-xs-1dot8 {
  -ms-flex: 0 0 15%;
      flex: 0 0 15%;
  max-width: 15%;
}

.col-xs-8dot4 {
  -ms-flex: 0 0 70%;
      flex: 0 0 70%;
  max-width: 70%;
}

.col-xs-8dot1 {
  -ms-flex: 0 0 67.5%;
      flex: 0 0 67.5%;
  max-width: 67.5%;
}

.col-xs-8dot2 {
  -ms-flex: 0 0 68.33333%;
      flex: 0 0 68.33333%;
  max-width: 68.33333%;
}

.col-xs-3dot5 {
  -ms-flex: 0 0 29.16667%;
      flex: 0 0 29.16667%;
  max-width: 29.16667%;
}

.col-xs-3gdot5 {
  -ms-flex: 0 0 29.16667%;
      flex: 0 0 29.16667%;
  max-width: 29.16667%;
}

.col-xs-6dot5 {
  -ms-flex: 0 0 54.16667%;
      flex: 0 0 54.16667%;
  max-width: 54.16667%;
}

.col-xs-5dot4 {
  -ms-flex: 0 0 45%;
      flex: 0 0 45%;
  max-width: 45%;
}

.col-xs-7dot5 {
  -ms-flex: 0 0 62.5%;
      flex: 0 0 62.5%;
  max-width: 62.5%;
}

.col-xs-4dot5 {
  -ms-flex: 0 0 37.5%;
      flex: 0 0 37.5%;
  max-width: 37.5%;
}

.col-xs-4gdot5 {
  -ms-flex: 0 0 37.5%;
      flex: 0 0 37.5%;
  max-width: 37.5%;
}

@media (min-width: 1200px) and (max-width: 1479.98px) {
  .col-xs-8dot3 {
    -ms-flex: 0 0 69.16667%;
        flex: 0 0 69.16667%;
    max-width: 69.16667%;
  }
}

.col-xs-9dot2 {
  -ms-flex: 0 0 76.66667%;
      flex: 0 0 76.66667%;
  max-width: 76.66667%;
}

.col-xs-8dot5 {
  -ms-flex: 0 0 70.83333%;
      flex: 0 0 70.83333%;
  max-width: 70.83333%;
}

.col-xs-8gdot5 {
  -ms-flex: 0 0 70.83333%;
      flex: 0 0 70.83333%;
  max-width: 70.83333%;
}

.col-xs-4dot1 {
  -ms-flex: 0 0 34.16667%;
      flex: 0 0 34.16667%;
  max-width: 34.16667%;
}

.col-xs-7dot9 {
  -ms-flex: 0 0 65.83333%;
      flex: 0 0 65.83333%;
  max-width: 65.83333%;
}

.col-xs-5dot5 {
  -ms-flex: 0 0 45.83333%;
      flex: 0 0 45.83333%;
  max-width: 45.83333%;
}

.col-xs-9dot5 {
  -ms-flex: 0 0 79.16667%;
      flex: 0 0 79.16667%;
  max-width: 79.16667%;
}

@media (min-width: 576px) {
  .col-sm-2dot2 {
    -ms-flex: 0 0 18.33333%;
        flex: 0 0 18.33333%;
    max-width: 18.33333%;
  }
}

@media (min-width: 576px) {
  .col-sm-2dot3 {
    -ms-flex: 0 0 19.16667%;
        flex: 0 0 19.16667%;
    max-width: 19.16667%;
  }
}

@media (min-width: 576px) {
  .col-sm-2dot4 {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 576px) {
  .col-sm-2gdot8 {
    -ms-flex: 0 0 23.66667%;
        flex: 0 0 23.66667%;
    max-width: 23.66667%;
  }
}

@media (min-width: 576px) {
  .col-sm-3dot64 {
    -ms-flex: 0 0 30.35833%;
        flex: 0 0 30.35833%;
    max-width: 30.35833%;
  }
}

@media (min-width: 576px) {
  .col-sm-3dot7 {
    -ms-flex: 0 0 30.83333%;
        flex: 0 0 30.83333%;
    max-width: 30.83333%;
  }
}

@media (min-width: 576px) {
  .col-sm-3dot2 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 576px) {
  .col-sm-3dot1 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 576px) {
  .col-sm-3dot6 {
    -ms-flex: 0 0 30%;
        flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 576px) {
  .col-sm-2dot8 {
    -ms-flex: 0 0 23.33333%;
        flex: 0 0 23.33333%;
    max-width: 23.33333%;
  }
}

@media (min-width: 576px) {
  .col-sm-2dot6 {
    -ms-flex: 0 0 21.66667%;
        flex: 0 0 21.66667%;
    max-width: 21.66667%;
  }
}

@media (min-width: 576px) {
  .col-sm-2dot5 {
    -ms-flex: 0 0 20.83333%;
        flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
}

@media (min-width: 576px) {
  .col-sm-2dot7 {
    -ms-flex: 0 0 22.5%;
        flex: 0 0 22.5%;
    max-width: 22.5%;
  }
}

@media (min-width: 576px) {
  .col-sm-1dot6 {
    -ms-flex: 0 0 13.33333%;
        flex: 0 0 13.33333%;
    max-width: 13.33333%;
  }
}

@media (min-width: 576px) {
  .col-sm-1dot8 {
    -ms-flex: 0 0 15%;
        flex: 0 0 15%;
    max-width: 15%;
  }
}

@media (min-width: 576px) {
  .col-sm-8dot4 {
    -ms-flex: 0 0 70%;
        flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (min-width: 576px) {
  .col-sm-8dot1 {
    -ms-flex: 0 0 67.5%;
        flex: 0 0 67.5%;
    max-width: 67.5%;
  }
}

@media (min-width: 576px) {
  .col-sm-8dot2 {
    -ms-flex: 0 0 68.33333%;
        flex: 0 0 68.33333%;
    max-width: 68.33333%;
  }
}

@media (min-width: 576px) {
  .col-sm-3dot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 576px) {
  .col-sm-3gdot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 576px) {
  .col-sm-6dot5 {
    -ms-flex: 0 0 54.16667%;
        flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }
}

@media (min-width: 576px) {
  .col-sm-5dot4 {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%;
    max-width: 45%;
  }
}

@media (min-width: 576px) {
  .col-sm-7dot5 {
    -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
    max-width: 62.5%;
  }
}

@media (min-width: 576px) {
  .col-sm-4dot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 576px) {
  .col-sm-4gdot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 1200px) and (max-width: 1479.98px) {
  .col-sm-8dot3 {
    -ms-flex: 0 0 69.16667%;
        flex: 0 0 69.16667%;
    max-width: 69.16667%;
  }
}

@media (min-width: 576px) {
  .col-sm-9dot2 {
    -ms-flex: 0 0 76.66667%;
        flex: 0 0 76.66667%;
    max-width: 76.66667%;
  }
}

@media (min-width: 576px) {
  .col-sm-8dot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 576px) {
  .col-sm-8gdot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 576px) {
  .col-sm-4dot1 {
    -ms-flex: 0 0 34.16667%;
        flex: 0 0 34.16667%;
    max-width: 34.16667%;
  }
}

@media (min-width: 576px) {
  .col-sm-7dot9 {
    -ms-flex: 0 0 65.83333%;
        flex: 0 0 65.83333%;
    max-width: 65.83333%;
  }
}

@media (min-width: 576px) {
  .col-sm-5dot5 {
    -ms-flex: 0 0 45.83333%;
        flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }
}

@media (min-width: 576px) {
  .col-sm-9dot5 {
    -ms-flex: 0 0 79.16667%;
        flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }
}

@media (min-width: 768px) {
  .col-md-2dot2 {
    -ms-flex: 0 0 18.33333%;
        flex: 0 0 18.33333%;
    max-width: 18.33333%;
  }
}

@media (min-width: 768px) {
  .col-md-2dot3 {
    -ms-flex: 0 0 19.16667%;
        flex: 0 0 19.16667%;
    max-width: 19.16667%;
  }
}

@media (min-width: 768px) {
  .col-md-2dot4 {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 768px) {
  .col-md-2gdot8 {
    -ms-flex: 0 0 23.66667%;
        flex: 0 0 23.66667%;
    max-width: 23.66667%;
  }
}

@media (min-width: 768px) {
  .col-md-3dot64 {
    -ms-flex: 0 0 30.35833%;
        flex: 0 0 30.35833%;
    max-width: 30.35833%;
  }
}

@media (min-width: 768px) {
  .col-md-3dot7 {
    -ms-flex: 0 0 30.83333%;
        flex: 0 0 30.83333%;
    max-width: 30.83333%;
  }
}

@media (min-width: 768px) {
  .col-md-3dot2 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 768px) {
  .col-md-3dot1 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 768px) {
  .col-md-3dot6 {
    -ms-flex: 0 0 30%;
        flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 768px) {
  .col-md-2dot8 {
    -ms-flex: 0 0 23.33333%;
        flex: 0 0 23.33333%;
    max-width: 23.33333%;
  }
}

@media (min-width: 768px) {
  .col-md-2dot6 {
    -ms-flex: 0 0 21.66667%;
        flex: 0 0 21.66667%;
    max-width: 21.66667%;
  }
}

@media (min-width: 768px) {
  .col-md-2dot5 {
    -ms-flex: 0 0 20.83333%;
        flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
}

@media (min-width: 768px) {
  .col-md-2dot7 {
    -ms-flex: 0 0 22.5%;
        flex: 0 0 22.5%;
    max-width: 22.5%;
  }
}

@media (min-width: 768px) {
  .col-md-1dot6 {
    -ms-flex: 0 0 13.33333%;
        flex: 0 0 13.33333%;
    max-width: 13.33333%;
  }
}

@media (min-width: 768px) {
  .col-md-1dot8 {
    -ms-flex: 0 0 15%;
        flex: 0 0 15%;
    max-width: 15%;
  }
}

@media (min-width: 768px) {
  .col-md-8dot4 {
    -ms-flex: 0 0 70%;
        flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (min-width: 768px) {
  .col-md-8dot1 {
    -ms-flex: 0 0 67.5%;
        flex: 0 0 67.5%;
    max-width: 67.5%;
  }
}

@media (min-width: 768px) {
  .col-md-8dot2 {
    -ms-flex: 0 0 68.33333%;
        flex: 0 0 68.33333%;
    max-width: 68.33333%;
  }
}

@media (min-width: 768px) {
  .col-md-3dot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 768px) {
  .col-md-3gdot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 768px) {
  .col-md-6dot5 {
    -ms-flex: 0 0 54.16667%;
        flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }
}

@media (min-width: 768px) {
  .col-md-5dot4 {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%;
    max-width: 45%;
  }
}

@media (min-width: 768px) {
  .col-md-7dot5 {
    -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
    max-width: 62.5%;
  }
}

@media (min-width: 768px) {
  .col-md-4dot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 768px) {
  .col-md-4gdot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 1200px) and (max-width: 1479.98px) {
  .col-md-8dot3 {
    -ms-flex: 0 0 69.16667%;
        flex: 0 0 69.16667%;
    max-width: 69.16667%;
  }
}

@media (min-width: 768px) {
  .col-md-9dot2 {
    -ms-flex: 0 0 76.66667%;
        flex: 0 0 76.66667%;
    max-width: 76.66667%;
  }
}

@media (min-width: 768px) {
  .col-md-8dot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 768px) {
  .col-md-8gdot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 768px) {
  .col-md-4dot1 {
    -ms-flex: 0 0 34.16667%;
        flex: 0 0 34.16667%;
    max-width: 34.16667%;
  }
}

@media (min-width: 768px) {
  .col-md-7dot9 {
    -ms-flex: 0 0 65.83333%;
        flex: 0 0 65.83333%;
    max-width: 65.83333%;
  }
}

@media (min-width: 768px) {
  .col-md-5dot5 {
    -ms-flex: 0 0 45.83333%;
        flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }
}

@media (min-width: 768px) {
  .col-md-9dot5 {
    -ms-flex: 0 0 79.16667%;
        flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }
}

@media (min-width: 992px) {
  .col-lg-2dot2 {
    -ms-flex: 0 0 18.33333%;
        flex: 0 0 18.33333%;
    max-width: 18.33333%;
  }
}

@media (min-width: 992px) {
  .col-lg-2dot3 {
    -ms-flex: 0 0 19.16667%;
        flex: 0 0 19.16667%;
    max-width: 19.16667%;
  }
}

@media (min-width: 992px) {
  .col-lg-2dot4 {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .col-lg-2gdot8 {
    -ms-flex: 0 0 23.66667%;
        flex: 0 0 23.66667%;
    max-width: 23.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg-3dot64 {
    -ms-flex: 0 0 30.35833%;
        flex: 0 0 30.35833%;
    max-width: 30.35833%;
  }
}

@media (min-width: 992px) {
  .col-lg-3dot7 {
    -ms-flex: 0 0 30.83333%;
        flex: 0 0 30.83333%;
    max-width: 30.83333%;
  }
}

@media (min-width: 992px) {
  .col-lg-3dot2 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 992px) {
  .col-lg-3dot1 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 992px) {
  .col-lg-3dot6 {
    -ms-flex: 0 0 30%;
        flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 992px) {
  .col-lg-2dot8 {
    -ms-flex: 0 0 23.33333%;
        flex: 0 0 23.33333%;
    max-width: 23.33333%;
  }
}

@media (min-width: 992px) {
  .col-lg-2dot6 {
    -ms-flex: 0 0 21.66667%;
        flex: 0 0 21.66667%;
    max-width: 21.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg-2dot5 {
    -ms-flex: 0 0 20.83333%;
        flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
}

@media (min-width: 992px) {
  .col-lg-2dot7 {
    -ms-flex: 0 0 22.5%;
        flex: 0 0 22.5%;
    max-width: 22.5%;
  }
}

@media (min-width: 992px) {
  .col-lg-1dot6 {
    -ms-flex: 0 0 13.33333%;
        flex: 0 0 13.33333%;
    max-width: 13.33333%;
  }
}

@media (min-width: 992px) {
  .col-lg-1dot8 {
    -ms-flex: 0 0 15%;
        flex: 0 0 15%;
    max-width: 15%;
  }
}

@media (min-width: 992px) {
  .col-lg-8dot4 {
    -ms-flex: 0 0 70%;
        flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (min-width: 992px) {
  .col-lg-8dot1 {
    -ms-flex: 0 0 67.5%;
        flex: 0 0 67.5%;
    max-width: 67.5%;
  }
}

@media (min-width: 992px) {
  .col-lg-8dot2 {
    -ms-flex: 0 0 68.33333%;
        flex: 0 0 68.33333%;
    max-width: 68.33333%;
  }
}

@media (min-width: 992px) {
  .col-lg-3dot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 992px) {
  .col-lg-3gdot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 992px) {
  .col-lg-6dot5 {
    -ms-flex: 0 0 54.16667%;
        flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }
}

@media (min-width: 992px) {
  .col-lg-5dot4 {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%;
    max-width: 45%;
  }
}

@media (min-width: 992px) {
  .col-lg-7dot5 {
    -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
    max-width: 62.5%;
  }
}

@media (min-width: 992px) {
  .col-lg-4dot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 992px) {
  .col-lg-4gdot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 1200px) and (max-width: 1479.98px) {
  .col-lg-8dot3 {
    -ms-flex: 0 0 69.16667%;
        flex: 0 0 69.16667%;
    max-width: 69.16667%;
  }
}

@media (min-width: 992px) {
  .col-lg-9dot2 {
    -ms-flex: 0 0 76.66667%;
        flex: 0 0 76.66667%;
    max-width: 76.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg-8dot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 992px) {
  .col-lg-8gdot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 992px) {
  .col-lg-4dot1 {
    -ms-flex: 0 0 34.16667%;
        flex: 0 0 34.16667%;
    max-width: 34.16667%;
  }
}

@media (min-width: 992px) {
  .col-lg-7dot9 {
    -ms-flex: 0 0 65.83333%;
        flex: 0 0 65.83333%;
    max-width: 65.83333%;
  }
}

@media (min-width: 992px) {
  .col-lg-5dot5 {
    -ms-flex: 0 0 45.83333%;
        flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }
}

@media (min-width: 992px) {
  .col-lg-9dot5 {
    -ms-flex: 0 0 79.16667%;
        flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-2dot2 {
    -ms-flex: 0 0 18.33333%;
        flex: 0 0 18.33333%;
    max-width: 18.33333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-2dot3 {
    -ms-flex: 0 0 19.16667%;
        flex: 0 0 19.16667%;
    max-width: 19.16667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-2dot4 {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  .col-xl-2gdot8 {
    -ms-flex: 0 0 23.66667%;
        flex: 0 0 23.66667%;
    max-width: 23.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3dot64 {
    -ms-flex: 0 0 30.35833%;
        flex: 0 0 30.35833%;
    max-width: 30.35833%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3dot7 {
    -ms-flex: 0 0 30.83333%;
        flex: 0 0 30.83333%;
    max-width: 30.83333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3dot2 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3dot1 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3dot6 {
    -ms-flex: 0 0 30%;
        flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 1200px) {
  .col-xl-2dot8 {
    -ms-flex: 0 0 23.33333%;
        flex: 0 0 23.33333%;
    max-width: 23.33333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-2dot6 {
    -ms-flex: 0 0 21.66667%;
        flex: 0 0 21.66667%;
    max-width: 21.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-2dot5 {
    -ms-flex: 0 0 20.83333%;
        flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-2dot7 {
    -ms-flex: 0 0 22.5%;
        flex: 0 0 22.5%;
    max-width: 22.5%;
  }
}

@media (min-width: 1200px) {
  .col-xl-1dot6 {
    -ms-flex: 0 0 13.33333%;
        flex: 0 0 13.33333%;
    max-width: 13.33333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-1dot8 {
    -ms-flex: 0 0 15%;
        flex: 0 0 15%;
    max-width: 15%;
  }
}

@media (min-width: 1200px) {
  .col-xl-8dot4 {
    -ms-flex: 0 0 70%;
        flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (min-width: 1200px) {
  .col-xl-8dot1 {
    -ms-flex: 0 0 67.5%;
        flex: 0 0 67.5%;
    max-width: 67.5%;
  }
}

@media (min-width: 1200px) {
  .col-xl-8dot2 {
    -ms-flex: 0 0 68.33333%;
        flex: 0 0 68.33333%;
    max-width: 68.33333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3dot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-3gdot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-6dot5 {
    -ms-flex: 0 0 54.16667%;
        flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-5dot4 {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%;
    max-width: 45%;
  }
}

@media (min-width: 1200px) {
  .col-xl-7dot5 {
    -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
    max-width: 62.5%;
  }
}

@media (min-width: 1200px) {
  .col-xl-4dot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 1200px) {
  .col-xl-4gdot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 1200px) and (max-width: 1479.98px) {
  .col-xl-8dot3 {
    -ms-flex: 0 0 69.16667%;
        flex: 0 0 69.16667%;
    max-width: 69.16667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-9dot2 {
    -ms-flex: 0 0 76.66667%;
        flex: 0 0 76.66667%;
    max-width: 76.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-8dot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-8gdot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-4dot1 {
    -ms-flex: 0 0 34.16667%;
        flex: 0 0 34.16667%;
    max-width: 34.16667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-7dot9 {
    -ms-flex: 0 0 65.83333%;
        flex: 0 0 65.83333%;
    max-width: 65.83333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-5dot5 {
    -ms-flex: 0 0 45.83333%;
        flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }
}

@media (min-width: 1200px) {
  .col-xl-9dot5 {
    -ms-flex: 0 0 79.16667%;
        flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-2dot2 {
    -ms-flex: 0 0 18.33333%;
        flex: 0 0 18.33333%;
    max-width: 18.33333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-2dot3 {
    -ms-flex: 0 0 19.16667%;
        flex: 0 0 19.16667%;
    max-width: 19.16667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-2dot4 {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1480px) {
  .col-wd-2gdot8 {
    -ms-flex: 0 0 23.66667%;
        flex: 0 0 23.66667%;
    max-width: 23.66667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-3dot64 {
    -ms-flex: 0 0 30.35833%;
        flex: 0 0 30.35833%;
    max-width: 30.35833%;
  }
}

@media (min-width: 1480px) {
  .col-wd-3dot7 {
    -ms-flex: 0 0 30.83333%;
        flex: 0 0 30.83333%;
    max-width: 30.83333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-3dot2 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-3dot1 {
    -ms-flex: 0 0 25.83333%;
        flex: 0 0 25.83333%;
    max-width: 25.83333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-3dot6 {
    -ms-flex: 0 0 30%;
        flex: 0 0 30%;
    max-width: 30%;
  }
}

@media (min-width: 1480px) {
  .col-wd-2dot8 {
    -ms-flex: 0 0 23.33333%;
        flex: 0 0 23.33333%;
    max-width: 23.33333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-2dot6 {
    -ms-flex: 0 0 21.66667%;
        flex: 0 0 21.66667%;
    max-width: 21.66667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-2dot5 {
    -ms-flex: 0 0 20.83333%;
        flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-2dot7 {
    -ms-flex: 0 0 22.5%;
        flex: 0 0 22.5%;
    max-width: 22.5%;
  }
}

@media (min-width: 1480px) {
  .col-wd-1dot6 {
    -ms-flex: 0 0 13.33333%;
        flex: 0 0 13.33333%;
    max-width: 13.33333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-1dot8 {
    -ms-flex: 0 0 15%;
        flex: 0 0 15%;
    max-width: 15%;
  }
}

@media (min-width: 1480px) {
  .col-wd-8dot4 {
    -ms-flex: 0 0 70%;
        flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (min-width: 1480px) {
  .col-wd-8dot1 {
    -ms-flex: 0 0 67.5%;
        flex: 0 0 67.5%;
    max-width: 67.5%;
  }
}

@media (min-width: 1480px) {
  .col-wd-8dot2 {
    -ms-flex: 0 0 68.33333%;
        flex: 0 0 68.33333%;
    max-width: 68.33333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-3dot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-3gdot5 {
    -ms-flex: 0 0 29.16667%;
        flex: 0 0 29.16667%;
    max-width: 29.16667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-6dot5 {
    -ms-flex: 0 0 54.16667%;
        flex: 0 0 54.16667%;
    max-width: 54.16667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-5dot4 {
    -ms-flex: 0 0 45%;
        flex: 0 0 45%;
    max-width: 45%;
  }
}

@media (min-width: 1480px) {
  .col-wd-7dot5 {
    -ms-flex: 0 0 62.5%;
        flex: 0 0 62.5%;
    max-width: 62.5%;
  }
}

@media (min-width: 1480px) {
  .col-wd-4dot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 1480px) {
  .col-wd-4gdot5 {
    -ms-flex: 0 0 37.5%;
        flex: 0 0 37.5%;
    max-width: 37.5%;
  }
}

@media (min-width: 1200px) and (max-width: 1479.98px) {
  .col-wd-8dot3 {
    -ms-flex: 0 0 69.16667%;
        flex: 0 0 69.16667%;
    max-width: 69.16667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-9dot2 {
    -ms-flex: 0 0 76.66667%;
        flex: 0 0 76.66667%;
    max-width: 76.66667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-8dot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-8gdot5 {
    -ms-flex: 0 0 70.83333%;
        flex: 0 0 70.83333%;
    max-width: 70.83333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-4dot1 {
    -ms-flex: 0 0 34.16667%;
        flex: 0 0 34.16667%;
    max-width: 34.16667%;
  }
}

@media (min-width: 1480px) {
  .col-wd-7dot9 {
    -ms-flex: 0 0 65.83333%;
        flex: 0 0 65.83333%;
    max-width: 65.83333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-5dot5 {
    -ms-flex: 0 0 45.83333%;
        flex: 0 0 45.83333%;
    max-width: 45.83333%;
  }
}

@media (min-width: 1480px) {
  .col-wd-9dot5 {
    -ms-flex: 0 0 79.16667%;
        flex: 0 0 79.16667%;
    max-width: 79.16667%;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
}

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/
[class*="gradient-overlay-half"] {
  position: relative;
  z-index: 1;
}

[class*="gradient-overlay-half"]::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  transition: all 0.2s ease-in-out;
}

.gradient-overlay-half-primary-video-v1::before {
  z-index: 2;
  background-image: linear-gradient(150deg, rgba(45, 21, 130, 0.9) 0%, rgba(25, 160, 255, 0.85) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-primary-v1::before {
  background-image: linear-gradient(150deg, rgba(45, 21, 130, 0.9) 0%, rgba(25, 160, 255, 0.85) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-primary-v2::before {
  background-image: linear-gradient(30deg, rgba(25, 160, 255, 0.85) 0%, rgba(45, 21, 130, 0.9) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-info-v1::before {
  background-image: linear-gradient(0deg, rgba(41, 124, 187, 0.92) 0%, rgba(0, 223, 252, 0.92) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v1::before {
  background-image: linear-gradient(0deg, #3b444f 0%, transparent 75%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v2::before {
  background-image: linear-gradient(150deg, rgba(56, 78, 98, 0.675) 0%, rgba(119, 131, 143, 0.3) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-bg-gradient::before {
  background: linear-gradient(180deg, #1e2022 0%, transparent 50%);
}

.gradient-overlay-half-bg-gradient:hover:before {
  background: linear-gradient(180deg, #1e2022 0%, transparent 75%);
}

.gradient-overlay-half-bg-gradient-v1::before {
  background: linear-gradient(180deg, #484848 0%, transparent 75%);
}

.gradient-overlay-half-bg-gradient-v2::before {
  background-color: #20274d;
  opacity: .4;
}

.gradient-overlay-half-bg-gradient-v2.gradient-overlay:hover:before {
  background: none;
}

.gradient-overlay-half-bg-gradient-v3::before {
  background: linear-gradient(180deg, #1e2022 0%, transparent 50%);
}

.gradient-overlay-half-bg-gradient-v4::before {
  background: linear-gradient(160deg, #1e2022 -18%, transparent 90%);
}

.gradient-overlay-half-bg-gradient-v5 {
  z-index: 0;
}

.gradient-overlay-half-bg-gradient-v5::before {
  background: linear-gradient(to top, #131313 6%, rgba(19, 19, 19, 0.2) 72%, rgba(34, 34, 34, 0) 89%, rgba(44, 44, 44, 0) 100%);
  z-index: 0;
}

.gradient-overlay-half-bg-gradient-v6 {
  z-index: 0;
}

.gradient-overlay-half-bg-gradient-v6::before {
  background: linear-gradient(to top, #131313 6%, rgba(19, 19, 19, 0.2) 72%, rgba(34, 34, 34, 0) 89%, rgba(44, 44, 44, 0) 100%);
  z-index: 0;
  border-radius: 0.188rem;
}

.gradient-overlay-half-bg-gradient-v7::before {
  background: linear-gradient(to top, #131313 6%, rgba(19, 19, 19, 0.2) 72%, rgba(34, 34, 34, 0) 89%, rgba(44, 44, 44, 0) 100%);
  z-index: inherit;
}

.gradient-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #297cbb;
  opacity: .0;
}

.gradient-overlay:hover:after {
  opacity: .4;
  z-index: -1;
  transition-duration: 0.5s;
}

.gradient-overlay-half-bg-orange::before {
  background-color: #ff9500;
  opacity: .85;
}

[class*="gradient-overlay-half-bg-dark"]::before {
  background-color: #000000;
  opacity: .7;
}

.gradient-overlay-half-bg-dark-v1::before {
  opacity: .6;
}

.gradient-overlay-half-bg-blue-light::before {
  background-color: #297cbb;
  opacity: .8;
}

.gradient-overlay-half-bg-grayish-blue::before {
  background-color: #3b444f;
  opacity: .8;
}

.gradient-overlay-half-bg-blue::before {
  background-color: #7eadfb;
  opacity: 0.4;
}

.gradient-overlay-half-bg-violet::before {
  background-color: rgba(73, 16, 128, 0.64);
}

.gradient-overlay-half-black-gradient::before {
  background-color: #18181a;
  opacity: 0.5;
}

.gradient-overlay-half-gray-gradient::before {
  background-color: #3b444f;
  opacity: 0.6;
}

.gradient-overlay-half-blue-gradient::before {
  background-color: #1a2b49;
  opacity: 0.4;
}

.gradient-overlay-half-sapphire-gradient::before {
  background-color: #002568;
  opacity: 0.7;
}

.gradient-overlay-half-sapphire-gradient::before {
  background-color: #002568;
  opacity: 0.7;
}

.gradient-overlay-half-bg-violet-light::before {
  background-color: #ac5ef1;
  opacity: 0.7;
}

.gradient-overlay-half-white-gradient::before {
  background-color: #ffffff;
  opacity: 0.15;
}

.gradient-overlay-half-bg-catalina-blue:before {
  background-color: #16142D;
  opacity: .4;
}

.gradient-overlay-half-bg-charcoal-gray:before {
  background-color: #484848;
  opacity: 0.5;
}

.gradient-overlay-half-bg-white::before {
  background-color: #fff;
  opacity: 1;
}

.gradient-overlay-half-bg-white:hover:before {
  opacity: 0;
}

.gradient-overlay-half-bg-white:hover i, .gradient-overlay-half-bg-white:hover h6, .gradient-overlay-half-bg-white:hover span {
  color: #fff !important;
}

.collapsed.text-gradient::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, transparent 0%, #ffffff 50%);
  opacity: .7;
  width: 100%;
  height: 80px;
  bottom: 30px;
}

.u-media-viewer__dark::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(11, 13, 16, 0.8);
  border-radius: 3px;
}

/*------------------------------------
  Links
------------------------------------*/
.link {
  display: inline-block;
}

.link__icon {
  position: relative;
  line-height: .7;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 1.375rem;
  height: 1.375rem;
  font-size: 1rem;
  color: #297cbb;
  background-color: rgba(41, 124, 187, 0.1);
  border-radius: 50%;
  transition: 0.3s;
}

.link__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.link:hover .link__icon {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* Collapse */
.link-collapse[aria-expanded="false"] .link-collapse__default {
  display: inline-block;
}

.link-collapse[aria-expanded="false"] .link-collapse__active {
  display: none;
}

.link-collapse[aria-expanded="true"] .link-collapse__default {
  display: none;
}

.link-collapse[aria-expanded="true"] .link-collapse__active {
  display: inline-block;
}

/* Link Light */
.link-light {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.7);
}

.link-light:hover {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.7);
}

/* Link Muted */
.link-muted {
  color: #8c98a4;
  border-bottom: 1px dashed #97a4af;
}

.link-muted:hover {
  border-color: #1b527c;
}

.link-muted__toggle-default {
  display: inline-block;
}

.link-muted__toggle-toggled {
  display: none;
}

.link-muted.toggled .link-muted__toggle-default {
  display: none;
}

.link-muted.toggled .link-muted__toggle-toggled {
  display: inline-block;
}

/* Link White */
.link-white {
  color: #fff;
  border-bottom: 1px dashed #fff;
}

.link-white:hover {
  color: #fff;
}

.link-collapse-custom[aria-expanded="false"] {
  position: static;
}

.link-collapse-custom[aria-expanded="false"]::before {
  background-image: linear-gradient(180deg, transparent 40%, #fff 99%);
  background-repeat: repeat-x;
  z-index: 1;
  bottom: 74px;
  height: auto;
}

/*------------------------------------
  Fill Colors
------------------------------------*/
.fill-none {
  fill: none !important;
}

.fill-white {
  fill: #fff !important;
}

.fill-dark {
  fill: #3b444f !important;
}

.fill-primary {
  fill: #297cbb !important;
}

.fill-primary-lighter {
  fill: #19a0ff !important;
}

.fill-primary-darker {
  fill: #196eff !important;
}

.fill-success {
  fill: #00c9a7 !important;
}

.fill-success-lighter {
  fill: #13d7b6 !important;
}

.fill-info {
  fill: #00dffc !important;
}

.fill-danger {
  fill: #de4437 !important;
}

.fill-danger-lighter {
  fill: #ec5245 !important;
}

.fill-warning {
  fill: #ffc107 !important;
}

.fill-warning-lighter {
  fill: #fdd14e !important;
}

.fill-warning-darker {
  fill: #efa02e !important;
}

.fill-gray-200 {
  fill: #f8fafd !important;
}

.fill-gray-300 {
  fill: #e7eaf3 !important;
}

.fill-gray-400 {
  fill: #bdc5d1 !important;
}

.fill-gray-500 {
  fill: #97a4af !important;
}

.fill-gray-600 {
  fill: #8c98a4 !important;
}

/*------------------------------------
  Stop Colors
------------------------------------*/
.stop-color-white {
  stop-color: #fff !important;
}

.stop-color-primary {
  stop-color: #297cbb !important;
}

.stop-color-primary-darker {
  stop-color: #196eff !important;
}

.stop-color-primary-lighter {
  stop-color: #19a0ff !important;
}

.stop-color-warning {
  stop-color: #ffc107 !important;
}

.stop-color-danger {
  stop-color: #de4437 !important;
}

.stop-color-info {
  stop-color: #00dffc !important;
}

.stop-color-indigo {
  stop-color: #2d1582 !important;
}

/*------------------------------------
  Stroke Colors
------------------------------------*/
.stroke-white {
  stroke: #fff !important;
}

.stroke-primary {
  stroke: #297cbb !important;
}

.stroke-primary-lighter {
  stroke: #19a0ff !important;
}

.stroke-primary-darker {
  stroke: #196eff !important;
}

.stroke-success {
  stroke: #00c9a7 !important;
}

.stroke-danger {
  stroke: #de4437 !important;
}

.stroke-warning {
  stroke: #ffc107 !important;
}

.stroke-info {
  stroke: #00dffc !important;
}

.stroke-gray-200 {
  stroke: #f8fafd !important;
}

.stroke-gray-300 {
  stroke: #e7eaf3 !important;
}

.stroke-gray-400 {
  stroke: #bdc5d1 !important;
}

.stroke-gray-500 {
  stroke: #97a4af !important;
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-img-hero-bottom {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.bg-img-hero-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-img-hero-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

/*------------------------------------
  Background Colors
------------------------------------*/
.bg-color-1 {
  background-color: #5392f9;
}

.bg-color-2 {
  background-color: #ebf0f7;
}

.bg-primary-darken {
  background-color: #0f2e46;
}

/*------------------------------------
  Borders
------------------------------------*/
.rounded-pill {
  border-radius: 6.1875rem;
}

.rounded-xs {
  border-radius: 0.188rem;
}

.rounded-top-pill {
  border-top-left-radius: 6.1875rem;
  border-top-right-radius: 6.1875rem;
}

.rounded-right-pill {
  border-top-right-radius: 6.1875rem;
  border-bottom-right-radius: 6.1875rem;
}

.rounded-bottom-pill {
  border-bottom-right-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.rounded-left-pill {
  border-top-left-radius: 6.1875rem;
  border-bottom-left-radius: 6.1875rem;
}

.rounded-top-left-pill {
  border-top-left-radius: 6.1875rem;
}

.rounded-bottom-left-pill {
  border-bottom-left-radius: 6.1875rem;
}

.rounded-pseudo {
  border-radius: 0.3125rem;
}

.rounded-pseudo::before, .rounded-pseudo::after {
  border-radius: 0.3125rem;
}

.rounded-top-pseudo {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

.rounded-top-pseudo::before, .rounded-top-pseudo::after {
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}

@media (min-width: 1200px) {
  .border-xl-0 {
    border: 0 !important;
  }
  .border-xl-top-0 {
    border-top: 0 !important;
  }
  .border-xl-right-0 {
    border-right: 0 !important;
  }
  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xl-left-0 {
    border-left: 0 !important;
  }
  .border-xl {
    border: 1px solid #e7eaf3 !important;
  }
  .border-xl-top {
    border-top: 1px solid #e7eaf3 !important;
  }
  .border-xl-right {
    border-right: 1px solid #e7eaf3 !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #e7eaf3 !important;
  }
  .border-xl-left {
    border-left: 1px solid #e7eaf3 !important;
  }
}

@media (min-width: 992px) {
  .border-lg-0 {
    border: 0 !important;
  }
  .border-lg-top-0 {
    border-top: 0 !important;
  }
  .border-lg-right-0 {
    border-right: 0 !important;
  }
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-lg-left-0 {
    border-left: 0 !important;
  }
  .border-lg {
    border: 1px solid #e7eaf3 !important;
  }
  .border-lg-top {
    border-top: 1px solid #e7eaf3 !important;
  }
  .border-lg-right {
    border-right: 1px solid #e7eaf3 !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #e7eaf3 !important;
  }
  .border-lg-left {
    border-left: 1px solid #e7eaf3 !important;
  }
}

.border-color-white {
  border-color: rgba(255, 255, 255, 0.149) !important;
}

.border-color-3 {
  border-color: #ebf0f7;
}

.border-color-1 {
  border-color: #eaeaea !important;
}

.border-color-7 {
  border-color: #e4e4e4 !important;
}

.border-color-8 {
  border-color: #ebf0f7 !important;
}

.border-color-13 {
  border-color: #b3b1ae !important;
}

.border-color-11 {
  border-color: #dce3ec !important;
}

.border-color-2 {
  border-color: #67747c;
}

.border-color-14 {
  border-color: #3c3c3c !important;
}

@media (min-width: 992px) {
  .border-lg-bottom {
    border-bottom: 1px solid #ebf0f7;
  }
}

.destination {
  position: relative;
}

.destination::after {
  content: '';
  height: 1px;
  width: 50px;
  background-color: #fff;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.border-width-2 {
  border-width: 2px !important;
}

.border-width-3 {
  border-width: 3px !important;
}

.rounded-border {
  border-radius: 0.188rem;
}

.rounded-border::before, .rounded-border::after {
  border-radius: 0.188rem;
}

.border-radius-2 {
  border-radius: 2px !important;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-bottom {
  border-bottom: 1px solid #ebf0f7;
}

@media (min-width: 576px) {
  .border-bottom-sm {
    border-bottom: 1px solid #ebf0f7;
  }
}

@media (min-width: 768px) {
  .border-bottom-md {
    border-bottom: 1px solid #ebf0f7;
  }
}

@media (min-width: 992px) {
  .border-bottom-lg {
    border-bottom: 1px solid #ebf0f7;
  }
}

@media (min-width: 1200px) {
  .border-bottom-xl {
    border-bottom: 1px solid #ebf0f7;
  }
}

@media (min-width: 1480px) {
  .border-bottom-wd {
    border-bottom: 1px solid #ebf0f7;
  }
}

.border-bottom-1 {
  border-bottom: 2px solid #c6cdd6 !important;
}

/*------------------------------------
  Position Spaces
------------------------------------*/
.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.top-90 {
  top: 90%;
}

.bottom-0 {
  bottom: 0;
}

.left-n100 {
  left: -100%;
}

.left-0 {
  left: 0;
}

.bottom-n66 {
  bottom: -66px;
}

.bottom-n86 {
  bottom: -86px;
}

@media (min-width: 768px) {
  .top-md-0 {
    top: 0;
  }
  .right-md-0 {
    right: 0;
  }
  .bottom-md-0 {
    bottom: 0;
  }
  .left-md-0 {
    left: 0;
  }
  .position-md-absolute {
    position: absolute;
  }
}

@media (min-width: 992px) {
  .top-lg-0 {
    top: 0;
  }
  .right-lg-0 {
    right: 0;
  }
  .bottom-lg-0 {
    bottom: 0;
  }
  .left-lg-0 {
    left: 0;
  }
  .position-lg-absolute {
    position: absolute;
  }
}

.btn-position {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: translateX(23px);
          transform: translateX(23px);
}

/*------------------------------------
  Content Centered
------------------------------------*/
.content-centered-y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

@media (min-width: 768px) {
  .content-centered-y--md {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

@media (min-width: 992px) {
  .content-centered-y--lg {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

.flex-center-between {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.flex-content-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.flex-horizontal-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

/* Vertical Center Alignment */
.position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (min-width: 1200px) {
  .position-50-horizontal {
    -webkit-transform: translate(-35%, 0);
            transform: translate(-35%, 0);
    transition: all 0.2s ease-in-out;
  }
}

@media (min-width: 1200px) {
  .position-50-hover:hover .position-50-horizontal {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

/*------------------------------------
  Margin Spacing
------------------------------------*/
.ml-n2 {
  margin-left: -.5rem;
}

.ml-n3 {
  margin-left: -1rem;
}

.mr-n2 {
  margin-right: -.5rem;
}

.mt-n1 {
  margin-top: -.25rem;
}

.mt-n5 {
  margin-top: -2rem;
}

.mt-n6 {
  margin-top: -2.5rem;
}

.mt-n9 {
  margin-top: -4rem;
}

.mt-n23 {
  margin-top: -10rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n9 {
  margin-bottom: -4rem;
}

/* Gutters X */
.mx-gutters-1 {
  margin-right: -.25rem;
  margin-left: -.25rem;
}

.mx-gutters-1 > .col,
.mx-gutters-1 > [class*="col-"] {
  padding-right: .25rem;
  padding-left: .25rem;
}

.mx-gutters-2 {
  margin-right: -.5rem;
  margin-left: -.5rem;
}

.mx-gutters-2 > .col,
.mx-gutters-2 > [class*="col-"] {
  padding-right: .5rem;
  padding-left: .5rem;
}

/*------------------------------------
  Opacity
------------------------------------*/
.opacity-md {
  opacity: .2;
}

.opacity-4 {
  opacity: .4;
}

.opacity-6 {
  opacity: .6;
}

.opacity-7 {
  opacity: .7;
}

.opacity-9 {
  opacity: .9;
}

/*------------------------------------
  Overflow
------------------------------------*/
@media (min-width: 992px) {
  .overflow-lg-visible {
    overflow: visible !important;
  }
}

/*------------------------------------
  Box Shadow
------------------------------------*/
.shadow-primary-lg {
  box-shadow: 0 0 50px rgba(41, 124, 187, 0.4) !important;
}

.shadow-soft {
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, 0.25) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-hover-2:hover, .shadow-hover-2:active, .shadow-hover-2:focus {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
}

.shadow-hover-2.tab-card:hover {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.tab-shadow {
  box-shadow: 0px 14px 50px 0px rgba(32, 32, 32, 0.15);
}

.shadow-hover-3:hover {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.slick-shadow {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.shadow-5 {
  box-shadow: 0px 0px 50px 0px rgba(32, 32, 32, 0.15);
}

.hover-shadow-none:hover {
  box-shadow: none;
}

.shadow-6 {
  box-shadow: 0px 1px 4px 0px rgba(27, 32, 50, 0.3);
}

/*------------------------------------
  Width
------------------------------------*/
.min-width-3 {
  min-width: 1rem !important;
}

.min-width-4 {
  min-width: 1.5rem !important;
}

.min-width-200 {
  min-width: 12.5rem;
}

.max-width-3 {
  max-width: 1rem !important;
}

.max-width-4 {
  max-width: 1.5rem !important;
}

.max-width-5 {
  max-width: 2rem !important;
}

.max-width-6 {
  max-width: 2.5rem !important;
}

.max-width-8 {
  max-width: 3.5rem;
}

.max-width-9 {
  max-width: 4rem;
}

.max-width-10 {
  max-width: 4.5rem;
}

.max-width-15 {
  max-width: 7rem;
}

.max-width-27 {
  max-width: 13rem;
}

.max-width-35 {
  max-width: 17rem;
}

.max-width-40 {
  max-width: 19.5rem;
}

.max-width-40 {
  max-width: 2.5rem;
}

.max-width-960 {
  max-width: 60rem;
}

@media (min-width: 1200px) {
  .max-width-960 {
    min-width: 60rem;
  }
}

.min-width-112 {
  min-width: 7rem;
}

.min-width-170 {
  min-width: 10.625rem;
}

@media (min-width: 768px) {
  .min-width-290 {
    min-width: 18.125rem;
  }
}

@media (min-width: 768px) {
  .min-width-256 {
    min-width: 16rem;
  }
}

@media (min-width: 768px) {
  .max-width-155 {
    max-width: 9.6875rem !important;
  }
}

.min-width-130 {
  min-width: 8.125rem;
}

.min-width-220 {
  min-width: 13.75rem;
}

.min-width-220 {
  min-width: 13.75rem;
}

.min-width-250 {
  min-width: 15.625rem;
}

.min-width-284 {
  min-width: 17.75rem;
}

.max-width-580 {
  max-width: 36.25rem;
}

.width-200 {
  width: 12.5rem;
}

.width-30 {
  width: 1.875rem;
}

.max-width-235 {
  max-width: 14.688rem;
}

.min-width-178 {
  min-width: 11.125rem;
}

.min-width-300 {
  min-width: 18.75rem;
}

@media (max-width: 575.98px) {
  .w-max-100 {
    width: 100% !important;
  }
}

@media (max-width: 767.98px) {
  .w-max-sm-100 {
    width: 100% !important;
  }
}

@media (max-width: 991.98px) {
  .w-max-md-100 {
    width: 100% !important;
  }
}

@media (max-width: 1199.98px) {
  .w-max-lg-100 {
    width: 100% !important;
  }
}

@media (max-width: 1479.98px) {
  .w-max-xl-100 {
    width: 100% !important;
  }
}

.w-max-wd-100 {
  width: 100% !important;
}

.min-width-254 {
  min-width: 15.875rem;
}

@media (min-width: 1200px) {
  .min-width-xl-447 {
    min-width: 27.938rem;
  }
}

.width-51 {
  width: 3.188rem;
}

.width-135 {
  width: 8.438rem !important;
}

.width-25 {
  width: 1.563rem;
}

.width-35 {
  width: 2.188rem;
}

.width-36 {
  width: 36px;
}

.width-40 {
  width: 2.5rem;
}

.width-45 {
  width: 2.813rem;
}

.width-50 {
  width: 3.125rem;
}

.width-80 {
  width: 5rem;
}

.width-90 {
  width: 5.625rem;
}

.width-223 {
  width: 13.875rem !important;
}

.width-100vw {
  width: 100vw;
}

@media (min-width: 1200px) {
  .min-width-xl-100 {
    min-width: 100%;
  }
}

.max-width-650 {
  max-width: 38.125rem;
}

.max-width-810 {
  max-width: 50.625rem;
}

.width-260 {
  width: 16.25rem;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .width-lg-200 {
    width: 12.5rem !important;
  }
}

.width-60 {
  width: 3.75rem;
}

.max-height-200 {
  max-height: 12.5rem;
}

.width-70 {
  width: 4.375rem;
}

.width-190 {
  width: 11.875rem;
}

@media (min-width: 1480px) {
  .width-960 {
    width: 60rem;
  }
}

.min-width-190 {
  min-width: 11.875rem;
}

/*------------------------------------
  Height
------------------------------------*/
.height-30vh {
  height: 30vh;
}

.height-40vh {
  height: 40vh;
}

.height-60vh {
  height: 60vh;
}

.height-100vh {
  height: 100vh;
}

.height-570 {
  height: 35.625rem;
}

.height-100vh-72 {
  height: calc(100vh - 72px);
}

@media (min-width: 992px) {
  .height-100vh-main {
    height: calc(100vh - 194px);
  }
}

@media (min-width: 1200px) {
  .height-100vh-main {
    height: calc(100vh - 214px);
  }
}

@media (min-width: 992px) {
  .height-100vh-main-1 {
    height: calc(100vh - 80px);
  }
}

@media (min-width: 992px) {
  .height-100vh-main-2 {
    height: calc(100vh - 155px);
  }
}

.height-4 {
  height: .25rem;
}

.height-25 {
  height: 1.563rem;
}

.height-35 {
  height: 2.188rem;
}

.height-36 {
  height: 36px;
}

.height-40 {
  height: 2.5rem;
}

.height-45 {
  height: 2.813rem;
}

.height-50 {
  height: 3.125rem;
}

.height-51 {
  height: 3.188rem;
}

.height-54 {
  height: 54px;
}

.height-60 {
  height: 3.75rem;
}

.min-height-60 {
  min-height: 3.75rem;
}

.height-380 {
  height: 23.75rem;
}

@media (max-width: 991.98px) {
  .height-350-md-down {
    height: 21.875rem;
  }
}

.height-400 {
  height: 25rem;
}

.height-450 {
  height: 28.125rem !important;
}

.max-height-100vh {
  max-height: 100vh;
}

.min-height-100vh {
  min-height: 100vh;
}

.min-height-50 {
  min-height: 3.125rem;
}

.min-height-155 {
  min-height: 9.6875rem;
}

.min-height-200 {
  min-height: 12.5rem;
}

.min-height-230 {
  min-height: 14.375rem;
}

.min-height-240 {
  min-height: 15rem;
}

.min-height-350 {
  min-height: 21.875rem !important;
}

.min-height-300 {
  min-height: 18.75rem;
}

.min-height-380 {
  min-height: 23.75rem;
}

.min-height-450 {
  min-height: 28.125rem;
}

@media (min-width: 768px) {
  .min-height-458 {
    min-height: 28.625rem;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

@media (min-width: 768px) {
  .min-height-150 {
    min-height: 9.375rem;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.min-height-500 {
  min-height: 31.25rem;
}

.min-height-550 {
  min-height: 34.375rem;
}

.min-height-600 {
  min-height: 37.5rem;
}

.min-height-620 {
  min-height: 38.75rem;
}

@media (max-width: 1199.98px) {
  .min-height-650 {
    min-height: 25rem;
  }
}

@media (min-width: 1200px) {
  .min-height-650 {
    min-height: 40.625rem;
  }
}

@media (max-width: 1199.98px) {
  .min-height-750 {
    min-height: 30rem;
  }
}

@media (min-width: 1200px) {
  .min-height-750 {
    min-height: 46.875rem;
  }
}

@media (max-width: 1199.98px) {
  .min-height-800 {
    min-height: 30rem;
  }
}

@media (min-width: 1200px) {
  .min-height-800 {
    min-height: 50rem;
  }
}

@media (max-width: 991.98px) {
  .min-height-720 {
    min-height: 26rem;
  }
}

@media (min-width: 992px) {
  .min-height-720 {
    min-height: 45rem;
  }
}

@media (max-width: 1199.98px) {
  .min-height-955 {
    min-height: 30rem;
  }
}

@media (min-width: 1200px) {
  .min-height-955 {
    min-height: 59.688rem;
  }
}

.min-height-960 {
  min-height: 60rem;
}

@media (max-width: 1479.98px) {
  .min-height-960 {
    min-height: 32rem;
  }
}

.max-height-760 {
  max-height: 47.5rem;
}

@media (min-width: 1480px) {
  .height-760 {
    height: 47.5rem;
  }
}

@media (min-width: 576px) {
  .height-sm-100vh {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .height-md-60vh {
    height: 60vh;
  }
  .height-md-100vh {
    height: 100vh;
  }
  .min-height-md-100vh {
    min-height: 100vh;
  }
}

@media (min-width: 992px) {
  .height-lg-100vh {
    height: 100vh;
  }
  .min-height-lg-auto {
    min-height: auto;
  }
  .min-height-lg-100vh {
    min-height: 100vh;
  }
}

@media (min-width: 1200px) {
  .min-height-xl-100vh {
    min-height: 100vh;
  }
}

.height-110 {
  height: 110px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (min-width: 768px) {
  .width-md-50 {
    width: 50%;
  }
}

.m-b-n1 {
  margin-bottom: -1px;
}

/*------------------------------------
Typography Font Size
------------------------------------*/
.font-size-1 {
  font-size: 0.875rem;
}

.font-size-2 {
  font-size: 1.5rem;
}

.font-size-3 {
  font-size: 2rem;
}

.font-size-4 {
  font-size: 2.5rem;
}

.font-size-5 {
  font-size: 3rem;
}

.font-size-8 {
  font-size: 0.5rem;
}

.font-size-10 {
  font-size: 0.625rem;
}

.font-size-11 {
  font-size: 0.688rem;
}

.font-size-21 {
  font-size: 1.313rem;
}

.font-size-12 {
  font-size: 0.75rem;
}

.font-size-13 {
  font-size: 0.813rem;
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-15 {
  font-size: 0.938rem;
}

.font-size-16 {
  font-size: 1rem;
}

.font-size-17 {
  font-size: 1.063rem;
}

.font-size-18 {
  font-size: 1.125rem;
}

.font-size-19 {
  font-size: 1.188rem;
}

.font-size-20 {
  font-size: 1.25rem;
}

.font-size-21 {
  font-size: 1.313rem;
}

.font-size-23 {
  font-size: 1.438rem;
}

.font-size-24 {
  font-size: 1.5rem;
}

.font-size-27 {
  font-size: 1.688rem !important;
}

.font-size-30 {
  font-size: 1.875rem;
}

.font-size-35 {
  font-size: 2.188rem;
}

.font-size-40 {
  font-size: 2.5rem;
}

.font-size-50 {
  font-size: 3.125rem;
}

.font-size-41 {
  font-size: 2.563rem;
}

.font-size-60 {
  font-size: 3.75rem;
}

.font-size-22 {
  font-size: 1.375rem;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .font-size-lg-16 {
    font-size: 1rem;
  }
}

.font-size-25 {
  font-size: 1.563rem;
}

.font-size-80 {
  font-size: 5rem;
}

@media (max-width: 575.98px) {
  .font-size-xs-40 {
    font-size: 2.5rem !important;
  }
}

@media (max-width: 575.98px) {
  .font-size-xs-30 {
    font-size: 1.875rem !important;
  }
}

@media (max-width: 575.98px) {
  .font-size-xs-20 {
    font-size: 1.25rem;
  }
}

@media (max-width: 575.98px) {
  .font-size-xs-160 {
    font-size: 10rem !important;
  }
}

@media (max-width: 991.98px) {
  .font-size-md-down-5 {
    font-size: 3rem;
  }
}

@media (max-width: 1199.98px) {
  .font-size-lg-15 {
    font-size: 0.938rem;
  }
}

.font-size-200 {
  font-size: 12.5rem;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .font-size-lg-120 {
    font-size: 7.5rem;
  }
}

/*------------------------------------
Font Weight
------------------------------------*/
.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

@media (max-width: 575.98px) {
  .font-size-xs-20 {
    font-size: 1.25rem;
  }
}

@media (max-width: 575.98px) {
  .font-size-xs-28 {
    font-size: 1.75rem !important;
  }
}

/*------------------------------------
Line Height
------------------------------------*/
.text-lh-sm {
  line-height: 1.2;
}

.text-lh-md {
  line-height: 1.8;
}

.text-lh-1 {
  line-height: 1;
}

.text-lh-2 {
  line-height: 2;
}

.text-lh-3 {
  line-height: 3;
}

.text-lh-inherit {
  line-height: 1.5;
}

.text-lh-1dot4 {
  line-height: 1.4;
}

.text-lh-1dot6 {
  line-height: 1.6;
}

.text-lh-1dot7 {
  line-height: 1.7;
}

.text-lh-lg {
  line-height: 1.9;
}

/*------------------------------------
Letter Spacing
------------------------------------*/
.letter-spacing-2 {
  letter-spacing: 0.125rem;
}

.letter-spacing-3 {
  letter-spacing: 0.188rem;
}

/* section-title */
.section-title {
  font-size: 1.875rem;
  color: #3b444f;
  font-weight: 500;
}

.section-title::after {
  display: block;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #297cbb;
  content: " ";
  margin: 1.5rem auto 0;
}

/* section-title */
.section-title-1 {
  font-size: 1.875rem;
  color: #fff;
  font-weight: 700;
}

.section-title-1::after {
  display: block;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #fff;
  content: " ";
  margin: 1.5rem auto 0;
}

.color-hover:hover {
  background: #ceac61 !important;
}

.color-hover:hover i, .color-hover:hover h6 {
  color: #fff !important;
}

.text-hover:hover {
  background: #f8bd5b !important;
}

.text-hover:hover h6, .text-hover:hover span {
  color: #fff !important;
}

.text-underline {
  text-decoration: underline;
}

.line-clamp {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3.5;
  -webkit-box-orient: vertical;
}

.cursor-pointer {
  cursor: pointer;
}

/*------------------------------------
  Text Colors
------------------------------------*/
.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}

.text-white-70[href]:hover {
  color: #fff;
}

.text-primary-lighten {
  color: #63a8dd;
}

@media (max-width: 575.98px) {
  .text-primary-max {
    color: #297cbb;
  }
}

@media (max-width: 767.98px) {
  .text-primary-max-sm {
    color: #297cbb;
  }
}

@media (max-width: 991.98px) {
  .text-primary-max-md {
    color: #297cbb;
  }
}

@media (max-width: 1199.98px) {
  .text-primary-max-lg {
    color: #297cbb;
  }
}

@media (max-width: 1479.98px) {
  .text-primary-max-xl {
    color: #297cbb;
  }
}

.text-primary-max-wd {
  color: #297cbb;
}

.card-title.text-dark:hover {
  color: #297cbb !important;
}

.text-black {
  color: #3b444f;
}

.text-color-1 {
  color: #67747c;
}

.text-color-13 {
  color: #b3b1ae;
}

.text-gray-14 {
  color: #3c3c3c;
}

.green-lighter {
  color: #b0d12b;
}

.text-blue-lighter-1 {
  color: #5392f9;
}

.pagination-v2-arrow-color {
  color: #297cbb;
}

.text-red-lighter-1 {
  color: #ff5a5f;
}

.text-dark-1 {
  color: #1e2022;
}

.text-gray-16 {
  color: #18181b;
}

.list-tab .list-link:hover i, .list-tab .list-link:hover span {
  color: #297cbb !important;
}

.list-tab .list-link:active {
  border-left: 2px solid #297cbb !important;
}

.list-tab .list-link:active i, .list-tab .list-link:active span {
  color: #297cbb !important;
}

.text-hover-primary:hover {
  background-color: #297cbb;
}

.text-hover-primary:hover h6, .text-hover-primary:hover span {
  color: #fff !important;
}

.hover-bg-gray-1:hover {
  background-color: #ebf0f7;
  transition: .3s;
}

/*------------------------------------
  Transform
------------------------------------*/
.transform-rotate-1 {
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
  -webkit-transform-origin: 30% 20%;
          transform-origin: 30% 20%;
}

/*------------------------------------
  Transitions
------------------------------------*/
/* 3D Hover */
.transition-3d-hover {
  transition: all 0.2s ease-in-out;
}

.transition-3d-hover:hover, .transition-3d-hover:focus {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/*------------------------------------
  Z-Index
------------------------------------*/
.z-index-n1 {
  z-index: -1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-4 {
  z-index: 4;
}

.flex-shrink-md-1 {
  -ms-flex-negative: 1;
      flex-shrink: 1;
}

/*-------------------------------------------*\
    2.43. Vendor
\*-------------------------------------------*/
/*------------------------------------
  Animate.css
------------------------------------*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
            transform: translate3d(0, 25%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
            transform: translate3d(0, 25%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
}

/*------------------------------------
  Area Chart
------------------------------------*/
.u-area-chart {
  position: relative;
  line-height: 0;
}

.u-area-chart .ct-point {
  stroke-width: .375rem;
}

.u-area-chart__tooltip {
  color: #77838f;
  background-color: #fff;
  border-radius: 0.3125rem;
  box-shadow: 0 0.375rem 0.9375rem 0.0625rem rgba(140, 152, 164, 0.2);
  padding: 1rem;
  font-size: .75rem;
  font-weight: 400;
  font-family: "Rubik", Helvetica, Arial, sans-serif;
}

.u-area-chart__tooltip::before {
  border: 8px #fff;
  margin-left: -.5rem;
}

.u-area-chart__tooltip .chartist-tooltip-value {
  font-weight: 600;
}

/*------------------------------------
  Cubeportfolio
------------------------------------*/
.u-cubeportfolio .u-cubeportfolio__item {
  font-size: .875rem;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  margin: 0;
  padding-left: .75rem;
  padding-right: .75rem;
}

.u-cubeportfolio .u-cubeportfolio__item:first-child {
  padding-left: 0;
}

.u-cubeportfolio .u-cubeportfolio__item:last-child {
  padding-right: 0;
}

.u-cubeportfolio .u-cubeportfolio__item:hover {
  color: #3b444f;
}

.u-cubeportfolio .u-cubeportfolio__item.cbp-filter-item-active {
  color: #297cbb;
  background-color: transparent;
  border: transparent;
}

.u-cubeportfolio .cbp-l-loadMore-stop {
  pointer-events: none;
  color: #77838f;
}

.u-cubeportfolio .cbp-caption-zoom .cbp-caption-activeWrap {
  background-color: rgba(41, 124, 187, 0.9);
}

/*------------------------------------
  Hover Animation Effects
------------------------------------*/
.u-cubeportfolio--reveal-v1 .cbp-l-caption-body {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  transition: 0.4s ease-in-out;
}

.u-cubeportfolio--reveal-v1 .cbp-caption:hover .cbp-l-caption-body {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

/*------------------------------------
  Custombox
------------------------------------*/
.custombox-lock {
  overflow: auto;
}

.u-custombox-no-scroll.custombox-lock {
  margin-right: 1.0625rem;
  overflow: hidden;
}

/*------------------------------------
  Datatable
------------------------------------*/
.u-datatable .dataTables_length,
.u-datatable .dataTables_filter,
.u-datatable .dataTables_info,
.u-datatable .dataTables_paginate {
  display: none;
}

.u-datatable__striped .odd {
  background-color: #f8fafd;
}

.u-datatable .u-datatable__trigger {
  cursor: pointer;
}

.u-datatable .u-datatable__trigger-icon::before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f107";
  margin-right: .75rem;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.u-datatable__content td[colspan] {
  padding-left: 0;
  padding-right: 0;
}

.u-datatable .opened .u-datatable__trigger-icon::before {
  content: "\f106";
}

.u-datatable__thead-icon {
  display: block;
  line-height: .7;
  cursor: pointer;
  color: #bdc5d1;
}

.u-datatable__thead-icon:hover {
  color: #297cbb;
}

/*------------------------------------
  DatePicker
------------------------------------*/
.u-datepicker {
  position: relative;
}

.u-datepicker .flatpickr-calendar {
  top: 3.75rem !important;
  left: 0 !important;
  box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.125);
}

.u-datepicker .flatpickr-calendar::before, .u-datepicker .flatpickr-calendar::after {
  display: none;
}

.u-datepicker .flatpickr-months {
  position: relative;
  background-color: #297cbb;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
  padding: .75rem;
}

.u-datepicker .flatpickr-months .cur-month,
.u-datepicker .flatpickr-months .cur-year {
  color: #fff;
}

.u-datepicker .flatpickr-current-month,
.u-datepicker .flatpickr-prev-month,
.u-datepicker .flatpickr-next-month {
  padding: 0;
}

.u-datepicker .flatpickr-prev-month,
.u-datepicker .flatpickr-next-month {
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.u-datepicker .flatpickr-prev-month.flatpickr-prev-month,
.u-datepicker .flatpickr-next-month.flatpickr-prev-month {
  left: .75rem;
}

.u-datepicker .flatpickr-prev-month.flatpickr-next-month,
.u-datepicker .flatpickr-next-month.flatpickr-next-month {
  right: .75rem;
}

.u-datepicker .flatpickr-prev-month svg,
.u-datepicker .flatpickr-next-month svg {
  fill: rgba(255, 255, 255, 0.7);
}

.u-datepicker .flatpickr-prev-month:hover svg,
.u-datepicker .flatpickr-next-month:hover svg {
  fill: #fff;
}

.u-datepicker .flatpickr-weekday {
  color: #3b444f;
  text-transform: uppercase;
  font-weight: 500;
}

.u-datepicker .flatpickr-day {
  color: #3b444f;
  border-color: transparent;
}

.u-datepicker .flatpickr-day:focus, .u-datepicker .flatpickr-day:hover {
  color: #297cbb;
  background-color: rgba(41, 124, 187, 0.1);
}

.u-datepicker .flatpickr-day.inRange {
  background-color: #e7eaf3;
  box-shadow: -0.3125rem 0 0 #e7eaf3, 0.3125rem 0 0 #e7eaf3;
}

.u-datepicker .flatpickr-day.today {
  color: #fff;
  background-color: #297cbb;
}

.u-datepicker .flatpickr-day.prevMonthDay, .u-datepicker .flatpickr-day.nextMonthDay {
  color: #e7eaf3;
}

.u-datepicker .flatpickr-day.prevMonthDay:focus, .u-datepicker .flatpickr-day.prevMonthDay:hover, .u-datepicker .flatpickr-day.nextMonthDay:focus, .u-datepicker .flatpickr-day.nextMonthDay:hover {
  color: #97a4af;
  background-color: #e7eaf3;
}

.u-datepicker .flatpickr-day.disabled {
  color: #e7eaf3;
}

.u-datepicker .flatpickr-day.disabled:hover {
  color: #97a4af;
  background-color: #e7eaf3;
}

.u-datepicker .flatpickr-day.selected {
  color: #297cbb;
  background-color: transparent;
  border-color: #297cbb;
}

.u-datepicker .flatpickr-day.selected:focus.prevMonthDay, .u-datepicker .flatpickr-day.selected:focus.nextMonthDay, .u-datepicker .flatpickr-day.selected:hover.prevMonthDay, .u-datepicker .flatpickr-day.selected:hover.nextMonthDay {
  color: #297cbb;
  background-color: transparent;
}

.u-datepicker .flatpickr-day.selected.startRange, .u-datepicker .flatpickr-day.selected.endRange {
  color: #fff;
  background-color: #297cbb;
}

.u-datepicker .numInputWrapper span.arrowUp,
.u-datepicker .numInputWrapper span.arrowDown {
  display: none;
}

.u-datepicker .numInputWrapper:hover {
  background-color: transparent;
}

/* Positions */
.u-datepicker--top .flatpickr-calendar {
  top: auto !important;
  bottom: 3.5rem !important;
}

/*------------------------------------
  Dzsparallaxer
------------------------------------*/
.dzsparallaxer {
  color: inherit;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
.u-fancybox-theme .fancybox-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-fancybox-theme .fancybox-slide--iframe .fancybox-content {
  position: static;
}

.u-fancybox-theme .fancybox-bg {
  background-color: #3b444f;
}

.fancybox-controls--canzoomIn .fancybox-placeholder,
.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: inherit;
}

.fancybox-slide.has-animation {
  display: block;
}

.fancybox-is-sliding .fancybox-slide.has-animation,
.fancybox-slide--current.has-animation,
.fancybox-slide--next.has-animation,
.fancybox-slide--previous.has-animation {
  display: none;
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated,
.fancybox-slide--current.has-animation.animated,
.fancybox-slide--next.has-animation.animated,
.fancybox-slide--previous.has-animation.animated {
  display: block;
}

.u-blur-30 {
  -webkit-filter: blur(30px);
          filter: blur(30px);
}

/*------------------------------------
  Fileuploader
------------------------------------*/
/* Default Style */
.fileuploader {
  margin-top: 0;
}

/* Front Style */
.u-fileuploader-input {
  display: block;
  cursor: pointer;
  background: #fff;
  border: 2px dashed #e7eaf3;
  border-radius: 0.3125rem;
  padding: 2rem 0;
  text-align: center;
}

.u-fileuploader-input__icon {
  display: block;
  color: #297cbb;
  font-size: 2.5rem;
}

/* Dragging State */
.u-fileuploader-input__inner,
.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__icon {
  transition: all 0.3s ease-in-out;
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__inner {
  opacity: .6;
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__icon {
  -webkit-transform: translateY(18px);
          transform: translateY(18px);
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__btn {
  -webkit-transform: none;
          transform: none;
  opacity: 1;
}

/*------------------------------------
  Select
------------------------------------*/
.dropdown-select.bootstrap-select li .dropdown-item.opt {
  padding-left: 1.5rem;
}

.dropdown-select.bootstrap-select .bs-searchbox {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.dropdown-select.bootstrap-select .dropdown-header .text {
  font-size: 80%;
  font-weight: 500;
  color: #3b444f;
  text-transform: uppercase;
}

.dropdown-select .form-control {
  margin-bottom: .5rem;
}

.nav-select .bootstrap-select button {
  background-color: #fff !important;
  border: none;
  font-weight: 500;
  padding-bottom: 14px;
}

.nav-select-1 .bootstrap-select button {
  background-color: transparent !important;
}

/*------------------------------------
  Slick
------------------------------------*/
[data-lazy] {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.slick-cloned [data-scs-animation-in] {
  opacity: 0;
}

.u-slick {
  background: require("./svg/preloaders/circle-preloader.svg") no-repeat 50% 50%;
}

.u-slick .js-next,
.u-slick .js-prev {
  opacity: 0;
}

.u-slick.slick-initialized {
  background: none;
}

.u-slick.slick-initialized .js-slide,
.u-slick.slick-initialized .js-thumb {
  visibility: visible;
  height: auto;
  overflow: visible;
}

.u-slick.slick-initialized .js-next,
.u-slick.slick-initialized .js-prev {
  opacity: 1;
}

.u-slick .js-slide,
.u-slick .js-thumb {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.u-slick .js-slide:first-child,
.u-slick .js-thumb:first-child {
  height: auto;
}

/*------------------------------------
  Summernote Editor
------------------------------------*/
.note-modal-footer {
  height: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 20px 30px;
}

.note-modal-footer .note-btn {
  float: inherit;
}

.u-summernote-editor .note-editor.note-frame {
  border-color: #e7eaf3;
}

.u-summernote-editor .note-editor.note-frame .note-statusbar {
  background-color: #f8fafd;
  border-top-color: #e7eaf3;
}

.u-summernote-editor .note-toolbar {
  background-color: #f8fafd;
}

.u-summernote-editor .note-toolbar > .note-btn-group {
  border: 1px solid #e7eaf3;
  border-radius: 0.3125rem;
}

.u-summernote-editor .note-toolbar .note-dropdown-menu {
  min-width: 12.5rem;
  border-color: #e7eaf3;
  box-shadow: 0 0 35px rgba(140, 152, 164, 0.125);
  padding: .5rem;
}

.u-summernote-editor .note-toolbar .note-color .note-dropdown-menu {
  min-width: 21.5rem;
}

.u-summernote-editor .note-btn:focus, .u-summernote-editor .note-btn:hover {
  color: #297cbb;
  background-color: transparent;
}

.u-summernote-editor .note-dropdown-item:hover {
  background-color: #f8fafd;
}

/*------------------------------------
  Tagsinput
------------------------------------*/
.u-tagsinput .bootstrap-tagsinput {
  width: 100%;
  border-color: #e7eaf3;
  border-radius: 0.3125rem;
  box-shadow: none;
  padding: .25rem;
  padding-bottom: 0;
}

.u-tagsinput .bootstrap-tagsinput::before {
  content: "|";
  display: inline-block;
  width: 1px;
  line-height: 1;
  font-size: .625rem;
  opacity: 0;
  padding: .75rem 0;
}

.u-tagsinput .bootstrap-tagsinput .tag {
  position: relative;
  display: inline-block;
  font-size: .875rem;
  color: #77838f;
  background-color: rgba(119, 131, 143, 0.1);
  border-radius: 0.3125rem;
  padding: .25rem 1.875rem .25rem .75rem;
  margin-bottom: .25rem;
  margin-right: 0;
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role="remove"] {
  position: absolute;
  right: .5rem;
  top: 50%;
  color: #77838f;
  font-size: 1.25rem;
  outline: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role="remove"]::after {
  content: "\00d7";
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role="remove"]:hover {
  color: #3b444f;
  box-shadow: none;
}

/*------------------------------------
  Typed.js
------------------------------------*/
.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
          animation: blink 0.7s infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*--------------------------------------------
  File for your custom SCSS & CSS style here.
----------------------------------------------*/
